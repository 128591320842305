
import Header from "@/components/header";

import ProfileForm from "./profile-form";
import ProfilePicture from "./profile-picture";
import ProfilEmail from "./profile-email";

import { useCurrentWorkspace } from "@/hooks/useCurrentWorkspace";
import { useCurrentUser } from "@/hooks/api/common";

export default function Page() {
  const { currentUser } = useCurrentUser()
  const { currentWorkspace } = useCurrentWorkspace()

  return (
    <main className="relative place-items-stretch overflow-auto">
      <Header className="border-none"></Header>

      <div className="overflow-y-scroll pb-24">
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <div className="flex flex-col gap-1.5">
              <div className="text-2xl">Profil</div>
              <div className="text-sm text-muted-foreground">Verwalten Sie Ihr Profil</div>
            </div>

            <div className="my-6 w-full border-t border-solid" />

            {currentUser && (
              <div className="max-w-xl text-sm">
                <ProfilePicture
                  id={currentUser.id}
                  workspaceId={currentWorkspace.id}
                  profilePicture={currentUser.profilePicture}
                  className="mb-6"
                />
                <ProfilEmail email={currentUser.email} className="mb-6" />
                <ProfileForm
                  id={currentUser.id}
                  fullname={currentUser.fullname ?? undefined}
                  username={currentUser.username ?? undefined}
                  className="px-px"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
