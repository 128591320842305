import * as z from "zod";
import { stringToJSONSchema } from "@/lib/utils/jsonSchema";
import { MilestoneSchema } from "@/components/_domain/milestone";

export const ProgressSnapshotSchema = z.object({
  id: z.string(),
  objectiveId: z.string(),
  userId: z.string(),
  health: z.string(),
  keyResultId: z.string().optional().nullable(),
  value: z.number().optional().optional().nullable(),
  description: z.string().optional().nullable(),
  snapshotDate: z.string(),
  properties: stringToJSONSchema.pipe(z.unknown()),
});

export type ProgressSnapshot = z.infer<typeof ProgressSnapshotSchema>;

export const QuantitativeProgressSnapshotSchema = ProgressSnapshotSchema.extend({});
export type QuantitativeProgressSnapshot = z.infer<typeof QuantitativeProgressSnapshotSchema>;

export const MilestoneProgressSnapshotSchema = ProgressSnapshotSchema.extend({
  properties: stringToJSONSchema.pipe(
    z.object({
      milestones: z.array(MilestoneSchema),
    }),
  ),
});
export type MilestoneProgressSnapshot = z.infer<typeof MilestoneProgressSnapshotSchema>;
