import { FC, PropsWithChildren, ReactElement, useEffect, useMemo, useState } from "react";

import { Combobox, ComboboxOption } from "@/components/combobox";

import { StatusSelectDropdownItem } from "./status-select-dropdown-item";

import { useGetStatusListForTeam } from "@/hooks/api/common";

export interface StatusSelectDropdownProps extends PropsWithChildren {
  team: {
    id: string;
  };
  asChild?: boolean;
  selected?: {
    statusId: string;
  } | null;
  onSelect?: (status: { id: string } | null) => void;
  side?: "top" | "right" | "bottom" | "left";
}

export const StatusSelectDropdown: FC<StatusSelectDropdownProps> = ({
  team,
  children,
  asChild,
  side,
  onSelect,
  ...props
}) => {
  const [selectedStatus, setSelectedStatus] = useState(props.selected ? props.selected : null);
  const { statusListForTeam } = useGetStatusListForTeam({ teamId: team.id });

  const comboboxOptions = useMemo(() => {
    const statusOptions = statusListForTeam
      .slice()
      .sort((a, b) => {
        return a.order - b.order;
      })
      .flatMap((u) => {
        if (!u) {
          return [];
        }
        return {
          id: u.id,
          value: u.name,
        };
      });
    return [{ id: "-1", value: "Nicht zugewiesen" }].concat(statusOptions);
  }, [statusListForTeam]);

  const renderItem = (option: ComboboxOption): ReactElement => {
    const status = statusListForTeam.find((s) => s.id == option.id);

    const selected = (option.id == "-1" && selectedStatus == null) || selectedStatus?.statusId == option.id;
    return <StatusSelectDropdownItem status={status} selected={selected} />;
  };

  const handleUserSelected = (selectedOption: ComboboxOption | null) => {
    if (!selectedOption) {
      return;
    }

    if (selectedOption.id == "-1") {
      setSelectedStatus(null);
      if (onSelect) {
        onSelect(null);
      }
      return;
    }

    setSelectedStatus({ statusId: selectedOption.id });
    if (onSelect) {
      onSelect(selectedOption);
    }
  };

  useEffect(() => {
    if (props.selected) {
      setSelectedStatus(props.selected);
    }
  }, [props.selected]);

  if (!comboboxOptions) {
    return <></>;
  }

  return (
    <Combobox
      asChild={asChild}
      options={comboboxOptions}
      renderItem={renderItem}
      onSelect={handleUserSelected}
      side={side}
    >
      {children}
    </Combobox>
  );
};
