import * as React from "react";
import { FC, PropsWithChildren, useState } from "react";

import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";

export type ComboboxOption = {
  id: string;
  value: string;
};

export interface ComboboxProps extends PropsWithChildren {
  options: ComboboxOption[];
  renderItem: (status: ComboboxOption) => React.ReactElement;
  onSelect?: (status: ComboboxOption | null) => void;
  placeholder?: string;
  side?: "top" | "right" | "bottom" | "left";
  align?: "start" | "center" | "end";
  asChild?: boolean;
  allowDeselect?: boolean;
  modal?: boolean;
}

export const Combobox: FC<ComboboxProps> = ({
  options,
  placeholder,
  renderItem,
  onSelect,
  asChild,
  side,
  align,
  children,
  allowDeselect,
  modal,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState<ComboboxOption | null>(null);

  function handleSelectOption(option: ComboboxOption) {
    if (!allowDeselect && selectedOption && selectedOption.id === option.id) {
      return; // nothing changed
    }
    setSelectedOption(option);
    if (onSelect) {
      onSelect(option);
    }
    setOpen(false);
  }

  return (
    <Popover open={open} onOpenChange={setOpen} modal={modal ?? false}>
      <PopoverTrigger asChild={asChild}>{children}</PopoverTrigger>
      <PopoverContent className="pointer-events-auto p-0" side={side ?? "right"} align={align ?? "start"}>
        <Command>
          <CommandInput placeholder={placeholder ?? "Select Option ..."} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.id}
                  value={option.value}
                  onSelect={() => handleSelectOption(option)}
                  className="p-0"
                >
                  {renderItem(option)}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
