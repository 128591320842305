import { useMutation } from "@apollo/client";
import { updatePriorityMutationDocument, getPriorityQueryDocument } from "@/graphql/common";

export interface UseUpdatePriorityProps {
  priorityId: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useUpdatePriority = ({ priorityId, onCompleted, onError }: UseUpdatePriorityProps) => {
  const [updatePriority] = useMutation(updatePriorityMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: getPriorityQueryDocument,
        variables: { input: { priorityId: priorityId } },
      },
    ],
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }
  return {
    updatePriority,
  };
};
