import { NavLink } from "react-router-dom";
import { SidebarProps } from "./sidebar-props.interface";
import { cn } from "@/lib/utils";

export const SidebarNavElement = (props: SidebarProps) => {
  return (
    <div className="margin-1 relative rounded">
      <NavLink {...props} className={cn(props.className, "select-none text-sm")}>
        {({ isActive }) => (
          <span
            className={[
              "relative flex h-8 items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0 hover:bg-accent hover:text-accent-foreground",
              isActive ? "bg-accent text-accent-foreground" : "",
            ].join(" ")}
          >
            {props.children}
          </span>
        )}
      </NavLink>
    </div>
  );
};
