import { toast } from "@/components/_ui/use-toast.ts";
import { Slot, type AsChildProps } from "@/components/slot.tsx";

import { deleteUnitMutationDocument, getUnitListQueryDocument } from "@/graphql/common";
import { useMutation } from "@apollo/client";

type UnitDeleteActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  unit: {
    id: string;
    teamId: string;
  };
};

export const UnitDeleteAction = ({ unit, asChild, ...props }: UnitDeleteActionProps) => {
  const Comp = asChild ? Slot : "button";

  const [deleteUnit] = useMutation(deleteUnitMutationDocument, {
    onCompleted: () => {
      toast({
        title: "Label gelöscht",
        description: "Wir haben die Einheit erfolgreich gelöscht.",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler beim Löschen der Einheit aufgetreten. Stellen Sie sich, dass kein Ziel diese Einheit verwendet bevor Sie sie löschen.",
        variant: "error",
      });
    },
    refetchQueries: [
      {
        query: getUnitListQueryDocument,
        variables: { input: { teamId: unit.teamId } },
      },
    ],
  });

  async function handleClick() {
    await deleteUnit({ variables: { input: { id: unit.id } } });
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default UnitDeleteAction;
