import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";

import { cn } from "@/lib/utils";

import { useDropzone } from "react-dropzone";
import IconUpload from "@/components/_icons/IconUpload";

const baseStyle = {};

const focusedStyle = {};

const acceptStyle = {};

const rejectStyle = {};

export interface InputFileProps extends PropsWithChildren {
  className?: string;
  readOnly?: boolean;
  open?: boolean;
  fileList?: File[];
  onOpenChange?: (open: boolean) => void;
  onChange: (acceptedFiles: File[]) => void;
}

export const InputFile: FC<InputFileProps> = ({ className, onChange, children, ...props }) => {
  const [readOnly] = useState(!!props.readOnly);
  const [fileList, setFileList] = useState<File[]>(props.fileList ?? [])

  const { getRootProps, getInputProps, open, isFocused, isDragActive, isDragAccept, isDragReject, acceptedFiles, isFileDialogActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const style = useMemo(
    () => {
      return {
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }
    },
    [isFocused, isDragAccept, isDragReject],
  );

  useEffect(() => {
    if (props.open) {
      open()
    }
  }, [props.open]);

  useEffect(() => {
    if (props.onOpenChange) {
      props.onOpenChange(isFileDialogActive)
    }
  }, [isFileDialogActive]);

  useEffect(() => {
    if (isFileDialogActive) {
      return
    }
    setFileList(acceptedFiles)
  }, [acceptedFiles, isFileDialogActive]);


  useEffect(() => {
    onChange(fileList)
  }, [fileList]);

  if (readOnly) {
    <div className={className} style={{ ...style }}>
      {children}
    </div>
  }

  return (
    <div className={cn(["relative", className])} {...getRootProps({ style })}>
      <input {...getInputProps()}></input>
      {children}


      {isDragActive &&
        <div className="absolute w-full h-full bg-background/80 inset-0 flex flex-row items-center justify-center text-center gap-2">
          <div className="">
            <IconUpload />
          </div>
          <div className="text-sm">Datei hochladen</div>
        </div>
      }
    </div>


  );
};
