import { z } from "zod";

export const userSchema = z.object({
  id: z.string(),
  email: z.string(),
  username: z.string().optional().nullable(),
  fullname: z.string().optional().nullable(),
  profilePicture: z.string().optional().nullable(),
});

export type User = z.infer<typeof userSchema>;


export const teamMembershipSchema = z.object({
  id: z.string(),
  teamId: z.string(),
  userId: z.string(),
  status: z.string(),
  role: z.string(),
  user: userSchema.optional().nullable()
});

export type TeamMembership = z.infer<typeof teamMembershipSchema>;

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const teamSchema = z.object({
  id: z.string(),
  workspaceId: z.string(),
  name: z.string(),
  visibility: z.string(),
  logo: z.string().optional().nullable(),
  logoType: z.string().optional().nullable(),
  color: z.string().optional().nullable(),
  teamMembershipList: z.array(teamMembershipSchema),
});

export type Team = z.infer<typeof teamSchema>;

