import { FC } from "react";

import { cn } from "@/lib/utils";
import IconHeartbeat from "@/components/_icons/IconHeartbeat";

export interface FilterByHealthDisplayViewProps {
  className?: string;
}

export const FilterByHealthDisplayView: FC<FilterByHealthDisplayViewProps> = ({ className }) => {
  return (
    <>
      <IconHeartbeat className={cn(["h-4 w-4 text-foreground/60", className])} />
      <span className="text-xs">Health</span>
    </>
  );
};
