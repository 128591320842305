import { FC } from "react";

import { cn } from "@/lib/utils";

import { PriorityIcon } from "@/components/_domain/priority/priority-icon";
interface PriorityViewEmptyProps {
  logoOnly?: boolean;
  className?: string;
}

export const PriorityViewEmpty: FC<PriorityViewEmptyProps> = ({ ...props }) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <PriorityIcon className={cn(["h-4 w-4", props.className])} />
      {!props.logoOnly && <div>Nicht zugewiesen</div>}
    </div>
  );
};

export default PriorityViewEmpty;
