import { FC, useEffect, useMemo, useState } from "react";

import { Button } from "@/components/_ui/button";
import IconArrowTinyRight from "@/components/_icons/IconArrowTinyRight";
import IconDots from "@/components/_icons/IconDots";
import IconCircleGroup from "@/components/_icons/IconCircleGroup";

import { TeamLabelGroupActionButton } from "./team-label-group-action-button";

import { TeamLabelCreateForm } from "../label/team-label-create-form";
import { TeamLabelGroupEditForm } from "./team-label-group-edit-form";
import { useCurrentUser, useGetLabelGroup, useTeamById } from "@/hooks/api/common";

import { TeamLabelListItem } from "../label/team-label-list-item";

export interface TeamLabelGroupListItemProps {
  labelGroup: {
    id: string;
  };
  team: {
    id: string;
  };
  className?: string;
}

export const TeamLabelGroupListItem: FC<TeamLabelGroupListItemProps> = ({ team, ...props }) => {
  const [showChildren, setShowChildren] = useState(false);
  const [showCreateLabelForm, setShowCreateLabelForm] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { currentUser } = useCurrentUser();
  const { userHasEditRights } = useTeamById({ id: team.id });

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    return userHasEditRights(currentUser.id);
  }, [currentUser, userHasEditRights]);

  const { labelGroup } = useGetLabelGroup({ id: props.labelGroup.id });

  const childLabels = useMemo(() => {
    if (!labelGroup) {
      return [];
    }
    return labelGroup.labels.slice().sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a.title.localeCompare(b.title);
    });
  }, [labelGroup]);

  function handleLabelHasBeenAdded() {
    // do nothing – quite likely the user wants to create more than one label per group
  }

  useEffect(() => {
    if (labelGroup?.labels.length == 0) {
      setShowCreateLabelForm(true);
      setShowChildren(true);
    }
  }, [labelGroup]);

  useEffect(() => {
    if (editMode) {
      setShowCreateLabelForm(false);
    }
  }, [editMode]);

  useEffect(() => {
    if (showCreateLabelForm) {
      setEditMode(false);
    }
  }, [showCreateLabelForm]);

  if (!labelGroup) {
    return <></>;
  }

  return (
    <div className="my-1 flex flex-col gap-1 rounded border p-1">
      {!editMode && (
        <div className="flex flex-row items-center justify-between">
          <div className="w-7">
            <Button variant="ghost" size="icon" onClick={() => setShowChildren(!showChildren)}>
              <IconArrowTinyRight className={showChildren ? "rotate-90" : "rotate-0"} />
            </Button>
          </div>
          <div className="w-7">
            <IconCircleGroup className="mx-auto h-3.5 w-3.5" style={{ color: labelGroup.color }} />
          </div>
          <div className="grow">{labelGroup.title}</div>
          <div className="w-8">
            {hasEditRights && (
              <TeamLabelGroupActionButton
                labelGroup={labelGroup}
                team={team}
                handleEditLabelGroup={() => setEditMode(true)}
                handleAddLabelToGroup={() => setShowCreateLabelForm(true)}
                asChild
              >
                <Button variant="ghost" size="icon">
                  <IconDots />
                </Button>
              </TeamLabelGroupActionButton>
            )}
          </div>
        </div>
      )}
      {editMode && (
        <TeamLabelGroupEditForm
          labelGroup={labelGroup}
          onCanceled={() => {
            setEditMode(false);
          }}
          onSuccess={() => {
            setEditMode(false);
          }}
        />
      )}
      {showCreateLabelForm && (
        <TeamLabelCreateForm
          team={team}
          labelGroupId={labelGroup.id}
          defaultLabelColor={labelGroup.color}
          onCanceled={() => {
            setShowCreateLabelForm(false);
          }}
          onSuccess={() => {
            handleLabelHasBeenAdded();
          }}
          className="pl-1"
        />
      )}
      {showChildren && (
        <div>
          {childLabels &&
            childLabels.map((label) => {
              return label ? (
                <TeamLabelListItem key={label.id} label={label} team={team} className="border-none p-0 pr-1" />
              ) : (
                <></>
              );
            })}
        </div>
      )}
    </div>
  );
};
