import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getUnitQueryDocument } from "@/graphql/common";

export interface UseGetUnitProps {
  id: string;
}

export const useGetUnit = ({ id }: UseGetUnitProps) => {
  const queryResult = useQuery(getUnitQueryDocument, {
    variables: { input: { id } },
  });

  const unit = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.unit;
  }, [queryResult.data]);

  return {
    queryResult,
    unit,
  };
};
