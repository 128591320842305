import { cn } from "@/lib/utils";
import { FC, PropsWithChildren } from "react";

interface SheetMainContentProps extends PropsWithChildren {
  className?: string;
}

export const SheetMainContent: FC<SheetMainContentProps> = ({ children, className }) => {
  return (
    <div
      className={cn(
        ["mb-0.5 flex flex-initial grow flex-col overflow-y-auto px-3 py-3 text-secondary-foreground"],
        className,
      )}
    >
      {children}
    </div>
  );
};
