import { useMutation } from "@apollo/client";
import { createObjectiveMutationDocument } from "@/graphql/common";
import { updateCacheWithNewObjective } from "./_utils";

type ObjectiveFragment = {
  id: string;
};

export interface UseCreateObjectiveProps {
  teamId: string;
  onCompleted?: (o: ObjectiveFragment | undefined) => void;
  onError?: (e: Error) => void;
}

export const useCreateObjective = ({ onCompleted, onError }: UseCreateObjectiveProps) => {
  const [createObjective] = useMutation(createObjectiveMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const objective = data?.createObjective;
      if (!objective) {
        return;
      }
      updateCacheWithNewObjective(cache, objective);
    },
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const id = o?.createObjective?.id;

    if (onCompleted) {
      if (id) {
        onCompleted({ id: id });
      } else {
        onCompleted(undefined);
      }
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createObjective,
  };
};
