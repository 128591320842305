import { Slot, type AsChildProps } from "@/components/slot.tsx";

import { useMutation } from "@apollo/client";
import { getLabelInstancesQueryDocument, deleteLabelInstanceMutationDocument } from "@/graphql/common";

type OwnerType = "OBJECTIVE";

type RemoveLabelFromEntityActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  labelInstance: {
    id: string;
  };
  owner: {
    id: string;
    type: OwnerType;
  };
  onSuccess?: () => void;
  onError?: () => void;
};

export const RemoveLabelFromEntityAction = ({
  labelInstance,
  owner,
  onSuccess,
  onError,
  asChild,
  ...props
}: RemoveLabelFromEntityActionProps) => {
  const Comp = asChild ? Slot : "button";
  const [deleteLabelInstance] = useMutation(deleteLabelInstanceMutationDocument, {
    onCompleted: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      if (onError) {
        onError();
      }
    },
    refetchQueries: [
      {
        query: getLabelInstancesQueryDocument,
        variables: { input: { ownerId: owner.id } },
      },
    ],
  });

  async function handleClick() {
    await deleteLabelInstance({
      variables: { input: { id: labelInstance.id } },
    });
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default RemoveLabelFromEntityAction;
