import { graphql } from "../../generated/gql";

export const getLabelGroupListForWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query GetLabelGroupListForWorkspace($input: GetLabelGroupsForWorkspaceInput!) {
    labelGroupsForWorkspace(getLabelGroupsForWorkspaceInput: $input) {
      id
      title
      color
      allowMultiSelect
      workspaceId
      teamId
      labels {
        id
        title
        color
        labelGroupId
      }
    }
  }
`);

export const getLabelGroupListForTeamQueryDocument = graphql(/* GraphQL */ `
  query GetLabelGroupListForTeam($input: GetLabelGroupsForTeamInput!) {
    labelGroupsForTeam(getLabelGroupsForTeamInput: $input) {
      id
      title
      color
      allowMultiSelect
      workspaceId
      teamId
      labels {
        id
        title
        color
        labelGroupId
      }
    }
  }
`);

export const getLabelGroupQueryDocument = graphql(/* GraphQL */ `
  query GetLabelGroup($input: GetLabelGroupInput!) {
    labelGroup(getLabelGroupInput: $input) {
      id
      title
      color
      allowMultiSelect
      workspaceId
      teamId
      labels {
        id
        title
        color
        labelGroupId
      }
    }
  }
`);
