import { FC, PropsWithChildren, useMemo } from "react";

import { groupBy } from "@/lib/utils";

import { ALLOWED_STATUS_TYPES } from "@/components/_domain/status/status-type";

import { StatusTableSection } from "./status-table-section";
import { useGetStatusListForTeam } from "@/hooks/api/common";

export interface StatusTablePropsWithChildren extends PropsWithChildren {
  team: {
    id: string;
  };
  className?: string;
}

export const StatusTable: FC<StatusTablePropsWithChildren> = ({ team }) => {
  const { statusListForTeam } = useGetStatusListForTeam({ teamId: team.id });

  const groupedStati = useMemo(() => {
    return groupBy(statusListForTeam, (status) => status.type);
  }, [statusListForTeam]);

  if (!statusListForTeam) {
    return <></>;
  }

  return (
    <>
      {ALLOWED_STATUS_TYPES.map((type) => {
        const statusListForTeamForType = groupedStati.get(type) ?? [];
        return (
          <StatusTableSection
            key={type}
            team={team}
            statusType={type}
            statusList={statusListForTeamForType}
            className="mb-4"
          />
        );
      })}
    </>
  );
};

export default StatusTable;
