import { FC, useContext } from "react";

import { RichTextToolbarContext } from "./useToolbarContext";
import { ToolbarCommandType } from "./ToolbarCommands";

import { MenubarItem } from "@/components/_ui/menubar";

export interface ToolbarButtonDropdownItemProps {
  command: ToolbarCommandType;
}

export const ToolbarButtonDropdownItem: FC<ToolbarButtonDropdownItemProps> = ({ command }) => {
  const { editor } = useContext(RichTextToolbarContext);

  function clickHandler() {
    if (!editor) {
      return;
    }
    command.execute(editor);
  }

  return (
    <MenubarItem
      onClick={() => {
        clickHandler();
      }}
    >
      {command.element}
    </MenubarItem>
  );
};
