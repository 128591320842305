import { FC, useMemo } from "react";

import { Unit } from "../models/key-result.schema";

export interface ProgressChipProps {
  className?: string;
  unit?: Unit | null;
  value: number;
  label: string;
}

export const ProgressChip: FC<ProgressChipProps> = ({ unit, value, label }) => {
  const symbolString = useMemo(() => {
    if (!unit) {
      return "";
    }

    if (unit.symbol.length <= 2) {
      return unit.symbol;
    }

    return ` ${unit.symbol}`;
  }, [unit]);

  return (
    <div className="my-1 rounded-full bg-muted px-2 text-xxs">
      <div>
        {label}: {value}
        {symbolString}
      </div>
    </div>
  );
};

export default ProgressChip;
