import { FC, PropsWithChildren, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import NotFound from "../../NotFound";

import { useQuery } from "@apollo/client";
import { findTeamInviteQueryDocument } from "@/graphql/common";
import AcceptInviteCard from "./accept-invite-card";
import JoinWorkspaceFirstCard from "./join-workspace-first-card";

export default function Page() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  if (!token) {
    return <NotFound />;
  }

  return <IsValidTokenInnerPage token={token} />;
}

export type WorkspaceProp = {
  id: string;
  name: string;
  workspaceUrl: string;
  logo?: string | null | undefined;
};

export type TeamProp = {
  id: string;
  name: string;
};

export type UserProp = {
  id: string;
  email: string;
  fullname?: string | null | undefined;
  username?: string | null | undefined;
  profilePicture?: string | null | undefined;
  activated: boolean;
};

export interface IsValidTokenInnerPageProps extends PropsWithChildren {
  token: string;
}

const IsValidTokenInnerPage: FC<IsValidTokenInnerPageProps> = ({ token }) => {
  const { data, loading } = useQuery(findTeamInviteQueryDocument, { variables: { input: token } });

  const hasPendingWorkspaceMembership = useMemo(() => {
    if (!data) {
      return false;
    }
    return data.findTeamInvite.pendingWorkspaceMembership;
  }, [data]);


  const user = useMemo(() => {
    if (!data) {
      return null;
    }
    return data.findTeamInvite.user;
  }, [data]);

  const team = useMemo(() => {
    if (!data) {
      return null;
    }
    return data.findTeamInvite.team;
  }, [data]);

  const workspace = useMemo(() => {
    return data?.findTeamInvite?.team?.workspace ?? null;
  }, [data]);

  if (loading) {
    return <></>;
  }

  if (!user) {
    return <NotFound />;
  }

  if (!team) {
    return <NotFound />;
  }

  if (!workspace) {
    return <NotFound />;
  }

  return <InnerPage token={token} workspace={workspace} team={team} user={user} hasPendingWorkspaceMembership={hasPendingWorkspaceMembership} />;
};

export interface InnerPageProps extends PropsWithChildren {
  token: string;
  workspace: WorkspaceProp;
  team: TeamProp;
  user: UserProp;
  hasPendingWorkspaceMembership: boolean
}

const InnerPage: FC<InnerPageProps> = ({ token, workspace, team, user, hasPendingWorkspaceMembership }) => {
  return (
    <div className="flex h-screen w-screen items-center justify-center bg-background text-foreground">
      {hasPendingWorkspaceMembership && <JoinWorkspaceFirstCard token={token} workspace={workspace} team={team} user={user}></JoinWorkspaceFirstCard>}
      {!hasPendingWorkspaceMembership && <AcceptInviteCard token={token} workspace={workspace} team={team} user={user}></AcceptInviteCard>}

    </div>
  );
};
