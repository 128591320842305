import { useMemo } from "react";

import { groupBy } from "@/lib/utils";

import { useTeamListForUserInWorkspace } from "../index";

export interface UseGetStatusListForTeamsInWorkspaceProps {
  workspaceId: string;
  userId: string;
  teamList: { id: string }[];
}

export const useGetStatusListForTeamsInWorkspace = ({
  workspaceId,
  userId,
  teamList,
}: UseGetStatusListForTeamsInWorkspaceProps) => {
  const { activeTeamList } = useTeamListForUserInWorkspace({ workspaceId, userId });

  const filteredTeamStatusList = useMemo(() => {
    return activeTeamList.flatMap((activeTeam) => {
      if (!teamList.find((t) => t.id === activeTeam.id)) {
        return [];
      }
      return activeTeam.statusList;
    });
  }, [activeTeamList, teamList]);

  const statusList = useMemo(() => {
    return [...filteredTeamStatusList];
  }, [filteredTeamStatusList]);

  const statusListGroupedByType = useMemo(() => {
    return groupBy(statusList, (status) => status.type);
  }, [statusList]);

  const statusListGroupedByName = useMemo(() => {
    return groupBy(statusList, (status) => status.name);
  }, [statusList]);

  const statusListOfUniqueNames = useMemo(() => {
    // make sure there is only one status per unqiue name
    // basically this dedupes the list, if there are multiple priorities with the same name across teams
    return Array.from(statusListGroupedByName.keys()).flatMap((key) => {
      const statusGroupList = statusListGroupedByName.get(key);
      if (!statusGroupList) {
        return [];
      }
      if (statusGroupList.length == 0) {
        return [];
      }
      return statusGroupList[0];
    });
  }, [statusListGroupedByName]);

  return {
    statusList,
    statusListOfUniqueNames,
    statusListGroupedByType,
    statusListGroupedByName,
  };
};
