import { FC, PropsWithChildren } from "react";

export interface ProfilEmailPropsWithChildren extends PropsWithChildren {
  email: string;
  className?: string;
}
export const ProfilEmail: FC<ProfilEmailPropsWithChildren> = ({ email }) => {
  return (
    <div className="px-px pt-2">
      <div className="mb-2 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        E-Mail
      </div>
      <div className="mb-4 text-sm text-muted-foreground">{email}</div>
    </div>
  );
};

export default ProfilEmail;
