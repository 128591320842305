import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from "@/components/_ui/dropdown-menu";

import LabelItem from "./label-item";
import LabelGroupItem from "./label-group-item";
import AddLabelToEntityAction from "./actions/add-label-to-entity.action";
import RemoveLabelFromEntityAction from "./actions/remove-label-from-enitity";
import {
  useGetLabelInstanceList,
  useGetLabelGroupListForTeamsInWorkspace,
  useGetLabelListForTeamsInWorkspace,
} from "@/hooks/api/common";

export interface LabelSelectDropdownProps extends PropsWithChildren {
  owner: {
    id: string;
    type: "OBJECTIVE";
  };
  workspace: {
    id: string;
  };
  user: {
    id: string;
  };
  team: {
    id: string;
  };
  asChild?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const LabelSelectDropdown: FC<LabelSelectDropdownProps> = ({
  owner,
  workspace,
  team,
  user,
  asChild,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const { labelList } = useGetLabelListForTeamsInWorkspace({
    workspaceId: workspace.id,
    teamList: [{ id: team.id }],
    userId: user.id,
  });
  const { labelGroupList } = useGetLabelGroupListForTeamsInWorkspace({
    workspaceId: workspace.id,
    userId: user.id,
    teamList: [{ id: team.id }],
  });
  const { labelInstanceList } = useGetLabelInstanceList({ ownerId: owner.id });

  const filteredLabelList = useMemo(() => {
    return labelList
      .slice()
      .filter((l) => !l.labelGroupId)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.title.localeCompare(b.title);
      });
  }, [labelList]);

  useEffect(() => {
    const newOpenValue = props.open ? true : false;
    const valueHasChanged = newOpenValue != open;

    if (!valueHasChanged) {
      return;
    }

    setOpen(newOpenValue);
    if (props.onOpenChange) {
      props.onOpenChange(newOpenValue);
    }
  }, [props.open]);

  function getLabelInstance(labelId: string) {
    if (!labelInstanceList) {
      return undefined;
    }
    return labelInstanceList.find((li) => li.labelId == labelId);
  }

  function renderMenuItemForLabel(label: { id: string }) {
    const labelInstance = getLabelInstance(label.id);

    if (!labelInstance) {
      return (
        <AddLabelToEntityAction key={label.id} label={label} owner={owner} workspace={workspace} asChild>
          <DropdownMenuItem
            key={label.id}
            className="cursor-pointer p-0 text-foreground/60 hover:bg-accent hover:text-accent-foreground"
          >
            <LabelItem label={label} owner={owner} workspace={workspace} />
          </DropdownMenuItem>
        </AddLabelToEntityAction>
      );
    }

    return (
      <RemoveLabelFromEntityAction key={label.id} labelInstance={labelInstance} owner={owner} asChild>
        <DropdownMenuItem
          key={label.id}
          className="cursor-pointer p-0 text-foreground/60 hover:bg-accent hover:text-accent-foreground"
        >
          <LabelItem labelInstance={labelInstance} label={label} owner={owner} workspace={workspace} />
        </DropdownMenuItem>
      </RemoveLabelFromEntityAction>
    );
  }

  if (!labelInstanceList || !filteredLabelList || !labelGroupList) {
    return <></>;
  }

  return (
    <DropdownMenu open={open} onOpenChange={setOpen} modal={false}>
      <DropdownMenuTrigger asChild={asChild} disabled={labelGroupList.length == 0 && filteredLabelList.length == 0}>
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent side="left" sticky="always">
        {/* Show the label groups first */}
        {labelGroupList.map((group) => {
          return (
            <DropdownMenuSub key={group.id}>
              <DropdownMenuSubTrigger className="cursor-pointer p-0 text-foreground/60 hover:bg-accent hover:text-accent-foreground">
                <LabelGroupItem labelGroup={group} />
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {group.labels
                    .slice()
                    .sort((a, b) => {
                      const titleA = a?.title ?? "";
                      const titleB = b?.title ?? "";

                      return titleA.localeCompare(titleB);
                    })
                    .map((label) => (label ? renderMenuItemForLabel(label) : <></>))}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          );
        })}
        {/* Show the labels without groups at the bottom */}
        {filteredLabelList.map((label) => renderMenuItemForLabel(label))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default LabelSelectDropdown;
