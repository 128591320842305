import { FC } from "react";

import { cn } from "@/lib/utils";

import { useCreateStatusInstance, useDeleteStatusInstance, useUpdateStatusInstance } from "@/hooks/api/common";
import { StatusSelectDropdown, StatusViewOptional } from "@/components/_domain/status";

import { Button } from "@/components/_ui/button";

import { Row } from "@tanstack/react-table";
import { Objective } from "@/components/_domain/objective/models";

interface ObjectiveListCellStatusProps {
  row: Row<Objective>;
  objective: {
    id: string;
  };
  className?: string;
}

export const ObjectiveListCellStatus: FC<ObjectiveListCellStatusProps> = ({ row, className, ...props }) => {
  const objective = row.original;
  const statusInstance = row.original.statusInstance;
  const { updateStatusInstance } = useUpdateStatusInstance({}); // always prefer this one
  const { createStatusInstance } = useCreateStatusInstance({ ownerId: props.objective.id, ownerType: "OBJECTIVE" });
  const { deleteStatusInstance } = useDeleteStatusInstance({ ownerId: props.objective.id, ownerType: "OBJECTIVE" });

  function assignStatus(status: { id: string }) {
    if (!objective) {
      return;
    }

    if (statusInstance) {
      updateStatusInstance({
        variables: {
          input: {
            id: statusInstance.id,
            statusId: status.id,
          },
        },
      });
    } else {
      createStatusInstance({
        variables: {
          input: {
            statusId: status.id,
            ownerId: props.objective.id,
            ownerType: "OBJECTIVE",
            teamId: objective.teamId,
          },
        },
      });
    }
  }

  function removeStatus() {
    if (!statusInstance) {
      return;
    }
    deleteStatusInstance({
      variables: { input: { id: statusInstance.id } },
    });
  }

  const handleOnSelect = (status: { id: string } | null) => {
    if (!status) {
      return removeStatus();
    }
    assignStatus(status);
  };

  if (!objective) {
    return <></>;
  }

  return (
    <StatusSelectDropdown
      team={{ id: objective.teamId }}
      onSelect={handleOnSelect}
      selected={statusInstance}
      side="bottom"
      asChild
    >
      <Button
        variant="ghost"
        size="icon"
        className={cn([
          "overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0",
          className,
        ])}
      >
        <StatusViewOptional
          status={statusInstance ? { id: statusInstance.statusId } : null}
          logoOnly={true}
          className={cn(["h-3 w-3", statusInstance ? "" : "text-muted-foreground"])}
          // className="h-[0.95rem] w-[0.95rem] p-[0.05rem] group-hover:h-4 group-hover:w-4 group-hover:p-0"
        />
      </Button>
    </StatusSelectDropdown>
  );
};
