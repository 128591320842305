import { graphql } from "../../generated/gql";

export const createFileMutationDocument = graphql(/* GraphQL */ `
  mutation createFile($input: FileCreateInput!) {
    createFile(fileCreateInput: $input) {
      id
      ownerId
      ownerType
      name
      size
      createdAt
      updatedAt
    }
  }
`);

export const updateFileMutationDocument = graphql(/* GraphQL */ `
  mutation updateFile($input: FileUpdateInput!) {
    updateFile(fileUpdateInput: $input) {
      id
      ownerId
      ownerType
      name
      size
      createdAt
      updatedAt
    }
  }
`);

export const replaceFileMutationDocument = graphql(/* GraphQL */ `
  mutation replaceFile($input: FileReplaceInput!) {
    replaceFile(fileReplaceInput: $input) {
      id
      ownerId
      ownerType
      name
      size
      createdAt
      updatedAt
    }
  }
`);

export const deleteFileMutationDocument = graphql(/* GraphQL */ `
  mutation deleteFile($input: FileDeleteInput!) {
    deleteFile(fileDeleteInput: $input) {
      id
      ownerId
      ownerType
      name
      size
      createdAt
      updatedAt
    }
  }
`);

