import { useState } from "react";

import { Row } from "@tanstack/react-table";
import { Button } from "@/components/_ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { Dialog, DialogContent } from "@/components/_ui/dialog";

import { MoreHorizontal } from "lucide-react";

import { unitSchema } from "./model";
import DeleteUnitAction from "../../../../routes/settings/teams/:id/units/actions/unit-delete-action";
import UnitEditForm from "./unit-edit-form";

interface DatatableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DatatableRowActions<TData>({ row }: DatatableRowActionsProps<TData>) {
  const [dialogisOpen, setDialogisOpen] = useState(false);

  const unit = unitSchema.parse(row.original);

  const keyResults = row.getValue("ziele");
  const length = keyResults instanceof Array ? keyResults.length : 0;
  const deleteDisabled = length > 0;

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="flex w-full flex-row items-center justify-end">
            <Button variant="ghost" size="icon" className="h-8 w-8 self-end p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem className="cursor-pointer" onClick={() => setDialogisOpen(true)}>
            Bearbeiten
          </DropdownMenuItem>
          <DeleteUnitAction asChild={true} unit={unit}>
            <DropdownMenuItem disabled={deleteDisabled} className="cursor-pointer">
              Löschen
            </DropdownMenuItem>
          </DeleteUnitAction>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={dialogisOpen} onOpenChange={setDialogisOpen}>
        <DialogContent className="top-[1%] max-w-xl translate-y-[0%] md:top-[20%]">
          <UnitEditForm
            unit={unit}
            team={{ id: unit.teamId }}
            triggerCloseDialog={() => {
              setDialogisOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
