import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection, RangeSelection } from "lexical";

export type Editor = ReturnType<typeof useLexicalComposerContext>[0];

type RichTextToolbarType = {
  editor: Editor | null;
  selection: RangeSelection | null;
};

export interface RichTextToolbarContextProps extends PropsWithChildren {
  editor: Editor;
}

export const RichTextToolbarContext = createContext<RichTextToolbarType>({ editor: null, selection: null });

export const RichTextToolbarContextProvider = ({ editor, children }: RichTextToolbarContextProps) => {
  const [_editor] = useState<Editor>(editor);
  const [selection, setSelection] = useState<RangeSelection | null>(null);

  useEffect(() => {
    const unregisterListener = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) return;

        setSelection(selection);
      });
    });
    return unregisterListener;
  }, [editor]);

  return (
    <RichTextToolbarContext.Provider value={{ editor: _editor, selection: selection }}>
      {children}
    </RichTextToolbarContext.Provider>
  );
};

export default RichTextToolbarContextProvider;
