import { FC, PropsWithChildren, ReactElement, useEffect, useMemo, useState } from "react";

import { Combobox, ComboboxOption } from "@/components/combobox";

import { useGetWorkspaceList } from "@/hooks/api/superuser/useWorkspace";

import { WorkspaceSelectDropdownItem } from "./workspace-select-dropdown-item";

export interface WorkspaceSelectDropdownProps extends PropsWithChildren {
  asChild?: boolean;
  selected?: {
    id: string;
  } | null;
  side?: "top" | "right" | "bottom" | "left";
  onSelect?: (user: { id: string } | null) => void;
}

export const WorkspaceSelectDropdown: FC<WorkspaceSelectDropdownProps> = ({
  children,
  side,
  asChild,
  onSelect,
  ...props
}) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState(props.selected ? props.selected : null);
  const { workspaceList } = useGetWorkspaceList(); // only with superuser privileges

  useEffect(() => {
    if (props.selected) {
      setSelectedWorkspace(props.selected);
    }
  }, [props.selected]);

  // for faster retrieval
  const workspaceMap = useMemo(() => {
    return new Map(workspaceList.map((obj) => [obj.id, obj]));
  }, [workspaceList]);

  const comboboxOptions = useMemo(() => {
    const workspaceOptions = workspaceList
      .slice()
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        const _a = a.name ?? "";
        const _b = b.name ?? "";

        return _a.localeCompare(_b);
      })
      .flatMap((u) => {
        if (!u) {
          return [];
        }
        return {
          id: u.id,
          value: `${u.name} (${u.workspaceUrl})`,
        };
      });
    return [{ id: "-1", value: "Kein Workspace" }].concat(workspaceOptions);
  }, [workspaceList]);

  const renderItem = (status: ComboboxOption): ReactElement => {
    const workspace = workspaceMap.get(status.id);
    const selected = (status.id == "-1" && selectedWorkspace == null) || selectedWorkspace?.id == status.id;
    return <WorkspaceSelectDropdownItem workspace={workspace} selected={selected} />;
  };

  const handleWorkspaceSelected = (selectedOption: ComboboxOption | null) => {
    if (!selectedOption) {
      return;
    }

    if (selectedOption.id == "-1") {
      setSelectedWorkspace(null);
      if (onSelect) {
        onSelect(null);
      }
      return;
    }

    setSelectedWorkspace(selectedOption);
    if (onSelect) {
      onSelect(selectedOption);
    }
  };

  if (!comboboxOptions) {
    return <></>;
  }

  return (
    <Combobox
      asChild={asChild}
      options={comboboxOptions}
      renderItem={renderItem}
      onSelect={handleWorkspaceSelected}
      side={side}
    >
      {children}
    </Combobox>
  );
};
