import { graphql } from "../../generated/gql";

// use this to retrieve all workspace memberships for admin management
export const workspaceMembershipListForWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query workspaceMembershipListForWorkspace($input: String!) {
    workspaceMembershipListForWorkspace(workspaceId: $input) {
      userId
      workspaceId
      role
      status
      updatedAt
      createdAt
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const findWorkspaceInviteQueryDocument = graphql(/* GraphQL */ `
  query findWorkspaceInvite($input: String!) {
    findWorkspaceInvite(token: $input) {
      userId
      workspaceId
      user {
        id
        email
        username
        fullname
        profilePicture
        activated
      }
      workspace {
        id
        name
        workspaceUrl
        logo
      }
    }
  }
`);
