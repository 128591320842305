import { FC, useMemo, useState } from "react";

import { Button } from "@/components/_ui/button";
import { TeamLabelGroupList } from "./label-group/team-label-group-list";
import TeamLabelList from "./label/team-label-list";

import { TeamLabelCreateForm } from "./label/team-label-create-form";
import { TeamLabelGroupCreateForm } from "./label-group/team-label-group-create-form";
import { useCurrentUser, useTeamById } from "@/hooks/api/common";

export interface TeamLabelTablePropsWithChildren {
  team: {
    id: string;
  };
}

export const TeamLabelTable: FC<TeamLabelTablePropsWithChildren> = ({ ...props }) => {
  const [showCreateLabel, setShowCreateLabel] = useState(false);
  const [showCreateLabelGroup, setShowCreateLabelGroup] = useState(false);

  const { currentUser } = useCurrentUser();
  const { team, userHasEditRights } = useTeamById({ id: props.team.id });

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    return userHasEditRights(currentUser.id);
  }, [currentUser, userHasEditRights]);

  if (!team) {
    return <></>;
  }

  return (
    <div>
      <div>
        <p className="font-light text-muted-foreground">
          Verwenden Sie Kategorien und Gruppen von Kategorien, um Ziele in Ihrem Team zu organisieren und zu filtern.
          Die in diesem Abschnitt erstellten Kategorien sind für alle Mitglieder dieses Teams verfügbar.
        </p>
        <div className="my-8"></div>
        {hasEditRights && (
          <div className="flex shrink grow flex-row items-center justify-end gap-3">
            <div className="flex flex-row gap-6"></div>
            <Button
              size="xs"
              variant="outline"
              disabled={showCreateLabel || showCreateLabelGroup}
              onClick={() => {
                setShowCreateLabelGroup(true);
              }}
            >
              Neue Gruppe
            </Button>
            <Button
              size="xs"
              variant="default"
              disabled={showCreateLabel || showCreateLabelGroup}
              onClick={() => {
                setShowCreateLabel(true);
              }}
            >
              Neue Kategorie
            </Button>
          </div>
        )}
      </div>
      <div className="mt-10">
        {showCreateLabel && (
          <TeamLabelCreateForm
            team={team}
            onCanceled={() => {
              setShowCreateLabel(false);
            }}
            onSuccess={() => {
              setShowCreateLabel(false);
            }}
          />
        )}
        {showCreateLabelGroup && (
          <TeamLabelGroupCreateForm
            team={team}
            onCanceled={() => {
              setShowCreateLabelGroup(false);
            }}
            onSuccess={() => {
              setShowCreateLabelGroup(false);
            }}
          />
        )}
        <TeamLabelGroupList team={team} />
        <TeamLabelList team={team} />
      </div>
    </div>
  );
};
