import { FC, PropsWithChildren, useMemo, useState } from "react";

import { Button } from "@/components/_ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/_ui/dialog";
import LoadingDots from "@/components/loading-dots";

import AvatarWorkspace from "@/components/_domain/workspace/avatar-workspace";
import {
  useTeamById,
  useTeamMembershipList,
  useWorkspaceGetById,
  useWorkspaceMembershipList,
} from "@/hooks/api/common";
import { NotYetTeamMemberSelectDropdown } from "./not-yet-team-member-select-dropdown";

import { toast } from "@/components/_ui/use-toast";
import { createTeamInviteMutationDocument, teamMembershipsForTeamQueryDocument } from "@/graphql/common";
import { apolloClient } from "@/services/apollo.service/apollo.service";
import { TeamMemberSelectChip } from "./not-yet-team-member-select-dropdown/not-yet-team-member-select-chip";

interface UserFragment {
  id: string;
}

export interface TeamMemberInviteButtonPropsWithChildren extends PropsWithChildren {
  team: {
    id: string;
  };
  className?: string;
}

export const TeamMemberInviteButton: FC<TeamMemberInviteButtonPropsWithChildren> = ({ className, ...props }) => {
  const { team } = useTeamById({ id: props.team.id });
  const { workspace } = team ? useWorkspaceGetById({ id: team.workspaceId }) : { workspace: undefined };

  const [selectedUsers, setSelectedUsers] = useState<UserFragment[]>([]);
  const { activeOrPendingWorkspaceMembershipList } = useWorkspaceMembershipList({ workspaceId: workspace?.id });
  const { teamMembershipList } = useTeamMembershipList({ teamId: props.team.id });

  const notYetPartOfTeamUserList = useMemo(() => {
    return activeOrPendingWorkspaceMembershipList.flatMap((wm) => {
      const teamMembership = teamMembershipList.find((tm) => {
        return tm.userId == wm.userId;
      });

      // We only want to show users who are NOT yet part of this workspace
      if (teamMembership) {
        return [];
      }
      if (!wm.user) {
        return [];
      }

      return {
        isTeamMember: true,
        teamMembership: teamMembership,
        user: wm.user,
      };
    });
  }, [activeOrPendingWorkspaceMembershipList, teamMembershipList]);

  const [dialogisOpen, setDialogisOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function onDialogOpenChange(open: boolean) {
    setDialogisOpen(open);
  }

  function handleButtonPressed() {
    setDialogisOpen(true);
  }

  function handleUserSelected(selectedUsers: UserFragment[]) {
    setSelectedUsers([...selectedUsers]);
  }

  function handleUserRemoved(removedUser: UserFragment) {
    setSelectedUsers(selectedUsers.slice().filter((u) => u.id != removedUser.id));
  }

  async function handleSubmit() {
    if (!team) {
      return;
    }

    setLoading(true);

    for (const selectedUser of selectedUsers) {
      // fail silently if there is an error
      const {} = await apolloClient.mutate({
        mutation: createTeamInviteMutationDocument,
        fetchPolicy: "no-cache",
        variables: {
          input: {
            teamId: team.id,
            userId: selectedUser.id,
          },
        },
        refetchQueries: [
          {
            query: teamMembershipsForTeamQueryDocument,
            variables: {
              input: team.id,
            },
          },
        ],
      });
    }

    setLoading(false);

    toast({
      title: "Einladungen verschickt",
      description: `Wir haben die Einladung verschickt.`,
      variant: "success",
    });
  }

  if (!team) {
    return <></>;
  }

  if (!workspace) {
    return <></>;
  }

  return (
    <div className={className}>
      <Button variant="default" size="xs" onClick={handleButtonPressed}>
        Personen einladen
      </Button>

      <Dialog open={dialogisOpen} onOpenChange={onDialogOpenChange}>
        <DialogContent className="p-10 sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>
              <div className="flex flex-row items-center justify-start">
                {workspace && (
                  <>
                    <AvatarWorkspace workspace={workspace} className="mr-3" />
                    <span className="text-base font-normal">
                      Laden Sie Personen in das Team
                      <strong>{` ${team.name} `}</strong>
                      ein.
                    </span>
                  </>
                )}
              </div>
            </DialogTitle>
            <DialogDescription asChild>
              <div className="h-4 w-full border-b border-solid" />
            </DialogDescription>
          </DialogHeader>
          <div className="text-sm font-light">
            Nutzer müssen bereits Teil Ihres Workspaces sein, um sie einzuladen. Falls Sie neue Nutzer zu dem Workspace
            hinzufügen wollen, wenden Sie sich an einen der Administratoren Ihres Workspaces.
          </div>
          <div className="relative max-w-full font-light">
            <div className="items center flex flex-row flex-wrap gap-1">
              {selectedUsers.map((user) => {
                return (
                  <TeamMemberSelectChip
                    key={user.id}
                    user={user}
                    workspace={workspace}
                    onRemove={handleUserRemoved}
                  ></TeamMemberSelectChip>
                );
              })}
            </div>
            <div className="mt-4">
              {notYetPartOfTeamUserList.length > 0 && (
                <NotYetTeamMemberSelectDropdown
                  workspace={workspace}
                  team={team}
                  selectedUsers={selectedUsers}
                  onSelect={handleUserSelected}
                  side="bottom"
                  asChild
                >
                  <Button
                    variant="ghost"
                    size="xs"
                    className="group overflow-x-hidden border font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
                  >
                    {selectedUsers.length == 0 && <>Nutzer auswählen</>}
                    {selectedUsers.length > 0 && <>Nutzer hinzufügen</>}
                  </Button>
                </NotYetTeamMemberSelectDropdown>
              )}
              {notYetPartOfTeamUserList.length == 0 && (
                <div className="mb-8 text-sm">
                  Es befinden sich keine Nutzer in ihrem Workspace, die nicht bereits Teil dieses Teams sind. Fügen Sie
                  neue Nutzer zuerst zu Ihrem Workspace hinzu, bevor Sie sie zu Teams hinzufügen.
                </div>
              )}
            </div>
            <div className="flex flex-row justify-end">
              <Button
                type="submit"
                size="sm"
                disabled={loading || notYetPartOfTeamUserList.length == 0}
                style={{ minWidth: "120px" }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading && (
                  <span className="cursor-wait">
                    <LoadingDots />
                  </span>
                )}
                {!loading && <span>Absenden</span>}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TeamMemberInviteButton;
