import { FC, useState } from "react";

import { Dialog, DialogContent } from "@/components/_ui/dialog";
import { Button } from "@/components/_ui/button";


import IconPlus from "@/components/_icons/IconPlus";
import { TeamCreateForm } from "../team-create-form";




interface TeamCreateDialogProps {
  workspace: {
    id: string
  }
  isSuperuser?: boolean
}

export const TeamCreateDialog: FC<TeamCreateDialogProps> = ({workspace, isSuperuser}) => {
  const [dialogisOpen, setDialogisOpen] = useState(false);

  function onDialogOpenChange(open: boolean) {
    setDialogisOpen(open);
  }

  return (
    <>
      <Button
        variant="ghost"
        size="xs"
        className="shadow-sm h-7 rounded-md border px-2 text-xs text-foreground/75"
        onClick={() => setDialogisOpen(true)}
      >
        <IconPlus className="mr-2" />
        Team Erstellen
      </Button>
      <Dialog open={dialogisOpen} onOpenChange={onDialogOpenChange} modal={true}>
        <DialogContent className="top-[1%] max-h-[99vh] max-w-xl translate-y-[0%] overflow-y-scroll md:top-[10%] md:max-h-[80vh]">
          <TeamCreateForm
            workspace={workspace}
            isSuperuser={isSuperuser}
            triggerCloseDialog={() => {
              setDialogisOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
