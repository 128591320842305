import { Link } from "react-router-dom";
import LoginForm from "./login-form";

import AuthPageLayout from "../AuthPageLayout";

export default function Page() {
  return (
    <AuthPageLayout>
      <div className="flex flex-col items-center justify-center space-y-3 border-b px-4 py-6 pt-8 text-center sm:px-16">
        <h3 className="text-xl font-semibold">Anmelden</h3>
        <p className="text-sm">Um zu beginnen, melden Sie sich bitte an</p>
      </div>

      <LoginForm />

      <p className="mb-8 text-center text-sm ">
        Haben Sie Ihr Passwort vergessen?{" "}
        <Link to="/forgot-password" className="font-semibold">
          Passwort zurücksetzen.
        </Link>{" "}
      </p>
    </AuthPageLayout>
  );
}
