import { FC, useState } from "react";

import { Button } from "@/components/_ui/button";

import { InputFile } from "@/components/_domain/files/input-file";

import { FileUploader, FileUploadAction, UploadResult } from "@/components/_domain/files/file-uploader";
import { FileDataTable, columns } from "@/components/_domain/files/file-datatable";
import { useGetFileListByOwner } from "@/hooks/api/common/useFile";
import { Dialog, DialogContent } from "@/components/_ui/dialog";
import { RadioGroupItem, RadioGroup } from "@/components/_ui/radio-group";
import { Label } from "@/components/_ui/label";


interface ObjectiveDocumentsTabProps {
  workspace: {
    id: string;
  };
  objective: {
    id: string;
  };
}

export const ObjectiveDocumentsTab: FC<ObjectiveDocumentsTabProps> = ({ objective }) => {

  const { fileList } = useGetFileListByOwner({ ownerId: objective.id })

  const [addFileDialogOpen, setAddFileDialogOpen] = useState<boolean>(false);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState<boolean>(false);
  const [keepBoth, setKeepBoth] = useState<boolean>(false);
  const [startUpload, setStartUpload] = useState<boolean>(false);

  const [newFileList, setNewFileList] = useState<File[]>([]);
  const [uploadQeueFileList, setUploadQeueFileList] = useState<FileUploadAction[]>([]);

  const handleFilesAdded = (newFileList: File[]) => {
    setNewFileList(newFileList)

    if (newFileList.length == 0) {
      return
    }

    let hasDuplicate = false;
    const fileUploadActionList: FileUploadAction[] = [];
    for (const newFile of newFileList) {
      const oldFile = fileList.find((file) => {
        return file.name == newFile.name
      })

      if (oldFile) {
        hasDuplicate = true
      }

      fileUploadActionList.push({
        newFile: newFile,
        oldFileId: oldFile ? oldFile.id : undefined,
        conflictHandling: keepBoth ? "CREATE" : "REPLACE"
      })
    }

    if (!hasDuplicate) {
      setStartUpload(true)
      setUploadQeueFileList(fileUploadActionList);
    } else {
      setUploadQeueFileList([]);
      setDuplicateDialogOpen(true)
    }
  }

  function handleUploadOptionSelect() {

    const fileUploadActionList: FileUploadAction[] = [];
    for (const newFile of newFileList) {
      const oldFile = fileList.find((file) => file.name == newFile.name)

      fileUploadActionList.push({
        newFile: newFile,
        oldFileId: oldFile ? oldFile.id : undefined,
        conflictHandling: keepBoth ? "CREATE" : "REPLACE"
      })
    }

    setUploadQeueFileList(fileUploadActionList);
    setStartUpload(true)
    setDuplicateDialogOpen(false)
  }


  function handleFileUploadFinished(_result: UploadResult) {
    // do nothing for now
  }

  return <main className="relative place-items-stretch overflow-auto">
    <InputFile fileList={newFileList} onChange={handleFilesAdded} open={addFileDialogOpen} onOpenChange={setAddFileDialogOpen}>


      <FileDataTable columns={columns} data={fileList} defaultSort={[{ id: 'name', desc: false }]}>
        <Button
          size="xs"
          variant="ghost"
          className="border"
          onClick={() => { setAddFileDialogOpen(true) }}
        >
          Dokument Hochladen
        </Button>
      </FileDataTable>

    </InputFile>

    {startUpload &&
      <FileUploader owner={{ id: objective.id, ownerType: "OBJECTIVE" }} fileUploadList={uploadQeueFileList} onFileUploadFinished={handleFileUploadFinished} />
    }

    <Dialog open={duplicateDialogOpen} onOpenChange={setDuplicateDialogOpen}>
      <DialogContent>
        <div className=" sm:max-w-lg">
          <div>
            <span className="text-base font-normal">Upload Optionen </span>
          </div>
          <div className="relative max-w-full font-light">
            <p className="mt-4 mb-4 text-xs">
              Ein oder mehrere Dokumente mit demselben Namen sind an diesem Ort bereits vorhanden. Möchten Sie die vorhandenen Dokumente durch eine neue Version ersetzen oder beide Elemente beibehalten?
            </p>
            <div>
              <RadioGroup
                className=" space-y-2"
                defaultValue={keepBoth ? "keepBoth" : "replace"}
                onValueChange={(selectedValue: string) => {
                  if (selectedValue == "keepBoth") {
                    setKeepBoth(true)
                  } else {
                    setKeepBoth(false)
                  }
                }}>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="replace" id="r1" />
                  <Label htmlFor="r1">Bestehende Dokumente ersetzen</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="keepBoth" id="r2" />
                  <Label htmlFor="r2">Alle Dokumente behalten</Label>
                </div>
              </RadioGroup>
            </div>
          </div>
          <div className="flex flex-row flex-wrap mt-4">
            <div className="flex w-full flex-row items-center justify-end gap-2">
              <Button
                type="button"
                variant="outline"
                size="xs"
                onClick={() => {
                  () => {
                    setNewFileList([])
                    setDuplicateDialogOpen(false)
                  }
                }}
              >
                Abbrechen
              </Button>
              <Button
                type="button"
                variant="default"
                size="xs"
                onClick={handleUploadOptionSelect}
              >
                Hochladen
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>

  </main >;
};
