import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getStatusInstanceQueryDocument } from "@/graphql/common";

export interface UseGetStatusInstanceProps {
  id?: string;
}

export const useGetStatusInstance = ({ id }: UseGetStatusInstanceProps) => {
  const queryResult = useQuery(getStatusInstanceQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id,
  });

  const statusInstance = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.statusInstance;
  }, [queryResult.data]);

  return {
    queryResult,
    statusInstance,
  };
};
