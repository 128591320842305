export type KeyResultTypeIDs = "QUANTITATIVE_KEY_RESULT" | "MILESTONE_KEY_RESULT";

export type KeyResultType = {
  id: KeyResultTypeIDs;
  displayValue: string;
};

export const keyResultTypes: KeyResultType[] = [
  { id: "QUANTITATIVE_KEY_RESULT", displayValue: "Quantitativer Indikator" },
  { id: "MILESTONE_KEY_RESULT", displayValue: "Meileinstein Indikator" },
];
