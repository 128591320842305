import { useMutation } from "@apollo/client";
import {
  getPriorityInstanceQueryDocument,
  getPriorityInstancebyOwnerQueryDocument,
  updatePriorityInstanceMutationDocument,
} from "@/graphql/common";

export interface UseUpdatePriorityInstanceProps {
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useUpdatePriorityInstance = ({ onCompleted, onError }: UseUpdatePriorityInstanceProps) => {
  const [updatePriorityInstance] = useMutation(updatePriorityInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const priorityInstance = data?.updatePriorityInstance;
      if (!priorityInstance) {
        return;
      }
      cache.writeQuery({
        query: getPriorityInstanceQueryDocument,
        variables: {
          input: { id: priorityInstance.id },
        },
        data: {
          priorityInstance: priorityInstance,
        },
      });
      cache.writeQuery({
        query: getPriorityInstancebyOwnerQueryDocument,
        variables: {
          input: { ownerId: priorityInstance.ownerId },
        },
        data: {
          priorityInstanceByOwner: priorityInstance,
        },
      });
    },
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    updatePriorityInstance,
  };
};
