import { ReactNode, createContext, useContext, useState } from "react";

export interface SidebarState {
  sidebarWidth: number;
  isFixed: boolean;
  isVisible: boolean;
}

type Props = {
  children?: ReactNode;
};

type LayoutContextType = {
  sidebarState: SidebarState | null;
  setSidebarState: (newState: SidebarState) => void;
};

const initialValue = {
  sidebarState: null,
  setSidebarState: () => {},
};

export const LayoutContext = createContext<LayoutContextType>(initialValue);

export const LayoutProvider = ({ children }: Props) => {
  const [sidebarState, setSidebarState] = useState<SidebarState | null>(null);

  return (
    <LayoutContext.Provider
      value={{
        sidebarState,
        setSidebarState,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

// Define the useLayout hook
export const useLayout = (): LayoutContextType => {
  const context = useContext(LayoutContext);

  if (!context) {
    throw new Error("useLayout must be used within LayoutContexProvider");
  }

  return context;
};
