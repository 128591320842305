import { FC } from "react";
// import { ObjectiveListViewSelectTabs } from "./objective-list-view-select-tabs";
import { ObjectiveListDisplayOptionsButton } from "./objective-list-display-options-button";
import { ObjectiveListFilterButton } from "./objective-list-filter-button";

export interface ObjectiveListConfigurationViewProps {}

export const ObjectiveListConfigurationView: FC<ObjectiveListConfigurationViewProps> = () => {
  return (
    <div className="max-w-screen relative flex h-[45px] shrink-0 items-center justify-between gap-2 border-b border-solid px-6 text-sm">
      <div className="flex flex-row items-center justify-start">
        <ObjectiveListFilterButton />
      </div>
      <div className="flex grow flex-row items-center justify-start"></div>
      <div className="flex flex-row items-center justify-end gap-2">
        {/* <ObjectiveListViewSelectTabs /> */}
        <ObjectiveListDisplayOptionsButton />
      </div>
    </div>
  );
};
