import { toast } from "@/components/_ui/use-toast.ts";
import { Slot, type AsChildProps } from "@/components/slot.tsx";

import { useMutation } from "@apollo/client";
import { updateLabelMutationDocument } from "@/graphql/common";
import {
  getLabelListForWorkspaceQueryDocument,
  getLabelQueryDocument,
  getLabelGroupQueryDocument,
} from "@/graphql/common";
import { useMemo } from "react";

type AddLabelToGroupActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  label: {
    id: string;
    labelGroupId?: string | null;
  };
  labelGroup: {
    id: string;
    title: string;
  };
  workspace: {
    id: string;
  };
};

export const AddLabelToGroupAction = ({
  label,
  labelGroup,
  workspace,
  asChild,
  ...props
}: AddLabelToGroupActionProps) => {
  const Comp = asChild ? Slot : "button";

  const refetchQueries = useMemo(() => {
    if (!label.labelGroupId) {
      return [
        {
          query: getLabelListForWorkspaceQueryDocument,
          variables: { input: { workspaceId: workspace.id } },
        },
        {
          query: getLabelGroupQueryDocument,
          variables: { input: { id: labelGroup.id } },
        },
        {
          query: getLabelQueryDocument,
          variables: { input: { id: label.id } },
        },
      ];
    }

    return [
      {
        query: getLabelListForWorkspaceQueryDocument,
        variables: { input: { workspaceId: workspace.id } },
      },
      {
        query: getLabelGroupQueryDocument,
        variables: { input: { id: labelGroup.id } },
      },
      {
        query: getLabelGroupQueryDocument,
        variables: { input: { id: label.labelGroupId } },
      },
      {
        query: getLabelQueryDocument,
        variables: { input: { id: label.id } },
      },
    ];
  }, [label]);

  const [updateLabel] = useMutation(updateLabelMutationDocument, {
    onCompleted: () => {
      toast({
        title: "Kategorie zu Gruppe hinzugefügt",
        description: `Wir haben die Kategorie erfolgreich zur Gruppe ${labelGroup.title} hinzugefügt.`,
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler bei der Vergabe der Gruppe aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
        variant: "error",
      });
    },
    refetchQueries: refetchQueries,
  });

  async function handleClick() {
    await updateLabel({ variables: { input: { id: label.id, labelGroupId: labelGroup.id } } });
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default AddLabelToGroupAction;
