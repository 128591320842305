import { graphql } from "../../generated/gql";

// only for SuperUser
export const superuser_createTeamMembershipMutationDocument = graphql(/* GraphQL */ `
  mutation superuser_createTeamMembership($input: TeamMembershipCreateInput!) {
    superuser_createTeamMembership(teamMembershipCreateInput: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

// only for SuperUser
export const superuser_deleteTeamMembershipMutationDocument = graphql(/* GraphQL */ `
  mutation superuser_deleteTeamMembership($input: TeamMembershipDeleteInput!) {
    superuser_deleteTeamMembership(teamMembershipDeleteInput: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

// only for SuperUser
export const superuser_updateTeamMembershipStatusMutationDocument = graphql(/* GraphQL */ `
  mutation superuser_updateTeamMembershipStatus($input: TeamMembershipUpdateStatusInput!) {
    superuser_updateTeamMembershipStatus(teamMembershipUpdateStatusInput: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);
