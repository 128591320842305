import { z } from "zod";

export const FilterByIdPayloadSchema = z.object({
  type: z.literal("filter_by_id"),
  idList: z.array(z.string().nullable()),
  mode: z.union([z.literal("included"), z.literal("not_included")]),
});

export type FilterByIdPayload = z.infer<typeof FilterByIdPayloadSchema>;
export type FilterByIdMode = FilterByIdPayload["mode"];
export type FilterByIdList = FilterByIdPayload["idList"];

export const filterById = (payload: FilterByIdPayload, id: string | null | undefined): boolean => {
  if (payload.mode == "included") {
    return payload.idList.includes(id ?? null);
  }

  if (payload.mode == "not_included") {
    return !payload.idList.includes(id ?? null);
  }

  // fall through; shouldn't happen
  return true;
};

export const filterByIdWithUnknownPayload = (payload: unknown, id: string | null | undefined): boolean => {
  // const safePayload = FilterByIdPayloadSchema.parse(payload);

  // return filterById(safePayload, id);
  const safePayload = FilterByIdPayloadSchema.safeParse(payload);
  if (!safePayload.success) {
    return true; // if we cannot apply the filter then don't filter at all
  }

  return filterById(safePayload.data, id);
};
