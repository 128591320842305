import { buildChartTheme } from "@visx/xychart";

export const themeLight = {
  xyChartTheme: buildChartTheme({
    backgroundColor: "#FFFFFF",
    colors: ["#222222", "#CDCDCD", "#CDCDCD", "#222222"],
    gridColor: "#336d88",
    gridColorDark: "#1d1b38",
    svgLabelBig: { fill: "#1d1b38" },
    tickLength: 6,

    xTickLineStyles: {
      stroke: "#000000",
    },
  }),
  progressInterval: {
    fill: "#F7F7F7",
  },
  progressSnapshot: {
    point: {
      fill: "#51B2E8",
    },
    curve: {
      stroke: "#51B2E8",
    },
  },
};

export const themeDark = {
  xyChartTheme: buildChartTheme({
    backgroundColor: "#181920",
    colors: ["#EEEEEE", "#888888", "#888888", "#EEEEEE"],
    gridColor: "#336d88",
    gridColorDark: "#1d1b38",
    svgLabelBig: { fill: "#1d1b38" },
    tickLength: 6,

    xAxisLineStyles: {
      stroke: "#FFFFFF",
    },
    xTickLineStyles: {
      stroke: "#FFFFFF",
    },
  }),
  progressInterval: {
    fill: "#282838",
  },
  progressSnapshot: {
    point: {
      fill: "#33A2E0",
    },
    curve: {
      stroke: "#33A2E0",
    },
  },
};
