import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { superuser_workspaceListQueryDocument } from "@/graphql/superuser";

export const useGetWorkspaceList = () => {
  const queryResult = useQuery(superuser_workspaceListQueryDocument);

  const workspaceList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }
    return queryResult.data.superuser_workspaceList;
  }, [queryResult.data]);

  return {
    queryResult,
    workspaceList,
  };
};
