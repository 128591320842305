import { FC, PropsWithChildren } from "react";

export interface FilterDisplayViewProps extends PropsWithChildren {}

export const FilterDisplayView: FC<FilterDisplayViewProps> = ({ children }) => {
  return (
    <div className="m-1 flex w-full cursor-pointer flex-row items-center gap-2 p-1 text-xs">
      <div className="grow">
        <div className="flex flex-row items-center gap-2">{children}</div>
      </div>
    </div>
  );
};
