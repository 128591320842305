import { graphql } from "../../generated/gql";

export const superuser_createWorkspaceMutationDocument = graphql(/* GraphQL */ `
  mutation superuser_createWorkspace($input: WorkspaceCreateInput!) {
    superuser_createWorkspace(workspaceCreateInput: $input) {
      id
      name
      workspaceUrl
      logo
      createdAt
      updatedAt
      workspaceMembershipList {
        id
        userId
        workspaceId
        role
        status
        updatedAt
        createdAt
      }
    }
  }
`);

export const superuser_duplicateWorkspaceMutationDocument = graphql(/* GraphQL */ `
  mutation superuser_duplicateWorkspace($input: WorkspaceDuplicateInput!) {
    superuser_duplicateWorkspace(workspaceDuplicateInput: $input) {
      id
      name
      workspaceUrl
      logo
      createdAt
      updatedAt
      workspaceMembershipList {
        id
        userId
        workspaceId
        role
        status
        updatedAt
        createdAt
      }
    }
  }
`);
