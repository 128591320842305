import { useMutation } from "@apollo/client";
import { teamByIdQueryDocument, teamMembershipsForTeamQueryDocument, teamsForUserInWorkspaceQueryDocument } from "@/graphql/common";
import { Superuser_CreateTeamMembershipMutation } from "@/graphql/generated/graphql";

import { superuser_createTeamMembershipMutationDocument, superuser_teamListForWorkspaceQueryDocument } from "@/graphql/superuser";

export type CreatedTeamMembership = Superuser_CreateTeamMembershipMutation["superuser_createTeamMembership"];

export interface UseCreateTeamMembershipProps {
  team: {
    id: string;
    workspaceId: string
  };
  onCompleted?: (o: CreatedTeamMembership | undefined) => void;
  onError?: (e: Error) => void;
}

export const useTeamMembershipCreate = ({ onCompleted, onError, ...props }: UseCreateTeamMembershipProps) => {
  const [createTeamMembership, queryResult] = useMutation(superuser_createTeamMembershipMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: teamMembershipsForTeamQueryDocument,
        variables: {
          input: props.team.id,
        },
      },
      {
        query: teamByIdQueryDocument,
        variables: { input: props.team.id },
      },
      {
        query: teamsForUserInWorkspaceQueryDocument,
        variables: { input: props.team.workspaceId },
      },
      {
        query: superuser_teamListForWorkspaceQueryDocument,
        variables: { input: props.team.workspaceId }
      },
    ],
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const createdTeamMembership = o?.createTeamMembership as CreatedTeamMembership;

    if (onCompleted) {
      onCompleted(createdTeamMembership ?? undefined);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    createTeamMembership,
  };
};
