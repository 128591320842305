import { FC, PropsWithChildren, useState } from "react";

import { Button } from "@/components/_ui/button";
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from "@/components/_ui/menubar";

import IconDots from "@/components/_icons/IconDots";
import IconTrash from "@/components/_icons/IconTrash";

import { toast } from "@/components/_ui/use-toast";

import DeleteConfirmation from "@/components/delete-confirmation";
import { useDeleteProgressSnapshot } from "@/hooks/api/common";

interface ProgressSnapshotEditDropdownProps extends PropsWithChildren {
  progressSnapshot: {
    id: string;
    objectiveId: string;
    keyResultId?: string | null | undefined;
  };
}

export const ProgressSnapshotEditDropdown: FC<ProgressSnapshotEditDropdownProps> = ({ progressSnapshot }) => {
  const [deleteConfirmationisOpen, setDeleteConfirmationisOpen] = useState(false);
  const { deleteProgressSnapshot, queryResult } = useDeleteProgressSnapshot({
    objectiveId: progressSnapshot.objectiveId,
    keyResultId: progressSnapshot.keyResultId ?? undefined,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  function onUpdateCompleted() {
    toast({
      title: "Fortschrittsbericht gelöscht",
      description: "Wir haben den Fortschrittsbericht erfolgreich gelöscht.",
      variant: "success",
    });
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler beim Löschen des Fortschrittsberichts aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
      variant: "error",
    });
  }

  async function handleDeleteProgressSnapshot() {
    const { loading } = queryResult;

    if (loading) {
      return;
    }

    deleteProgressSnapshot({
      variables: {
        input: {
          id: progressSnapshot.id,
        },
      },
    });
  }
  return (
    <>
      <Menubar className="shadow-none rounded-md">
        <MenubarMenu>
          <MenubarTrigger asChild>
            <Button variant="ghost" size="sm" className="select-none rounded-md">
              <IconDots />
            </Button>
          </MenubarTrigger>
          <MenubarContent className="rounded-md" align="end">
            <MenubarItem
              onClick={() => {
                setDeleteConfirmationisOpen(true);
              }}
            >
              <div className="flex flex-row items-center gap-1">
                <IconTrash className="mr-2 h-3 w-3 text-muted-foreground" />
                Fortschrittsbericht löschen
              </div>
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>

      <DeleteConfirmation
        open={deleteConfirmationisOpen}
        onOpenChange={setDeleteConfirmationisOpen}
        onConfirm={() => {
          handleDeleteProgressSnapshot();
        }}
        title={`Sind Sie sich sicher, dass Sie dieses Ziel löschen möchten?`}
        description={`Diese Aktion kann nicht rückgängig gemacht werden. Das Ziel und alle damit verbundenen Teilziele werden unwiderruflich gelöscht.`}
      />
    </>
  );
};
