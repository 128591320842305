import React, { createContext, useState, useContext, ReactNode, FC } from "react";
import { TAB_DATA, TabDataKey } from "../objective-tabs";
import { useGetObjective, useWorkspaceGetById } from "@/hooks/api/common";
import { useNavigate } from "react-router-dom";

interface ObjectiveUIContextType {
  showCreateChildObjectiveView: boolean;
  setShowCreateChildObjectiveView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTab: TabDataKey;
  setSelectedTab: (tabId: TabDataKey) => void;
}

const ObjectiveUIContext = createContext<ObjectiveUIContextType | undefined>(undefined);

// Define a type for the props that the provider will accept
interface ObjectiveUIContextProviderProps {
  children: ReactNode;
  workspace: {
    id: string;
  };
  objective: {
    id: string;
  };
  selectedTab?: {
    id: string;
  };
}

// Create the provider component
export const ObjectiveUIContextProvider: FC<ObjectiveUIContextProviderProps> = ({ children, ...props }) => {
  const navigate = useNavigate();
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });
  const { objective } = useGetObjective({ id: props.objective.id });

  const validTab = TAB_DATA.find((v) => props.selectedTab && v.id === props.selectedTab?.id);
  const [selectedTab, _setSelectedTab] = useState<TabDataKey>(validTab ? validTab.id : "details");

  const [showCreateChildObjectiveView, setShowCreateChildObjectiveView] = useState(false);

  const setSelectedTab = (tabId: TabDataKey) => {
    _setSelectedTab(tabId);

    if (!workspace) {
      return;
    }
    if (!objective) {
      return;
    }

    navigate(`/${workspace.workspaceUrl}/goals/${objective.id}/${tabId}`);
  };

  return (
    <ObjectiveUIContext.Provider
      value={{
        showCreateChildObjectiveView,
        setShowCreateChildObjectiveView,
        selectedTab,
        setSelectedTab,
      }}
    >
      {children}
    </ObjectiveUIContext.Provider>
  );
};

// Define the useObjectiveUIState hook
export const useObjectiveUIState = (): ObjectiveUIContextType => {
  const context = useContext(ObjectiveUIContext);

  if (!context) {
    throw new Error("useObjectiveUIState must be used within a ChildProvider");
  }

  return context;
};
