import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { superuser_teamListForWorkspaceQueryDocument } from "@/graphql/superuser/queries/team.queries";

export interface useTeamListForWorkspaceQueryProps {
  workspaceId: string;
}

export const useTeamListForWorkspaceQuery = (props: useTeamListForWorkspaceQueryProps) => {
  const queryResult = useQuery(superuser_teamListForWorkspaceQueryDocument, { variables: { input: props.workspaceId } });

  const teamList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }
    return queryResult.data.superuser_teamListForWorkspace;
  }, [queryResult.data]);


  return {
    queryResult,
    teamList,
  };
};
