import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getStatusQueryDocument } from "@/graphql/common";

export interface UseGetStatusProps {
  id?: string | null;
}

export const useGetStatus = ({ id }: UseGetStatusProps) => {
  const queryResult = useQuery(getStatusQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id,
  });

  const status = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.status;
  }, [queryResult.data]);

  return {
    queryResult,
    status,
  };
};
