import { FC, PropsWithChildren, useMemo } from "react";

import { useGetLabelListForTeam } from "@/hooks/api/common";
import { TeamLabelListItem } from "./team-label-list-item";

export interface TeamLabelListPropsWithChildren extends PropsWithChildren {
  team: {
    id: string;
  };
  className?: string;
}

export const TeamLabelList: FC<TeamLabelListPropsWithChildren> = ({ team }) => {
  const { labelList } = useGetLabelListForTeam({ teamId: team.id });

  const sortedLabelList = useMemo(() => {
    return labelList
      .slice()
      .filter((l) => !l.labelGroupId)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.title.localeCompare(b.title);
      });
  }, [labelList]);

  return (
    <>
      {sortedLabelList.map((label) => (
        <TeamLabelListItem key={label.id} label={label} team={team} />
      ))}
    </>
  );
};

export default TeamLabelList;
