import { useMutation } from "@apollo/client";
import { teamMembershipsForTeamQueryDocument, joinTeamMutationDocument, teamsForUserInWorkspaceQueryDocument } from "@/graphql/common";
import { JoinTeamMutation } from "@/graphql/generated/graphql";

export type JoinedTeamMembership = JoinTeamMutation["joinTeam"];

export interface UseJoinTeamProps {
  workspace: {
    id: string,
  }
  team: {
    id: string;
  };
  onCompleted?: (o: JoinedTeamMembership | undefined) => void;
  onError?: (e: Error) => void;
}

export const useTeamJoin = ({ onCompleted, onError, ...props }: UseJoinTeamProps) => {
  const [joinTeam, queryResult] = useMutation(joinTeamMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: teamMembershipsForTeamQueryDocument,
        variables: {
          input: props.team.id,
        },
      },
      {
        query: teamsForUserInWorkspaceQueryDocument,
         variables: { input: props.workspace.id } 
      }
    ],
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const joinTeamMembership = o?.joinTeam as JoinedTeamMembership;

    if (onCompleted) {
      onCompleted(joinTeamMembership ?? undefined);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    joinTeam,
  };
};
