import { graphql } from "../../generated/gql";

export const getUnitListQueryDocument = graphql(/* GraphQL */ `
  query GetUnitList($input: GetUnitsInput!) {
    units(getUnitsInput: $input) {
      id
      teamId
      title
      symbol
      keyResults {
        id
        objectiveId
      }
    }
  }
`);

export const getUnitQueryDocument = graphql(/* GraphQL */ `
  query GetUnit($input: GetUnitInput!) {
    unit(getUnitInput: $input) {
      id
      teamId
      title
      symbol
      keyResults {
        id
        objectiveId
      }
    }
  }
`);
