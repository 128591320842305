import Header from "@/components/header";
import NotFound from "@/routes/NotFound";

import { TeamLabelTable } from "./team-label-table";
import { useParams } from "react-router-dom";

export default function Page() {
  const { teamId } = useParams();

  if (!teamId) {
    return <NotFound />;
  }

  return (
    <main className="relative max-h-screen place-items-stretch overflow-auto">
      <Header className="border-none"></Header>

      <div className="overflow-y-scroll pb-24" style={{ maxHeight: "calc(100vh - 56px)" }}>
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <div className="flex flex-col gap-1.5">
              <div className="text-2xl">Kategorien</div>
              <div className="text-sm text-muted-foreground">Verwalten Sie die Kategorien Ihres Teams</div>
            </div>

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <TeamLabelTable team={{ id: teamId }} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
