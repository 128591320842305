import { FC, useMemo } from "react";

import { cn } from "@/lib/utils";

import { HealthType, HealthTypes } from "./health-types";
import IconHeartbeatCircle from "@/components/_icons/IconHeartbeatCircle";

interface HealthViewProps {
  type?: HealthType | null;
  modified?: boolean;
  logoOnly?: boolean;
  className?: string;
}

export const HealthView: FC<HealthViewProps> = ({ type, modified, ...props }) => {
  const color = useMemo(() => {
    if (!type) {
      return "#666a73";
    }
    if (type == HealthTypes.onTrack) {
      return "#42af22";
    }
    if (type == HealthTypes.atRisk) {
      return "#c98d21";
    }
    if (type == HealthTypes.offTrack) {
      return "#d85d4f";
    }
  }, [type]);

  return (
    <div className="flex flex-row items-center gap-2">
      <IconHeartbeatCircle className={cn(["h-4 w-4", props.className])} style={{ color: color }} />
      {!props.logoOnly && (
        <>
          {!type && <span style={{ color: color }}>Nicht zugewiesen</span>}
          {type == HealthTypes.onTrack && <span style={{ color: color }}>On Track</span>}
          {type == HealthTypes.atRisk && <span style={{ color: color }}>At Risk</span>}
          {type == HealthTypes.offTrack && <span style={{ color: color }}>Off Track</span>}
          {modified && <div className="ml h-1.5 w-1.5 rounded-full" style={{ backgroundColor: color }}></div>}
        </>
      )}
    </div>
  );
};

export default HealthView;
