import { LucideProps } from "lucide-react";

const Icon = ({ ...props }: LucideProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="circle-group"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 239 237"
      {...props}
    >
      <path
        fill="currentColor"
        d="M138.197 35.0527C131.056 33.1032 123.54 32.0625 115.781 32.0625C68.8888 32.0625 30.875 70.0763 30.875 116.969C30.875 123.881 31.701 130.6 33.2596 137.033C13.2047 123.614 0 100.755 0 74.8125C0 33.4947 33.4947 0 74.8125 0C101.521 0 124.96 13.9955 138.197 35.0527ZM182.197 78.0527C175.056 76.1032 167.54 75.0625 159.781 75.0625C112.889 75.0625 74.875 113.076 74.875 159.969C74.875 166.881 75.701 173.6 77.2596 180.033C57.2047 166.614 44 143.755 44 117.812C44 76.4947 77.4947 43 118.812 43C145.521 43 168.96 56.9955 182.197 78.0527ZM163.812 236.625C205.13 236.625 238.625 203.13 238.625 161.812C238.625 120.495 205.13 87 163.812 87C122.495 87 89 120.495 89 161.812C89 203.13 122.495 236.625 163.812 236.625Z"
      />
    </svg>
  );
};

export default Icon;
