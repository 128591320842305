import { FC, useState } from "react";

import { Dialog, DialogContent } from "@/components/_ui/dialog";
import { Button } from "@/components/_ui/button";

import UnitCreateForm from "./unit-create-form";

interface UnitCreateDialogProps {
  team: {
    id: string;
  };
}

const UnitCreateDialog: FC<UnitCreateDialogProps> = ({ team }) => {
  const [dialogisOpen, setDialogisOpen] = useState(false);

  function onDialogOpenChange(open: boolean) {
    setDialogisOpen(open);
  }

  return (
    <>
      <Button size="xs" variant="default" onClick={() => setDialogisOpen(true)}>
        Neue Einheit
      </Button>
      <Dialog open={dialogisOpen} onOpenChange={onDialogOpenChange}>
        <DialogContent className="top-[1%] max-w-xl translate-y-[0%] md:top-[20%]">
          <UnitCreateForm
            team={team}
            triggerCloseDialog={() => {
              setDialogisOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UnitCreateDialog;
