import { SidebarNavElement } from "@/components/sidebar";

import IconArrowTinyRight from "@/components/_icons/IconArrowTinyRight";

import { useCurrentUser, useTeamById, useWorkspaceGetById } from "@/hooks/api/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { TeamIcon } from "@/components/_domain/team/team-icon";
import IconInvisible from "@/components/_icons/IconInvisible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { Button } from "@/components/_ui/button";
import IconDots from "@/components/_icons/IconDots";

import { cn } from "@/lib/utils";

export interface SidebarAppTeamNavElementProps {
  team: {
    id: string;
  };
  expanded?: boolean | undefined;
}

export const SidebarAppTeamNavElement = ({ ...props }: SidebarAppTeamNavElementProps) => {
  const [expanded, setExpanded] = useState(props.expanded != undefined ? props.expanded : false);
  const [showDropdown, setShowDropdown] = useState(false);

  const { currentUser } = useCurrentUser();
  const { team, userHasEditRights } = useTeamById({ id: props.team.id });
  const { workspace } = useWorkspaceGetById({ id: team?.workspaceId });

  const { teamId } = useParams();

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    return userHasEditRights(currentUser.id);
  }, [currentUser, userHasEditRights]);

  const makeTeamUrl = useCallback(
    (path: string) => {
      if (!workspace) {
        return "";
      }
      if (!team) {
        return "";
      }

      return `/${workspace.workspaceUrl}/teams/${team.id}/${path}`;
    },
    [workspace, team],
  );

  const makeTeamSettingsUrl = useCallback(
    (path: string) => {
      if (!workspace) {
        return "";
      }
      if (!team) {
        return "";
      }

      return `/${workspace.workspaceUrl}/settings/teams/${team.id}/${path}`;
    },
    [workspace, team],
  );

  useEffect(() => {
    if (teamId == props.team.id) {
      setExpanded(true);
    }
  }, [teamId, props.team]);

  if (!team) {
    return <></>;
  }

  return (
    <div className="group">
      <div className="relative m-1 cursor-pointer rounded">
        <div
          className={cn([
            "my-0.5 flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-sm hover:bg-accent hover:text-accent-foreground",
            showDropdown ? "bg-accent text-accent-foreground" : "",
          ])}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <div className="rounded bg-muted p-1">
            <TeamIcon team={team} />
          </div>
          <div className="pointer-events-none relative flex h-8 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
            {team.name}
          </div>
          <div>
            {!expanded && <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 text-foreground" />}
            {expanded && <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 rotate-90 text-foreground" />}
          </div>
          <div className="grow"></div>
          <div
            className={cn(["justify-self-end opacity-0 group-hover:opacity-100", showDropdown ? "opacity-100" : ""])}
          >
            <DropdownMenu open={showDropdown} onOpenChange={setShowDropdown}>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" className="">
                  <IconDots className="h-3 w-3" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <Link to={makeTeamSettingsUrl("general")}>
                  <DropdownMenuItem className="cursor-pointer">Team Einstellungen</DropdownMenuItem>
                </Link>
                {!hasEditRights && (
                  <Link to={makeTeamSettingsUrl("members")}>
                    <DropdownMenuItem className="cursor-pointer">Mitglieder ansehen</DropdownMenuItem>
                  </Link>
                )}

                {hasEditRights && (
                  <>
                    <DropdownMenuSeparator />
                    <Link to={makeTeamSettingsUrl("members")}>
                      <DropdownMenuItem className="cursor-pointer">Mitglieder verwalten</DropdownMenuItem>
                    </Link>
                    <Link to={makeTeamSettingsUrl("labels")}>
                      <DropdownMenuItem className="cursor-pointer">Kategorien verwalten</DropdownMenuItem>
                    </Link>
                    <Link to={makeTeamSettingsUrl("status")}>
                      <DropdownMenuItem className="cursor-pointer">Status verwalten</DropdownMenuItem>
                    </Link>
                    <Link to={makeTeamSettingsUrl("priority")}>
                      <DropdownMenuItem className="cursor-pointer">Prioritäten verwalten</DropdownMenuItem>
                    </Link>
                    <Link to={makeTeamSettingsUrl("units")}>
                      <DropdownMenuItem className="cursor-pointer">Einheiten verwalten</DropdownMenuItem>
                    </Link>
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      {expanded && (
        <SidebarNavElement to={makeTeamUrl("goals")}>
          <IconInvisible className="ml-4 mr-2 h-2.5 w-2.5" />
          {/* <IconMountain className="ml-4 mr-2 h-2.5 w-2.5" /> */}
          Ziele
        </SidebarNavElement>
      )}
    </div>
  );
};
