import { Row, flexRender } from "@tanstack/react-table";

import { TableCell, TableRow } from "@/components/_ui/table";

import { Objective } from "@/components/_domain/objective/models";

interface ObjectiveListViewRowProps {
  row: Row<Objective>;
}

export function ObjectiveListViewRow({ row }: ObjectiveListViewRowProps) {
  return (
    <TableRow key={row.id} data-state={row.getIsSelected() && "selected"} className="text-xs last:border-b">
      {row.getVisibleCells().map((cell, j) => (
        <TableCell
          key={cell.id}
          style={{
            width: cell.column.getSize() === Number.MAX_SAFE_INTEGER ? "min-content" : cell.column.getSize(),
            paddingRight: row.getVisibleCells().length === j + 1 ? "30px" : undefined,
          }}
          className="px-0.5"
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      ))}
    </TableRow>
  );
}
