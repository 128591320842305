import { FC, useEffect, useState } from "react";

import { cn } from "@/lib/utils";

import { Button } from "@/components/_ui/button";

import { AvatarUser, UserSelectDropdown } from "@/components/_domain/user";
import IconUserUnassigned from "@/components/_icons/IconUserUnassigned";

import { useCreateAssignee, useDeleteAssignee, useUpdateAssignee } from "@/hooks/api/common";

import { Row } from "@tanstack/react-table";
import { Objective } from "@/components/_domain/objective/models";

interface ObjectiveListCellAssigneeProps {
  row: Row<Objective>;
  className?: string;
}

export const ObjectiveListCellAssignee: FC<ObjectiveListCellAssigneeProps> = ({ row, className }) => {
  const objective = row.original;
  const assignee = row.original.assignee;
  const assignedUser = row.original?.assignee?.user;

  const [initialized, setInitialized] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(assignee?.userId ?? null);

  const { updateAssignee } = useUpdateAssignee({}); // always prefer this one
  const { createAssignee } = useCreateAssignee({
    ownerId: objective.id,
    ownerType: "OBJECTIVE",
    newUserId: userId ?? undefined,
    oldUserId: assignee?.userId ?? undefined,
  });
  const { deleteAssignee } = useDeleteAssignee({
    ownerId: objective.id,
    ownerType: "OBJECTIVE",
    oldUserId: assignee?.userId ?? undefined,
  });

  function assignObjectiveToUser() {
    if (!objective) {
      return;
    }

    if (!userId) {
      return;
    }

    if (assignee) {
      updateAssignee({
        variables: {
          input: {
            id: assignee.id,
            userId: userId,
          },
        },
      });
    } else {
      createAssignee({
        variables: {
          input: {
            userId: userId,
            ownerId: objective.id,
            ownerType: "OBJECTIVE",
            teamId: objective.teamId,
          },
        },
      });
    }
  }

  function removeAssignedUserFromObjective() {
    if (!assignee) {
      return;
    }
    deleteAssignee({
      variables: { input: { id: assignee.id } },
    });
  }

  const handleOnSelect = (user: { id: string } | null) => {
    setInitialized(true);
    setUserId(user ? user.id : null);
  };

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (!userId) {
      removeAssignedUserFromObjective();
    } else {
      assignObjectiveToUser();
    }
  }, [userId]);

  return (
    <UserSelectDropdown
      team={{ id: objective.teamId }}
      onSelect={handleOnSelect}
      selected={assignedUser}
      side="bottom"
      asChild
    >
      <Button
        variant="ghost"
        size="icon"
        className={cn([
          "group overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0",
          className,
        ])}
      >
        {!assignedUser && (
          <>
            <IconUserUnassigned className="h-[1.125rem] w-[1.125rem] text-muted-foreground/50 group-hover:text-muted-foreground" />
          </>
        )}
        {assignedUser && (
          <>
            <AvatarUser user={assignedUser} className="h-[1.125rem] w-[1.125rem] text-xxs" />
          </>
        )}
      </Button>
    </UserSelectDropdown>
  );
};
