import { FC, useCallback } from "react";
import { Link, useParams } from "react-router-dom";

import { Header } from "@/components/header";

import NotFound from "@/routes/NotFound";

import { useLayout } from "@/hooks/useLayout";
import { useWorkspaceGetById } from "@/hooks/api/common";
import { useTeamListForWorkspaceQuery } from "@/hooks/api/superuser";

import { TeamCreateDialog } from "@/components/_domain/team/team-create-dialog";
import { TeamDatatable, columns } from "@/components/_domain/team/team-datatable";


interface InnerPageProps {
  workspaceId: string;
}

const InnerPage: FC<InnerPageProps> = ({ workspaceId }) => {
  const { sidebarState } = useLayout();

  const  {workspace} = useWorkspaceGetById({id: workspaceId})
  const { teamList} = useTeamListForWorkspaceQuery({workspaceId: workspaceId})

  const makeAdminUrl = useCallback((path: string) => {
    return `/admin/${path}`;
  }, []);

  if (!workspace) {
    return <></>;
  }

  return (
    <div className="flex h-screen max-h-screen flex-col overflow-y-hidden">
      <Header className={sidebarState?.isFixed ? "pl-6" : "pl-12"}>
        <div className="flex flex-row items-center gap-2">
          <Link to={makeAdminUrl("")}>
            <div className="my-0.5 flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-sm ">
              <span className="rounded p-1">⚠️</span>
              <span className="pointer-events-none relative flex h-8 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
                Admin Panel
              </span>
            </div>
          </Link>
          <div>›</div>
          <Link to={makeAdminUrl("workspaces")}>
            <div className="flex flex-row items-center overflow-hidden truncate text-ellipsis whitespace-nowrap">
              Workspace Management
            </div>
          </Link>
          <div>›</div>
          <Link to={makeAdminUrl(`workspaces/${workspaceId}`)}>
            <div className="flex flex-row items-center overflow-hidden truncate text-ellipsis whitespace-nowrap">
              {workspace.name}
            </div>
          </Link>
        </div>
        <div className="flex flex-row items-center gap-2">
          <TeamCreateDialog workspace={workspace} isSuperuser={true}/>
        </div>
      </Header>

      <div className="px-0">
        <TeamDatatable columns={columns} data={teamList} />
      </div>
    </div>
  );
}

export default function Page() {
  const { workspaceId } = useParams();

  if (!workspaceId) {
    return <NotFound />;
  }

  return (
      <InnerPage workspaceId={workspaceId} />
  );
};

