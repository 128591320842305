import { graphql } from "../../generated/gql";

export const superuser_workspaceListQueryDocument = graphql(/* GraphQL */ `
  query superuser_workspaceList {
    superuser_workspaceList {
      id
      name
      workspaceUrl
      logo
      updatedAt
      createdAt
      workspaceMembershipList {
        id
        userId
        workspaceId
        role
        status
        updatedAt
        createdAt
      }
    }
  }
`);
