import { FC } from "react";

import { cn } from "@/lib/utils";

import { Link } from "react-router-dom";
import { Button } from "@/components/_ui/button";

import IconChevronDown from "@/components/_icons/IconChevronDown";
import IconChevronUp from "@/components/_icons/IconChevronUp";

import { useWorkspaceGetById } from "@/hooks/api/common";

import { Row } from "@tanstack/react-table";
import { Objective } from "@/components/_domain/objective/models";

interface ObjectiveListCellTitleProps {
  row: Row<Objective>;
  workspace: {
    id: string;
  };
  objective: {
    id: string;
  };
  className?: string;
}

export const ObjectiveListCellTitle: FC<ObjectiveListCellTitleProps> = ({ row, className, ...props }) => {
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });
  const objective = row.original;

  if (!workspace) {
    return <></>;
  }

  if (!objective) {
    return <></>;
  }

  return (
    <div
      className={cn([
        "relative flex h-full flex-row items-center justify-start gap-1",
        row.depth > 0 ? "font-normal" : "",
        className,
      ])}
      style={{ paddingLeft: `${row.depth * 1}rem`, minWidth: "20rem" }}
    >
      {/* {row.depth > 0 && (
        <div
          className={`absolute`}
          style={{ left: `${(row.depth - 1) * 1 + 0.15}rem`, top: "-1rem", bottom: "-0.5rem" }}
        >
          <div className="h-full border-l"></div>
        </div>
      )} */}
      <Link
        to={`/${workspace.workspaceUrl}/goals/${objective.id}`}
        className={cn(["", false ? "font-normal" : "font-medium", className])}
      >
        {objective.title != "" ? objective.title : <span className="text-muted-foreground">Kein Titel</span>}
      </Link>
      {objective.children && objective.children.length > 0 && (
        <div className="ml-1.5">
          <span className="whitespace-nowrap text-xs font-light text-muted-foreground/60">
            {objective.children.length == 1 && <> {`·  ${objective.children.length} Teilziel  `}</>}
            {objective.children.length > 1 && <>{`·  ${objective.children.length} Teilziele  `}</>}
          </span>
          {row.getCanExpand() && (
            <Button
              variant="ghost"
              size="icon"
              className="overflow-x-hidden font-light text-muted-foreground/60 ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0  focus-visible:ring-offset-0"
              {...{
                onClick: () => {
                  row.toggleExpanded();
                },
                style: { cursor: "pointer" },
              }}
            >
              {row.getIsExpanded() && <IconChevronUp className="h-2 w-2" />}
              {!row.getIsExpanded() && <IconChevronDown className="h-2 w-2" />}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
