import { useMutation } from "@apollo/client";

import { workspaceMembershipListForWorkspaceQueryDocument } from "@/graphql/common";

import { superuser_workspaceListQueryDocument } from "@/graphql/superuser";
import { superuser_deleteWorkspaceMembershipMutationDocument } from "@/graphql/superuser/mutations/workspace-membership.mutations";
import { Superuser_DeleteWorkspaceMembershipMutation } from "@/graphql/generated/graphql";

export type DeletedWorkspaceMembership =
  Superuser_DeleteWorkspaceMembershipMutation["superuser_deleteWorkspaceMembership"];

export interface UseDeleteWorkspaceMembershipProps {
  workspace: {
    id: string;
  };
  onCompleted?: (o: DeletedWorkspaceMembership | undefined) => void;
  onError?: (e: Error) => void;
}

export const useWorkspaceMembershipDelete = ({ onCompleted, onError, ...props }: UseDeleteWorkspaceMembershipProps) => {
  const [deleteWorkspaceMembership, queryResult] = useMutation(superuser_deleteWorkspaceMembershipMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: workspaceMembershipListForWorkspaceQueryDocument,
        variables: {
          input: props.workspace.id,
        },
      },
      {
        query: superuser_workspaceListQueryDocument,
      },
    ],
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const deletedWorkspaceMembership = o?.superuser_deleteWorkspaceMembership as DeletedWorkspaceMembership;

    if (onCompleted) {
      onCompleted(deletedWorkspaceMembership ?? undefined);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    deleteWorkspaceMembership,
  };
};
