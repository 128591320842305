import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_NORMAL as NORMAL_PRIORITY,
  SELECTION_CHANGE_COMMAND as ON_SELECTION_CHANGE,
} from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { usePointerInteractions } from "./usePointerInteractions";

interface KeyboardEvent {
  key: string;
  preventDefault: () => void;
}

const DEFAULT_DOM_ELEMENT = document.body;

type FloatingMenuCoords = { x: number; y: number } | undefined;

export type FloatingMenuComponentProps = {
  editor: ReturnType<typeof useLexicalComposerContext>[0];
  shouldShow: boolean;
};

export type FloatingMenuPluginProps = {
  element?: HTMLElement;
  MenuComponent?: React.FC<FloatingMenuComponentProps>;
};

export function FloatingMenuPlugin({ element, MenuComponent }: FloatingMenuPluginProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [coords, setCoords] = useState<FloatingMenuCoords>(undefined);
  const show = coords !== undefined;

  const [editor] = useLexicalComposerContext();
  const { isPointerDown, isPointerReleased } = usePointerInteractions();

  const calculatePositionAndOpenMenu = useCallback(() => {
    if (isPointerDown) {
      return setCoords(undefined);
    }
    if (!ref.current) {
      return setCoords(undefined);
    }

    const calcY = (top: number, menuRect: DOMRect) => {
      return top + document.body.scrollTop - menuRect.height - 10;
    };
    const calcX = (left: number, menuRect: DOMRect) => {
      const xCoordinate = left + document.body.scrollLeft - menuRect.width / 2;
      if (xCoordinate < 0) {
        // the floating menu would be out of the viewport
        return 10;
      }
      return xCoordinate;
    };

    const nativeSelection = window.getSelection();
    const anchorNode = nativeSelection?.anchorNode;

    const isEmptyLine = anchorNode && anchorNode instanceof Element;
    if (isEmptyLine) {
      const rect = anchorNode.getBoundingClientRect();
      const y = calcY(rect.top, ref.current.getBoundingClientRect());
      const x = calcX(rect.left, ref.current.getBoundingClientRect());

      return setCoords({ x: x, y: y });
    }

    const range = nativeSelection?.rangeCount !== 0 && nativeSelection?.getRangeAt(0);
    if (range) {
      const rect = range.getBoundingClientRect();
      const y = calcY(rect.top, ref.current.getBoundingClientRect());
      const x = calcX(rect.left, ref.current.getBoundingClientRect());
      return setCoords({ x: x, y: y });
    }

    return setCoords(undefined);
  }, [isPointerDown]);

  const $handleSelectionChange = useCallback(() => {
    if (editor.isComposing()) return false;

    if (editor.getRootElement() !== document.activeElement) {
      setCoords(undefined);
      return true;
    }

    const selection = $getSelection();

    if ($isRangeSelection(selection) && !selection.anchor.is(selection.focus)) {
      calculatePositionAndOpenMenu();
    } else {
      setCoords(undefined);
    }

    return true;
  }, [editor, calculatePositionAndOpenMenu]);

  useEffect(() => {
    const unregisterCommand = editor.registerCommand(ON_SELECTION_CHANGE, $handleSelectionChange, NORMAL_PRIORITY);
    return unregisterCommand;
  }, [editor, $handleSelectionChange]);

  useEffect(() => {
    if (!show && isPointerReleased) {
      editor.getEditorState().read(() => {
        $handleSelectionChange();
      });
    }
    // Adding show to the dependency array causes an issue if
    // a range selection is dismissed by navigating via arrow keys.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPointerReleased, $handleSelectionChange, editor]);

  useLayoutEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key == "Tab") {
        event.preventDefault();
        editor.getEditorState().read(() => {
          calculatePositionAndOpenMenu();
        });
      }
      if (event.key == "Escape") {
        event.preventDefault();
        setCoords(undefined);
      }
    };

    return editor.registerRootListener((rootElement: null | HTMLElement, prevRootElement: null | HTMLElement) => {
      if (prevRootElement !== null) {
        prevRootElement.removeEventListener("keydown", onKeyDown);
      }
      if (rootElement !== null) {
        rootElement.addEventListener("keydown", onKeyDown);
      }
    });
  }, [editor]);

  if (!MenuComponent) return null;

  return createPortal(
    <div
      ref={ref}
      aria-hidden={!show}
      className="border-card-background pointer-events-auto z-[99] rounded-md border bg-background text-foreground shadow-md outline-none	"
      style={{
        position: "fixed",
        top: coords?.y,
        left: coords?.x,
        visibility: show ? "visible" : "hidden",
        opacity: show ? 1 : 0,
      }}
    >
      <MenuComponent editor={editor} shouldShow={show} />
    </div>,
    element ?? DEFAULT_DOM_ELEMENT,
  );
}
