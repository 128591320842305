import NotFound from "@/routes/NotFound";



export default function Page() {
  return (
    <NotFound text="Leider konnten wir den Download Link nicht finden. Stellen Sie sicher, dass Sie eingeloggt sind und versuchen Sie es erneut. Falls das Problem weiterhin besteht, kontaktieren Sie bitten den Support.">

    </NotFound>
  );
}
