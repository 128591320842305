import { FC, PropsWithChildren, useMemo } from "react";

import { cn } from "@/lib/utils";

import { Badge } from "@/components/_ui/badge";
import AvatarUser from "@/components/_domain/user/avatar-user";

import { useCurrentUser, useTeamById, useTeamMembershipList } from "@/hooks/api/common";

import TeamMembershipActionButton from "./components/team-member-action-button";

export interface TeamMemberListItemPropsWithChildren extends PropsWithChildren {
  team: {
    id: string;
    workspaceId: string
  };
  user: {
    id: string;
  };
  className?: string;
}

export const TeamMemberListItem: FC<TeamMemberListItemPropsWithChildren> = ({ ...props }) => {
  const { currentUser, isSuperUser } = useCurrentUser();
  const { userHasEditRights } = useTeamById({ id: props.team.id });
  const { teamMembershipList, getTeamMembershipForUser } = useTeamMembershipList({ teamId: props.team.id });

  const teamMembership = useMemo(() => {
    return getTeamMembershipForUser(props.user.id);
  }, [teamMembershipList, props.user]);

  const user = useMemo(() => {
    if (!teamMembership) {
      return null;
    }

    if (!teamMembership.user) {
      return null;
    }

    return teamMembership.user;
  }, [teamMembership]);

  const shouldShowEditButton = useMemo(() => {
    if (!currentUser) {
      return false;
    }

    if (props.user.id == currentUser.id) {
      return true;
    }

    if (isSuperUser) {
      return true
    }

    return userHasEditRights(currentUser.id);
  }, []);

  if (!teamMembership) {
    return <></>;
  }

  if (!user) {
    return <></>;
  }

  return (
    <div className="flex h-14 min-h-0 flex-row items-center justify-between gap-2 border-b border-solid py-2">
      <div
        className={cn("flex h-min flex-row items-center gap-4", teamMembership.status == "ACTIVE" ? "" : "grayscale")}
      >
        <a>
          {" "}
          <AvatarUser user={user} />
        </a>
        <div
          className={cn(
            "flex flex-col items-start text-sm",
            teamMembership.status == "LEFT" ? "line-through" : "",
            teamMembership.status == "SUSPENDED" ? "line-through" : "",
          )}
        >
          {user.fullname && <span className="w-56 font-medium text-foreground">{user.fullname}</span>}
          <span className="w-56 font-light text-muted-foreground">{user.email}</span>
        </div>
      </div>
      <div className="flex flex-row justify-start gap-4 overflow-hidden text-sm font-light" style={{ width: "250px" }}>
        <span>
          {teamMembership.status == "ACTIVE" &&
            teamMembership.role.charAt(0).toUpperCase() + teamMembership.role.slice(1).toLowerCase()}
          {teamMembership.status == "PENDING" && (
            <>
              <span className="mr-2">
                {teamMembership.role.charAt(0).toUpperCase() + teamMembership.role.slice(1).toLowerCase()}
              </span>
              <Badge className="text-xs font-normal" variant="secondary">
                ausstehend
              </Badge>
            </>
          )}
          {teamMembership.status == "SUSPENDED" && <span className="text-muted-foreground">deaktiviert</span>}
          {teamMembership.status == "LEFT" && <span className="text-muted-foreground">ausgetreten</span>}
        </span>
      </div>
      <div className="flex flex-row items-end gap-4 text-sm">
        <TeamMembershipActionButton
          className={shouldShowEditButton ? "" : "invisible"}
          team={props.team}
          teamMembership={teamMembership}
        />
      </div>
    </div>
  );
};

export default TeamMemberListItem;
