import { FC } from "react";

import { useGetKeyResult } from "@/hooks/api/common";
import {
  MILESTONE_KEY_RESULT_TYPE,
  QUANTITATIVE_KEY_RESULT_TYPE,
} from "../../../../objective-sheet/key-result/models/key-result.form.schema";
import { ProgressSnapshotValueQuantInput } from "./progress-snapshot-value-quant-input";
import { ProgressSnapshotValueMilestoneInput } from "./progress-snapshot-value-milestone-input";

export interface ProgressSnapshotValueInputProps {
  keyResult?: {
    id: string;
  };
  value?: {
    value: number;
    properties: Object;
  };
  onValueChange?: (
    value:
      | {
          value: number;
          properties: Object;
        }
      | undefined,
  ) => void;
}

export const ProgressSnapshotValueInput: FC<ProgressSnapshotValueInputProps> = ({ value, onValueChange, ...props }) => {
  const { keyResult } = props.keyResult ? useGetKeyResult({ id: props.keyResult.id }) : { keyResult: undefined };

  if (keyResult?.type == QUANTITATIVE_KEY_RESULT_TYPE) {
    return <ProgressSnapshotValueQuantInput keyResult={keyResult} value={value} onValueChange={onValueChange} />;
  }

  if (keyResult?.type == MILESTONE_KEY_RESULT_TYPE) {
    return <ProgressSnapshotValueMilestoneInput keyResult={keyResult} value={value} onValueChange={onValueChange} />;
  }
  return <></>;
};

export default ProgressSnapshotValueInput;
