import { FC, PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

import { format } from "date-fns";
import { de } from "date-fns/locale";

import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/_ui/hover-card";

export interface DateHoverProps extends PropsWithChildren {
  className?: string;
  date: Date;
}

export const DateHover: FC<DateHoverProps> = ({ className, date }) => {
  return (
    <HoverCard>
      <HoverCardTrigger>
        <div className={cn("flex flex-row items-center text-xs", className)}>{format(date, "PPP", { locale: de })}</div>
      </HoverCardTrigger>
      <HoverCardContent side="top" align="start" className="w-auto p-3">
        <div className="flex flex-row items-start gap-2 text-sm">{format(date, "PPP", { locale: de })}</div>
      </HoverCardContent>
    </HoverCard>
  );
};
