import { useMutation } from "@apollo/client";
import {
  createStatusInstanceMutationDocument,
  getObjectiveQueryDocument,
  getStatusInstanceQueryDocument,
  getStatusInstancebyOwnerQueryDocument,
} from "@/graphql/common";
import { useMemo } from "react";

export interface UseCreateStatusInstanceProps {
  ownerId: string;
  ownerType: "OBJECTIVE";
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useCreateStatusInstance = ({ ownerId, ownerType, onCompleted, onError }: UseCreateStatusInstanceProps) => {
  const ownerQueryDocument = useMemo(() => {
    if (ownerType == "OBJECTIVE") {
      return {
        query: getObjectiveQueryDocument,
        variables: { input: { id: ownerId } },
      };
    }
    return null;
  }, [ownerId, ownerType]);

  const refetchQueries = useMemo(() => {
    if (ownerQueryDocument) {
      return [ownerQueryDocument];
    }
    return [];
  }, [ownerQueryDocument]);

  const [createStatusInstance] = useMutation(createStatusInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const statusInstance = data?.createStatusInstance;
      if (!statusInstance) {
        return;
      }
      cache.writeQuery({
        query: getStatusInstanceQueryDocument,
        variables: {
          input: { id: statusInstance.id },
        },
        data: {
          statusInstance: statusInstance,
        },
      });
      cache.writeQuery({
        query: getStatusInstancebyOwnerQueryDocument,
        variables: {
          input: { ownerId: statusInstance.ownerId },
        },
        data: {
          statusInstanceByOwner: statusInstance,
        },
      });
    },
    refetchQueries: refetchQueries,
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createStatusInstance,
  };
};
