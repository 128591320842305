import { FC, PropsWithChildren, ReactElement, useEffect, useMemo, useState } from "react";

import { Combobox, ComboboxOption } from "@/components/combobox";

import { NotYetTeamMemberSelectDropdownItem } from "./not-yet-team-member-select-dropdown-item";

import { useTeamMembershipList, useWorkspaceMembershipList } from "@/hooks/api/common";

type UserFragment = {
  id: string;
};

export interface NotYetTeamMemberSelectDropdownProps extends PropsWithChildren {
  workspace: {
    id: string;
  };
  team: {
    id: string;
  };
  asChild?: boolean;
  side?: "top" | "right" | "bottom" | "left";
  selectedUsers?: UserFragment[];
  onSelect?: (selectedUser: UserFragment[]) => void;
}

export const NotYetTeamMemberSelectDropdown: FC<NotYetTeamMemberSelectDropdownProps> = ({
  workspace,
  children,
  side,
  asChild,
  onSelect,
  ...props
}) => {
  const [selectedUsers, setSelectedUsers] = useState<UserFragment[]>([]);
  const { activeOrPendingWorkspaceMembershipList } = useWorkspaceMembershipList({ workspaceId: workspace.id });
  const { teamMembershipList } = useTeamMembershipList({ teamId: props.team.id });

  const userTeamMemberList = useMemo(() => {
    return activeOrPendingWorkspaceMembershipList.flatMap((wm) => {
      const teamMembership = teamMembershipList.find((tm) => {
        return tm.userId == wm.userId;
      });

      // We only want to show users who are NOT yet part of this workspace
      if (teamMembership) {
        return [];
      }
      if (!wm.user) {
        return [];
      }

      return {
        isTeamMember: true,
        teamMembership: teamMembership,
        user: wm.user,
        isPending: wm.status == "PENDING",
      };
    });
  }, [activeOrPendingWorkspaceMembershipList, teamMembershipList]);

  // for faster retrieval
  const userTeamMemberMap = useMemo(() => {
    return new Map(userTeamMemberList.map((obj) => [obj.user.id, obj]));
  }, [userTeamMemberList]);

  const comboboxOptions = useMemo(() => {
    const userOptions = userTeamMemberList
      .slice()
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        const _a = a.user.username ?? "";
        const _b = b.user.username ?? "";

        return _a.localeCompare(_b);
      })
      .flatMap((u) => {
        if (!u) {
          return [];
        }
        return {
          id: u.user.id,
          value: `${u?.user.username ?? ""} ; ${u?.user.fullname ?? ""} ; ${u?.user.email ?? ""}`,
        };
      });
    return [{ id: "-1", value: "Kein Verantwortlicher" }].concat(userOptions);
  }, [userTeamMemberList]);

  useEffect(() => {
    if (props.selectedUsers == undefined) {
      return;
    }
    setSelectedUsers(props.selectedUsers);
  }, [props.selectedUsers]);

  const renderItem = (status: ComboboxOption): ReactElement => {
    const userTeamMember = userTeamMemberMap.get(status.id);

    if (!userTeamMember) {
      return <></>;
    }

    const selected = selectedUsers.find((u) => u.id == userTeamMember.user.id);

    return (
      <NotYetTeamMemberSelectDropdownItem
        user={userTeamMember.user}
        isPending={userTeamMember.isPending}
        selected={!!selected}
      />
    );
  };

  const handleUserSelected = (selectedOption: ComboboxOption | null) => {
    if (!selectedOption) {
      return;
    }

    const selected = selectedUsers.find((u) => u.id == selectedOption.id);

    if (!selected) {
      // select the specific user
      const updatedSelectedUsers = [...selectedUsers, { id: selectedOption.id }];
      setSelectedUsers(updatedSelectedUsers);
      if (onSelect) {
        onSelect(updatedSelectedUsers);
      }
    }

    if (selected) {
      //unselect the specific user
      const filtered = selectedUsers.filter((u) => u.id != selectedOption.id);
      const updatedSelectedUsers = [...filtered];
      setSelectedUsers(updatedSelectedUsers);
      if (onSelect) {
        onSelect(updatedSelectedUsers);
      }
    }
  };

  if (!comboboxOptions) {
    return <></>;
  }

  return (
    <Combobox
      asChild={asChild}
      options={comboboxOptions}
      renderItem={renderItem}
      onSelect={handleUserSelected}
      side={side}
      allowDeselect={true}
    >
      {children}
    </Combobox>
  );
};
