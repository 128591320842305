import { FC, PropsWithChildren, useMemo, useState } from "react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from "@/components/_ui/dropdown-menu";

import IconTrash from "@/components/_icons/IconTrash";
import IconPen from "@/components/_icons/IconPen";
import IconCircleGroup from "@/components/_icons/IconCircleGroup";
import IconCircleSolid from "@/components/_icons/IconCircleSolid";
import IconArrowRight from "@/components/_icons/IconArrowRight";

import DeleteLabelAction from "./actions/delete-label-action";
import AddLabelToGroupAction from "./actions/add-label-to-group-action";

import RemoveLabelFromGroupAction from "./actions/remove-label-from-group-action";

import DeleteConfirmation from "@/components/delete-confirmation";
import { useGetLabel, useGetLabelGroupListForWorkspace } from "@/hooks/api/common";

export interface WorkspaceLabelActionButtonProps extends PropsWithChildren {
  label: {
    id: string;
  };
  workspace: {
    id: string;
  };
  handleEditLabel: () => void;
  asChild?: boolean;
}

export const WorkspaceLabelActionButton: FC<WorkspaceLabelActionButtonProps> = ({
  workspace,
  handleEditLabel,
  asChild,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [deleteConfirmationisOpen, setDeleteConfirmationisOpen] = useState(false);
  const [triggerDelete, setTriggerDelete] = useState(false);

  const { label } = useGetLabel({ id: props.label.id });
  const { labelGroupList } = useGetLabelGroupListForWorkspace({ workspaceId: workspace.id });

  const filteredLabelGroupList = useMemo(() => {
    return labelGroupList
      .slice()
      .filter((group) => group.id !== label?.labelGroupId)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.title.localeCompare(b.title);
      });
  }, [labelGroupList]);

  if (!label) {
    return <></>;
  }

  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild={asChild}>{children}</DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            className="cursor-pointer text-foreground/60 hover:bg-accent hover:text-accent-foreground"
            aria-selected
            onClick={handleEditLabel}
          >
            <div className="relative flex items-center overflow-hidden text-ellipsis">
              <IconPen className="mr-2 h-3 w-3" />
              Kategorie bearbeiten
            </div>
          </DropdownMenuItem>

          <DropdownMenuSub>
            <DropdownMenuSubTrigger className="cursor-pointer text-foreground/60 hover:bg-accent hover:text-accent-foreground">
              <div className="relative flex items-center overflow-hidden text-ellipsis">
                <IconArrowRight className="mr-2 h-3 w-3" />
                {label.labelGroupId ? "In Gruppe verschieben" : "Zu Gruppe hinzufügen"}
              </div>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {label.labelGroupId && (
                  <RemoveLabelFromGroupAction label={label} workspace={workspace} asChild>
                    <DropdownMenuItem className="flex cursor-pointer flex-row items-center text-foreground/60 hover:bg-accent hover:text-accent-foreground">
                      <IconCircleSolid className="mr-2 h-2.5 w-2.5 text-foreground/40" />
                      Keine Gruppe
                    </DropdownMenuItem>
                  </RemoveLabelFromGroupAction>
                )}
                {filteredLabelGroupList.map((group) => (
                  <AddLabelToGroupAction key={group.id} label={label} labelGroup={group} workspace={workspace} asChild>
                    <DropdownMenuItem className="flex cursor-pointer flex-row items-center text-foreground/60 hover:bg-accent hover:text-accent-foreground">
                      <IconCircleGroup className="mr-1.5 h-3 w-3" style={{ color: group.color }} />
                      {group.title}
                    </DropdownMenuItem>
                  </AddLabelToGroupAction>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuSeparator />

          <DropdownMenuItem
            className="cursor-pointer text-foreground/60 hover:bg-accent hover:text-accent-foreground"
            aria-selected
            onClick={() => {
              setDeleteConfirmationisOpen(true);
            }}
          >
            <div className="relative flex items-center overflow-hidden text-ellipsis">
              <IconTrash className="mr-2 h-3 w-3" />
              Kategorie löschen
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DeleteLabelAction asChild label={label} workspace={workspace} forceDelete={triggerDelete}>
        <DeleteConfirmation
          open={deleteConfirmationisOpen}
          onOpenChange={setDeleteConfirmationisOpen}
          onConfirm={() => {
            setTriggerDelete(true);
          }}
          title={`Sind Sie sich sicher, dass Sie diese Kategorie löschen möchten?`}
          description={`Diese Aktion kann nicht rückgängig gemacht werden. Die Kategorie wird unwideruflich gelöscht und von allen Objekten entfernt.`}
        />
      </DeleteLabelAction>
    </>
  );
};

export default WorkspaceLabelActionButton;
