import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";

import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";

import { FilterCommandConfiguration, FilterCommandView } from "./components";
import { Dialog, DialogContent } from "@/components/_ui/dialog";

import { useObjectiveListState } from "@/components/_domain/objective/hooks";

export interface ObjectiveFilterSelectProps extends PropsWithChildren {
  asChild?: boolean;
  side?: "top" | "right" | "bottom" | "left";
  align?: "start" | "center" | "end";
}

export const ObjectiveFilterSelect: FC<ObjectiveFilterSelectProps> = ({ children, side, align, asChild }) => {
  const { table, columnFilters, fn } = useObjectiveListState();

  const [showPopover, setShowPopover] = useState(false);
  const [replacePopoverContentView, setReplacePopoverContentView] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState<FilterCommandConfiguration | null>(null);

  const columns = useMemo(() => {
    return table
      .getAllColumns()
      .slice()
      .flatMap((column) => {
        if (!column.getCanFilter()) {
          return [];
        }
        if (columnFilters.find((cf) => cf.id == column.id)) {
          return [];
        }

        return column;
      });
  }, [table, columnFilters]);

  const handleFinished = useCallback(() => {
    setShowPopover(false);
    setShowModal(false);
    setSelectedFilterOption(null);
  }, []);

  useEffect(() => {
    if (showPopover == true) {
      return;
    }

    setReplacePopoverContentView(false);
  }, [showPopover]);

  useEffect(() => {
    if (showModal == true) {
      return;
    }

    setSelectedFilterOption(null);
  }, [showModal]);

  const filterOptions: FilterCommandConfiguration[] = useMemo(() => {
    return columns.flatMap((column) => {
      const columnConfiguration = fn.getConfigurationForColumn(column.id);
      if (!columnConfiguration) {
        return [];
      }

      return {
        id: columnConfiguration.columnId,
        displayView: columnConfiguration.getDisplayView(),
        configurationView: columnConfiguration.getConfigurationView(handleFinished),
        configurationViewType: columnConfiguration.getConfigurationViewType(),
      };
    });
  }, [columns]);

  const handleFilterSelect = (filter: FilterCommandConfiguration) => {
    if (filter.configurationViewType == "none") {
      return;
    }

    if (filter.configurationViewType == "popover") {
      setSelectedFilterOption(filter);
      setReplacePopoverContentView(true);
      return;
    }

    if (filter.configurationViewType == "modal") {
      setSelectedFilterOption(filter);
      setShowModal(true);

      return;
    }
  };

  if (!filterOptions) {
    return <></>;
  }

  return (
    <>
      <Popover open={showPopover} onOpenChange={setShowPopover} modal={false}>
        <PopoverTrigger asChild={asChild}>{children}</PopoverTrigger>
        <PopoverContent className="p-0" side={side ?? "right"} align={align ?? "start"}>
          {replacePopoverContentView &&
            selectedFilterOption &&
            selectedFilterOption.configurationViewType == "popover" && <>{selectedFilterOption.configurationView}</>}
          {(!replacePopoverContentView ||
            !selectedFilterOption ||
            selectedFilterOption.configurationViewType !== "popover") && (
            <FilterCommandView filterList={filterOptions} onSelect={handleFilterSelect} />
          )}
        </PopoverContent>
      </Popover>

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent>
          {selectedFilterOption && selectedFilterOption.configurationViewType == "modal" && (
            <>{selectedFilterOption.configurationView}</>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
