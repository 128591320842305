import { LucideProps } from "lucide-react";

const Icon = ({ ...props }: LucideProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="parent-objective"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.111 1H4.89l-.207.005C2.631 1.111 1 2.781 1 4.826v6.348l.005.203C1.113 13.396 2.81 15 4.89 15h.26l.105-.007a.77.77 0 0 0 .672-.758.772.772 0 0 0-.778-.766h-.26l-.159-.005c-1.214-.08-2.173-1.075-2.173-2.29V4.826l.005-.157c.082-1.194 1.093-2.138 2.328-2.138h6.222l.16.005c1.214.08 2.18 1.075 2.18 2.29v.104a.774.774 0 0 0 .771.662c.43 0 .778-.343.778-.766l-.005-.203C14.887 2.604 13.19 1 11.11 1Zm3.857 8.903a.295.295 0 0 0-.038-.325l-2.025-2.462a.326.326 0 0 0-.484-.015l-2.314 2.461a.303.303 0 0 0-.082.207c0 .17.142.308.318.308h1.122c-.155 3.783-3.159 4.305-3.198 4.312a.311.311 0 0 0-.267.303v.025c.013.16.152.284.318.283.054 0 5.305-.058 5.518-4.923h.845a.32.32 0 0 0 .287-.174Z"
      />
    </svg>
  );
};

export default Icon;
