import { FC } from "react";

import { StatusTypes } from "./status-type";

import IconCircleDashed from "@/components/_icons/IconCircleDashed";
import IconCircleOutline from "@/components/_icons/IconCircleOutline";
import IconCircleFilledHalf from "@/components/_icons/IconCircleFilledHalf";
import IconCircleChecked from "@/components/_icons/IconCircleChecked";
import IconCircleCanceled from "@/components/_icons/IconCircleCanceled";

export interface StatusIconProps {
  status: {
    color: string;
    type: string;
  };
  className?: string;
}

export const StatusIcon: FC<StatusIconProps> = ({ status, className }) => {
  if (status.type == StatusTypes.backlog) {
    return <IconCircleDashed className={className} style={{ color: status.color }} />;
  }
  if (status.type == StatusTypes.unstarted) {
    return <IconCircleOutline className={className} style={{ color: status.color }} />;
  }
  if (status.type == StatusTypes.started) {
    return <IconCircleFilledHalf className={className} style={{ color: status.color }} />;
  }
  if (status.type == StatusTypes.completed) {
    return <IconCircleChecked className={className} style={{ color: status.color }} />;
  }
  if (status.type == StatusTypes.canceled) {
    return <IconCircleCanceled className={className} style={{ color: status.color }} />;
  }

  // return the "unstarted" icon by default
  return <IconCircleOutline className={className} style={{ color: status.color }} />;
};

export default StatusIcon;
