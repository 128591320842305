import { FC, useEffect, useRef, useState } from "react";
import { Button } from "@/components/_ui/button";

import { useUpdateFile } from "@/hooks/api/common/useFile";

import { toast } from "@/components/_ui/use-toast";
import { Input } from "@/components/_ui/input";



interface FileUpdateFormProps {
    file: {
        id: string;
        name: string;
        ownerId: string;
    };
    onCancel?: () => void;
    onSuccess?: () => void;
}
export const FileUpdateForm: FC<FileUpdateFormProps> = ({ file, onSuccess, onCancel }) => {
    const { updateFile, loading } = useUpdateFile({ id: file.id, ownerId: file.ownerId })

    const inputRef = useRef<HTMLInputElement>(null);

    const [fileName, setFileName] = useState(file.name);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        // select the text of the filename except for the file extension
        if (inputRef.current) {
            inputRef.current.focus();
            setTimeout(() => {
                inputRef.current?.setSelectionRange(0, file.name.replace(/\.[^/.]+$/, "").length);
            }, 1);
        }
    }, [])


    async function handleCancelClicked() {
        if (onCancel) {
            onCancel()
        }
    }

    async function handleApplyClicked() {
        if (loading) {
            return
        }
        if (fileName.length === 0) {
            setError("Bitte geben Sie einen Namen für das Dokument ein.");
            return;
        }
        try {
            await updateFile({
                variables: {
                    input: {
                        id: file.id,
                        name: fileName,
                    },
                },
            });

            if (onSuccess) {

                toast({
                    title: "Dokument umbenannt",
                    description: `Das Dokument "${file.name}" wurde erfolgreich zu "${fileName}" umbenannt.`,
                    variant: "success",
                });

                onSuccess();
            }
        } catch (e) {
            setError("Das Dokument konnte nicht umbenannt werden. Bitte laden Sie die Seite neu und versuchen Sie es noch einmal.")
        }
    }

    return (
        <div>
            <div className="mb-4 text-sm">Dokument umbenennen</div>
            <div className="mb-4">
                <Input
                    ref={inputRef}
                    value={fileName}
                    onChange={(e) => {
                        setFileName(e.currentTarget.value);
                    }}
                    placeholder={file.name}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleApplyClicked();
                        }
                    }}
                    className="ring-offset-backgroundtransition-colors inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-normal focus:outline-none focus:ring-0 focus-visible:outline-none  focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50"

                />
                {error && error != "" &&
                    <div className="mt-2 text-sm text-red-500">
                        {error}
                    </div>
                }
            </div>
            <div className="flex flex-row items-center justify-end gap-2">
                <Button
                    size="xs"
                    variant="ghost"
                    className="border"
                    onClick={() => {
                        handleCancelClicked();
                    }}
                >
                    Abbrechen
                </Button>
                <Button
                    size="xs"
                    variant="default"
                    disabled={fileName == ""}
                    onClick={() => {
                        handleApplyClicked();
                    }}
                >
                    Speichern
                </Button>
            </div>
        </div>
    );
};
