import { FC, useMemo, useState } from "react";

import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/_ui/select";
import { useToast } from "@/components/_ui/use-toast";

import IconTeams from "@/components/_icons/IconTeams";

import { useCurrentUser, useTeamById, useTeamUpdate } from "@/hooks/api/common";

import { IconPicker } from "@/components/icon-picker";
import { IconView } from "@/components/icon-picker/icon-view";
import { Icon, iconTypefromString } from "@/components/icon-picker/icon.schema";

import {
  VisibilityType,
  VisibilityTypes,
  teamVisibilityTypefromString,
} from "@/components/_domain/team/team-visibility.schema";

export interface TeamEditFormProps {
  team: {
    id: string;
  };
  className?: string;
}
export const TeamEditForm: FC<TeamEditFormProps> = ({ className, ...props }) => {
  const { toast } = useToast();
  const { currentUser } = useCurrentUser();

  const { team, userHasEditRights } = useTeamById({ id: props.team.id });
  const { updateTeam } = useTeamUpdate({
    teamId: props.team.id,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    if (currentUser.superUser) {
      return true
    }
    return userHasEditRights(currentUser.id);
  }, [currentUser, userHasEditRights]);

  const teamIcon = useMemo(() => {
    if (!team) {
      return null;
    }

    if (!team.logo) {
      return null;
    }

    if (!team.logoType) {
      return null;
    }

    const iconType = iconTypefromString(team.logoType);

    if (!iconType) {
      return null;
    }

    return {
      value: team.logo,
      mode: iconType,
      color: team.color,
    } as Icon;
  }, [team]);

  const teamVisiblity = useMemo(() => {
    if (!team) {
      return null;
    }

    const visibilityType = teamVisibilityTypefromString(team.visibility);

    if (!visibilityType) {
      return null;
    }

    return visibilityType;
  }, [team]);

  const [name, setName] = useState(team?.name ?? "");
  const [icon, setIcon] = useState<Icon | null>(teamIcon);
  const [visibility, setVisibility] = useState<VisibilityType>(teamVisiblity ?? VisibilityTypes.public);

  // 2. Define a submit handler.
  function onSubmit() {
    let logo = {};

    if (icon) {
      logo = {
        logoType: icon.mode,
        logo: icon.value,
        color: icon.color,
      };
    }

    updateTeam({
      variables: {
        input: {
          id: props.team.id,
          name: name,
          visibility: visibility,
          ...logo,
        },
      },
    });
  }

  async function onUpdateCompleted(team: { id: string } | undefined) {
    toast({
      title: "Gespeichert",
      description: "Das Team wurde erfolgreich erstellt.",
      variant: "success",
    });

    if (!team) {
      return;
    }
  }

  async function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium">Team Icon und Name</div>
        <div className="flex flex-row items-center gap-2">
          <IconPicker asChild onChange={setIcon}>
            <Button variant="secondary" size="icon" className="h-9 w-10" disabled={!hasEditRights}>
              {icon && <IconView icon={icon} />}
              {!icon && <IconTeams className="text-foreground/40" />}
            </Button>
          </IconPicker>
          <Input
            type="text"
            placeholder="zB. Nachhaltigkeit"
            value={name}
            disabled={!hasEditRights}
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
            className="without-ring"
          />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium">Sichtbarkeit</div>
        <div>
          <Select
            defaultValue={visibility}
            disabled={!hasEditRights}
            onValueChange={(value) => {
              const visibility = teamVisibilityTypefromString(value);

              if (!visibility) {
                return;
              }

              setVisibility(visibility);
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Sichtbarkeit" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={VisibilityTypes.public}>Öffentlich</SelectItem>
              <SelectItem value={VisibilityTypes.private}>Privat</SelectItem>
              <SelectItem value={VisibilityTypes.hidden}>Versteckt</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <Button
        type="submit"
        className="flex h-10 w-32 items-center justify-center rounded-md border text-sm transition-all focus:outline-none"
        disabled={!hasEditRights}
        onClick={onSubmit}
      >
        Speichern
      </Button>
    </div>
  );
};
