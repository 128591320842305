import { FC } from "react";
import { Badge } from "@/components/_ui/badge";
import { useGetObjective } from "@/hooks/api/common";

interface ObjectiveBadgeProps {
  objective: {
    id: string;
  };
}
export const ObjectiveBadge: FC<ObjectiveBadgeProps> = ({ ...props }) => {
  const { objective } = useGetObjective(props.objective);

  if (!objective) {
    return <></>;
  }

  return (
    <Badge variant="secondary" className="text-xs font-light">
      {objective.title}
    </Badge>
  );
};
