import { useCallback, useEffect } from "react";
import { AutoLinkNode, $createLinkNode, $isLinkNode } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { $createTextNode, TextNode } from "lexical";

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const AutoLinkPlugin = () => {
  const [editor] = useLexicalComposerContext();

  const textNodeTransform = useCallback((textNode: TextNode) => {
    if (!textNode.isSimpleText() || !textNode.getTextContent() || $isLinkNode(textNode.getParent())) return;
    const regex = RegExp(URL_MATCHER, "g");
    const matchArr = regex.exec(textNode.getTextContent());
    if (matchArr === null) {
      return;
    }
    const [firstNode, secondNode] = textNode.splitText(matchArr.index, regex.lastIndex);
    const linkNode = $createLinkNode(matchArr[0], {
      title: matchArr[0],
      target: "_blank",
    }).append($createTextNode(matchArr[0]));
    if (matchArr.index > 0 && secondNode) {
      secondNode.replace(linkNode);
    } else {
      firstNode.replace(linkNode);
    }
  }, []);

  useEffect(() => {
    if (!editor.hasNodes([AutoLinkNode])) {
      throw new Error("InterpolationsPlugin: VariableInterpolationNode not registered on editor");
    }

    return editor.registerNodeTransform(TextNode, textNodeTransform);
  }, [editor]);

  return null;
};
