import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getLabelGroupQueryDocument } from "@/graphql/common";

export interface UseGetLabelGroupProps {
  id: string;
}

export const useGetLabelGroup = ({ id }: UseGetLabelGroupProps) => {
  const queryResult = useQuery(getLabelGroupQueryDocument, {
    variables: { input: { id } },
  });

  const labelGroup = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.labelGroup;
  }, [queryResult.data]);

  return {
    queryResult,
    labelGroup,
  };
};
