import { useMutation } from "@apollo/client";
import {
  deleteStatusInstanceMutationDocument,
  getObjectiveQueryDocument,
  getStatusInstancebyOwnerQueryDocument,
} from "@/graphql/common";
import { useMemo } from "react";

export interface UseDeleteStatusInstanceProps {
  ownerId: string;
  ownerType: "OBJECTIVE";
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useDeleteStatusInstance = ({ ownerId, ownerType, onCompleted, onError }: UseDeleteStatusInstanceProps) => {
  const ownerQueryDocument = useMemo(() => {
    if (ownerType == "OBJECTIVE") {
      return {
        query: getObjectiveQueryDocument,
        variables: { input: { id: ownerId } },
      };
    }
    return null;
  }, [ownerId, ownerType]);

  const refetchQueries = useMemo(() => {
    const queries = [
      {
        query: getStatusInstancebyOwnerQueryDocument,
        variables: { input: { ownerId: ownerId } },
      },
    ];

    if (ownerQueryDocument) {
      return [...queries, ownerQueryDocument];
    }
    return [...queries];
  }, [ownerId, ownerQueryDocument]);

  const [deleteStatusInstance] = useMutation(deleteStatusInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: refetchQueries,
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deleteStatusInstance,
  };
};
