import { useState } from "react";
import LoadingDots from "@/components/loading-dots";

import { requestPasswordResetLink } from "@/services/auth.service";

import { APIError } from "@/services/errors.service";

export default function PasswordForgottenForm() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const email = e.currentTarget.email.value;

    try {
      await requestPasswordResetLink(email);
      setErrors([]);
      setSuccessMessage(
        `Wir haben eine E-Mail mit Informationen zum Zurücksetzen Ihres Passworts an ${email} geschickt.`,
      );
      // window.location.replace(new URL(`${window.location.origin}/`))
    } catch (e) {
      setErrorMessages(e);
    } finally {
      setLoading(false);
    }
  };

  // Todo: Figure out how to turn error messages into nice human readable text
  const setErrorMessages = (error: unknown) => {
    if (error instanceof APIError) {
      setErrors(error.toArray());
    } else if (error instanceof Error) {
      setErrors([error.message]);
    } else {
      setErrors(["Etwas ist schiefgegangen. Bitte laden Sie die Seite neu."]);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-4 bg-background px-4 py-8 sm:px-16">
      <div>
        <label htmlFor="email" className="block text-xs text-foreground">
          Email Adresse
        </label>
        <input
          id="email"
          name="email"
          type="email"
          placeholder="angela.merkel@kommune.de"
          autoComplete="email"
          required
          className="shadow-sm mt-1 block w-full appearance-none rounded-md border bg-transparent px-3 py-2 placeholder:text-muted-foreground focus:border-black focus:outline-none focus:ring-black sm:text-sm"
        />
      </div>
      <div className="text-sm">
        {errors.length > 0 && (
          <ul className="list-none text-destructive">
            {errors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        )}
        {successMessage && <ul className="list-none text-green-500">{successMessage}</ul>}
      </div>
      <button
        disabled={loading}
        className={`${loading
            ? "cursor-not-allowed border-gray-200 bg-gray-100"
            : "border-black bg-primary text-primary-foreground hover:bg-primary/90"
          } flex h-10 w-full items-center justify-center rounded-md border text-sm transition-all focus:outline-none`}
      >
        {loading ? <LoadingDots color="#808080" /> : <p>Link Anfordern</p>}
      </button>
    </form>
  );
}
