import { FC, PropsWithChildren, useState } from "react";

import { Dialog, DialogContent, DialogTrigger } from "@/components/_ui/dialog";
import { TeamBrowseList } from "./team-browselist";

interface TeamBrowseAllDialogProps extends PropsWithChildren {
  asChild?: boolean;
  className?: string;
  workspace: {
    id: string;
  };
  user: {
    id: string;
  };
}

export const TeamBrowseAllDialog: FC<TeamBrowseAllDialogProps> = ({
  children,
  asChild,
  className,
  workspace,
  user,
}) => {
  const [dialogisOpen, setDialogisOpen] = useState(false);

  function onDialogOpenChange(open: boolean) {
    setDialogisOpen(open);
  }

  return (
    <>
      <Dialog open={dialogisOpen} onOpenChange={onDialogOpenChange}>
        <DialogTrigger asChild={asChild} className={className}>
          {children}
        </DialogTrigger>
        <DialogContent className="top-[1%] max-h-[99vh] max-w-xl translate-y-[0%] overflow-y-scroll md:top-[10%] md:max-h-[80vh]">
          {/* <WorkspaceCreateForm
            triggerCloseDialog={() => {
              setDialogisOpen(false);
            }}
          /> */}
          <TeamBrowseList workspace={workspace} user={user} />
        </DialogContent>
      </Dialog>
    </>
  );
};
