import { FC, useMemo } from "react";

import { ObjectiveListViewTypes } from "./objective-list-view-type";

import { useObjectiveListDisplayOptions } from "../hooks";
import { ObjectiveListView } from "./objective-list-main-view/objective-simple-list-view";

export interface ObjectiveListOverviewProps {}

export const ObjectiveListOverview: FC<ObjectiveListOverviewProps> = ({}) => {
  const { selectedObjectiveListViewType } = useObjectiveListDisplayOptions();

  const mainView = useMemo(() => {
    if (selectedObjectiveListViewType == ObjectiveListViewTypes.LIST_VIEW) {
      return <ObjectiveListView />;
    }
    if (selectedObjectiveListViewType == ObjectiveListViewTypes.TABLE_VIEW) {
      return <></>;
    }
    if (selectedObjectiveListViewType == ObjectiveListViewTypes.BOARD_VIEW) {
      return <></>;
    }

    //default
    return <ObjectiveListView />;
  }, [selectedObjectiveListViewType]);

  return (
    <div className="overflow-y-scroll pb-24">
      <div className="relative flex grow items-stretch">{mainView}</div>
    </div>
  );
};
