import { Button } from "@/components/_ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger } from "@/components/_ui/select";
import { cn } from "@/lib/utils";
import { ArrowDownIcon, ArrowUpIcon } from "@radix-ui/react-icons";

import { Table } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";

interface ObjectiveListOrderingSelectProps<TData> {
  table: Table<TData>;
  getDisplayValueForColumn: (id: string) => string;
}

interface SelectedColumn {
  columnId: string;
  displayValue: string;
  direction: "asc" | "desc";
}

export function ObjectiveListOrderingSelect<TData>({
  table,
  getDisplayValueForColumn,
}: ObjectiveListOrderingSelectProps<TData>) {
  const [selectedColumn, setSelectedColumn] = useState<SelectedColumn | null>(null);

  const columns = useMemo(() => {
    return table
      .getAllColumns()
      .slice()
      .filter((column) => column.getCanSort())
      .sort((a, b) => {
        const displayValueA = getDisplayValueForColumn(a.id).toLocaleLowerCase();
        const displayValueB = getDisplayValueForColumn(b.id).toLocaleLowerCase();
        return displayValueA.localeCompare(displayValueB);
      });
  }, [table]);

  useEffect(() => {
    const sortedColumn = columns.find((c) => c.getIsSorted() !== false);

    if (!sortedColumn) {
      return;
    }

    setSelectedColumn({
      columnId: sortedColumn.id,
      displayValue: getDisplayValueForColumn(sortedColumn.id),
      direction: sortedColumn.getIsSorted() === "desc" ? "desc" : "asc",
    });
  }, []);

  const getColumnForId = useCallback(
    (id: string) => {
      return columns.find((c) => c.id == id);
    },
    [columns],
  );

  const onValueChange = (columnId: string) => {
    const column = getColumnForId(columnId);

    if (!column) {
      return;
    }

    const currentSortDirection = column.getIsSorted();
    const descending = currentSortDirection === false || currentSortDirection === "desc" ? false : true;

    table.resetSorting();
    column.toggleSorting(descending);

    setSelectedColumn({
      columnId: columnId,
      displayValue: getDisplayValueForColumn(columnId),
      direction: descending ? "desc" : "asc",
    });
  };

  const toggleDirection = () => {
    if (!selectedColumn) {
      return;
    }
    const column = getColumnForId(selectedColumn.columnId);

    if (!column) {
      return;
    }

    const currentSortDirection = column.getIsSorted();
    const descending = currentSortDirection === false || currentSortDirection === "asc" ? true : false;

    column.toggleSorting(descending);

    setSelectedColumn({
      columnId: selectedColumn.columnId,
      displayValue: getDisplayValueForColumn(selectedColumn.columnId),
      direction: descending ? "desc" : "asc",
    });
  };

  return (
    <>
      <Select value={selectedColumn?.columnId ?? ""} onValueChange={onValueChange}>
        <SelectTrigger className="h-6 px-2 text-xs ring-offset-background transition-colors hover:bg-accent hover:text-accent-foreground focus:outline-none focus:ring-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0">
          <div className="flex flex-row items-center justify-between text-xs">
            {!selectedColumn && "Klicken um zu sortieren"}
            {selectedColumn && (
              <>
                {selectedColumn.direction === "desc" && <ArrowDownIcon className="mr-2 h-3 w-3" />}
                {selectedColumn.direction === "asc" && <ArrowUpIcon className="mr-2 h-3 w-3" />}
                {selectedColumn.displayValue}
              </>
            )}
          </div>
        </SelectTrigger>
        <SelectContent className="p-0">
          {columns.map((column) => {
            return (
              <SelectItem
                key={column.id}
                value={column.id}
                className="flex h-6 flex-row items-center justify-between text-xs"
              >
                {getDisplayValueForColumn(column.id)}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
      {selectedColumn && (
        <Button
          variant={"ghost"}
          size="icon"
          className={cn(["ml-1 h-6 rounded-md border text-left text-xs font-normal "])}
          onClick={() => {
            toggleDirection();
          }}
        >
          <>
            {selectedColumn.direction === "desc" && <ArrowDownIcon className="mx-2 h-3 w-3" />}
            {selectedColumn.direction === "asc" && <ArrowUpIcon className="mx-2 h-3 w-3" />}
          </>
        </Button>
      )}
    </>
  );
}
