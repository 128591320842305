import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Link } from "react-router-dom";
import PasswordResetForm from "./reset-form";

import NotFound from "../../NotFound";
import { isValidPasswordResetToken } from "@/services/auth.service";

export default function Page() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  if (!token) {
    return <NotFound />;
  }

  return <IsValidTokenInnerPage token={token} />;
}

export interface IsValidTokenInnerPageProps extends PropsWithChildren {
  token: string;
}

const IsValidTokenInnerPage: FC<IsValidTokenInnerPageProps> = ({ token }) => {
  const [email, setEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    checkTokenAndGetMetaData();
  }, []);

  async function checkTokenAndGetMetaData() {
    try {
      const { email } = await isValidPasswordResetToken(token);
      setEmail(email);
    } catch (e) {
      // implicit error handling, since e-mail stays null
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <></>;
  }

  if (!email) {
    return (
      <NotFound text="Der Passwort-Reset-Link wurde nicht gefunden oder ist bereits abgelaufen.">
        <p className="mb-4 text-center text-xs text-muted-foreground">
          Wenn sie Ihr Passwort vergessen haben können Sie einen neuen{" "}
          <Link to="/forgot-password" className="font-semibold text-muted-foreground">
            Link zum zurücksetzen
          </Link>{" "}
          anfordern.
        </p>
        <p className="mb-4 text-center text-xs text-muted-foreground">
          Falls Sie sich doch an Ihr Passwort erinnern können Sie sich{" "}
          <Link to="/login" className="font-semibold text-muted-foreground">
            direkt anmelden.
          </Link>
        </p>
      </NotFound>
    );
  }

  return <InnerPage token={token} email={email} />;
};

export interface InnerPageProps extends PropsWithChildren {
  token: string;
  email: string;
}

const InnerPage: FC<InnerPageProps> = ({ token, email }) => {
  useEffect(() => { }, []);

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-background text-foreground">
      <div className="z-10 w-full max-w-md overflow-hidden rounded border shadow-xl">
        <div className="flex flex-col items-center justify-center space-y-3 border-b px-4 py-6 pt-8 text-center sm:px-16">
          <h3 className="text-xl font-semibold">Passwort zurücksetzen</h3>
          <p className="text-sm text-muted-foreground">
            Wählen Sie ein neues Passwort {email && `für Ihren Account ${email} `}
            aus.
          </p>
        </div>

        <PasswordResetForm token={token} />

        <p className="mb-8 text-center text-sm text-muted-foreground">
          Erinnern Sie sich an Ihre Login Daten?{" "}
          <Link to="/register" className="font-semibold text-muted-foreground">
            Melden Sie sich an.
          </Link>{" "}
        </p>
      </div>
    </div>
  );
};
