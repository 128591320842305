import { FC, useCallback, useMemo, useState } from "react";

import TextareaAutosize from "react-textarea-autosize";
import { RichTextEditor } from "@/components/rich-text-editor";

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { useMutation } from "@apollo/client";
import { updateObjectiveMutationDocument } from "@/graphql/common";

import debounce from "lodash.debounce";
import { toast } from "@/components/_ui/use-toast";

enum SyncState {
  SAVING = "speichert ...",
  SAVED = "gespeichert",
  UNSAVED_CANGES = "ungesicherte Änderungen",
}

const formSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
});

export interface ObjectiveEditFormProps {
  className?: string;
  objectiveFormData: {
    id: string;
    title: string;
    properties?: string | null | undefined;
    parentId?: string | null | undefined;
  };
}
export const ObjectiveEditForm: FC<ObjectiveEditFormProps> = ({ objectiveFormData }) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const [syncState, setSyncState] = useState<SyncState | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: objectiveFormData.title,
      description: objectiveFormData.properties ?? "",
    },
  });
  const [updateObjective] = useMutation(updateObjectiveMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });
  const debouncedSaveChanges = useCallback(
    debounce(() => {
      saveChanges();
    }, 1500),
    [],
  );

  const initialEditorState = useMemo(() => {
    if (!objectiveFormData.properties) {
      return undefined;
    }
    if (objectiveFormData.properties == "") {
      return undefined;
    }
    return objectiveFormData.properties;
  }, [objectiveFormData.properties]);

  async function handleFormChange() {
    setDirty(true);
    setSyncState(SyncState.UNSAVED_CANGES);
    debouncedSaveChanges();
  }

  async function onRichTextEditorChange(value: string, json: string) {
    if (value == "") {
      form.setValue("description", "");
    } else {
      form.setValue("description", json);
    }
    handleFormChange();
  }

  async function saveChanges() {
    setDirty(false);
    setSyncState(SyncState.SAVING);
    await updateObjective({
      variables: {
        input: {
          id: objectiveFormData.id,
          title: form.getValues("title"),
          properties: form.getValues("description"),
        },
      },
    });
  }

  async function onUpdateCompleted() {
    if (!dirty) {
      setSyncState(SyncState.SAVED);
    }
  }

  async function onUpdateError() {
    if (!dirty) {
      setSyncState(SyncState.UNSAVED_CANGES);
    }
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <form onChange={handleFormChange}>
      <div className="grid w-full gap-10">
        <div className="mx-auto w-full">
          <TextareaAutosize
            autoFocus
            id="title"
            defaultValue={"Neues Ziel"}
            placeholder="Name des Ziels"
            className="font-base mt-3 w-full resize-none appearance-none overflow-hidden bg-transparent p-3 text-xl focus:outline-none"
            {...form.register("title")}
          />
          <RichTextEditor onChange={onRichTextEditorChange} initialValue={initialEditorState} />
          <div className="ml-4 flex flex-row justify-start italic">
            <div className="text-xxs text-muted-foreground">{syncState ?? " "}</div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ObjectiveEditForm;
