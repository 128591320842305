import { FC } from "react";
import { useGetObjective } from "@/hooks/api/common";

import { ObjectiveDescription } from "./objective-description";
import { ObjectiveChildTable } from "./objective-child-table";

interface ObjectiveDetailTabProps {
  workspace: {
    id: string;
  };
  objective: {
    id: string;
  };
}

export const ObjectiveDetailTab: FC<ObjectiveDetailTabProps> = (props) => {
  const { objective } = useGetObjective({ id: props.objective.id });

  if (!objective) {
    return <></>;
  }

  return (
    <main className="relative place-items-stretch overflow-auto">
      <ObjectiveDescription {...props} />
      <ObjectiveChildTable {...props} />
      <hr className="mx-3 py-3" />
    </main>
  );
};
