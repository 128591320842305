import { FC } from "react";
import { Link, useParams } from "react-router-dom";

import Header from "@/components/header";
import { Loading } from "@/components/loading"
import NotFound from "@/routes/NotFound";

import { useCurrentWorkspace } from "@/hooks/useCurrentWorkspace";
import { useCurrentUser, useTeamListForUserInWorkspace, useWorkspaceGetById } from "@/hooks/api/common";

import {
  ObjectiveListStateProvider,
  ObjectiveCreateHandlerProvider,
  ObjectiveListDisplayOptionsStateProvider,
} from "@/components/_domain/objective/hooks";

import {
  ObjectiveListOverview,
  ObjectiveListQuickSearch,
  ObjectiveListFilterView,
  ObjectiveListConfigurationView,
} from "@/components/_domain/objective/objective-list-overview";
import { useGetObjectiveListAssignedToUser } from "@/hooks/api/common/useObjective/useGetObjectiveListAssignedToUser";

import IconMyGoals from "@/components/_icons/IconMyGoals";
import { useLayout } from "@/hooks/useLayout";
import { useUserObjectiveListColumnConfiguration } from "./useUserObjectiveListColumnConfiguration";

interface InnerPageProps {
  workspace: {
    id: string;
  };
}

const InnerPage: FC<InnerPageProps> = ({ ...props }) => {
  const { workspace } = useWorkspaceGetById(props.workspace);
  const { sidebarState } = useLayout();

  if (!workspace) {
    return <></>;
  }

  return (
    <div className="flex h-screen max-h-screen flex-col overflow-y-hidden">
      <Header className={sidebarState?.isFixed ? "pl-6" : "pl-12"}>
        <div className="flex flex-row items-center">
          <Link to={`/${workspace.workspaceUrl}/my-goals`} className="flex flex-row items-center">
            <IconMyGoals className="mr-2 h-3 w-3 text-foreground" />
            <span className="flex flex-row items-center">Meine Ziele</span>
          </Link>
        </div>
        <div className="flex flex-row items-center">
          <ObjectiveListQuickSearch />
        </div>
      </Header>

      <ObjectiveListConfigurationView />
      <ObjectiveListFilterView />
      <ObjectiveListOverview />
    </div>
  );
};

function AuthenticatedPage({ currentUser }: { currentUser: { id: string } }) {
  const { workspaceUrl } = useParams();
  const { currentWorkspace } = useCurrentWorkspace();
  const { activeTeamList } =
    currentUser && currentWorkspace
      ? useTeamListForUserInWorkspace({ workspaceId: currentWorkspace.id, userId: currentUser.id })
      : { activeTeamList: [] };

  const { objectiveList } = useGetObjectiveListAssignedToUser({
    user: currentUser ?? { id: "" },
    teamList: activeTeamList,
  });

  const tableColumnConfiguration = useUserObjectiveListColumnConfiguration({
    workspace: currentWorkspace,
    user: currentUser,
    teamList: activeTeamList,
  });

  if (!workspaceUrl) {
    return <NotFound />;
  }

  if (!currentUser) {
    return <NotFound />;
  }

  return (
    <ObjectiveListStateProvider
      workspace={currentWorkspace}
      user={currentUser}
      teamList={activeTeamList}
      objectiveList={objectiveList}
      showChildren={true}
      enableExpanding={false}
      config={tableColumnConfiguration}
    >
      <ObjectiveListDisplayOptionsStateProvider>
        <ObjectiveCreateHandlerProvider>
          <InnerPage workspace={currentWorkspace} />
        </ObjectiveCreateHandlerProvider>
      </ObjectiveListDisplayOptionsStateProvider>
    </ObjectiveListStateProvider>
  );
}

export default function Page() {
  const { currentUser, loading } = useCurrentUser();

  if (loading) {
    return <Loading />
  }


  if (!currentUser) {
    return <NotFound />;
  }

  return <AuthenticatedPage currentUser={currentUser} />;
}
