import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getLabelQueryDocument } from "@/graphql/common";

export interface UseGetLabelProps {
  id?: string | null;
}

export const useGetLabel = ({ id }: UseGetLabelProps) => {
  const queryResult = useQuery(getLabelQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id,
  });

  const label = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.label;
  }, [queryResult.data]);

  return {
    queryResult,
    label,
  };
};
