import { FC } from "react";

import { Link } from "react-router-dom";

interface ObjectiveChildListItemProps {
  objective: {
    id: string;
    title: string;
  } | null;
}

export const ObjectiveChildListItem: FC<ObjectiveChildListItemProps> = ({ objective }) => {
  if (!objective) {
    return <></>;
  }

  return (
    <Link key={objective.id} to={`../${objective.id}`}>
      <div className="flex cursor-pointer select-none flex-row items-center rounded-md px-2 py-2 text-xs hover:bg-muted">
        <span>{objective.title}</span>
      </div>
    </Link>
  );
};

export default ObjectiveChildListItem;
