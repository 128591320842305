import { FC } from "react";

import { cn } from "@/lib/utils";

import IconPriorityHigh from "@/components/_icons/IconPriorityHigh";

export interface FilterByPriorityDisplayViewProps {
  className?: string;
}

export const FilterByPriorityDisplayView: FC<FilterByPriorityDisplayViewProps> = ({ className }) => {
  return (
    <>
      <IconPriorityHigh className={cn(["h-4 w-4 text-foreground/60", className])} />
      <span className="text-xs">Priorität</span>
    </>
  );
};
