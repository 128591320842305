import { usePreLoader } from "@/hooks/api/common";
import { ReactNode } from "react";

interface PreLoaderProps {
  workspace: {
    id: string;
  };
  user: {
    id: string;
  };
  children: ReactNode;
}

export const PreLoader = ({ workspace, user, children }: PreLoaderProps) => {
  const { loading } = usePreLoader({ workspace, user });

  if (loading) {
    return <></>;
  }

  return <>{children}</>;
};
