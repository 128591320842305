import { FC, PropsWithChildren, ReactElement, useMemo, useState } from "react";

import { Combobox, ComboboxOption } from "@/components/combobox";

import { HealthSelectDropdownItem } from "./health-select-dropdown-item";
import { ALLOWED_HEALTH_TYPES, HealthType, HealthTypes } from "../health-types";

export interface HealthSelectDropdownProps extends PropsWithChildren {
  defaultValue?: HealthType;
  onSelect?: (health: HealthType) => void;
  asChild?: boolean;
}

export const HealthSelectDropdown: FC<HealthSelectDropdownProps> = ({ children, asChild, onSelect, ...props }) => {
  const [selectedHealth, setSelectedHealth] = useState<HealthType>(props.defaultValue ?? HealthTypes.onTrack);

  const comboboxOptions = useMemo(() => {
    return ALLOWED_HEALTH_TYPES.slice().flatMap((u) => {
      if (!u) {
        return [];
      }
      return {
        id: u,
        value: u as HealthType,
      };
    });
  }, []);

  const renderItem = (option: ComboboxOption): ReactElement => {
    const selected = selectedHealth == option.id;
    return <HealthSelectDropdownItem type={option.value as HealthType} selected={selected} />;
  };

  const handleUserSelected = (selectedOption: ComboboxOption | null) => {
    if (!selectedOption) {
      return;
    }

    setSelectedHealth(selectedOption.value as HealthType);
    if (onSelect) {
      onSelect(selectedOption.value as HealthType);
    }
  };

  if (!comboboxOptions) {
    return <></>;
  }

  return (
    <Combobox
      asChild={asChild}
      options={comboboxOptions}
      renderItem={renderItem}
      onSelect={handleUserSelected}
      placeholder="Gesundheit"
      side="bottom"
    >
      {children}
    </Combobox>
  );
};
