import { graphql } from "../../generated/gql";

export const getStatusInstanceQueryDocument = graphql(/* GraphQL */ `
  query GetStatusInstance($input: GetStatusInstanceInput!) {
    statusInstance(getStatusInstanceInput: $input) {
      id
      statusId
      ownerId
      ownerType
      teamId
    }
  }
`);

export const getStatusInstancebyOwnerQueryDocument = graphql(/* GraphQL */ `
  query GetStatusInstanceByOwner($input: GetStatusInstanceByOwnerInput!) {
    statusInstanceByOwner(getStatusInstanceByOwnerInput: $input) {
      id
      statusId
      ownerId
      ownerType
      teamId
    }
  }
`);
