import { FC, useState, useMemo } from "react";

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { useMutation } from "@apollo/client";
import {
  getObjectiveQueryDocument,
  getKeyResultQueryDocument,
  updateKeyResultMutationDocument,
} from "@/graphql/common";

import { toast } from "@/components/_ui/use-toast";
import { Button } from "@/components/_ui/button";
import AvatarWorkspace from "@/components/_domain/workspace/avatar-workspace";
import { Badge } from "@/components/_ui/badge";

import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/_ui/form";
import { Input } from "@/components/_ui/input";

import { cn } from "@/lib/utils";
import { Textarea } from "@/components/_ui/textarea";

import {
  MILESTONE_KEY_RESULT_TYPE,
  QUANTITATIVE_KEY_RESULT_TYPE,
  keyResultFormSchema as formSchema,
} from "../models/key-result.form.schema";

import KeyResultTypeSelect from "../components/key-result-type-select";
import KeyResultUnitSelect from "../components/key-result-unit-select";
import KeyResultMilestoneInput from "../components/key-result-milestones-input";
import InputDate from "@/components/input-date";

import {
  KeyResult,
  MilestoneKeyResult,
  MilestoneKeyResultSchema,
  QuantitativeKeyResult,
  QuantitativeKeyResultSchema,
} from "../models/key-result.schema";
import { useGetKeyResult, useGetObjective } from "@/hooks/api/common";

export interface KeyResultEditFormProps {
  className?: string;
  objective: {
    id: string;
  };
  keyResult: {
    id: string;
  };
  workspace: {
    id: string;
    name: string;
    workspaceUrl: string;
    logo?: string | null;
  };
  triggerCloseDialog: () => void;
}

interface InnerKeyResultEditFormProps extends KeyResultEditFormProps {
  keyResult: KeyResult;
  team: {
    id: string;
  };
}

const minDate = new Date("1900-01-01");

export const KeyResultEditForm: FC<KeyResultEditFormProps> = ({ keyResult: _keyResult, ...props }) => {
  const { keyResult } = useGetKeyResult({ id: _keyResult.id });
  const { objective } = useGetObjective({ id: props.objective.id });

  if (!keyResult) {
    return <></>;
  }

  if (!objective) {
    return <></>;
  }

  return <InnerKeyResultEditForm keyResult={keyResult} team={{ id: objective.teamId }} {...props} />;
};

const InnerKeyResultEditForm: FC<InnerKeyResultEditFormProps> = ({
  keyResult,
  objective,
  team,
  workspace,
  triggerCloseDialog,
}) => {
  const [minDateTarget, setMinDateTarget] = useState(new Date(minDate));

  const quantitativeKeyResult: QuantitativeKeyResult | null = useMemo(() => {
    const parsedQuantitativeKeyResult = QuantitativeKeyResultSchema.safeParse(keyResult);
    if (parsedQuantitativeKeyResult.success) {
      return parsedQuantitativeKeyResult.data;
    }

    return null;
  }, [keyResult]);

  const milestoneKeyResult: MilestoneKeyResult | null = useMemo(() => {
    const parsedMilestoneKeyResult = MilestoneKeyResultSchema.safeParse(keyResult);

    if (parsedMilestoneKeyResult.success) {
      return parsedMilestoneKeyResult.data;
    }

    return null;
  }, [keyResult]);

  const [formType, setFormType] = useState<string>(
    quantitativeKeyResult
      ? QUANTITATIVE_KEY_RESULT_TYPE
      : milestoneKeyResult
        ? MILESTONE_KEY_RESULT_TYPE
        : QUANTITATIVE_KEY_RESULT_TYPE,
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: keyResult.title,
      description: keyResult.description ?? "",
      type: keyResult.type,
      initialValue: keyResult.initialValue.toString(),
      targetValue: keyResult.targetValue.toString(),
      unit: keyResult.unitId ?? undefined,
      startDate: keyResult.startDate,
      targetDate: keyResult.targetDate,
      milestones:
        keyResult.type == MILESTONE_KEY_RESULT_TYPE && milestoneKeyResult
          ? milestoneKeyResult.properties.milestones
          : [],
    },
  });

  const [updateKeyResult] = useMutation(updateKeyResultMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: getObjectiveQueryDocument,
        variables: { input: { id: objective.id } },
      },
      {
        query: getKeyResultQueryDocument,
        variables: { input: { id: keyResult.id } },
      },
    ],
  });

  // eslint-disable-next-line
  async function onUpdateCompleted(updatedkeyResult: any) {
    const { id, title } = updatedkeyResult.updateKeyResult;

    if (id && title) {
      toast({
        title: "Erfolgsindikator erstellt",
        description: (
          <div className="gap-21 flex flex-col">
            <span>Die Änderungen des Erfolgsindikator {title} wurde gespeichert.</span>
          </div>
        ),
        variant: "success",
      });
    }

    form.reset();
    triggerCloseDialog();
  }

  async function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.

    if (!keyResult) {
      return;
    }

    const keyResultType = values.type;

    if (keyResultType == QUANTITATIVE_KEY_RESULT_TYPE) {
      const updatedKeyResult = {
        id: keyResult.id,
        unitId: values.unit ? values.unit : null,
        title: values.title,
        description: values.description,
        initialValue: Number(values.initialValue),
        targetValue: Number(values.targetValue),
        startDate: values.startDate,
        targetDate: values.targetDate,

        type: keyResultType,
      };

      await updateKeyResult({
        variables: {
          input: {
            ...updatedKeyResult,
          },
        },
      });
    }

    if (keyResultType == MILESTONE_KEY_RESULT_TYPE) {
      const newKeyResult = {
        id: keyResult.id,
        unitId: null,
        title: values.title,
        description: values.description,
        initialValue: 0,
        targetValue: values.milestones.length,
        currentValue: values.milestones.filter((m) => m.completed).length,
        startDate: values.startDate,
        targetDate: values.targetDate,

        type: keyResultType,
        properties: JSON.stringify({
          milestones: values.milestones,
        }),
      };
      await updateKeyResult({
        variables: {
          input: {
            ...newKeyResult,
          },
        },
      });
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className={cn("space-y-6")}>
        <div className="grid w-full">
          {/* Header */}
          <div className="flex flex-row items-center gap-1">
            <Badge variant="outline" className="py-2 opacity-40">
              <div className="flex flex-row items-center gap-1">
                <AvatarWorkspace workspace={workspace} className="h-5 w-5 text-xxs" />
                <span className="ml-1 overflow-hidden text-ellipsis font-normal">{workspace.name}</span>
              </div>
            </Badge>
            <span className="ml-1 mr-1">{"›"}</span>
            <div className="block w-48 overflow-hidden truncate text-ellipsis whitespace-nowrap text-xs">
              Erfolgsindikator Anpassen
            </div>
          </div>
          {/* Main Part */}
          <div className="mx-auto mb-5 mt-3 flex w-full flex-col gap-2">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name des Indikators</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Was versuchen Sie zu messen?"
                      autoComplete="off"
                      className="focus-visible:ring-slate-600 dark:focus-visible:ring-slate-600"
                    />
                  </FormControl>
                  <FormMessage className="px-2" />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Beschreibung</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="Was müssen andere Personen über diesen Zielindikator wissen?"
                      autoComplete="off"
                      className="focus-visible:ring-slate-600 dark:focus-visible:ring-slate-600"
                    />
                  </FormControl>
                  <FormMessage className="px-2" />
                </FormItem>
              )}
            />

            <div className="mt-2 flex flex-row justify-between">
              <FormField
                control={form.control}
                name="startDate"
                render={({ field }) => (
                  <FormItem className="flex w-[48%] flex-col">
                    <FormLabel>Zeitrahmen</FormLabel>
                    <InputDate
                      placeholder="Startdatum"
                      defaultValue={field.value}
                      onValueChange={(value) => {
                        form.setValue("startDate", value ?? "");

                        if (!value) {
                          setMinDateTarget(minDate);
                        } else {
                          const startDate = new Date(value);
                          const startDatePlusOne = new Date(startDate.getTime() + 86400000); // 1 day in milleseconds
                          setMinDateTarget(startDatePlusOne);
                        }
                      }}
                      minDate={minDate}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="targetDate"
                render={({ field }) => (
                  <FormItem className="flex w-[48%] flex-col">
                    <FormLabel>&nbsp;</FormLabel>
                    <InputDate
                      placeholder="Zieldatum"
                      defaultValue={field.value}
                      onValueChange={(value) => {
                        form.setValue("targetDate", value ?? "");
                      }}
                      minDate={minDateTarget}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {keyResult && (
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormDescription>Wie wollen Sie Erfolg messen?</FormDescription>
                    <FormLabel>Art des Indikators</FormLabel>
                    <KeyResultTypeSelect
                      onValueChange={(e) => {
                        setFormType(e);
                        field.onChange(e);
                      }}
                      defaultValue={keyResult.type}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {formType == QUANTITATIVE_KEY_RESULT_TYPE && (
              <div className="mt-2 flex flex-row justify-between">
                <FormField
                  control={form.control}
                  name="initialValue"
                  render={({ field }) => (
                    <FormItem className="flex w-[31%] flex-col">
                      <FormLabel>Fortschrittsmessung</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type="number"
                          step={0.01}
                          min={0}
                          autoComplete="off"
                          placeholder="Initialwert"
                          className="focus-visible:ring-slate-600 dark:focus-visible:ring-slate-600"
                        />
                      </FormControl>
                      <FormMessage className="px-2" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="targetValue"
                  render={({ field }) => (
                    <FormItem className="flex w-[31%] flex-col">
                      <FormLabel>&nbsp;</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type="number"
                          step={0.01}
                          min={0}
                          autoComplete="off"
                          placeholder="Zielwert"
                          className="focus-visible:ring-slate-600 dark:focus-visible:ring-slate-600"
                        />
                      </FormControl>
                      <FormMessage className="px-2" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="unit"
                  render={({ field }) => (
                    <FormItem className="flex w-[31%] flex-col">
                      <FormLabel>&nbsp;</FormLabel>
                      <KeyResultUnitSelect onValueChange={field.onChange} defaultValue={field.value} team={team} />
                      <FormMessage className="px-2" />
                    </FormItem>
                  )}
                />
              </div>
            )}

            {formType == MILESTONE_KEY_RESULT_TYPE && (
              <FormField
                control={form.control}
                name="milestones"
                render={() => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Meilensteine</FormLabel>
                    <KeyResultMilestoneInput
                      onValueChange={(value) => {
                        form.setValue("milestones", value);
                      }}
                      defaultValue={milestoneKeyResult ? milestoneKeyResult.properties.milestones : undefined}
                    />
                    <FormMessage className="px-2" />
                  </FormItem>
                )}
              />
            )}
          </div>
          {/* <div className="border-b"></div> */}
          <div className="flex flex-row items-center justify-end gap-4 pt-2">
            <Button type="submit" size="sm">
              Erfolgsindikator speichern
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default KeyResultEditForm;
