import { ColumnDef } from "@tanstack/react-table";
import { Objective } from "@/components/_domain/objective/models";

import { ObjectiveListCellStartDate, ObjectiveListColumnHeader } from "../..";

import { compareDates } from "@/lib/utils";
import { ObjectiveColumnConfiguration } from "../column-definition-type";

interface StartDateColumnDefinitionProps {
  workspaceId: string;
  columnId: string;
  orderValue: number;
}

export const StartDateColumnDefinition = ({
  columnId,
  orderValue,
}: StartDateColumnDefinitionProps): ObjectiveColumnConfiguration => {
  return {
    columnId: columnId,
    getDefaultVisibilityState: () => false,
    getDisplayValue: () => "Start Datum",
    getOrderValue: () => orderValue, // prefix with 100, 200, 300 to assign to sections
    getColumnDef: (): ColumnDef<Objective> => {
      return {
        id: columnId,
        accessorKey: columnId, // key needed to enable sorting / filtering
        enableHiding: true,
        enableSorting: true,
        enableColumnFilter: false,
        sortingFn: (rowA, rowB) => {
          if (rowA.original.keyResult && !rowB.original.keyResult) {
            return 1;
          }
          if (rowB.original.keyResult && !rowA.original.keyResult) {
            return -1;
          }

          if (!rowA.original.keyResult) {
            return 0;
          }
          if (!rowB.original.keyResult) {
            return 0;
          }

          return compareDates(new Date(rowA.original.keyResult.startDate), new Date(rowB.original.keyResult.startDate));
        },
        size: 1, // set to 1 instead to 0 for it to work in Safari
        header: ({ column }) => (
          <ObjectiveListColumnHeader column={column} title="" className="px-1 text-xs font-normal" />
        ),
        cell: ({ row }) => <ObjectiveListCellStartDate row={row} className="mx-2" />,
      };
    },
    getDisplayView: () => <></>,
    getConfigurationView: (_onFinished: () => void) => <></>,
    getConfigurationViewType: () => "none",
    getFilterPaneView: () => <></>,
  };
};
