import { FC, PropsWithChildren, useContext, useState } from "react";

import { CurrentWorkspaceType, WorkspaceContext } from "@/hooks/useCurrentWorkspace";

import { Button } from "@/components/_ui/button";
import WorkspaceLabelGroupList from "./label-group/workspace-label-group-list";
import WorkspaceLabelList from "./label/workspace-label-list";

import WorkspaceLabelCreateForm from "./label/workspace-label-create-form";
import WorkspaceLabelGroupCreateForm from "./label-group/workspace-label-group-create-form";

export interface WorkspaceLabelTablePropsWithChildren extends PropsWithChildren {
  workspace: CurrentWorkspaceType;
  className?: string;
}

export const WorkspaceLabelTable: FC<WorkspaceLabelTablePropsWithChildren> = ({ workspace }) => {
  const { hasEditRights } = useContext(WorkspaceContext);
  const [showCreateLabel, setShowCreateLabel] = useState(false);
  const [showCreateLabelGroup, setShowCreateLabelGroup] = useState(false);

  return (
    <div>
      <div>
        <p className="font-light text-muted-foreground">
          Verwenden Sie Kategorien und Gruppen von Kategorien, um Ziele in Ihrem Workspace zu organisieren und zu
          filtern. Die in diesem Abschnitt erstellten Kategorien sind für alle Teams des Workspaces verfügbar.
        </p>
        <div className="my-8"></div>
        {hasEditRights && (
          <div className="flex shrink grow flex-row items-center justify-end gap-3">
            <div className="flex flex-row gap-6"></div>
            <Button
              size="xs"
              variant="outline"
              disabled={showCreateLabel || showCreateLabelGroup}
              onClick={() => {
                setShowCreateLabelGroup(true);
              }}
            >
              Neue Gruppe
            </Button>
            <Button
              size="xs"
              variant="default"
              disabled={showCreateLabel || showCreateLabelGroup}
              onClick={() => {
                setShowCreateLabel(true);
              }}
            >
              Neue Kategorie
            </Button>
          </div>
        )}
      </div>
      <div className="mt-10">
        {showCreateLabel && (
          <WorkspaceLabelCreateForm
            workspace={workspace}
            onCanceled={() => {
              setShowCreateLabel(false);
            }}
            onSuccess={() => {
              setShowCreateLabel(false);
            }}
          />
        )}
        {showCreateLabelGroup && (
          <WorkspaceLabelGroupCreateForm
            workspace={workspace}
            onCanceled={() => {
              setShowCreateLabelGroup(false);
            }}
            onSuccess={() => {
              setShowCreateLabelGroup(false);
            }}
          />
        )}
        <WorkspaceLabelGroupList workspace={workspace} />
        <WorkspaceLabelList workspace={workspace} />
      </div>
    </div>
  );
};

export default WorkspaceLabelTable;
