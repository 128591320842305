import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getLabelGroupListForWorkspaceQueryDocument } from "@/graphql/common";

export interface UseGetLabelGroupListForWorkspaceProps {
  workspaceId: string;
}

export const useGetLabelGroupListForWorkspace = ({ workspaceId }: UseGetLabelGroupListForWorkspaceProps) => {
  const queryResult = useQuery(getLabelGroupListForWorkspaceQueryDocument, {
    variables: { input: { workspaceId } },
  });

  const labelGroupList = useMemo(() => {
    const { data } = queryResult;

    if (!data) {
      return [];
    }

    return data.labelGroupsForWorkspace.slice().sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a.title.localeCompare(b.title);
    });
  }, [queryResult]);

  return {
    queryResult,
    labelGroupList,
  };
};
