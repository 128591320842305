import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { getAccessToken } from "../storage.service";
import { ObjectiveFragment } from "@/components/_domain/objective/models";
import { mergeArraysByField } from "./_utils";
import { Label, LabelInstance } from "@/graphql/generated/graphql";

export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;

const httpLink = createHttpLink({
  uri: `${API_ENDPOINT}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = getAccessToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          workspaceById: {
            read(workspace, { args, toReference }) {
              if (workspace) {
                return workspace;
              }
              return toReference({ __typename: "Workspace", id: args?.workspaceId });
            },
          },
          workspaceByUrl: {
            read(workspace, { args, toReference }) {
              if (workspace) {
                return workspace;
              }
              return toReference({ __typename: "Workspace", id: args?.workspaceUrl });
            },
          },
          teamById: {
            read(team, { args, toReference }) {
              if (team) {
                return team;
              }
              return toReference({ __typename: "Team", id: args?.teamId });
            },
          },
          objective: {
            read(objective, { args, toReference }) {
              if (objective) {
                return objective;
              }
              return toReference({ __typename: "Objective", id: args?.getObjectiveInput?.id });
            },
          },
          priority: {
            read(priority, { args, toReference }) {
              if (priority) {
                // There's already a item associated with this query, so return it
                return priority;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "Priority",
                id: args?.getPriorityInput?.id,
              });
            },
          },
          priorityInstance: {
            read(priorityInstance, { args, toReference }) {
              if (priorityInstance) {
                return priorityInstance;
              }
              return toReference({ __typename: "PriorityInstance", id: args?.getPriorityInstanceInput?.id });
            },
          },
          status: {
            read(status, { args, toReference }) {
              if (status) {
                // There's already a item associated with this query, so return it
                return status;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "Status",
                id: args?.getStatusInput?.id,
              });
            },
          },
          statusInstance: {
            read(statusInstance, { args, toReference }) {
              if (statusInstance) {
                return statusInstance;
              }
              return toReference({ __typename: "StatusInstance", id: args?.getStatusInstanceInput?.id });
            },
          },
          assignee: {
            read(assignee, { args, toReference }) {
              if (assignee) {
                return assignee;
              }
              return toReference({ __typename: "Assignee", id: args?.getAssigneeInput?.id });
            },
          },
          label: {
            read(label, { args, toReference }) {
              if (label) {
                // There's already a item associated with this query, so return it
                return label;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "Label",
                id: args?.getLabelInput?.id,
              });
            },
          },
          labelGroup: {
            read(labelGroup, { args, toReference }) {
              if (labelGroup) {
                // There's already a item associated with this query, so return it
                return labelGroup;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "LabelGroup",
                id: args?.getLabelGroupInput?.id,
              });
            },
          },
          labelInstance: {
            read(labelInstance, { args, toReference }) {
              if (labelInstance) {
                return labelInstance;
              }
              return toReference({ __typename: "LabelInstance", id: args?.getLabelInstanceInput?.id });
            },
          },
          unit: {
            read(unit, { args, toReference }) {
              if (unit) {
                // There's already a item associated with this query, so return it
                return unit;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "Unit",
                id: args?.getUnitInput?.id,
              });
            },
          },
          keyResult: {
            read(keyResult, { args, toReference }) {
              if (keyResult) {
                return keyResult;
              }
              return toReference({ __typename: "KeyResult", id: args?.getKeyResultInput?.id });
            },
          },
          progressSnapshot: {
            read(progressSnapshot, { args, toReference }) {
              if (progressSnapshot) {
                return progressSnapshot;
              }
              return toReference({ __typename: "ProgressSnapshot", id: args?.getProgressSnapshotInput?.id });
            },
          },
          objectiveList: {
            merge(existing: ObjectiveFragment[], incoming: ObjectiveFragment[], { readField, mergeObjects }) {
              const merged = mergeArraysByField(existing, incoming, "id", readField, mergeObjects);
              return merged;
            },
          },
          labelInstances: {
            merge(existing: LabelInstance[], incoming: LabelInstance[], { readField, mergeObjects }) {
              const merged = mergeArraysByField(existing, incoming, "id", readField, mergeObjects);
              return merged;
            },
          },
          labelsForTeam: {
            merge(existing: Label[], incoming: Label[], { readField, mergeObjects }) {
              const merged = mergeArraysByField(existing, incoming, "id", readField, mergeObjects);
              return merged;
            },
          },
          //
        },
      },
    },
  }),
});
