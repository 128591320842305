import { LucideProps } from "lucide-react";

const Icon = ({ ...props }: LucideProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="heartbeat"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      height="16"
      width="16"
      {...props}
    >
      <path
        opacity="0.87"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1C2.79086 1 1 2.79086 1 5V11C1 13.2091 2.79086 15 5 15H11C13.2091 15 15 13.2091 15 11V5C15 2.79086 13.2091 1 11 1H5ZM7.72114 4.29396C7.63221 3.98271 7.35362 3.76363 7.03017 3.75061C6.70672 3.73759 6.41142 3.93356 6.29775 4.23666L4.98025 7.75H3.5C3.08579 7.75 2.75 8.08579 2.75 8.5C2.75 8.91422 3.08579 9.25 3.5 9.25H5.5C5.81263 9.25 6.09247 9.05608 6.20225 8.76335L6.90367 6.89289L8.27886 11.706C8.36382 12.0034 8.62263 12.2182 8.93059 12.2468C9.23855 12.2754 9.5325 12.1121 9.67082 11.8354L10.9635 9.25H12.5C12.9142 9.25 13.25 8.91422 13.25 8.5C13.25 8.08579 12.9142 7.75 12.5 7.75H10.5C10.2159 7.75 9.95622 7.91051 9.82918 8.16459L9.19145 9.44005L7.72114 4.29396Z"
      ></path>
    </svg>
  );
};

export default Icon;
