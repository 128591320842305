import { useMemo } from "react";

import { useGetLabelGroupListForWorkspace } from ".";
import { useTeamListForUserInWorkspace } from "../useWorkspace";

export interface UseGetLabelGroupListForTeamsInWorkspaceProps {
  workspaceId: string;
  userId: string;
  teamList: { id: string }[];
}

export const useGetLabelGroupListForTeamsInWorkspace = ({
  workspaceId,
  userId,
  teamList,
}: UseGetLabelGroupListForTeamsInWorkspaceProps) => {
  const { activeTeamList } = useTeamListForUserInWorkspace({ workspaceId, userId });
  const { labelGroupList: workspaceLabelGroupList } = useGetLabelGroupListForWorkspace({ workspaceId });

  const filteredTeamLabelGroupList = useMemo(() => {
    return activeTeamList.flatMap((activeTeam) => {
      if (!teamList.find((t) => t.id === activeTeam.id)) {
        return [];
      }
      return activeTeam.labelGroupList;
    });
  }, [activeTeamList, teamList]);

  const labelGroupList = useMemo(() => {
    return [...filteredTeamLabelGroupList, ...workspaceLabelGroupList];
  }, [filteredTeamLabelGroupList, workspaceLabelGroupList]);

  return {
    labelGroupList,
  };
};
