import { FC, useMemo, useState } from "react";

import { cn } from "@/lib/utils";

import { Button } from "@/components/_ui/button";
import IconCircleSolid from "@/components/_icons/IconCircleSolid";
import IconDots from "@/components/_icons/IconDots";

import { TeamLabelActionButton } from "./team-label-action-button";
import { TeamLabelEditForm } from "./team-label-edit-form";
import { useCurrentUser, useGetLabel, useTeamById } from "@/hooks/api/common";

export interface TeamLabelListItemProps {
  label: {
    id: string;
  };
  team: {
    id: string;
  };
  className?: string;
}

export const TeamLabelListItem: FC<TeamLabelListItemProps> = ({ team, className, ...props }) => {
  const [editMode, setEditMode] = useState(false);

  const { currentUser } = useCurrentUser();
  const { userHasEditRights } = useTeamById({ id: team.id });

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    return userHasEditRights(currentUser.id);
  }, [currentUser, userHasEditRights]);

  const { label } = useGetLabel({ id: props.label.id });

  if (!label) {
    return <></>;
  }
  return (
    <div className={cn(["my-1 flex flex-row items-center justify-between rounded border p-1 pr-2", className])}>
      {!editMode && (
        <div className="flex w-full flex-row items-center justify-between">
          <div className="w-7"></div>
          <div className="mr-1 w-6">
            <IconCircleSolid className="mx-auto h-2.5 w-2.5" style={{ color: label.color }} />
          </div>
          <div className="grow">
            <span>{label.title}</span>
            {label.instances.length > 0 && (
              <span className="ml-1 text-muted-foreground/40">
                {` · ${label.instances.length} ${label.instances.length == 1 ? "Ziel" : "Ziele"}`}
              </span>
            )}
          </div>
          <div>
            {hasEditRights && (
              <TeamLabelActionButton label={label} team={team} handleEditLabel={() => setEditMode(true)} asChild>
                <Button variant="ghost" size="icon">
                  <IconDots />
                </Button>
              </TeamLabelActionButton>
            )}
          </div>
        </div>
      )}
      {editMode && (
        <TeamLabelEditForm
          label={label}
          onCanceled={() => {
            setEditMode(false);
          }}
          onSuccess={() => {
            setEditMode(false);
          }}
        />
      )}
    </div>
  );
};
