import { FC } from "react";

import { cn } from "@/lib/utils";

import { useGetLabel } from "@/hooks/api/common";
import IconCircleSolid from "@/components/_icons/IconCircleSolid";
import IconDashesHorizontal from "@/components/_icons/IconDashesHorizontal";

interface LabelViewProps {
  label?: {
    id: string;
  } | null;
  className?: string;
  logoOnly?: boolean;
}

export const LabelView: FC<LabelViewProps> = ({ ...props }) => {
  const { label } = useGetLabel({ id: props.label?.id });

  if (!label) {
    return (
      <div className="flex flex-row items-center gap-2 overflow-x-hidden">
        <IconDashesHorizontal className={cn(["h-2.5 w-2.5", props.className])} />
        {!props.logoOnly && <div className="text-nowrap-custom overflow-hidden">Nicht zugewiesen</div>}
      </div>
    );
  }

  return (
    <div className="flex flex-row items-center gap-2 overflow-x-hidden">
      <IconCircleSolid className={cn(["h-2.5 w-2.5", props.className])} style={{ color: label.color }} />
      {!props.logoOnly && <div className="text-nowrap-custom overflow-hidden">{label.title}</div>}
    </div>
  );
};

export default LabelView;
