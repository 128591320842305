import { FC, PropsWithChildren, useEffect, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import { WorkspaceProvider } from "@/hooks/useCurrentWorkspace";

import SidebarSettings from "@/components/sidebar-settings/sidebar-settings";
import NotFound from "@/routes/NotFound";
import { useCurrentUser, useWorkspaceGetByUrl } from "@/hooks/api/common";;
import { removeWorkspaceSettings } from "@/services/workspace.service";

export interface InnerPageProps extends PropsWithChildren {
  workspaceUrl: string;
}
const InnerPage: FC<InnerPageProps> = ({ workspaceUrl }) => {
  const { workspace, queryResult } = useWorkspaceGetByUrl({ workspaceUrl });
  const { currentUser } = useCurrentUser();

  const workspaceWithMembership = useMemo(() => {
    if (!workspace) {
      return null;
    }

    const wsm = workspace.workspaceMembershipList.find((wsm) => wsm.userId == currentUser?.id);
    return {
      ...workspace,
      workspaceMembership: {
        role: wsm?.role ?? "USER",
        status: wsm?.status ?? "SUSPENDED",
      },
    };
  }, [workspace]);

  useEffect(() => {
    if (queryResult.error) {
      removeWorkspaceSettings();
    }
  }, [queryResult]);

  if (!workspaceWithMembership) {
    return <NotFound />;
  }

  return (
    <WorkspaceProvider workspace={workspaceWithMembership}>
      <div className="w-full- flex h-full min-h-screen flex-row items-stretch overflow-hidden bg-background text-foreground">
        <SidebarSettings workspace={workspaceWithMembership} />
        <div className="flex min-w-0 shrink grow basis-0 flex-col">
          <Outlet />
        </div>
      </div>
    </WorkspaceProvider>
  );
};

export default function Page() {
  const { workspaceUrl } = useParams();

  if (!workspaceUrl) {
    return <NotFound />;
  }

  return <InnerPage workspaceUrl={workspaceUrl} />;
}
