import { graphql } from "../../generated/gql";

export const createLabelMutationDocument = graphql(/* GraphQL */ `
  mutation createLabel($input: CreateLabelInput!) {
    createLabel(createLabelInput: $input) {
      id
      title
      color
      labelGroupId
    }
  }
`);

export const updateLabelMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateLabel($input: UpdateLabelInput!) {
    updateLabel(updateLabelInput: $input) {
      id
      title
      color
      labelGroupId
    }
  }
`);

export const deleteLabelMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteLabel($input: DeleteLabelInput!) {
    deleteLabel(deleteLabelInput: $input) {
      id
      title
      color
      labelGroupId
    }
  }
`);
