import * as z from "zod";

export const LabelSchema = z.object({
  id: z.string(),
  title: z.string(),
  color: z.string(),
  workspaceId: z.string(),
  labelGroupId: z.string().optional().nullable(),
});
export type Label = z.infer<typeof LabelSchema>;
