import { FC, useEffect, useState } from "react";
import { createId } from "@paralleldrive/cuid2";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseFormProps, useFieldArray, useForm } from "react-hook-form";

import { Input } from "@/components/_ui/input";
import { Button } from "@/components/_ui/button";

import IconTrash from "@/components/_icons/IconTrash";

const schema = z.object({
  items: z.array(
    z.object({
      _id: z.string(),
      index: z.number(),
      value: z.string().min(1),
    }),
  ),
});

export type Item = z.infer<typeof schema>["items"][number];

function useZodForm<TSchema extends z.ZodType>(
  props: Omit<UseFormProps<TSchema["_input"]>, "resolver"> & {
    schema: TSchema;
  },
) {
  const form = useForm<TSchema["_input"]>({
    ...props,
    resolver: zodResolver(props.schema, undefined, {
      // This makes it so we can use `.transform()`s on the schema without same transform getting applied again when it reaches the server
      raw: true,
    }),
  });

  return form;
}

export interface InputMultipleProps {
  onValueChange?: (value: Item[]) => void;
  defaultValue?: Item[];
  newItemPlaceholder?: string;
  itemPlaceholder?: string;
}

export const InputMultiple: FC<InputMultipleProps> = ({
  onValueChange,
  defaultValue,
  newItemPlaceholder,
  itemPlaceholder,
}) => {
  const [items, setItems] = useState<Item[]>(() => (defaultValue ? defaultValue : []));

  const { register, control, getValues, watch } = useZodForm({
    schema: schema,
    defaultValues: { items },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    name: "items",
    control,
  });

  useEffect(() => {
    // const subscription = watch((value, { name, type }) => {
    const subscription = watch(() => {
      const _items = getValues("items");
      setItems(_items);

      if (onValueChange) {
        onValueChange([..._items]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      createNewItem();
    }
  };

  const createNewItem = () => {
    const newItem = {
      _id: createId(),
      index: items.length,
      value: "",
    };
    setItems([...items, newItem]);
    append(newItem);
  };

  return (
    <div>
      {fields.map((field, index) => {
        return (
          <div className="mb-2 flex flex-col" key={field.id}>
            <div className="flex flex-row ">
              <Input
                {...register(`items.${index}.value` as const)}
                placeholder={itemPlaceholder}
                defaultValue={field.value}
                className="border border-gray-300 p-2"
                autoFocus={index == items.length - 1}
                autoComplete="off"
                onKeyDown={handleKeyDown}
              />

              <Button
                variant="outline"
                size="sm"
                className="relative ml-3 flex items-center justify-center overflow-hidden text-ellipsis text-foreground/60"
                onClick={() => remove(index)}
              >
                <IconTrash className="h-3 w-3" />
              </Button>
            </div>
          </div>
        );
      })}

      <Input
        type="text"
        autoComplete="off"
        placeholder={newItemPlaceholder}
        className="w-1/3 focus-visible:ring-slate-600 dark:focus-visible:ring-slate-600"
        onFocus={() => createNewItem()}
      />
    </div>
  );
};

export default InputMultiple;
