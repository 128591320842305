import { useMutation } from "@apollo/client";
import {
  getStatusInstanceQueryDocument,
  getStatusInstancebyOwnerQueryDocument,
  updateStatusInstanceMutationDocument,
} from "@/graphql/common";

export interface UseUpdateStatusInstanceProps {
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useUpdateStatusInstance = ({ onCompleted, onError }: UseUpdateStatusInstanceProps) => {
  const [updateStatusInstance] = useMutation(updateStatusInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const statusInstance = data?.updateStatusInstance;
      if (!statusInstance) {
        return;
      }
      cache.writeQuery({
        query: getStatusInstanceQueryDocument,
        variables: {
          input: { id: statusInstance.id },
        },
        data: {
          statusInstance: statusInstance,
        },
      });
      cache.writeQuery({
        query: getStatusInstancebyOwnerQueryDocument,
        variables: {
          input: { ownerId: statusInstance.ownerId },
        },
        data: {
          statusInstanceByOwner: statusInstance,
        },
      });
    },
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    updateStatusInstance,
  };
};
