import { FC, useMemo } from "react";
import AvatarUser from "./avatar-user";
import IconUserUnassigned from "../../_icons/IconUserUnassigned";
import IconCheckmark from "../../_icons/IconCheckmark";

export interface UserSelectDropdownItemProps {
  user?: {
    id: string;
    email: string;
    profilePictureUrl?: string | null;
    username?: string | null;
    fullname?: string | null;
  };
  selected?: boolean;
}

export const UserSelectDropdownItem: FC<UserSelectDropdownItemProps> = ({ user, selected }) => {
  const displayValue = useMemo(() => {
    if (!user) {
      return "nicht zugewiesen";
    }

    if (user.username) {
      return user.username;
    }

    if (user.fullname) {
      return user.fullname;
    }

    return user.email;
  }, [user]);

  if (!user) {
    return (
      <div className="m-1 flex w-full cursor-pointer flex-row items-center gap-2 p-1 text-xs">
        <IconUserUnassigned className="mr-2 h-5 w-5 shrink-0 text-foreground/60" />
        <div className="grow">{displayValue}</div>
        <div className="mr-2 w-3">
          {selected && (
            <IconCheckmark className="mr-2 h-3 w-3 shrink-0 self-end justify-self-center text-foreground/60" />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="m-1 flex w-full cursor-pointer flex-row items-center gap-2 p-1 text-xs">
      <AvatarUser user={user} className="mr-2 h-5 w-5 shrink-0" />
      <div className="grow">{displayValue}</div>
      <div className="mr-2 w-3">
        {selected && (
          <IconCheckmark className="mr-2 h-3 w-3 shrink-0 self-end justify-self-center text-foreground/60" />
        )}
      </div>
    </div>
  );
};
