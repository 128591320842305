import { FC, useState } from "react";
import { Button } from "@/components/_ui/button";
import { Popover, PopoverPortalContent } from "@/components/_ui/popover";
import { PopoverTrigger } from "@radix-ui/react-popover";

import { format } from "date-fns";
import { de } from "date-fns/locale";

import { CalendarIcon } from "lucide-react";
import Datepicker from "@/components/datepicker/datepicker";
import { cn } from "@/lib/utils";

export interface InputDateProps {
  onValueChange?: (value: string | null) => void;
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export const InputDate: FC<InputDateProps> = ({
  onValueChange,
  defaultValue,
  disabled,
  placeholder,
  minDate,
  maxDate,
  className,
}) => {
  const [value, setValue] = useState<string | null>(defaultValue ? defaultValue : null);

  const handleDateSelected = (selectedDate: Date | null) => {
    if (!selectedDate) {
      return null;
    }

    const dateString = selectedDate.toISOString();
    setValue(dateString);
    if (onValueChange) {
      onValueChange(dateString);
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant={"ghost"}
          className={cn(["w-full border pl-3 text-left font-normal", className ?? ""])}
        >
          {value ? format(value, "PPP", { locale: de }) : <span className="text-muted-foreground">{placeholder}</span>}
          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverPortalContent className="w-auto p-0" align="start">
        <Datepicker
          onValueChange={handleDateSelected}
          defaultValue={defaultValue ? new Date(defaultValue) : undefined}
          minDate={minDate}
          maxDate={maxDate}
        />
      </PopoverPortalContent>
    </Popover>
  );
};

export default InputDate;
