import { graphql } from "../../generated/gql";

export const createLabelInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation createLabelInstance($input: CreateLabelInstanceInput!) {
    createLabelInstance(createLabelInstanceInput: $input) {
      id
      labelId
      ownerId
      ownerType
      workspaceId
      teamId
      label {
        id
        title
        color
        workspaceId
        teamId
      }
    }
  }
`);

export const deleteLabelInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteLabelInstance($input: DeleteLabelInstanceInput!) {
    deleteLabelInstance(deleteLabelInstanceInput: $input) {
      id
      labelId
      ownerId
      ownerType
      workspaceId
      teamId
      label {
        id
        title
        color
        workspaceId
        teamId
      }
    }
  }
`);
