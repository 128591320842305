export * from "./VariableInterpolationNode";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { Klass, LexicalNode } from "lexical";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
// import { ImageNode } from "../plugins"
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { ExtendedTextNode } from "./ExtendedTextNode";
import { VariableInterpolationNode } from "./VariableInterpolationNode";

type RichTextNodes = ReadonlyArray<
  | Klass<LexicalNode>
  | {
      replace: Klass<LexicalNode>;
      with: <
        T extends {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          new (...args: any): any;
        },
      >(
        node: InstanceType<T>,
      ) => LexicalNode;
    }
>;

export const richTextNodes: RichTextNodes = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  CodeHighlightNode,
  AutoLinkNode,
  LinkNode,
  HorizontalRuleNode,
  // ImageNode,
  VariableInterpolationNode,
  ExtendedTextNode,
];
