import { Table, TableBody, TableCell, TableRow } from "@/components/_ui/table";

import { ObjectiveListViewRow } from "./objective-list-view-row";

import { useObjectiveListState } from "../../../hooks";

export function ObjectiveListView({}) {
  const { table, columns } = useObjectiveListState();

  const rows = table.getRowModel().rows;

  return (
    <Table>
      {/* <ObjectiveListViewHeader table={table} /> */}
      <TableBody className="border-b">
        {rows?.length > 0 && (
          <>
            {rows.map((row, i) => (
              <ObjectiveListViewRow key={i} row={row} />
            ))}
          </>
        )}
        {(!rows || rows.length == 0) && (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
