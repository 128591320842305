import { FC } from "react";

import { Button } from "@/components/_ui/button";

import IconCircleSolid from "@/components/_icons/IconCircleSolid";

import { OwnerType } from "@/components/_domain/labels/models/owner-types";
import { useGetLabel } from "@/hooks/api/common";
import { LabelGroupSelectDropdown } from "@/components/_domain/labels/label-group-select-dropdown";

interface ObjectiveLabelGroupInstanceItemProps {
  owner: {
    id: string;
    type: OwnerType;
  };
  workspace: {
    id: string;
  };
  user: {
    id: string;
  };
  team: {
    id: string;
  };
  labelGroup: {
    id: string;
  };
  labelInstance: {
    id: string;
    labelId: string;
  };
}

export const ObjectiveLabelGroupInstanceItem: FC<ObjectiveLabelGroupInstanceItemProps> = ({
  workspace,
  team,
  owner,
  user,
  labelInstance,
  ...props
}) => {
  const { label } = useGetLabel({ id: labelInstance.labelId });

  if (!label) {
    return <></>;
  }

  return (
    <LabelGroupSelectDropdown
      owner={{ id: owner.id, type: "OBJECTIVE" }}
      workspace={workspace}
      team={team}
      user={user}
      labelGroup={{ id: props.labelGroup.id }}
      asChild
    >
      <Button
        variant="outline"
        size="sm"
        className="h-6 rounded-full text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
      >
        <IconCircleSolid className="mr-1 h-2.5 w-2.5" style={{ color: label.color }} />
        {label.title}
      </Button>
    </LabelGroupSelectDropdown>
  );
};

export default ObjectiveLabelGroupInstanceItem;
