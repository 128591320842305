import { FC, PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

export interface HeaderPropsWithChildren extends PropsWithChildren {
  className?: string;
}

export const Header: FC<HeaderPropsWithChildren> = ({ children, className }) => {
  return (
    <header
      className={cn(
        "max-w-screen relative flex h-[56px]	shrink-0 items-center justify-between gap-2 border-b border-solid px-6 text-sm",
        className,
      )}
    >
      {children}
    </header>
  );
};

export default Header;
