import { FC } from "react";

import { StatusView } from ".";
import { StatusViewEmpty } from "./status-view-empty";

interface StatusViewOptionalProps {
  status?: {
    id: string;
  } | null;
  logoOnly?: boolean;
  className?: string;
}

export const StatusViewOptional: FC<StatusViewOptionalProps> = ({ status, ...props }) => {
  if (!status) {
    return <StatusViewEmpty {...props} />;
  }

  return <StatusView status={status} {...props} />;
};

export default StatusViewOptional;
