import { useParams } from "react-router-dom";

import Header from "@/components/header";
import NotFound from "@/routes/NotFound";

import { PriorityTable } from "./priority-table";

export default function Page() {
  const { teamId } = useParams();

  if (!teamId) {
    return <NotFound />;
  }

  return (
    <main className="relative max-h-screen place-items-stretch overflow-auto">
      <Header className="border-none"></Header>

      <div className="overflow-y-scroll pb-12" style={{ maxHeight: "calc(100vh - 56px)" }}>
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <div className="flex flex-col gap-1.5">
              <div className="text-2xl">Priorität</div>
              <div className="text-sm text-muted-foreground">
                Verwalten Sie Prioritäten, die Objekten in Ihrem Team zugeordnet werden können.
              </div>
            </div>

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <div>
                <div>
                  <p className="font-light text-muted-foreground">
                    Ordnen Sie Zielen in Ihrem Team eine Priorität zu, um sie zu organisieren und zu filtern. Die in
                    diesem Abschnitt erstellten Prioritäten sind für alle Mitglieder ihres Team verfügbar.
                  </p>
                  <div className="my-8" />
                  <PriorityTable team={{ id: teamId }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
