import { FC, PropsWithChildren, useMemo } from "react";

import { groupBy } from "@/lib/utils";

import { ALLOWED_PRIORITY_TYPES } from "@/components/_domain/priority/priority-type";

import { PriorityTableSection } from "./priority-table-section";
import { useGetPriorityList } from "@/hooks/api/common";

export interface PriorityTablePropsWithChildren extends PropsWithChildren {
  team: {
    id: string;
  };
  className?: string;
}

export const PriorityTable: FC<PriorityTablePropsWithChildren> = ({ team }) => {
  const { priorityList } = useGetPriorityList({ teamId: team.id });

  const groupedPriorityList = useMemo(() => {
    return groupBy(priorityList, (priority) => priority.type);
  }, [priorityList]);

  if (!priorityList) {
    return <></>;
  }

  return (
    <>
      {ALLOWED_PRIORITY_TYPES.map((type) => {
        const priorityForType = groupedPriorityList.get(type) ?? [];
        return (
          <PriorityTableSection
            key={type}
            team={team}
            priorityType={type}
            priorityList={priorityForType}
            className="mb-4"
          />
        );
      })}
    </>
  );
};

export default PriorityTable;
