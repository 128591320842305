import { FC, useMemo } from "react";

import { cn } from "@/lib/utils";

import { useTeamById } from "@/hooks/api/common";

import { Icon, iconTypefromString } from "@/components/icon-picker/icon.schema";
import { IconView } from "@/components/icon-picker/icon-view";
import IconTeams from "@/components/_icons/IconTeams";

export interface TeamIconProps {
  team: {
    id: string;
  };
  className?: string;
}

export const TeamIcon: FC<TeamIconProps> = ({ ...props }) => {
  const { team } = useTeamById({ id: props.team.id });

  const icon = useMemo(() => {
    if (!team) {
      return null;
    }

    if (!team.logo) {
      return null;
    }

    if (!team.logoType) {
      return null;
    }

    const iconType = iconTypefromString(team.logoType);

    if (!iconType) {
      return null;
    }

    return {
      value: team.logo,
      mode: iconType,
      color: team.color,
    } as Icon;
  }, [team]);

  if (!icon) {
    return <IconTeams className={cn(["h-3 w-3 text-sm text-foreground/40", props.className])} />;
  }

  return <IconView icon={icon} className={cn(["h-3 w-3 text-sm", props.className])} />;
};
