export const WORKSPACE_KEY = "PRIMARY_WORKSPACE_KEY";

export type WorkspaceSettings = {
  id: string;
  name: string;
  workspaceUrl: string;
  logo?: string | null | undefined;
};

// eslint-disable-next-line
export function isWorkspaceSettingType(workspace: any): workspace is WorkspaceSettings {
  const keys = ["name", "workspaceUrl", "logo"];
  // defaulting to false for effective checking
  let checking = false;
  // iterating over keys from the `userExample` as they r the same keys for `User`
  for (const t of keys) {
    checking = t in workspace;

    // all keys need to be present or it fails
    if (!checking) {
      return false;
    }
  }
  // otherwise it passes and return true
  return checking;
}

export const getWorkspaceSettings = (): WorkspaceSettings | null => {
  const workspaceString = localStorage.getItem(WORKSPACE_KEY);
  if (!workspaceString) {
    return null;
  }

  const workspaceObject = JSON.parse(workspaceString);
  if (!workspaceObject) {
    return null;
  }

  if (!isWorkspaceSettingType(workspaceObject)) {
    return null;
  }

  return workspaceObject;
};

export const setWorkspaceSettings = (workspace: WorkspaceSettings) => {
  localStorage.setItem(WORKSPACE_KEY, JSON.stringify(workspace));
};

export const removeWorkspaceSettings = () => {
  localStorage.removeItem(WORKSPACE_KEY);
};
