import { Outlet, useNavigate } from "react-router-dom";

import { useAuth } from "@/hooks/useAuth";
import { posthogService } from "@/services/posthog.service";

import { AdminSidebar } from "@/components/sidebar-admin";
import { useCurrentUser } from "@/hooks/api/common";

export default function Page() {
  const navigate = useNavigate();

  const { isSuperUser, queryResult } = useCurrentUser();
  const { setAuthenticated } = useAuth();

  if (queryResult.loading) {
    return <></>
  }

  if (!isSuperUser) {
    posthogService.capture("UNAUTHORIZED_ADMIN_ACCESS_ATTEMPT");

    setAuthenticated(false);
    navigate("/");
    return <></>;
  }

  return (
    <div className="w-full- flex h-full min-h-screen flex-row items-stretch overflow-hidden bg-background text-foreground">
      <AdminSidebar />
      <div
        className="absolute left-0 right-0 top-0 h-3 select-none text-center text-xxs text-white"
        style={{ background: "red", zIndex: 100 }}
      >
        Admin Panel
      </div>
      <div className="mt-3 flex min-w-0 shrink grow basis-0 flex-col">
        <Outlet />
      </div>
    </div>
  );
}
