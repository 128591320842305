import { FC } from "react";

import IconCheckmark from "@/components/_icons/IconCheckmark";

import { HealthType } from "../health-types";
import { HealthView } from "../health-view";

export interface HealthSelectDropdownItemProps {
  type: HealthType;
  selected?: boolean;
}

export const HealthSelectDropdownItem: FC<HealthSelectDropdownItemProps> = ({ type, selected }) => {
  return (
    <div className="m-1 flex w-full cursor-pointer flex-row items-center gap-2 p-1 text-xs">
      <div className="grow">
        <HealthView type={type} />
      </div>
      <div className="mr-2 w-3">
        {selected && (
          <IconCheckmark className="mr-2 h-3 w-3 shrink-0 self-end justify-self-center text-foreground/60" />
        )}
      </div>
    </div>
  );
};
