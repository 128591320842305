import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useTeamById, useTeamMembershipList, useWorkspaceGetById, useTeamJoin } from "@/hooks/api/common";

import { toast } from "@/components/_ui/use-toast";
import { TeamIcon } from "../team-icon";
import { Button } from "@/components/_ui/button";
import { CheckIcon } from "@heroicons/react/24/solid";

interface TeamBrowseListItemProps {
  workspace: {
    id: string;
  };
  team: {
    id: string;
  };
  user: {
    id: string;
  };
}

export const TeamBrowseListItem: FC<TeamBrowseListItemProps> = ({ user, ...props }) => {
  const navigate = useNavigate();

  const { workspace } = useWorkspaceGetById(props.workspace);
  const { team } = useTeamById(props.team);
  const { teamMembershipList, getTeamMembershipForUser } = useTeamMembershipList({ teamId: props.team.id });

  const { joinTeam, queryResult } = useTeamJoin({
    workspace: {id: props.workspace.id},
    team: { id: props.team.id },
    onCompleted: handleOnComplete,
    onError: handleOnError,
  });

  const loading = useMemo(() => {
    return queryResult.loading;
  }, [queryResult]);

  const teamMembershipForUser = useMemo(() => {
    return getTeamMembershipForUser(user.id);
  }, [getTeamMembershipForUser]);

  function handleOnComplete() {
    toast({
      title: "Team beigetreten",
      description: "Sie sind jetzt Teil des Teams. Wir leiten Sie nun zur Team Seite weiter.",
      variant: "success",
    });

    if (!workspace) {
      return;
    }

    if (!team) {
      return;
    }

    const redirectUrl = `/${workspace.workspaceUrl}/teams/${team.id}`;
    setTimeout(() => {
      navigate(redirectUrl, {
        replace: true,
      });
    }, 1000);
  }

  function handleOnError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  const handleJoinTeam = () => {
    if (loading) {
      return;
    }

    joinTeam({
      variables: {
        input: {
          teamId: props.team.id,
        },
      },
    });
  };

  if (!team) {
    return <></>;
  }

  return (
    <div
      key={team.id}
      onClick={() => {}}
      className="flex cursor-pointer select-none flex-col items-start rounded-md px-2 py-2 text-sm hover:bg-muted"
    >
      <div className="flex w-full flex-row items-center gap-1">
        <TeamIcon team={team} />
        <span className="pointer-events-none relative flex h-8 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0 hover:bg-accent hover:text-accent-foreground">
          {team.name}
        </span>
        <div className="grow"></div>
        <div className="pr-3">
          {team.visibility == "PUBLIC" && (
            <>
              {!teamMembershipForUser && (
                <Button variant="outline" size="xs" className="" onClick={() => handleJoinTeam()}>
                  Beitreten
                </Button>
              )}
              {teamMembershipForUser && teamMembershipForUser.status == "LEFT" && (
                <Button variant="outline" size="xs" className="" onClick={() => handleJoinTeam()}>
                  Beitreten
                </Button>
              )}
              {teamMembershipForUser && teamMembershipForUser.status == "PENDING" && (
                <Button variant="outline" size="xs" className="" onClick={() => handleJoinTeam}>
                  Einladung annehmen
                </Button>
              )}
            </>
          )}
          {team.visibility == "PRIVATE" && (
            <>
              {teamMembershipForUser && teamMembershipForUser.status == "LEFT" && (
                <Button variant="outline" size="xs" className="" onClick={() => handleJoinTeam()}>
                  Beitreten
                </Button>
              )}
              {teamMembershipForUser && teamMembershipForUser.status == "PENDING" && (
                <Button variant="outline" size="xs" className="" onClick={() => handleJoinTeam}>
                  Einladung annehmen
                </Button>
              )}
            </>
          )}
          {team.visibility == "HIDDEN" && (
            <>
              {teamMembershipForUser && teamMembershipForUser.status == "LEFT" && (
                <Button variant="outline" size="xs" className="" onClick={() => handleJoinTeam()}>
                  Beitreten
                </Button>
              )}
              {teamMembershipForUser && teamMembershipForUser.status == "PENDING" && (
                <Button variant="outline" size="xs" className="" onClick={() => handleJoinTeam()}>
                  Einladung annehmen
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      <div className="flex flex-row items-center gap-2">
        {teamMembershipForUser && (
          <>
            {teamMembershipForUser.status == "ACTIVE" && (
              <>
                <div className="flex flex-row items-center gap-0.5 text-xxs text-green-600">
                  <CheckIcon className="h-2.5 w-2.5" />
                  {teamMembershipForUser.role == "ADMIN" && <>Administrator</>}
                  {teamMembershipForUser.role != "ADMIN" && <>Mitglied</>}
                </div>
              </>
            )}

            {teamMembershipForUser.status == "PENDING" && (
              <>
                <div className="flex flex-row items-center gap-0.5 text-xxs text-muted-foreground">
                  Ausstehende Einladung
                </div>
              </>
            )}

            {teamMembershipForUser.status == "LEFT" && (
              <>
                <div className="flex flex-row items-center gap-0.5 text-xxs text-muted-foreground">Ausgetreten</div>
              </>
            )}

            <div className="text-xxs text-muted-foreground">
              <span>{"·"}</span>
            </div>
          </>
        )}

        {teamMembershipList.length == 1 && (
          <div className="text-xxs text-muted-foreground">{teamMembershipList.length} Mitglied</div>
        )}
        {teamMembershipList.length != 1 && (
          <div className="text-xxs text-muted-foreground">{teamMembershipList.length} Mitglieder</div>
        )}
        <div className="text-xxs text-muted-foreground">
          <span>{"·"}</span>
        </div>
        <div className="text-xxs text-muted-foreground">
          {team.visibility == "PUBLIC" && <>Öffentliches Team</>}
          {team.visibility == "PRIVATE" && (
            <>
              {teamMembershipForUser && teamMembershipForUser.status != "SUSPENDED" && <>Privates Team</>}

              {(!teamMembershipForUser || teamMembershipForUser?.status == "SUSPENDED") && (
                <>Privates Team (Bitten Sie einen Administrator um eine Einladung)</>
              )}
            </>
          )}
          {team.visibility == "HIDDEN" && <>Verstecktes Team</>}
        </div>
      </div>
    </div>
  );
};
