import { graphql } from "../../generated/gql";

export const createUnitMutationDocument = graphql(/* GraphQL */ `
  mutation createUnit($input: CreateUnitInput!) {
    createUnit(createUnitInput: $input) {
      id
      teamId
      title
      symbol
      keyResults {
        id
        objectiveId
      }
    }
  }
`);

export const updateUnitMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateUnit($input: UpdateUnitInput!) {
    updateUnit(updateUnitInput: $input) {
      id
      teamId
      title
      symbol
      keyResults {
        id
        objectiveId
      }
    }
  }
`);

export const deleteUnitMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteUnit($input: DeleteUnitInput!) {
    deleteUnit(deleteUnitInput: $input) {
      id
      teamId
      title
      symbol
      keyResults {
        id
        objectiveId
      }
    }
  }
`);
