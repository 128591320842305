import { LucideProps } from "lucide-react";

const Icon = ({ ...props }: LucideProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="content"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.10972 1.93688C2 2.20775 2 2.55017 2 3.235V12.765C2 13.4498 2 13.7922 2.10972 14.0631C2.26213 14.4394 2.56058 14.7379 2.93688 14.8903C3.20775 15 3.55017 15 4.235 15H11.765C12.4498 15 12.7922 15 13.0631 14.8903C13.4394 14.7379 13.7379 14.4394 13.8903 14.0631C14 13.7922 14 13.4498 14 12.765V7V3.235C14 2.55017 14 2.20775 13.8903 1.93688C13.7379 1.56058 13.4394 1.26213 13.0631 1.10972C12.7922 1 12.4498 1 11.765 1H9.63636H4.235C3.55017 1 3.20775 1 2.93688 1.10972C2.56058 1.26213 2.26213 1.56058 2.10972 1.93688ZM12 3.5C12 3.22386 11.7761 3 11.5 3H4.5C4.22386 3 4 3.22386 4 3.5V5.5C4 5.77614 4.22386 6 4.5 6H4.60961C4.83905 6 5.03904 5.84385 5.09468 5.62127L5.42425 4.30299C5.46877 4.12492 5.62876 4 5.81231 4H7.25V11C7.25 11.5523 6.80228 12 6.25 12H5.83333C5.3731 12 5 12.3731 5 12.8333C5 12.9254 5.07462 13 5.16667 13H10.8333C10.9254 13 11 12.9254 11 12.8333C11 12.3731 10.6269 12 10.1667 12H9.75C9.19772 12 8.75 11.5523 8.75 11V4H10.1877C10.3712 4 10.5312 4.12492 10.5757 4.30299L10.9053 5.62127C10.961 5.84385 11.161 6 11.3904 6H11.5C11.7761 6 12 5.77614 12 5.5V3.5Z"
      ></path>
    </svg>
  );
};

export default Icon;
