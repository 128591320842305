import { FC, PropsWithChildren, ReactElement } from "react";

import { MenubarMenu, MenubarTrigger, MenubarContent } from "@/components/_ui/menubar";

export interface ToolbarButtonDropdownProps extends PropsWithChildren {
  trigger: ReactElement;
}

export const ToolbarButtonDropdown: FC<ToolbarButtonDropdownProps> = ({ trigger, children }) => {
  return (
    <MenubarMenu>
      <MenubarTrigger className="h-8 text-xs">{trigger}</MenubarTrigger>
      <MenubarContent>{children}</MenubarContent>
    </MenubarMenu>
  );
};
