import { useMutation } from "@apollo/client";
import {
  deleteProgressSnapshotMutationDocument,
  getKeyResultQueryDocument,
  getProgressSnapshotsForKeyResultQueryDocument,
  getProgressSnapshotsQueryDocument,
} from "@/graphql/common";
import { useMemo } from "react";

type ObjectiveFragment = {
  id: string;
};

export interface UseDeleteProgressSnapshotProps {
  objectiveId: string;
  keyResultId?: string;
  onCompleted?: (o: ObjectiveFragment | undefined) => void;
  onError?: (e: Error) => void;
}

export const useDeleteProgressSnapshot = ({
  objectiveId,
  keyResultId,
  onCompleted,
  onError,
}: UseDeleteProgressSnapshotProps) => {
  const refetchQueries = useMemo(() => {
    if (keyResultId) {
      return [
        {
          query: getProgressSnapshotsQueryDocument,
          variables: { input: { objectiveId: objectiveId } },
        },
        {
          query: getProgressSnapshotsForKeyResultQueryDocument,
          variables: { input: { keyResultId: keyResultId } },
        },
        {
          query: getKeyResultQueryDocument,
          variables: { input: { id: keyResultId } },
        },
      ];
    }

    return [
      {
        query: getProgressSnapshotsQueryDocument,
        variables: { input: { objectiveId: objectiveId } },
      },
    ];
  }, [objectiveId, keyResultId]);

  const [deleteProgressSnapshot, queryResult] = useMutation(deleteProgressSnapshotMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: refetchQueries,
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const id = o?.deleteProgressSnapshot?.id;

    if (onCompleted) {
      if (id) {
        onCompleted({ id: id });
      } else {
        onCompleted(undefined);
      }
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    deleteProgressSnapshot,
  };
};
