import { graphql } from "../../generated/gql";

export const createAssigneeMutationDocument = graphql(/* GraphQL */ `
  mutation createAssignee($input: CreateAssigneeInput!) {
    createAssignee(createAssigneeInput: $input) {
      id
      userId
      teamId
      ownerId
      ownerType
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const updateAssigneeMutationDocument = graphql(/* GraphQL */ `
  mutation updateAssignee($input: UpdateAssigneeInput!) {
    updateAssignee(updateAssigneeInput: $input) {
      id
      teamId
      userId
      ownerId
      ownerType
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const deleteAssigneeMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteAssignee($input: DeleteAssigneeInput!) {
    deleteAssignee(deleteAssigneeInput: $input) {
      id
      userId
      teamId
      ownerId
      ownerType
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);
