import { FC } from "react";

// import { LabelViewOptional } from "@/components/_domain/label";

import IconCheckmark from "@/components/_icons/IconCheckmark";
import { LabelView } from "@/components/_domain/labels";

export interface LabelGroupSelectDropdownItemProps {
  label: {
    id: string;
  };
  selected?: boolean;
}

export const LabelGroupSelectDropdownItem: FC<LabelGroupSelectDropdownItemProps> = ({ label, selected }) => {
  return (
    <div className="m-1 flex w-full cursor-pointer flex-row items-center gap-2 p-1 text-xs">
      <div className="grow">
        <LabelView label={label} />
      </div>
      <div className="mr-2 w-3">
        {selected && (
          <IconCheckmark className="mr-2 h-3 w-3 shrink-0 self-end justify-self-center text-foreground/60" />
        )}
      </div>
    </div>
  );
};
