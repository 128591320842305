import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";

import { cn } from "@/lib/utils";

import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { toast } from "@/components/_ui/use-toast";
import { ColorPicker } from "@/components/color-picker";

import { StatusType } from "../../../../../../../components/_domain/status/status-type";
import { useCreateStatus } from "@/hooks/api/common";
import { StatusIcon } from "@/components/_domain/status";

export interface StatusFormCreateProps extends PropsWithChildren {
  team: {
    id: string;
  };
  statusType: StatusType;
  onSuccess?: () => void;
  onCanceled?: () => void;
  className?: string;
}

export const StatusFormCreate: FC<StatusFormCreateProps> = ({ team, statusType, onSuccess, onCanceled, className }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [newStatusName, setNewStatusName] = useState("");
  const [newStatusColor, setNewStatusColor] = useState("#95999F");

  const { createStatus } = useCreateStatus({
    teamId: team.id,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  async function handleCreateNewStatus() {
    if (newStatusName == "" || newStatusColor == "") {
      toast({
        title: "Fehler",
        description: "Bitte wählen Sie einen Titel und eine Farbe aus.",
        variant: "error",
      });
      return;
    }

    await createStatus({
      variables: {
        input: {
          teamId: team.id,
          name: newStatusName,
          color: newStatusColor,
          type: statusType,
        },
      },
    });
  }

  function reset() {
    // do not reset the color; the most likely case is that users want to create several objects of the same color
    // setNewStatusColor("#95999F")
    setNewStatusName("");
    ref.current?.focus();
  }

  function onUpdateCompleted() {
    reset();
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div
      className={cn(["my-1 flex flex-row items-center justify-between gap-2 rounded border bg-muted p-2", className])}
    >
      <div className="w-9">
        <ColorPicker
          asChild
          value={newStatusColor}
          onSelect={(color) => {
            setNewStatusColor(color);
          }}
        >
          <Button variant="outline" size="sm">
            <StatusIcon className="h-4 w-4" status={{ type: statusType, color: newStatusColor }} />
          </Button>
        </ColorPicker>
      </div>
      <div className="grow">
        <Input
          ref={ref}
          autoFocus
          value={newStatusName}
          onChange={(e) => setNewStatusName(e.currentTarget.value)}
          placeholder="Name des Status"
          className="bg-background ring-0 hover:ring-0 focus:ring-0 active:ring-0"
          style={{
            boxShadow: "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          }}
        />
      </div>
      <div>
        <Button variant="outline" size="sm" className="cursor-pointer" onClick={onCanceled}>
          Abbrechen
        </Button>
      </div>
      <div>
        <Button
          variant="default"
          size="sm"
          className="cursor-pointer"
          disabled={newStatusName == ""}
          onClick={handleCreateNewStatus}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};

export default StatusFormCreate;
