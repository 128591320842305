import * as z from "zod";

export const QUANTITATIVE_KEY_RESULT_TYPE = "QUANTITATIVE_KEY_RESULT";
export const MILESTONE_KEY_RESULT_TYPE = "MILESTONE_KEY_RESULT";
export type KEY_RESULT_TYPES = "QUANTITATIVE_KEY_RESULT" | "MILESTONE_KEY_RESULT";

function isNumeric(str: string) {
  if (typeof str != "string") return false; // we only process strings!

  return (
    !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export const keyResultFormSchema = z
  .object({
    title: z.string().min(1, "Der Name des Indikators darf nicht leer sein."),
    description: z.string(),
    type: z.string().refine(
      (value: string) => {
        const validTypes = [QUANTITATIVE_KEY_RESULT_TYPE, MILESTONE_KEY_RESULT_TYPE];
        if (validTypes.includes(value)) {
          return true;
        }
        return false;
      },
      {
        message: "Ungültiger Wert Indikatortyp.",
        path: ["type"],
      },
    ),
    initialValue: z.string(),
    targetValue: z.string(),
    unit: z.string().optional(),
    startDate: z.string({
      required_error: "Bitte wählen Sie eine Startzeitpunkt aus.",
    }),
    targetDate: z.string({
      required_error: "Bitte wählen Sie ein Zieldatum aus.",
    }),
    milestones: z.array(
      z.object({
        id: z.string(),
        index: z.number(),
        value: z.string().min(1),
        completed: z.boolean().default(false),
      }),
    ),
  })
  .refine(
    (data) => {
      // -----------------------------------
      // DATE CONVERSION AND VALIDATION
      // -----------------------------------
      return new Date(data.startDate) < new Date(data.targetDate);
    },
    {
      message: "Das Zieldatum darf nicht vor dem Startdatum liegen.",
      path: ["targetDate"],
    },
  )
  .refine(
    (data) => {
      // -----------------------------------
      // QUANTITATIVE_KEY_RESULT_TYPE VALIDATION
      // -----------------------------------
      if (data.type != QUANTITATIVE_KEY_RESULT_TYPE) {
        return true;
      }
      return isNumeric(data.initialValue);
    },
    {
      message: "Der Initialwert muss eine gültige Zahl sein.",
      path: ["initialValue"],
    },
  )
  .refine(
    (data) => {
      if (data.type != QUANTITATIVE_KEY_RESULT_TYPE) {
        return true;
      }
      return isNumeric(data.targetValue);
    },
    {
      message: "Der Zielwert muss eine gültige Zahl sein.",
      path: ["targetValue"],
    },
  )
  .refine(
    (data) => {
      if (data.type != QUANTITATIVE_KEY_RESULT_TYPE) {
        return true;
      }
      return parseFloat(data.initialValue) != parseFloat(data.targetValue);
    },
    {
      message: "Initial- und Zielwert müssen sich unterscheiden.",
      path: ["targetValue"],
    },
  )
  .refine(
    (data) => {
      // -----------------------------------
      // MILESTONE_KEY_RESULT_TYPE VALIDATION
      // -----------------------------------
      if (data.type != MILESTONE_KEY_RESULT_TYPE) {
        return true;
      }
      return data.milestones.length > 0 && data.milestones[0].value.length > 0;
    },
    {
      message: "Ein Meilensteinindikator benötigt mindestens einen Meilenstein.",
      path: ["milestones"],
    },
  );

export type Milestone = z.infer<typeof keyResultFormSchema>["milestones"][number];
