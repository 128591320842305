import { BrowserRouter } from "react-router-dom";
import { useSentryRoutes } from "@/lib/sentry";

import { useAuth } from "@/hooks/useAuth";
import { routes } from "./route-config";
import { Toaster } from "@/components/_ui/toaster";

function Routes() {
  const { authenticated } = useAuth();
  const routing = useSentryRoutes(routes(authenticated));

  return <>{routing}</>;
}

export function Router() {
  return (
    <BrowserRouter>
      <Routes />
      <Toaster />
    </BrowserRouter>
  );
}

export default Router;
