import { graphql } from "../../generated/gql";

export const getPriorityQueryDocument = graphql(/* GraphQL */ `
  query GetPriority($input: GetPriorityInput!) {
    priority(getPriorityInput: $input) {
      id
      teamId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);

export const getPriorityListForTeamQueryDocument = graphql(/* GraphQL */ `
  query GetPriorityListForTeam($input: GetPriorityListForTeamInput!) {
    priorityListForTeam(getPriorityListForTeamInput: $input) {
      id
      teamId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);
