import { FC } from "react";
import { Tabs, TabsList, TabsTrigger } from "@/components/_ui/tabs";
import { TAB_DATA, TabDataKey } from "./objective-tabs.model";
import { useObjectiveUIState } from "../hooks/useObjectiveUIState";

interface ObjectiveTabNavigationBarProps {}

export const ObjectiveTabNavigationBar: FC<ObjectiveTabNavigationBarProps> = () => {
  const { selectedTab, setSelectedTab } = useObjectiveUIState();

  const handleTabValueChanged = (val: string) => {
    const tab_value = TAB_DATA.find((v) => v.id === val);
    if (!tab_value) {
      return;
    }
    setSelectedTab(tab_value.id);
  };

  const handleTabButtonPressed = (val: TabDataKey) => {
    setSelectedTab(val);
  };

  return (
    <div className="flex w-full flex-row items-center border-b border-solid py-2 text-sm">
      <Tabs className="w-[400px]" value={selectedTab} onValueChange={handleTabValueChanged}>
        <TabsList>
          {TAB_DATA.map((v) => {
            return (
              <TabsTrigger
                key={v.id}
                value={v.id}
                onClick={() => {
                  handleTabButtonPressed(v.id);
                }}
                className="text-xs"
              >
                {v.label}
              </TabsTrigger>
            );
          })}
        </TabsList>
      </Tabs>
    </div>
  );
};
