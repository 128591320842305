import { useMemo } from "react";

export const useDate = () => {
  const TODAY = useMemo(() => {
    const d = new Date();
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
  }, []);

  return {
    TODAY,
  };
};
