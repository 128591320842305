import { useMemo } from "react";

import { useGetObjectiveListForTeams } from "..";

export interface UseGetObjectiveListAssignedToUserProps {
  user: {
    id: string;
  };
  teamList: { id: string }[];
}

export const useGetObjectiveListAssignedToUser = ({ user, teamList }: UseGetObjectiveListAssignedToUserProps) => {
  const { getObjectivesListForUser } = useGetObjectiveListForTeams({ teamList: teamList });

  const filteredObjectiveList = useMemo(() => {
    return getObjectivesListForUser(user.id);
  }, [user, teamList, getObjectivesListForUser]);

  const topLevelObjectiveList = useMemo(() => {
    return filteredObjectiveList
      .slice()
      .filter((o) => o.parentId === null)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.title.localeCompare(b.title);
      });
  }, [filteredObjectiveList]);

  return {
    objectiveList: filteredObjectiveList,
    topLevelObjectiveList,
  };
};
