import { FC, PropsWithChildren, ReactElement, useCallback, useMemo } from "react";

import { Combobox, ComboboxOption } from "@/components/combobox";

import {
  useCreateLabelInstance,
  useDeleteLabelInstance,
  useGetLabelInstanceList,
  useGetLabelListForTeamsInWorkspace,
} from "@/hooks/api/common";

import { LabelGroupSelectDropdownItem } from "./label-group-select-dropdown-item";
import { OwnerType } from "../models/owner-types";

export interface LabelGroupSelectDropdownProps extends PropsWithChildren {
  workspace: {
    id: string;
  };
  team: {
    id: string;
  };
  user: {
    id: string;
  };
  owner: {
    id: string;
    type: OwnerType;
  };
  labelGroup: {
    id: string;
  };
  asChild?: boolean;
}

export const LabelGroupSelectDropdown: FC<LabelGroupSelectDropdownProps> = ({
  workspace,
  team,
  user,
  owner,
  labelGroup,
  children,
  asChild,
}) => {
  const { labelListGroupedByGroupId } = useGetLabelListForTeamsInWorkspace({
    workspaceId: workspace.id,
    teamList: [{ id: team.id }],
    userId: user.id,
  });
  const { labelInstanceList } = useGetLabelInstanceList({ ownerId: owner.id });
  const { createLabelInstance } = useCreateLabelInstance({ ownerId: owner.id, ownerType: owner.type });
  const { deleteLabelInstance } = useDeleteLabelInstance({ ownerId: owner.id, ownerType: owner.type });

  const labelList = useMemo(() => {
    return labelListGroupedByGroupId.get(labelGroup.id) ?? [];
  }, [labelListGroupedByGroupId]);

  const comboboxOptions = useMemo(() => {
    return labelList
      .slice()
      .filter((l) => {
        return l.labelGroupId == labelGroup.id;
      })
      .flatMap((u) => {
        if (!u) {
          return [];
        }
        return {
          id: u.id,
          value: u.title,
        };
      });
  }, [labelList]);

  const isSelected = useCallback(
    (labelId: string) => {
      const labelInstance = labelInstanceList.find((e) => e.labelId == labelId);
      return !!labelInstance;
    },
    [labelList, labelInstanceList],
  );

  const renderItem = (option: ComboboxOption): ReactElement => {
    const label = labelList.find((e) => e.id == option.id);

    if (!label) {
      return <></>;
    }

    return <LabelGroupSelectDropdownItem label={label} selected={isSelected(label.id)} />;
  };

  const handleUserSelected = async (selectedOption: ComboboxOption | null) => {
    if (!selectedOption) {
      return;
    }

    const labelId = selectedOption.id;
    const hasBeenSelected = isSelected(labelId);

    if (!hasBeenSelected) {
      await createLabelInstance({
        variables: {
          input: { labelId: labelId, ownerId: owner.id, ownerType: owner.type, workspaceId: workspace.id },
        },
      });
    }
    if (hasBeenSelected) {
      const labelInstance = labelInstanceList.find((e) => e.labelId == labelId);
      if (!labelInstance) {
        return;
      }
      await deleteLabelInstance({
        variables: { input: { id: labelInstance.id } },
      });
    }
  };

  if (!comboboxOptions) {
    return <></>;
  }

  return (
    <Combobox asChild={asChild} options={comboboxOptions} renderItem={renderItem} onSelect={handleUserSelected}>
      {children}
    </Combobox>
  );
};
