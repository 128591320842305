import React, { createContext, useState, useContext, ReactNode, FC } from "react";

interface ObjectiveCreateHandlerContextType {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ObjectiveCreateHandlerContext = createContext<ObjectiveCreateHandlerContextType | undefined>(undefined);

// Define a type for the props that the provider will accept
interface ObjectiveCreateHandlerProviderProps {
  children: ReactNode;
}

// Create the provider component
export const ObjectiveCreateHandlerProvider: FC<ObjectiveCreateHandlerProviderProps> = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <ObjectiveCreateHandlerContext.Provider
      value={{
        showModal,
        setShowModal,
      }}
    >
      {children}
    </ObjectiveCreateHandlerContext.Provider>
  );
};

// Define the useObjectiveCreateHandler hook
export const useObjectiveCreateHandler = (): ObjectiveCreateHandlerContextType => {
  const context = useContext(ObjectiveCreateHandlerContext);

  if (!context) {
    throw new Error("useObjectiveCreateHandler must be used within a ChildProvider");
  }

  return context;
};
