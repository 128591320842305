import { useCallback, useEffect, useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getObjectivesQueryDocument } from "@/graphql/common";

import { updateCacheFromObjectiveForTeamsQueryResult } from "./_utils";

export interface UseGetObjectiveListForTeamProps {
  teamList: { id: string }[];
  shouldUpdateCache?: boolean
}

export const useGetObjectiveListForTeams = ({ teamList, shouldUpdateCache }: UseGetObjectiveListForTeamProps) => {
  const queryResult = useQuery(getObjectivesQueryDocument, {
    variables: { input: { teamIdList: teamList.map((t) => t.id) } },
    skip: teamList.length == 0,
  });

  const objectiveList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }
    return queryResult.data.objectiveList
    .slice()
    .sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a.title.localeCompare(b.title);
    });;
  }, [queryResult.data]);

  const topLevelObjectiveList = useMemo(() => {
    return objectiveList
      .slice()
      .filter((o) => o.parentId === null)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.title.localeCompare(b.title);
      });
  }, [objectiveList]);

  const getObjectivesListForParent = useCallback(
    (parentId: string) => {
      return objectiveList
        .slice()
        .filter((o) => o.parentId === parentId)
        .sort((a, b) => {
          if (!a || !b) {
            return 0;
          }
          return a.title.localeCompare(b.title);
        });
    },
    [objectiveList],
  );

  const getObjectivesListForUser = useCallback(
    (userId: string) => {
      return objectiveList
        .slice()
        .filter((o) => o.assignee && o.assignee.userId === userId)
        .sort((a, b) => {
          if (!a || !b) {
            return 0;
          }
          return a.title.localeCompare(b.title);
        });
    },
    [objectiveList],
  );

  useEffect(() => {
    // - Writing Queryies to the cache seem to be expensive (especially for lists)
    // - Thus they should be avoided, if retrieval via type policies is available
    if (shouldUpdateCache) {
      updateCacheFromObjectiveForTeamsQueryResult(objectiveList, teamList); // slow 
    }
  }, [objectiveList]);

  return {
    queryResult,
    objectiveList,
    topLevelObjectiveList,
    getObjectivesListForParent,
    getObjectivesListForUser,
  };
};
