import { FC, PropsWithChildren, useMemo, useState } from "react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";

import IconTrash from "@/components/_icons/IconTrash";
import IconPen from "@/components/_icons/IconPen";
import IconPlus from "@/components/_icons/IconPlus";

import { DeleteConfirmation } from "@/components/delete-confirmation";
import DeleteLabelGroupAction from "./actions/delete-label-group-action";

import { useQuery } from "@apollo/client";
import { getLabelGroupQueryDocument } from "@/graphql/common";

export interface TeamLabelGroupActionButtonProps extends PropsWithChildren {
  labelGroup: {
    id: string;
  };
  team: {
    id: string;
  };
  handleEditLabelGroup: () => void;
  handleAddLabelToGroup: () => void;
  asChild?: boolean;
}

export const TeamLabelGroupActionButton: FC<TeamLabelGroupActionButtonProps> = ({
  team,
  handleEditLabelGroup,
  handleAddLabelToGroup,
  asChild,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [deleteConfirmationisOpen, setDeleteConfirmationisOpen] = useState(false);
  const [triggerDelete, setTriggerDelete] = useState(false);
  const { data } = useQuery(getLabelGroupQueryDocument, {
    variables: { input: { id: props.labelGroup.id } },
  });

  const labelGroup = useMemo(() => {
    if (!data) {
      return null;
    }

    return data.labelGroup;
  }, [data]);

  if (!labelGroup) {
    return <></>;
  }

  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild={asChild}>{children}</DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            className="cursor-pointer text-foreground/60 hover:bg-accent hover:text-accent-foreground"
            aria-selected
            onClick={handleEditLabelGroup}
          >
            <div className="relative flex items-center overflow-hidden text-ellipsis">
              <IconPen className="mr-2 h-3 w-3" />
              Gruppe bearbeiten
            </div>
          </DropdownMenuItem>

          <DropdownMenuItem
            className="cursor-pointer text-foreground/60 hover:bg-accent hover:text-accent-foreground"
            aria-selected
            onClick={handleAddLabelToGroup}
          >
            <div className="relative flex items-center overflow-hidden text-ellipsis">
              <IconPlus className="mr-2 h-3 w-3" />
              Label hinzufügen
            </div>
          </DropdownMenuItem>

          <DropdownMenuItem
            className="cursor-pointer text-foreground/60 hover:bg-accent hover:text-accent-foreground"
            aria-selected
            onClick={() => {
              setDeleteConfirmationisOpen(true);
            }}
          >
            <div className="relative flex items-center overflow-hidden text-ellipsis">
              <IconTrash className="mr-2 h-3 w-3" />
              Label löschen
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DeleteLabelGroupAction asChild labelGroup={labelGroup} team={team} forceDelete={triggerDelete}>
        <DeleteConfirmation
          open={deleteConfirmationisOpen}
          onOpenChange={setDeleteConfirmationisOpen}
          onConfirm={() => {
            setTriggerDelete(true);
          }}
          title={`Sind Sie sich sicher, dass Sie diese Label-Gruppe löschen möchten?`}
          description={`Diese Aktion kann nicht rückgängig gemacht werden. Die Gruppe und alle damit verbundenen Labels werden unwideruflich gelöscht und von allen Zielen entfernt.`}
        />
      </DeleteLabelGroupAction>
    </>
  );
};
