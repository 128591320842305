import { FC, PropsWithChildren } from "react";
import IconExclamationSolid from "@/components/_icons/IconExclamationSolid";

export interface NotFoundPageProps extends PropsWithChildren {
  text?: string;
}

const Page: FC<NotFoundPageProps> = ({ text, children }) => {
  return (
    <main className="align-center relative flex h-screen select-none justify-center overflow-auto">
      <div className="relative flex flex-col items-center self-center">
        <h4 className="relative flex grow items-stretch overflow-hidden align-middle text-sm">
          <IconExclamationSolid className="my-auto mr-2 h-3 w-3" />
          404 – Not Found
        </h4>
        <p className="mt-4 text-center text-xs text-muted-foreground">
          {text ? text : "Leider konnten wir die Seite nach der du suchst nicht finden."}
        </p>
        {children && <div className="mt-4">{children}</div>}
      </div>
    </main>
  );
};

export default Page;
