import { LucideProps } from "lucide-react";

const Icon = ({ ...props }: LucideProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="heartbeat-circle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="24"
      width="24"
      {...props}
    >
      <circle cx="12" cy="12" r="12" fill="currentColor" opacity={0.3} />
      <path
        stroke="currentColor"
        fill="none"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(4,4)"
        d="M1 9H4L6 13L10 3L12 7H15"
      />
    </svg>
  );
};

export default Icon;
