import { graphql } from "../../generated/gql";

export const teamMembershipsForTeamQueryDocument = graphql(/* GraphQL */ `
  query teamMembershipsForTeam($input: String!) {
    teamMembershipsForTeam(teamId: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const findTeamInviteQueryDocument = graphql(/* GraphQL */ `
  query findTeamInvite($input: String!) {
    findTeamInvite(token: $input) {
      userId
      teamId
      pendingWorkspaceMembership
      user {
        id
        email
        username
        fullname
        profilePicture
        activated
      }
      team {
        id
        workspaceId
        name
        visibility
        logo
        color
        workspace {
          id
          name
          workspaceUrl
          logo
        }
      }
    }
  }
`);
