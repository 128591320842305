import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";

import { DatatableColumnHeader } from "@/components/datatable-column-header";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
import { File } from "./model";
import { DatatableRowActions } from "./row-actions";
import { stringFromFileSize } from "@/lib/utils";
import { FileDownloader } from "../file-downloader";


export const columns: ColumnDef<File>[] = [
  {
    id: "name",
    accessorKey: "name",
    enableHiding: false,
    size: 400,
    header: ({ column }) => <DatatableColumnHeader column={column} title="Name" />,
    cell: ({ row }) => <FileDownloader file={row.original} mode="OPEN">{row.original.name}</FileDownloader>,
  },
  {
    id: "size",
    accessorKey: "size",
    accessorFn: (original) => {
      return stringFromFileSize(original.size, true)
    },
    header: ({ column }) => <DatatableColumnHeader column={column} title="Dateigröße" />,
  },
  {
    id: "updatedAt",
    accessorKey: "updatedAt",
    accessorFn: (original) => {
      return format(new Date(original.createdAt), "yyyy-MM-dd");
    },
    header: ({ column }) => <DatatableColumnHeader column={column} title="Zuletzt geändert am" />,
  },
  {
    id: "createdAt",
    accessorKey: "createdAt",
    accessorFn: (original) => {
      return format(new Date(original.createdAt), "yyyy-MM-dd");
    },
    header: ({ column }) => <DatatableColumnHeader column={column} title="Hinzugefügt am" />,
  },
  {
    id: "actions",
    cell: ({ row }) => <DatatableRowActions row={row} file={row.original} />,
  },
];
