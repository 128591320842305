import { FC, useContext, useEffect, useState } from "react";

import { cn } from "@/lib/utils";

import { RichTextToolbarContext } from "./useToolbarContext";
import { ToolbarCommandType } from "./ToolbarCommands";

import { MenubarMenu, MenubarTrigger } from "@/components/_ui/menubar";

import { LexicalNode } from "lexical";
import { $isLinkNode, LinkNode } from "@lexical/link";
import { AddLinkDialog } from "../plugins/link-plugins/AddLinkDialog";

export interface ToolbarButtonLinkProps {
  command: ToolbarCommandType;
}

export const ToolbarButtonLink: FC<ToolbarButtonLinkProps> = ({ command }) => {
  const { editor, selection } = useContext(RichTextToolbarContext);
  const [showAddLinkDialog, setShowAddLinkDialog] = useState(false);
  const [selectionUrl, setSelectionUrl] = useState<string | null>(null);
  const [selectedText, setSelectedText] = useState<string | null>(null);

  useEffect(() => {
    if (!editor) {
      return;
    }
    editor.getEditorState().read(() => {
      if (!selection) {
        setSelectedText(null);
        return;
      }

      setSelectedText(selection.getTextContent());
    });
  }, [selection]);

  function getUrlFromNode(node: LexicalNode): Promise<string | null> {
    return new Promise((resolve) => {
      if (!editor) {
        return resolve(null);
      }

      editor.getEditorState().read(() => {
        const maybeLinkNode = node.getParent();

        if ($isLinkNode(maybeLinkNode)) {
          const linkNode = maybeLinkNode as LinkNode;
          resolve(linkNode.getURL());
        } else {
          resolve(null);
        }
      });
    });
  }

  async function findLinkElement() {
    if (!editor) {
      return;
    }
    if (!selection) {
      return;
    }

    const nodes = selection.getNodes();
    const selectedNode = nodes.length == 1 && nodes[0];
    if (!selectedNode) {
      return;
    }

    const url = await getUrlFromNode(selectedNode);

    setSelectionUrl(url);
  }

  async function clickHandler() {
    if (!editor) {
      return;
    }
    await findLinkElement();
    setShowAddLinkDialog(true);
  }

  function isActive() {
    if (!selection) {
      return false;
    }
    return command.isActive(selection);
  }

  function isDisabled() {
    return !(selectedText && selectedText.length > 0);
  }

  if (isDisabled()) {
    return <></>;
  }

  return (
    <>
      <MenubarMenu>
        <MenubarTrigger
          disabled={isDisabled()}
          onClick={() => {
            clickHandler();
          }}
          className={cn(
            isActive() ? "bg-muted" : "",
            isDisabled() ? "cursor-auto text-muted-foreground" : "",
            "h-8 px-3 py-3 text-xs ",
          )}
        >
          {command.element}
        </MenubarTrigger>
      </MenubarMenu>
      {editor && (
        <AddLinkDialog
          url={selectionUrl}
          open={showAddLinkDialog}
          onClose={() => {
            setShowAddLinkDialog(false);
            setSelectionUrl(null);
          }}
          editor={editor}
        />
      )}
    </>
  );
};
