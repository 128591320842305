import { FC } from "react";

import { cn } from "@/lib/utils";

import { AvatarUser } from "@/components/_domain/user";

import { useUserInWorkspace } from "@/hooks/api/common";

import IconUserUnassigned from "@/components/_icons/IconUserUnassigned";

export interface FilterByAssigneeUserAvatarProps {
  workspace: {
    id: string;
  };
  user?: {
    id: string;
  } | null;
  className?: string;
  logoOnly?: boolean;
}

export const FilterByAssigneeUserAvatar: FC<FilterByAssigneeUserAvatarProps> = ({ className, logoOnly, ...props }) => {
  const { user } = useUserInWorkspace({ workspaceId: props.workspace.id, userId: props.user?.id });

  if (!user) {
    return (
      <div className="flex flex-row items-center gap-2">
        <IconUserUnassigned className={cn(["h-3.5 w-3.5 shrink-0 text-foreground/60", className])} />
        {!logoOnly && <div>Nicht zugewiesen</div>}
      </div>
    );
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <AvatarUser user={user} className={cn(["h-3.5 w-3.5 shrink-0", className])} />
      {!logoOnly && <div>{user.username ?? user.email}</div>}
    </div>
  );
};
