import { useMutation } from "@apollo/client";
import { createPriorityMutationDocument, getPriorityListForTeamQueryDocument } from "@/graphql/common";

export interface UseCreatePriorityProps {
  teamId: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useCreatePriority = ({ teamId, onCompleted, onError }: UseCreatePriorityProps) => {
  const [createPriority] = useMutation(createPriorityMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: getPriorityListForTeamQueryDocument,
        variables: { input: { teamId: teamId } },
      },
    ],
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createPriority,
  };
};
