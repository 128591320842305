import { graphql } from "../../generated/gql";

export const updateUserProfileDocument = graphql(/* GraphQL */ `
  mutation updateUser($input: UserUpdateInput!) {
    updateUser(userUpdateInput: $input) {
      id
      email
      fullname
      username
      createdAt
      updatedAt
    }
  }
`);

export const updateUserPasswordDocument = graphql(/* GraphQL */ `
  mutation updatePassword($input: PasswordUpdateInput!) {
    updatePassword(passwordUpdateInput: $input) {
      id
      email
      fullname
      username
      createdAt
      updatedAt
    }
  }
`);
