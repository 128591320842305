import { FC, useMemo } from "react";
import AvatarUser from "@/components/_domain/user/avatar-user";
import IconUserUnassigned from "@/components/_icons/IconUserUnassigned";
import IconCheckmark from "@/components/_icons/IconCheckmark";
import { cn } from "@/lib/utils";

export interface NotYetTeamMemberSelectDropdownItemProps {
  user?: {
    id: string;
    email: string;
    profilePictureUrl?: string | null;
    username?: string | null;
    fullname?: string | null;
  };
  selected?: boolean;
  isPending?: boolean;
}

export const NotYetTeamMemberSelectDropdownItem: FC<NotYetTeamMemberSelectDropdownItemProps> = ({
  user,
  selected,
  isPending,
}) => {
  const displayValue = useMemo(() => {
    if (!user) {
      return "nicht zugewiesen";
    }

    const appendix = isPending ? " (ausstehend)" : "";

    if (user.username) {
      return `${user.username}${appendix}`;
    }

    if (user.fullname) {
      return `${user.fullname}${appendix}`;
    }

    return `${user.email}${appendix}`;
  }, [user, isPending]);

  if (!user) {
    return (
      <div className="m-1 flex w-full cursor-pointer flex-row items-center gap-2 p-1 text-xs">
        <IconUserUnassigned className="mr-2 h-5 w-5 shrink-0 text-foreground/60" />
        <div className="grow">{displayValue}</div>
        <div className="mr-2 w-3">
          {selected && (
            <IconCheckmark className="mr-2 h-3 w-3 shrink-0 self-end justify-self-center text-foreground/60" />
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn([
        "m-1 flex w-full cursor-pointer flex-row items-center gap-2 p-1 text-xs",
        isPending ? "grayscale" : "",
      ])}
    >
      <AvatarUser user={user} className="mr-2 h-5 w-5 shrink-0" />
      <div className="grow">{displayValue}</div>
      <div className="mr-2 w-3">
        {selected && (
          <IconCheckmark className="mr-2 h-3 w-3 shrink-0 self-end justify-self-center text-foreground/60" />
        )}
      </div>
    </div>
  );
};
