import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getStatusListForTeamQueryDocument } from "@/graphql/common";
import { StatusTypes } from "@/components/_domain/status/status-type";

export interface UseGetStatiProps {
  teamId: string;
}

export const useGetStatusListForTeam = ({ teamId }: UseGetStatiProps) => {
  const queryResult = useQuery(getStatusListForTeamQueryDocument, {
    variables: { input: { teamId } },
  });

  const statusListForTeam = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    return queryResult.data.statusListForTeam.map((s) => {
      let order = 0;

      if (s.type == StatusTypes.backlog) {
        order = 100;
      }
      if (s.type == StatusTypes.unstarted) {
        order = 200;
      }
      if (s.type == StatusTypes.started) {
        order = 300;
      }
      if (s.type == StatusTypes.completed) {
        order = 400;
      }
      if (s.type == StatusTypes.canceled) {
        order = 500;
      }
      return {
        ...s,
        order,
      };
    });
  }, [queryResult.data]);

  return {
    queryResult,
    statusListForTeam,
  };
};
