import { useEffect, useMemo } from "react";

import { toast } from "@/components/_ui/use-toast.ts";
import { Slot, type AsChildProps } from "@/components/slot.tsx";

import {
  getLabelListForWorkspaceQueryDocument,
  getLabelGroupQueryDocument,
  deleteLabelMutationDocument,
} from "@/graphql/common";
import { useMutation } from "@apollo/client";

type DeletLabelActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  label: {
    id: string;
    labelGroupId?: string | null;
  };
  workspace: {
    id: string;
  };
  forceDelete?: boolean;
};

export const DeletLabelAction = ({ label, workspace, asChild, forceDelete, ...props }: DeletLabelActionProps) => {
  const Comp = asChild ? Slot : "button";
  const refetchQueries = useMemo(() => {
    if (!label.labelGroupId) {
      return [
        {
          query: getLabelListForWorkspaceQueryDocument,
          variables: { input: { workspaceId: workspace.id } },
        },
      ];
    }

    return [
      {
        query: getLabelListForWorkspaceQueryDocument,
        variables: { input: { workspaceId: workspace.id } },
      },
      {
        query: getLabelGroupQueryDocument,
        variables: { input: { id: label.labelGroupId } },
      },
    ];
  }, [label]);

  const [deleteLabel] = useMutation(deleteLabelMutationDocument, {
    onCompleted: () => {
      toast({
        title: "Kategorie gelöscht",
        description: "Wir haben die Kategorie erfolgreich gelöscht.",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler beim Löschen der Kategorie aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
        variant: "error",
      });
    },
    refetchQueries: refetchQueries,
  });

  useEffect(() => {
    if (forceDelete) {
      triggerDeleteLabel();
    }
  }, [forceDelete]);

  async function triggerDeleteLabel() {
    await deleteLabel({ variables: { input: { id: label.id } } });
  }

  function handleClick() {
    triggerDeleteLabel();
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default DeletLabelAction;
