import { graphql } from "../../generated/gql";


export const fileQueryDocument = graphql(/* GraphQL */ `
  query file($input: FileGetInput!) {
    file(fileGetInput: $input) {
        id
        ownerId
        ownerType
        name
        size
        createdAt
        updatedAt
    }
  }
`);



export const fileListForOwnerQueryDocument = graphql(/* GraphQL */ `
  query fileListForOwner($input: FileListGetByOwnerInput!) {
    fileListForOwner(fileListGetByOwnerInput: $input) {
        id
        ownerId
        ownerType
        name
        size
        createdAt
        updatedAt
    }
  }
`);


