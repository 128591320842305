import { FC, useMemo, useState } from "react";

import { RichTextEditor } from "@/components/rich-text-editor";

import { toast } from "@/components/_ui/use-toast";
import { Button } from "@/components/_ui/button";
import { Link } from "react-router-dom";

import { useCreateProgressSnapshot } from "@/hooks/api/common";

import { ProgressSnapshotHeader } from "./components/progress-snapshot-header";
import { ProgressSnapshotHealthInput } from "./components/progress-snapshot-health-input";
import { ProgressSnapshotDateInput } from "./components/progress-snapshot-date-input";
import { ProgressSnapshotValueInput } from "./components/progress-snapshot-value-input";
import { HealthTypes, healthTypefromString } from "@/components/_domain/progress-snapshot/health-types";
import { useProgressUpdateUIStateState } from "../../hooks/useCreateProgressUpdateUIState";

export interface ProgressSnapshotCreateFormProps {
  className?: string;
  workspace: {
    id: string;
    name: string;
    workspaceUrl: string;
    profilePicture?: string;
  };
  objective: {
    id: string;
    title: string;
  };
  keyResult?: {
    id: string;
  } | null;
  onSuccess: () => void;
}

export const ProgressSnapshotCreateForm: FC<ProgressSnapshotCreateFormProps> = ({
  workspace,
  objective,
  keyResult,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [removedFromDom, setRemovedFromDom] = useState(false);
  const { newProgressSnapshotForm, resetNewPogressSnapshotForm, updateNewProgressSnapshot } =
    useProgressUpdateUIStateState();

  const initialEditorState = useMemo(() => {
    if (!newProgressSnapshotForm.description) {
      return undefined;
    }
    if (newProgressSnapshotForm.description == "") {
      return undefined;
    }
    return newProgressSnapshotForm.description;
  }, [newProgressSnapshotForm]);

  const { createProgressSnapshot } = useCreateProgressSnapshot({
    objectiveId: objective.id,
    keyResultId: keyResult ? keyResult.id : undefined,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  async function onRichTextEditorChange(value: string, json: string) {
    if (value == "") {
      updateNewProgressSnapshot({ description: "" });
    } else {
      updateNewProgressSnapshot({ description: json });
    }
  }

  async function handleCreateNewProgressSnapshot() {
    if (loading) {
      return;
    }

    const { description, health, snapshotDate, value, properties } = newProgressSnapshotForm;

    const descriptionHasValue = description && description != "";
    const healthHasValue = health != "";
    const valueHasValue = value != "";
    const propertiesHasValue = value != "";

    if (![descriptionHasValue, healthHasValue, valueHasValue].some((e) => e)) {
      // no values have been modifed
      toast({
        title: "Mindesten ein Wert muss befüllt sein",
        description: "Bitte ändern Sie mindesten eines der Felder um einen Fortschrittsbericht zu erstellen.",
        variant: "default",
      });
      return;
    }

    const newSnapshot = {
      objectiveId: objective.id,
      keyResultId: keyResult ? keyResult.id : undefined,
      value: keyResult && valueHasValue ? Number(value) : undefined,
      health: healthHasValue ? health : HealthTypes.onTrack,
      description: descriptionHasValue ? description : "",
      snapshotDate: snapshotDate,
      properties: propertiesHasValue ? properties : undefined,
    };

    setLoading(true);
    await createProgressSnapshot({
      variables: {
        input: {
          ...newSnapshot,
        },
      },
    });
  }

  async function onUpdateCompleted() {
    setLoading(false);
    toast({
      title: "Fortschrittsbericht erstellt",
      description: (
        <div className="flex flex-col gap-2">
          <div>
            <span>Der Fortschrittsbericht für das Ziel</span>
            <span className="font-medium">{` ${objective.title} `}</span>
            <span>wurde erstellt.</span>
          </div>
          <Link to={`${workspace.workspaceUrl}/strategic-goals/${objective.id}/activity`}>Jetzt anzeigen</Link>
        </div>
      ),
      variant: "success",
    });

    resetNewPogressSnapshotForm();
    forceRichTextEditorRerender();
    onSuccess();
  }

  async function onUpdateError() {
    setLoading(false);
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  function forceRichTextEditorRerender() {
    setRemovedFromDom(true);
    setTimeout(() => {
      setRemovedFromDom(false);
    }, 1);
  }

  return (
    <form>
      <div className="grid w-full">
        {/* Header */}
        <ProgressSnapshotHeader workspace={workspace} objective={objective} />
        {/* Main Part */}
        <div className="mx-auto max-h-[70vh] w-full overflow-y-scroll md:max-h-[40vh] ">
          {!removedFromDom && (
            <RichTextEditor
              onChange={onRichTextEditorChange}
              hideFloatingMenu={false}
              placeholder="Schreiben Sie einen Fortschrittsbericht ..."
              initialValue={initialEditorState}
            />
          )}
        </div>
        <div className="flex flex-row items-center gap-2 py-2">
          <ProgressSnapshotHealthInput
            objective={objective}
            value={healthTypefromString(newProgressSnapshotForm.health)}
            onValueChange={(value) => {
              updateNewProgressSnapshot({ health: value ?? "" });
            }}
          />
          <ProgressSnapshotDateInput
            keyResult={keyResult}
            value={newProgressSnapshotForm.snapshotDate}
            onValueChange={(value) => {
              if (value) {
                updateNewProgressSnapshot({ snapshotDate: value });
              }
            }}
          />
          {!!keyResult && (
            <ProgressSnapshotValueInput
              keyResult={{ id: keyResult.id }}
              value={
                newProgressSnapshotForm.value
                  ? {
                      value: Number(newProgressSnapshotForm.value),
                      properties: JSON.parse(newProgressSnapshotForm.properties),
                    }
                  : undefined
              }
              onValueChange={(value) => {
                if (!value) {
                  updateNewProgressSnapshot({ value: "", properties: "" });
                  return;
                }
                updateNewProgressSnapshot({
                  value: value.value.toString(),
                  properties: JSON.stringify(value.properties),
                });
              }}
            />
          )}
        </div>
        <div className="border-b"></div>
        <div className="flex flex-row items-center justify-end gap-4 pt-2">
          <Button type="button" size="xs" onClick={() => handleCreateNewProgressSnapshot()}>
            Bericht erstellen
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ProgressSnapshotCreateForm;
