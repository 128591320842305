import { FC, useState } from "react";
import { Button } from "@/components/_ui/button";


import { apolloClient } from "@/services/apollo.service/apollo.service";
import {
    deleteFileMutationDocument,
    fileListForOwnerQueryDocument,
} from "@/graphql/common";
import { toast } from "@/components/_ui/use-toast";

interface FileDeleteForm {
    file: {
        id: string;
        name: string;
        ownerId: string;
    };
    onCancel?: () => void;
    onSuccess?: () => void;
}
export const FileDeleteForm: FC<FileDeleteForm> = ({ file, onSuccess, onCancel }) => {
    const [loading, setLoading] = useState(false);


    async function handleCancelPressed() {
        if (onCancel) {
            onCancel()
        }
    }

    async function handleConfirmationButtonPressed() {
        if (loading) {
            return
        }

        setLoading(true)
        try {
            await apolloClient.mutate({
                mutation: deleteFileMutationDocument,
                fetchPolicy: "no-cache",
                variables: {
                    input: {
                        id: file.id
                    },
                },
                refetchQueries: [
                    {
                        query: fileListForOwnerQueryDocument,
                        variables: { input: { ownerId: file.ownerId } },
                    },
                ],
            });

            if (onSuccess) {
                toast({
                    title: "Dokument gelöscht",
                    description: `Das Dokument "${file.name}" wurde gelöscht.`,
                    variant: "success",
                });
                onSuccess();
            }
        } catch (e) {
            toast({
                title: "Fehler",
                description: "Das Dokument konnte nicht gelöscht werden. Bitte laden Sie die Seite neu und versuchen Sie es noch einmal.",
                variant: "error",
            });
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className=" sm:max-w-lg">
            <div>
                <span className="text-base font-normal">Dokument löschen </span>
            </div>
            <div className="relative max-w-full font-light">
                <p className="mt-4 mb-2 text-xs">
                    Sind Sie sich sicher, dass sie das Dokument <span className="font-medium">{file.name}</span> löschen
                    wollen?
                </p>
                <p className="mb-4 text-xs">
                    Bitte beachten Sie, dass diese Aktion nicht rückgängig gemacht werden kann. Das Dokument wird unwiderruflich gelöscht.
                </p>
            </div>
            <div className="flex flex-row flex-wrap mt-2">
                <div className="flex w-full flex-row items-center justify-end gap-2">
                    <Button
                        type="button"
                        variant="outline"
                        size="xs"
                        // className="rounded-md border text-sm"
                        onClick={handleCancelPressed}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        variant="destructive"
                        size="xs"

                        // className="flex h-10 w-full items-center justify-center rounded-md border text-sm transition-all hover:bg-destructive/90 focus:outline-none"
                        onClick={handleConfirmationButtonPressed}
                    >
                        Dokument Löschen
                    </Button>
                </div>
            </div>
        </div>
    );
};
