import { NavLink } from "react-router-dom";
import { SidebarProps } from "./sidebar-props.interface";
import { cn } from "@/lib/utils";

export const SidebarHeaderElement = (props: SidebarProps) => {
  return (
    <div className="margin-1 relative rounded">
      <NavLink
        {...props}
        to=""
        className={cn(props.className, "pointer-events-none cursor-none select-none text-sm text-muted-foreground")}
      >
        <span className="pointer-events-none relative flex h-8 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
          {props.children}
        </span>
      </NavLink>
    </div>
  );
};
