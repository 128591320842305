import { FC } from "react";

import {
  ObjectiveOverviewTab,
  ObjectiveDetailTab,
  ObjectiveActivityFeedTab,
  ObjectiveDocumentsTab,
  TabDataKey,
} from ".";

interface ObjectiveTabViewProps {
  workspace: {
    id: string;
  };
  objective: {
    id: string;
  };
  tabId: TabDataKey | undefined;
}

export const ObjectiveTabView: FC<ObjectiveTabViewProps> = ({ workspace, objective, tabId }) => {
  if (tabId == "overview") {
    return <ObjectiveOverviewTab workspace={workspace} objective={objective} />;
  }
  if (tabId == "details") {
    return <ObjectiveDetailTab workspace={workspace} objective={objective} />;
  }
  if (tabId == "activity") {
    return <ObjectiveActivityFeedTab workspace={workspace} objective={objective} />;
  }
  if (tabId == "documents") {
    return <ObjectiveDocumentsTab workspace={workspace} objective={objective} />;
  }

  return <ObjectiveOverviewTab workspace={workspace} objective={objective} />;
};
