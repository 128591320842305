import { graphql } from "../../generated/gql";

export const createPriorityInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation createPriorityInstance($input: CreatePriorityInstanceInput!) {
    createPriorityInstance(createPriorityInstanceInput: $input) {
      id
      teamId
      priorityId
      ownerId
      ownerType
      priority {
        id
        teamId
        name
        color
        type
      }
    }
  }
`);

export const updatePriorityInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation updatePriorityInstance($input: UpdatePriorityInstanceInput!) {
    updatePriorityInstance(updatePriorityInstanceInput: $input) {
      id
      teamId
      priorityId
      ownerId
      ownerType
      priority {
        id
        teamId
        name
        color
        type
      }
    }
  }
`);

export const deletePriorityInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation DeletePriorityInstance($input: DeletePriorityInstanceInput!) {
    deletePriorityInstance(deletePriorityInstanceInput: $input) {
      id
      teamId
      priorityId
      ownerId
      ownerType
      priority {
        id
        teamId
        name
        color
        type
      }
    }
  }
`);
