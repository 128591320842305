import { useMutation } from "@apollo/client";
import { deleteStatusMutationDocument, getStatusListForTeamQueryDocument } from "@/graphql/common";

export interface UseDeleteStatusProps {
  teamId: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useDeleteStatus = ({ teamId, onCompleted, onError }: UseDeleteStatusProps) => {
  const [deleteStatus] = useMutation(deleteStatusMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: getStatusListForTeamQueryDocument,
        variables: { input: { teamId: teamId } },
      },
    ],
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deleteStatus,
  };
};
