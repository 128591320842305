import { FC, PropsWithChildren, useRef, useState, useCallback, useEffect, useMemo } from "react";
import { cn } from "@/lib/utils";
import IconSheet from "../_icons/IconSheet";
import IconSheetFixed from "../_icons/IconSheetFixed";

interface SheetProps extends PropsWithChildren {
  userCanResize?: boolean;
}

export const Sheet: FC<SheetProps> = ({ userCanResize, children }) => {
  const defaultWidth = 320;
  const minWidth = 320;
  const maxWidth = 480;
  const breakpoint = 1280;
  const sheetRef = useRef<HTMLElement>(null);
  const [isHidden, setIsHidden] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [sheetWidth, setSheetWidth] = useState(defaultWidth);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const canResize = useMemo(() => {
    if (screenWidth < breakpoint) {
      return false;
    }

    return userCanResize;
  }, [screenWidth]);

  const sheetStyle = useMemo(() => {
    // sheet is hidden and should not be visible
    if (isHidden && !isHover) {
      return {
        minWidth: minWidth,
        maxWidth: maxWidth,
        width: sheetWidth,
        right: -defaultWidth,
        top: 56,
        bottom: 0,
        borderRadius: "0.25rem",
        overflow: "hidden",
        transition: "0.155s cubic-bezier(.36,-0.01,0,.77)",
      };
    }
    // sheet is hidden and should show as overlay
    if (isHidden && isHover) {
      return {
        minWidth: minWidth,
        maxWidth: maxWidth,
        width: defaultWidth,
        right: -1,
        top: 56,
        bottom: 0,
        borderRadius: "0.25rem",
        overflow: "hidden",
        transition: "0.155s cubic-bezier(.36,-0.01,0,.77)",
      };
    }

    // sheet is permanently visible
    return {
      minWidth: minWidth,
      maxWidth: maxWidth,
      width: sheetWidth,
      right: 0,
      top: 0,
      transition: "0s cubic-bezier(.36,-0.01,0,.77)",
    };
  }, [isHidden, isHover, sheetWidth]);

  function hideSheet() {
    setIsHidden(true);
    setIsHover(false);
    setSheetWidth(0);
  }

  function showSheet() {
    setIsHidden(false);
    setIsHover(false);
    setSheetWidth(defaultWidth);
  }

  function showsheetOverlay() {
    setIsHover(true);
  }

  function hideSheetOverlay() {
    setIsHover(false);
  }

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (event: any) => {
      if (!isResizing) {
        return;
      }
      if (!sheetRef.current) {
        return;
      }

      const translatedWidth = sheetRef.current.getBoundingClientRect().right - event.clientX;
      // Hide sheet if we are getting close to the edge
      if (translatedWidth < 50) {
        setIsResizing(false);
        hideSheet();
        return;
      }

      // otherwise just minimize until minWidth
      const sheetWidth = Math.min(maxWidth, Math.max(minWidth, translatedWidth));
      setSheetWidth(sheetWidth);
    },
    [isResizing],
  );

  const handleWindowResize = () => {
    const { innerWidth: width } = window;

    if (width < breakpoint) {
      hideSheet();
    }
    setScreenWidth(width);
  };

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  useEffect(() => {
    handleWindowResize(); // call initially on first component load
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div>
      <div style={{ width: isHidden ? 0 : sheetWidth }}></div>
      {isHidden && isHover && (
        <div
          className="backdrop-blur-sm fixed bottom-0 left-0 right-0 top-0 bg-white/80 dark:bg-slate-950/80"
          style={{ zIndex: 8 }}
          onMouseEnter={() => (canResize ? hideSheetOverlay() : () => {})}
          onClick={() => hideSheetOverlay()}
        ></div>
      )}
      <aside
        ref={sheetRef}
        // onMouseDown={(e) => e.preventDefault()}
        className={`fixed right-0 z-10 border-l border-solid ${isHover ? "border shadow-lg" : ""} `}
        style={sheetStyle}
      >
        {canResize && (
          <div
            onMouseDown={startResizing}
            onClick={hideSheet}
            className={cn([
              "absolute bottom-0 top-0 w-2 cursor-col-resize resize-x hover:border-l-2 hover:border-foreground",
              isResizing ? "border-l-2 border-foreground" : "",
            ])}
            style={{ left: -2 }}
          />
        )}
        <nav
          className="relative flex h-full min-h-screen shrink-0 grow flex-col bg-background"
          style={{ minHeight: isHidden ? "calc(100vh - 56px)" : "100vh" }}
        >
          {children}
        </nav>
      </aside>
      {!isHidden && (
        // show a button to hide the sheet
        <div
          className={`fixed right-3 top-0 z-10 ml-3 flex min-h-[56px] w-6 cursor-pointer flex-row items-center justify-center`}
          onClick={hideSheet}
        >
          <IconSheetFixed className="text-muted-foreground hover:text-foreground" />
        </div>
      )}
      {isHidden && canResize && (
        <div
          className={`fixed right-3 top-0 z-10 ml-3 flex min-h-[56px] w-6 cursor-pointer flex-row items-center justify-center`}
          onMouseEnter={() => showsheetOverlay()}
          onClick={() => showSheet()}
        >
          <IconSheet className="text-muted-foreground hover:text-foreground" />
        </div>
      )}
      {isHidden && !canResize && !isHover && (
        <div
          className={`fixed right-3 top-0 z-10 ml-3 flex min-h-[56px] w-6 cursor-pointer flex-row items-center justify-center`}
          onClick={() => showsheetOverlay()}
        >
          <IconSheet className="text-muted-foreground hover:text-foreground" />
        </div>
      )}
      {isHidden && !canResize && isHover && (
        <div
          className={`fixed right-3 top-0 z-10 ml-3 flex min-h-[56px] w-6 cursor-pointer flex-row items-center justify-center`}
          onClick={() => hideSheetOverlay()}
        >
          <IconSheetFixed className="text-muted-foreground hover:text-foreground" />
        </div>
      )}
      {/* {isHidden && (
        // overlay on the corner to bring up the sheet by just hovering to the side
        <div
          className={`fixed top-0 bottom-0 right-0 h-full flex flex-col justify-center`}
          style={{ width: isHover ? defaultWidth : 24, zIndex: 8 }}
          onMouseEnter={showsheetOverlay}
        />
      )} */}
    </div>
  );
};

export default Sheet;
