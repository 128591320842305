import { graphql } from "../../generated/gql";

export const getProgressSnapshotQueryDocument = graphql(/* GraphQL */ `
  query GetProgressSnapshot($input: GetProgressSnapshotInput!) {
    progressSnapshot(getProgressSnapshotInput: $input) {
      id
      objectiveId
      keyResultId
      userId
      value
      health
      description
      snapshotDate
      properties
    }
  }
`);

export const getProgressSnapshotsQueryDocument = graphql(/* GraphQL */ `
  query GetProgressSnapshots($input: GetProgressSnapshotsInput!) {
    progressSnapshots(getProgressSnapshotsInput: $input) {
      id
      objectiveId
      keyResultId
      userId
      value
      health
      description
      snapshotDate
      properties
    }
  }
`);

export const getProgressSnapshotsForKeyResultQueryDocument = graphql(/* GraphQL */ `
  query GetProgressSnapshotsForKeyResults($input: GetProgressSnapshotsForKeyResultInput!) {
    progressSnapshotsForKeyResult(getProgressSnapshotsForKeyResultInput: $input) {
      id
      objectiveId
      keyResultId
      userId
      value
      health
      description
      snapshotDate
      properties
    }
  }
`);
