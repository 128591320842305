import { FC, ReactElement } from "react";

import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";
import { FilterDisplayView } from ".";

export interface FilterCommandConfiguration {
  id: string;
  displayView: ReactElement;
  configurationView?: ReactElement | null;
  configurationViewType: "none" | "popover" | "modal";
}

export interface FilterCommandViewProps {
  filterList: FilterCommandConfiguration[];
  onSelect: (filter: FilterCommandConfiguration) => void;
}

export const FilterCommandView: FC<FilterCommandViewProps> = ({ filterList, onSelect }) => {
  return (
    <Command>
      <CommandInput placeholder={"Filter..."} />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          {filterList.map((filter) => (
            <CommandItem key={filter.id} value={filter.id} onSelect={() => onSelect(filter)} className="p-0">
              <FilterDisplayView>{filter.displayView}</FilterDisplayView>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
