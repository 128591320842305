import { FC } from "react";

import { PriorityViewOptional } from "@/components/_domain/priority";

import IconCheckmark from "@/components/_icons/IconCheckmark";

export interface PrioritySelectDropdownItemProps {
  priority?: {
    id: string;
  } | null;
  selected?: boolean;
}

export const PrioritySelectDropdownItem: FC<PrioritySelectDropdownItemProps> = ({ priority, selected }) => {
  return (
    <div className="m-1 flex w-full cursor-pointer flex-row items-center gap-2 p-1 text-xs">
      <div className="grow">
        <PriorityViewOptional priority={priority} />
      </div>
      <div className="mr-2 w-3">
        {selected && (
          <IconCheckmark className="mr-2 h-3 w-3 shrink-0 self-end justify-self-center text-foreground/60" />
        )}
      </div>
    </div>
  );
};
