import { FC, PropsWithChildren } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { cn } from "@/lib/utils";

import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/_ui/form";

import LoadingDots from "@/components/loading-dots";
import { useToast } from "@/components/_ui/use-toast";

import { useMutation } from "@apollo/client";
import { updateUserProfileDocument } from "@/graphql/common";

const formSchema = z.object({
  fullname: z.string().min(3, {
    message: "Der Name muss mindestenz drei Zeichen lang sein.",
  }),
  username: z.string().min(3, {
    message: "Der Benutzername muss mindestenz drei Zeichen lang sein.",
  }),
});

export interface ProfileFormPropsWithChildren extends PropsWithChildren {
  id: string;
  fullname: string | undefined;
  username: string | undefined;
  className?: string;
}
export const ProfileForm: FC<ProfileFormPropsWithChildren> = ({ className, id, fullname, username }) => {
  const { toast } = useToast();
  const [updateProfile, { loading }] = useMutation(updateUserProfileDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullname: fullname,
      username: username,
    },
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    updateProfile({
      variables: {
        input: {
          id: id,
          fullname: values.fullname,
          username: values.username,
        },
      },
    });
  }

  async function onUpdateCompleted() {
    toast({
      title: "Gespeichert",
      description: "Dein Profil wurde erfolgreich aktualisert.",
      variant: "success",
    });
  }

  async function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className={cn("space-y-6", className)}>
        <FormField
          control={form.control}
          name="fullname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormDescription>Ihr vollständiger Name.</FormDescription>
              <FormControl>
                <Input placeholder="Angela Merkel" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Benutzername</FormLabel>
              <FormDescription>Ihr Vorname oder Rufname. Wie immer Sie hier genannt werden möchten.</FormDescription>
              <FormControl>
                <Input placeholder="Angela" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          type="submit"
          disabled={loading}
          className={`${
            loading ? "cursor-not-allowed bg-muted" : "bg-primary text-primary-foreground hover:bg-primary/90"
          } flex h-10 w-32 items-center justify-center rounded-md border text-sm transition-all focus:outline-none`}
        >
          {loading ? <LoadingDots color="#808080" /> : <p>Speichern</p>}
        </Button>
      </form>
    </Form>
  );
};

export default ProfileForm;
