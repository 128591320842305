import { graphql } from "../../generated/gql";

// only for SuperUser
export const superuser_deleteWorkspaceMembershipMutationDocument = graphql(/* GraphQL */ `
  mutation superuser_deleteWorkspaceMembership($input: WorkspaceMembershipDeleteInput!) {
    superuser_deleteWorkspaceMembership(workspaceMembershipDeleteInput: $input) {
      userId
      workspaceId
      role
      status
      updatedAt
      createdAt
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

// only for SuperUser
export const superuser_createWorkspaceMembershipMutationDocument = graphql(/* GraphQL */ `
  mutation superuser_createWorkspaceMembership($input: WorkspaceMembershipCreateInput!) {
    superuser_createWorkspaceMembership(workspaceMembershipCreateInput: $input) {
      userId
      workspaceId
      role
      status
      updatedAt
      createdAt
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);
