import Page from "./page.tsx";

const routeConfig = {
  // it renders this element
  element: <Page />,

  // with this data loaded before rendering
  // loader: async () => {
  //   return fetch(
  //     `/fake/api/teams/${params.teamId}.json`,
  //     { signal: request.signal }
  //   );
  // },

  // // performing this mutation when data is submitted to it
  // action: async ({ request }) => {
  //   return updateFakeTeam(await request.formData());
  // },

  // // and renders this element in case something went wrong
  // errorElement: <ErrorBoundary />,
};

export default routeConfig;
