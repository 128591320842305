import { graphql } from "../../generated/gql";

export const createStatusInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation createStatusInstance($input: CreateStatusInstanceInput!) {
    createStatusInstance(createStatusInstanceInput: $input) {
      id
      teamId
      statusId
      ownerId
      ownerType
      status {
        id
        teamId
        name
        color
        type
      }
    }
  }
`);

export const updateStatusInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation updateStatusInstance($input: UpdateStatusInstanceInput!) {
    updateStatusInstance(updateStatusInstanceInput: $input) {
      id
      teamId
      statusId
      ownerId
      ownerType
      status {
        id
        teamId
        name
        color
        type
      }
    }
  }
`);

export const deleteStatusInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteStatusInstance($input: DeleteStatusInstanceInput!) {
    deleteStatusInstance(deleteStatusInstanceInput: $input) {
      id
      teamId
      statusId
      ownerId
      ownerType
      status {
        id
        teamId
        name
        color
        type
      }
    }
  }
`);
