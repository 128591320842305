import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { apolloClient } from "@/services/apollo.service/apollo.service";
import { currentUserQueryDocument } from "@/graphql/common";

import { getWorkspaceSettings, setWorkspaceSettings, isWorkspaceSettingType } from "@/services/workspace.service";
import WorkspaceSelect from "./WorkspaceSelect";

export const WorkspaceInitialization = () => {
  const navigate = useNavigate();
  // const [error, SetError] = useState<string | null>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    selectInitialWorkspace();
  }, []);

  async function selectInitialWorkspace() {
    const localWorkspaceSettings = getWorkspaceSettings();

    if (!localWorkspaceSettings) {
      const workspaceMembershipList = await getActiveWorkspacesFromApi();

      if (
        workspaceMembershipList instanceof Array &&
        workspaceMembershipList.length == 1 &&
        workspaceMembershipList[0].workspace &&
        isWorkspaceSettingType(workspaceMembershipList[0].workspace)
      ) {
        setWorkspaceSettings(workspaceMembershipList[0].workspace);
        navigate(`/${workspaceMembershipList[0].workspace.workspaceUrl}`, {
          replace: true,
        });
      }
      setInitialized(true);
    } else {
      setInitialized(true);
      navigate(`/${localWorkspaceSettings.workspaceUrl}`, {
        replace: true,
      });
    }
  }

  async function getActiveWorkspacesFromApi() {
    const { data } = await apolloClient.query({
      query: currentUserQueryDocument,
    });
    if (!data.currentUser.workspaceMembershipList) {
      return [];
    }

    const workspaceMembershipList = data.currentUser.workspaceMembershipList;
    const activeWorkspaceMembershipList = workspaceMembershipList.filter((e) => e.status == "ACTIVE");

    return activeWorkspaceMembershipList;
  }

  if (!initialized) {
    return <main className="align-center relative flex h-screen select-none justify-center overflow-auto"></main>;
  }

  return <WorkspaceSelect />;
};

export default WorkspaceInitialization;
