import { graphql } from "../../generated/gql";

export const getLabelListForWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query GetLabelListForWorkspace($input: GetLabelsForWorkspaceInput!) {
    labelsForWorkspace(getLabelsForWorkspaceInput: $input) {
      id
      title
      color
      labelGroupId
      workspaceId
      teamId
      instances {
        id
      }
    }
  }
`);

export const getLabelListForTeamQueryDocument = graphql(/* GraphQL */ `
  query GetLabelListForTeam($input: GetLabelsForTeamInput!) {
    labelsForTeam(getLabelsForTeamInput: $input) {
      id
      title
      color
      labelGroupId
      workspaceId
      teamId
      instances {
        id
      }
    }
  }
`);

export const getLabelQueryDocument = graphql(/* GraphQL */ `
  query GetLabel($input: GetLabelInput!) {
    label(getLabelInput: $input) {
      id
      title
      color
      labelGroupId
      workspaceId
      teamId
      instances {
        id
      }
    }
  }
`);
