import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getPriorityInstanceQueryDocument } from "@/graphql/common";

export interface UseGetPriorityInstanceProps {
  id?: string;
}

export const useGetPriorityInstance = ({ id }: UseGetPriorityInstanceProps) => {
  const queryResult = useQuery(getPriorityInstanceQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id,
  });

  const priorityInstance = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.priorityInstance;
  }, [queryResult.data]);

  return {
    queryResult,
    priorityInstance,
  };
};
