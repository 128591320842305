import IconCircleDashed from "@/components/_icons/IconCircleDashed";
import { FC } from "react";

export interface FilterByStatusDisplayViewProps {}

export const FilterByStatusDisplayViewProps: FC<FilterByStatusDisplayViewProps> = () => {
  return (
    <>
      <IconCircleDashed className="h-4 w-4 text-foreground/60" />
      <span className="text-xs">Status</span>
    </>
  );
};
