import { WorkspaceCreateDialog } from "@/components/_domain/workspace/workspace-create-dialog";
import { WorkspaceDatatable, columns } from "@/components/_domain/workspace/workspace-datatable";
import { WorkspaceDuplicateDialog } from "@/components/_domain/workspace/workspace-duplicate-dialog";
import { Header } from "@/components/header";

import { useGetWorkspaceList } from "@/hooks/api/superuser/useWorkspace";

import { useLayout } from "@/hooks/useLayout";
import { useCallback } from "react";
import { Link } from "react-router-dom";

export default function Page() {
  const { sidebarState } = useLayout();

  const { workspaceList } = useGetWorkspaceList();

  const makeAdminUrl = useCallback((path: string) => {
    return `/admin/${path}`;
  }, []);

  if (!workspaceList) {
    return <></>;
  }

  return (
    <div className="flex h-screen max-h-screen flex-col overflow-y-hidden">
      <Header className={sidebarState?.isFixed ? "pl-6" : "pl-12"}>
        <div className="flex flex-row items-center gap-2">
          <Link to={makeAdminUrl("")}>
            <div className="my-0.5 flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-sm ">
              <span className="rounded p-1">⚠️</span>
              <span className="pointer-events-none relative flex h-8 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
                Admin Panel
              </span>
            </div>
          </Link>
          <div>›</div>
          <Link to={makeAdminUrl("")}>
            <div className="flex flex-row items-center overflow-hidden truncate text-ellipsis whitespace-nowrap">
              Workspace Management
            </div>
          </Link>
        </div>
        <div className="flex flex-row items-center gap-2">
          <WorkspaceDuplicateDialog />
          <WorkspaceCreateDialog />
        </div>
      </Header>

      <div className="px-0">
        <WorkspaceDatatable columns={columns} data={workspaceList} />
      </div>
    </div>
  );
}
