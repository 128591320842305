import { FC } from "react";

import { cn } from "@/lib/utils";

import IconContent from "@/components/_icons/IconContent";

export interface FilterByTitleDisplayViewProps {
  className?: string;
}

export const FilterByTitleDisplayView: FC<FilterByTitleDisplayViewProps> = ({ className }) => {
  return (
    <>
      <IconContent className={cn(["h-4 w-4 text-foreground/60", className])} />
      <span className="text-xs">Titel</span>
    </>
  );
};
