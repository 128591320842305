import { graphql } from "../../generated/gql";

export const createLabelGroupMutationDocument = graphql(/* GraphQL */ `
  mutation createLabelGroup($input: CreateLabelGroupInput!) {
    createLabelGroup(createLabelGroupInput: $input) {
      id
      title
      color
      workspaceId
      teamId
      labels {
        id
        title
        color
        labelGroupId
        workspaceId
        teamId
      }
    }
  }
`);

export const updateLabelGroupMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateLabelGroup($input: UpdateLabelGroupInput!) {
    updateLabelGroup(updateLabelGroupInput: $input) {
      id
      title
      color
      workspaceId
      teamId
      labels {
        id
        title
        color
        labelGroupId
        workspaceId
        teamId
      }
    }
  }
`);

export const deleteLabelGroupMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteLabelGroup($input: DeleteLabelGroupInput!) {
    deleteLabelGroup(deleteLabelGroupInput: $input) {
      id
      title
      color
      workspaceId
      teamId
      labels {
        id
        title
        color
        labelGroupId
        workspaceId
        teamId
      }
    }
  }
`);
