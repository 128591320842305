import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getUnitListQueryDocument } from "@/graphql/common";

export interface UseGetUnitListProps {
  teamId: string;
}

export const useGetUnitList = ({ teamId }: UseGetUnitListProps) => {
  const queryResult = useQuery(getUnitListQueryDocument, {
    variables: { input: { teamId } },
  });

  const unitList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    return queryResult.data.units;
  }, [queryResult.data]);

  return {
    queryResult,
    unitList,
  };
};
