import { FC } from "react";

import { useGetProgressSnapshot } from "@/hooks/api/common";
import { UserHover } from "@/components/_domain/user";
import { ProgressSnapshotChangedPropertiesView, ProgressSnapshotDescriptionView } from "./progress-snapshot-views";
import { cn } from "@/lib/utils";
import { ProgressSnapshotEditDropdown } from "../../../progress-snapshot/progress-snapshot-edit-dropdown";

interface ProgressSnapshotEventListItemProps {
  workspace: {
    id: string;
  };
  progressSnapshot: {
    id: string;
  };
}

export const ProgressSnapshotEventListItem: FC<ProgressSnapshotEventListItemProps> = ({ workspace, ...props }) => {
  const { progressSnapshot } = useGetProgressSnapshot({
    id: props.progressSnapshot.id,
  });

  if (!progressSnapshot) {
    return <></>;
  }

  return (
    <div className="flex flex-row items-center">
      <div className="z-[1] mr-4 flex w-6 shrink-0 flex-col items-center self-start bg-background py-1	">
        {/* User */}
        <UserHover user={{ id: progressSnapshot.userId }} workspace={workspace} type="avatar" />
      </div>
      <div
        className={cn([
          "flex w-full shrink grow basis-auto flex-col",
          progressSnapshot.description ? "shadow-md -ml-2 rounded-md border border-muted bg-background p-4 pt-2" : "",
        ])}
      >
        {progressSnapshot.description && (
          <>
            {/* <div className="text-sm font-medium text-muted-foreground">Fortschrittsbericht</div> */}
            <ProgressSnapshotDescriptionView workspace={workspace} progressSnapshot={progressSnapshot} />
            <ProgressSnapshotChangedPropertiesView workspace={workspace} progressSnapshot={progressSnapshot} />
          </>
        )}

        {!progressSnapshot.description && (
          <ProgressSnapshotChangedPropertiesView workspace={workspace} progressSnapshot={progressSnapshot} />
        )}
      </div>
      <div className="items-top flex h-full flex-row self-start pl-2">
        <ProgressSnapshotEditDropdown progressSnapshot={progressSnapshot} />
      </div>
    </div>
  );
};
