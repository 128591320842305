import { FC, PropsWithChildren, useEffect, useState } from "react";

import { InputImage } from "./input-image";

export interface UploadUrl {
  permaUrl: string;
  signedUrl: string;
}

export interface InputImageUploadProps extends PropsWithChildren {
  className?: string;
  readOnly?: boolean;
  getSignedUploadUrl: () => Promise<UploadUrl | null>;
  onSuccess: (file: UploadUrl) => void;
  onError: (error: Error) => void;
}

export const InputImageUpload: FC<InputImageUploadProps> = ({
  className,
  children,
  getSignedUploadUrl,
  onSuccess,
  onError,
  ...props
}) => {
  const [readOnly] = useState(!!props.readOnly);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const uploadFile = (file: File, url: string) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url, true);
      xhr.onload = () => {
        const status = xhr.status;
        if (status === 200) {
          resolve(true);
        } else {
          reject(new Error("Upload failed."));
        }
      };
      xhr.onerror = () => {
        reject(new Error("Upload failed."));
      };
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.setRequestHeader("Cache-Control", "public, max-age=604800, immutable");
      xhr.send(file);
    });
  };

  const handleImageInputChange = (file: File) => {
    setFile(file);
  };

  const startFileUpload = async () => {
    if (!file) {
      return;
    }
    if (loading) {
      return;
    }

    setLoading(true);

    // 1. Get Upload Url
    const uploadUrl = await getSignedUploadUrl();

    if (!uploadUrl) {
      onError(new Error("Could not fetch a valid upload URL from server"));
      setLoading(false);
      return;
    }

    // 3. Upload Image
    const success = await uploadFile(file, uploadUrl.signedUrl);

    if (!success) {
      onError(new Error("Could not upload file to storage"));
      setLoading(false);
      return;
    }

    onSuccess(uploadUrl);
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      // setTimeout bc of weird Safari Mobile bug
      if (!file) {
        return;
      }

      startFileUpload();
    }, 100);
  }, [file]);

  return (
    <InputImage className={className} readOnly={readOnly} onChange={handleImageInputChange}>
      {children}
    </InputImage>
  );
};
