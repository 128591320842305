import { FC } from "react";

import { Button } from "@/components/_ui/button";
import IconPlus from "@/components/_icons/IconPlus";

import { LabelSelectDropdown } from "@/components/_domain/labels";

import { useGetLabelGroupListForTeamsInWorkspace, useGetLabelInstanceList } from "@/hooks/api/common";

import { ObjectiveLabelInstanceView } from "./objective-label-instance-view";
import { ObjectiveLabelGroupInstanceView } from "./objective-label-group-instance-view";

interface ObjectiveLabelViewProps {
  objective: {
    id: string;
  };
  workspace: {
    id: string;
  };
  team: {
    id: string;
  };
  user: {
    id: string;
  };
}

export const ObjectiveLabelView: FC<ObjectiveLabelViewProps> = ({ objective, workspace, team, user }) => {
  const { labelInstanceList } = useGetLabelInstanceList({ ownerId: objective.id });
  const { labelGroupList } = useGetLabelGroupListForTeamsInWorkspace({
    workspaceId: workspace.id,
    userId: user.id,
    teamList: [{ id: team.id }],
  });

  if (!labelInstanceList) {
    return <></>;
  }

  return (
    <>
      <div className="mb-5 flex flex-row items-start gap-2">
        <div className="mt-2 w-24 shrink-0 font-medium">Kategorie</div>
        <div className="grow">
          <LabelSelectDropdown
            owner={{ id: objective.id, type: "OBJECTIVE" }}
            workspace={workspace}
            team={team}
            user={user}
            asChild
          >
            <Button variant="ghost" size="sm" className="font-light">
              <IconPlus className="mr-2 h-2 w-2" />
              Kategorie hinzufügen
            </Button>
          </LabelSelectDropdown>
        </div>
      </div>

      {labelInstanceList.length > 0 && (
        <>
          <ObjectiveLabelInstanceView
            workspace={workspace}
            team={team}
            user={user}
            owner={{ id: objective.id, type: "OBJECTIVE" }}
          />
          {labelGroupList.map((labelGroup) => (
            <ObjectiveLabelGroupInstanceView
              key={labelGroup.id}
              workspace={workspace}
              team={team}
              user={user}
              owner={{ id: objective.id, type: "OBJECTIVE" }}
              labelGroup={labelGroup}
            />
          ))}
        </>
      )}
    </>
  );
};

export default ObjectiveLabelView;
