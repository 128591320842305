import { useMemo, useState } from "react";

import { Row } from "@tanstack/react-table";
import { Button } from "@/components/_ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { Dialog, DialogContent } from "@/components/_ui/dialog";

import { MoreHorizontal } from "lucide-react";

import { teamSchema } from "./model";
import { TeamEditForm } from "../team-edit-form";
import { TeamDeleteFormWithEmail } from "../team-delete-form";
import { TeamMemberList } from "../team-member-list";

interface DatatableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DatatableRowActions<TData>({ row }: DatatableRowActionsProps<TData>) {
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [membershipDialogIsOpen, setMembershipDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

  const team = useMemo(() => {
    return teamSchema.parse(row.original);
  }, [row]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="flex w-full flex-row items-center justify-end">
            <Button variant="ghost" size="icon" className="h-8 w-8 self-end p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem className="cursor-pointer" onClick={() => setEditDialogIsOpen(true)}>
            Bearbeiten
          </DropdownMenuItem>
          <DropdownMenuItem className="cursor-pointer" onClick={() => setMembershipDialogIsOpen(true)}>
            Mitglieder verwalten
          </DropdownMenuItem>

          <DropdownMenuItem className="cursor-pointer" onClick={() => setDeleteDialogIsOpen(true)}>
            Löschen
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={editDialogIsOpen} onOpenChange={setEditDialogIsOpen}>
        <DialogContent className="top-[1%] max-w-xl translate-y-[0%] md:top-[20%]">
          <TeamEditForm team={team} />
        </DialogContent>
      </Dialog>

      <Dialog open={membershipDialogIsOpen} onOpenChange={setMembershipDialogIsOpen}>
        <DialogContent className="top-[1%] max-w-3xl translate-y-[0%] md:top-[10%] ">
          <div className="w-full overflow-y-scroll p-8" style={{ maxHeight: "calc(100vh - 15vh)", minHeight: "20rem" }}>
            <TeamMemberList team={team} />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteDialogIsOpen} onOpenChange={setDeleteDialogIsOpen}>
        <DialogContent className="top-[1%] max-w-xl translate-y-[0%] md:top-[20%]">
          <TeamDeleteFormWithEmail
            team={team}
            onSuccess={() => {
              setDeleteDialogIsOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
