import { graphql } from "../../generated/gql";

export const getStatusListForTeamQueryDocument = graphql(/* GraphQL */ `
  query GetStatusListForTeam($input: GetStatusListForTeamInput!) {
    statusListForTeam(getStatusListForTeamInput: $input) {
      id
      teamId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);

export const getStatusQueryDocument = graphql(/* GraphQL */ `
  query GetStatus($input: GetStatusInput!) {
    status(getStatusInput: $input) {
      id
      teamId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);
