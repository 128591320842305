import { FC } from "react";

import { useGetObjective } from "@/hooks/api/common";
import { useObjectiveUIState } from "../../../hooks/useObjectiveUIState";

import { ObjectiveChildList } from "./objective-child-list";
import { ObjectiveCreateChildForm } from "./objective-create-child-form";

interface ObjectiveChildTableProps {
  objective: {
    id: string;
  };
}

export const ObjectiveChildTable: FC<ObjectiveChildTableProps> = ({ ...props }) => {
  const { objective } = useGetObjective({ id: props.objective.id });
  const { showCreateChildObjectiveView, setShowCreateChildObjectiveView } = useObjectiveUIState();

  function hideCreateChildObjective() {
    setShowCreateChildObjectiveView(false);
  }

  if (!objective) {
    return <></>;
  }

  return (
    <>
      <ObjectiveChildList objective={objective} />

      {showCreateChildObjectiveView && (
        <ObjectiveCreateChildForm
          team={{ id: objective.teamId }}
          parentObjective={{ id: objective.id }}
          callbacks={{ hideCreateChildObjective }}
        />
      )}
    </>
  );
};
