import { FC } from "react";
import { Button } from "@/components/_ui/button";

import { ObjectiveFilterSelect } from "../../objective-list-filter-pane-view/objective-filter-select-dropdown";
import { useObjectiveListState } from "../../../hooks";

import IconFilter from "@/components/_icons/IconFilter";
import { Cross2Icon } from "@radix-ui/react-icons";

export interface ObjectiveListFilterButtonProps {}

export const ObjectiveListFilterButton: FC<ObjectiveListFilterButtonProps> = () => {
  const { columnFilters, table } = useObjectiveListState();

  return (
    <ObjectiveFilterSelect side="bottom" asChild={true}>
      <Button
        variant="ghost"
        size="xs"
        className="h-6 w-full justify-start overflow-x-hidden border text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
        onClick={(e) => {
          if (columnFilters.length > 0) {
            e.preventDefault();
            e.stopPropagation();
            table.setColumnFilters([]);
          }
        }}
      >
        {columnFilters.length == 0 && (
          <>
            <IconFilter className="mr-2 h-3.5 w-3.5 text-foreground/60" />
            Filter
          </>
        )}
        {columnFilters.length > 0 && (
          <>
            Filter entfernen
            <Cross2Icon className="ml-2 h-3.5 w-3.5 text-foreground/60" />
          </>
        )}
      </Button>
    </ObjectiveFilterSelect>
  );
};
