import { FC, useEffect, useMemo, useState } from "react";

import { FormControl } from "@/components/_ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger } from "@/components/_ui/select";

import { Unit } from "../models/key-result.schema";
import { useGetUnitList } from "@/hooks/api/common";

export interface KeyResultUnitSelectProps {
  onValueChange?: (value: string | undefined) => void;
  defaultValue?: string;
  team: {
    id: string;
  };
}

export const KeyResultUnitSelect: FC<KeyResultUnitSelectProps> = ({ onValueChange, defaultValue, team, ...props }) => {
  const { unitList } = useGetUnitList({ teamId: team.id });

  const santitizedDefaultValue: string | undefined = useMemo(() => {
    for (const { id } of unitList) {
      if (id == defaultValue) {
        return id;
      }
    }

    return undefined;
  }, [defaultValue, unitList]);

  const [formValue, setFormValue] = useState(santitizedDefaultValue);
  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);
  const [touched, setTouched] = useState(false);

  const getUnitForId = (id: string): Unit | null => {
    for (const unit of unitList) {
      if (id == unit.id) {
        return unit;
      }
    }

    return null;
  };

  useEffect(() => {
    if (touched) {
      return;
    }
    setFormValue(santitizedDefaultValue);
    if (santitizedDefaultValue) {
      setSelectedUnit(getUnitForId(santitizedDefaultValue));
    }
  }, [santitizedDefaultValue]);

  const handleValueChange = (value: string) => {
    const newValue = value == "NO_UNIT_SELECTED" ? undefined : value;

    setFormValue(newValue);
    if (newValue) {
      setSelectedUnit(getUnitForId(newValue));
    } else {
      setSelectedUnit(null);
    }

    setTouched(true);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  return (
    <Select onValueChange={handleValueChange} {...props}>
      <FormControl>
        <SelectTrigger>
          {formValue && (
            <span>
              {
                <>
                  {selectedUnit && (
                    <>
                      {selectedUnit.title} ({selectedUnit.symbol})
                    </>
                  )}
                  {!selectedUnit && <></>}
                </>
              }
            </span>
          )}
          {!formValue && <span className="text-muted-foreground">Keine Einheit</span>}
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        <>
          <SelectItem key="0" value={"NO_UNIT_SELECTED"}>
            <span className="text-muted-foreground">Keine Einheit</span>
          </SelectItem>
          {unitList.map(({ id, title, symbol }) => {
            return (
              <SelectItem key={id} value={id}>
                {title} ({symbol})
              </SelectItem>
            );
          })}
        </>
      </SelectContent>
    </Select>
  );
};

export default KeyResultUnitSelect;
