import { FC, useMemo, useState } from "react";

import { Button } from "@/components/_ui/button";

import { Priority } from "../model/priority.schema";
import { PriorityType } from "@/components/_domain/priority/priority-type";
import { PriorityTableRow } from "./priority-table-row";
import { PriorityFormCreate } from "./actions/priority-form-create";
import { useCurrentUser, useTeamById } from "@/hooks/api/common";

export interface PriorityTableSectionProps {
  team: {
    id: string;
  };
  priorityType: PriorityType;
  priorityList: Priority[];
  className?: string;
}

export const PriorityTableSection: FC<PriorityTableSectionProps> = ({
  priorityType,
  priorityList,
  className,
  ...props
}) => {
  const [showCreatePriorityForm, setShowCreatePriorityForm] = useState(false);
  const { currentUser } = useCurrentUser();

  const { team, userHasEditRights } = useTeamById({ id: props.team.id });

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    return userHasEditRights(currentUser.id);
  }, [currentUser, userHasEditRights]);

  function handleCreatePriorityButtonPressed() {
    setShowCreatePriorityForm(true);
  }

  function onFormCanceled() {
    setShowCreatePriorityForm(false);
  }

  function onFormSuccess() {
    setShowCreatePriorityForm(false);
  }

  if (!team) {
    return <></>;
  }

  return (
    <div className={className}>
      <div className="mb-2 flex w-full flex-row items-center justify-between">
        <div className="text-muted-foreground">{priorityType}</div>
        <div>
          {hasEditRights && (
            <Button
              size="icon"
              variant="ghost"
              className="text-base text-foreground/80"
              onClick={handleCreatePriorityButtonPressed}
            >
              +
            </Button>
          )}
        </div>
      </div>
      {priorityList.map((s) => (
        <PriorityTableRow key={s.id} priority={s} team={team} hideDeleteAction={priorityList.length < 2} />
      ))}
      {showCreatePriorityForm && (
        <PriorityFormCreate
          team={team}
          priorityType={priorityType}
          onCanceled={onFormCanceled}
          onSuccess={onFormSuccess}
        />
      )}
    </div>
  );
};

export default PriorityTableSection;
