import { useCallback, useMemo } from "react";

import { groupBy } from "@/lib/utils";

import { useGetLabelListForWorkspace, useTeamListForUserInWorkspace } from "../index";
import { Label } from "@/graphql/generated/graphql";

export interface UseGetLabelListForTeamsInWorkspaceProps {
  workspaceId: string;
  userId: string;
  teamList: { id: string }[];
}

export const useGetLabelListForTeamsInWorkspace = ({
  workspaceId,
  userId,
  teamList,
}: UseGetLabelListForTeamsInWorkspaceProps) => {
  const { activeTeamList } = useTeamListForUserInWorkspace({ workspaceId, userId });
  const { labelList: workspaceLabelList } = useGetLabelListForWorkspace({ workspaceId });

  const filteredTeamLabelList = useMemo(() => {
    return activeTeamList.flatMap((activeTeam) => {
      if (!teamList.find((t) => t.id === activeTeam.id)) {
        return [];
      }
      return activeTeam.labelList;
    });
  }, [activeTeamList, teamList]);

  const labelList = useMemo(() => {
    return [...filteredTeamLabelList, ...workspaceLabelList];
  }, [filteredTeamLabelList, workspaceLabelList]);

  const labelListWithoutGroup = useMemo(() => {
    return labelList.filter((l) => !l.labelGroupId);
  }, [labelList]);

  const labelListGroupedByGroupId = useMemo(() => {
    return groupBy(labelList, (label) => label.labelGroupId);
  }, [labelList]);

  const getLabelListWithDistinctTitle = useCallback(
    (labelList: Omit<Label, "instances">[]) => {
      // make sure there is only one label per unqiue name
      // basically this dedupes the list, if there are multiple priorities with the same name across teams

      const labelListGroupedByTitle = groupBy(labelList, (label) => label.title);

      return Array.from(labelListGroupedByTitle.keys()).flatMap((key) => {
        const labelGroupList = labelListGroupedByTitle.get(key);
        if (!labelGroupList) {
          return [];
        }
        if (labelGroupList.length == 0) {
          return [];
        }
        return labelGroupList[0];
      });
    },
    [labelList],
  );

  return {
    labelList,
    labelListWithoutGroup,
    labelListGroupedByGroupId,
    getLabelListWithDistinctTitle,
  };
};
