import { FC, PropsWithChildren, useMemo } from "react";

import WorkspaceLabelListItem from "./workspace-label-list-item";
import { useGetLabelListForWorkspace } from "@/hooks/api/common";

export interface WorkspaceLabelListPropsWithChildren extends PropsWithChildren {
  workspace: {
    id: string;
  };
  className?: string;
}

export const WorkspaceLabelList: FC<WorkspaceLabelListPropsWithChildren> = ({ workspace }) => {
  const { labelList } = useGetLabelListForWorkspace({ workspaceId: workspace.id });

  const sortedLabelList = useMemo(() => {
    return labelList
      .slice()
      .filter((l) => !l.labelGroupId)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.title.localeCompare(b.title);
      });
  }, [labelList]);

  return (
    <>
      {sortedLabelList.map((label) => (
        <WorkspaceLabelListItem key={label.id} label={label} workspace={workspace} />
      ))}
    </>
  );
};

export default WorkspaceLabelList;
