import { Dialog, DialogContent } from "@/components/_ui/dialog";
import { Button } from "@/components/_ui/button";

import IconCompose from "@/components/_icons/IconCompose";

import { useObjectiveCreateHandler } from "../hooks/useCreateObjectiveHandler";

import ObjectiveCreateForm from "./objective-create-form";
import { useTeamById, useWorkspaceGetById } from "@/hooks/api/common";
import { FC } from "react";

export interface ObjectiveCreateDialogProps {
  workspace: {
    id: string;
  };
  team: {
    id: string;
  };
}

export const ObjectiveCreateDialog: FC<ObjectiveCreateDialogProps> = ({ ...props }) => {
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });
  const { team } = useTeamById({ id: props.team.id });
  const { showModal, setShowModal } = useObjectiveCreateHandler();

  function onDialogOpenChange(open: boolean) {
    setShowModal(open);
  }

  if (!workspace) {
    return <></>;
  }

  if (!team) {
    return <></>;
  }

  return (
    <>
      <Button
        variant="ghost"
        size="xs"
        className="shadow-sm h-7 rounded-md border px-2 text-xs text-foreground/75"
        onClick={() => setShowModal(true)}
      >
        <IconCompose className="mr-2" />
        Neues Ziel
      </Button>
      <Dialog open={showModal} onOpenChange={onDialogOpenChange}>
        <DialogContent className="top-[1%] max-w-3xl translate-y-[0%] md:top-[20%]">
          <ObjectiveCreateForm
            workspace={workspace}
            team={team}
            triggerCloseDialog={() => {
              onDialogOpenChange(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
