import { useCallback } from "react";
import { NavLink } from "react-router-dom";

import { useCurrentWorkspace } from "@/hooks/useCurrentWorkspace";

import { Sidebar, SidebarHeaderElement, SidebarMainContent, SidebarNavElement, SidebarTopContent } from "../sidebar";

import { useCurrentUser, useWorkspaceGetById } from "@/hooks/api/common";

import IconInvisible from "../_icons/IconInvisible";
import IconChevronLeft from "../_icons/IconChevronLeft";
import IconUser from "../_icons/IconUser";
import IconBuildingFilled from "../_icons/IconBuildingFilled";
import IconTeams from "../_icons/IconTeams";
import { TeamBrowseAllDialog } from "../_domain/team/team-browse-all-dialog";
import { MagnifyingGlassPlusIcon, PlusIcon } from "@heroicons/react/24/solid";

import { SidebarSettingsAllTeamsNavElement } from "./components/sidebar-settings-all-teams-nav-element";

export interface SettingsSidebarProps {
  workspace: {
    id: string;
  };
}

const SettingsSidebar = ({ ...props }: SettingsSidebarProps) => {
  const { currentUser } = useCurrentUser();
  const { hasEditRights } = useCurrentWorkspace(); // TODO: Refactor this – it introduces inconsistencies
  const { workspace } = useWorkspaceGetById(props.workspace);

  const makeSettingsUrl = useCallback(
    (path: string) => {
      if (!workspace) {
        return "";
      }

      return `/${workspace.workspaceUrl}/settings/${path}`;
    },
    [workspace],
  );

  if (!workspace) {
    return <></>;
  }

  return (
    <Sidebar>
      {/* Top Section of the Sidebar */}
      <SidebarTopContent>
        <NavLink to="/" className="select-none text-xl">
          <span className="relative flex h-8 items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
            <IconChevronLeft className="mr-3 h-4 w-4" />
            Einstellungen
          </span>
        </NavLink>
      </SidebarTopContent>

      {/* Middle Section of the Sidebar */}
      <SidebarMainContent>
        <SidebarHeaderElement to={`/${workspace.workspaceUrl}/settings/account`}>
          <IconUser className="mr-2 h-4 w-4" />
          Account
        </SidebarHeaderElement>
        <SidebarNavElement to={`/${workspace.workspaceUrl}/settings/account/profile`}>
          <IconInvisible className="mr-2 h-3 w-3" />
          Profil
        </SidebarNavElement>
        <SidebarNavElement to={`/${workspace.workspaceUrl}/settings/account/security`}>
          <IconInvisible className="mr-2 h-3 w-3" />
          Sicherheit
        </SidebarNavElement>

        <div className="h-4 shrink-0"></div>

        <SidebarHeaderElement to={`/${workspace.workspaceUrl}/settings/workspace`}>
          <IconBuildingFilled className="mr-2 h-4 w-4" />
          Workspace
        </SidebarHeaderElement>
        <SidebarNavElement to={`/${workspace.workspaceUrl}/settings/workspace/general`}>
          <IconInvisible className="mr-2 h-3 w-3" />
          Allgemein
        </SidebarNavElement>
        {hasEditRights && (
          <SidebarNavElement to={`/${workspace.workspaceUrl}/settings/workspace/members`}>
            <IconInvisible className="mr-2 h-3 w-3" />
            Mitglieder
          </SidebarNavElement>
        )}
        {hasEditRights && (
          <SidebarNavElement to={`/${workspace.workspaceUrl}/settings/workspace/labels`}>
            <IconInvisible className="mr-2 h-3 w-3" />
            Kategorien
          </SidebarNavElement>
        )}

        <div className="h-4 shrink-0"></div>

        <SidebarHeaderElement to={`/${workspace.workspaceUrl}/settings/teams`}>
          <IconTeams className="mr-2 h-4 w-4" />
          Teams
        </SidebarHeaderElement>

        {currentUser && (
          <>
            <SidebarSettingsAllTeamsNavElement workspace={workspace} user={currentUser} />

            <div className="mt-4" />

            <TeamBrowseAllDialog asChild={true} workspace={workspace} user={currentUser} className="w-full">
              <div className="margin-1 hover: relative cursor-pointer rounded">
                <div className="select-none text-sm">
                  <div className="relative flex h-8 items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0 hover:bg-accent hover:text-accent-foreground ">
                    <MagnifyingGlassPlusIcon className="mr-2 h-3.5 w-3.5" />
                    Team beitreten
                  </div>
                </div>
              </div>
            </TeamBrowseAllDialog>

            <div className="mt-1" />

            <SidebarNavElement to={makeSettingsUrl("new-team")}>
              <PlusIcon className="mr-2 h-3.5 w-3.5" />
              Team hinzufügen
            </SidebarNavElement>
          </>
        )}
      </SidebarMainContent>
    </Sidebar>
  );
};

export default SettingsSidebar;
