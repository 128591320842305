import React, { createContext, useState, useContext, ReactNode, FC, useCallback, useMemo } from "react";

import { ProgressSnapshotCreateForm } from "../progress-snapshot/progress-snapshot-create-form/progress-snapshot-create-form.schema";
import { useDate } from "@/hooks/useDate";

interface ProgressUpdateUIStateContextType {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  newProgressSnapshotForm: ProgressSnapshotCreateForm;
  hasActiveChanges: boolean;
  updateNewProgressSnapshot: (progressSnapshotCreateFormFragment: Partial<ProgressSnapshotCreateForm>) => void;
  resetNewPogressSnapshotForm: () => void;
}

const ProgressUpdateUIStateContext = createContext<ProgressUpdateUIStateContextType | undefined>(undefined);

// Define a type for the props that the provider will accept
interface ProgressUpdateUIStateContextProviderProps {
  children: ReactNode;
}

// Create the provider component
export const ProgressUpdateUIStateContextProvider: FC<ProgressUpdateUIStateContextProviderProps> = ({ children }) => {
  const { TODAY } = useDate();
  const defaultValue: ProgressSnapshotCreateForm = useMemo(() => {
    return {
      description: "",
      health: "",
      snapshotDate: TODAY.toISOString(),
      keyResultId: "",
      value: "",
      properties: "",
    };
  }, [TODAY]);
  const [showModal, setShowModal] = useState(false);
  const [newProgressSnapshotForm, setNewProgressSnapshotForm] = useState<ProgressSnapshotCreateForm>(defaultValue);

  const resetNewPogressSnapshotForm = useCallback(() => {
    setNewProgressSnapshotForm(defaultValue);
  }, [defaultValue, newProgressSnapshotForm]);

  const updateNewProgressSnapshot = useCallback(
    (progressSnapshotCreateFormFragment: Partial<ProgressSnapshotCreateForm>) => {
      const newValues = {
        ...newProgressSnapshotForm,
        ...progressSnapshotCreateFormFragment,
      };
      setNewProgressSnapshotForm(newValues);
    },
    [newProgressSnapshotForm],
  );

  const hasActiveChanges = useMemo(() => {
    if (defaultValue.description != newProgressSnapshotForm.description) {
      return true;
    }
    if (defaultValue.health != newProgressSnapshotForm.health) {
      return true;
    }
    if (defaultValue.snapshotDate != newProgressSnapshotForm.snapshotDate) {
      return true;
    }
    if (defaultValue.keyResultId != newProgressSnapshotForm.keyResultId) {
      return true;
    }
    if (defaultValue.value != newProgressSnapshotForm.value) {
      return true;
    }
    if (defaultValue.properties != newProgressSnapshotForm.properties) {
      return true;
    }
    return false;
  }, [defaultValue, newProgressSnapshotForm]);

  return (
    <ProgressUpdateUIStateContext.Provider
      value={{
        showModal,
        setShowModal,
        newProgressSnapshotForm,
        hasActiveChanges,
        resetNewPogressSnapshotForm,
        updateNewProgressSnapshot,
      }}
    >
      {children}
    </ProgressUpdateUIStateContext.Provider>
  );
};

// Define the useProjectUpdateUIStateState hook
export const useProgressUpdateUIStateState = (): ProgressUpdateUIStateContextType => {
  const context = useContext(ProgressUpdateUIStateContext);

  if (!context) {
    throw new Error("useProjectUpdateUIStateState must be used within a ChildProvider");
  }

  return context;
};
