import { graphql } from "../../generated/gql";

export const getPriorityInstanceQueryDocument = graphql(/* GraphQL */ `
  query GetPriorityInstance($input: GetPriorityInstanceInput!) {
    priorityInstance(getPriorityInstanceInput: $input) {
      id
      priorityId
      ownerId
      ownerType
      teamId
    }
  }
`);

export const getPriorityInstancebyOwnerQueryDocument = graphql(/* GraphQL */ `
  query GetPriorityInstanceByOwner($input: GetPriorityInstanceByOwnerInput!) {
    priorityInstanceByOwner(getPriorityInstanceByOwnerInput: $input) {
      id
      priorityId
      ownerId
      ownerType
      teamId
    }
  }
`);
