import { FC, PropsWithChildren } from "react";

import { Button } from "@/components/_ui/button";
import { CurrentWorkspaceType } from "@/hooks/useCurrentWorkspace";

import { WorkspaceDeleteDialogWithEmail } from "./workspace-delete-dialog-email";

export interface WorkspaceDeleteDialogWithEmailButton extends PropsWithChildren {
  workspace: CurrentWorkspaceType;
  className?: string;
  redirectAfterDelete?: boolean;
}

export const WorkspaceDeleteDialogWithEmailButton: FC<WorkspaceDeleteDialogWithEmailButton> = ({
  className,
  workspace,
  redirectAfterDelete,
}) => {
  return (
    <WorkspaceDeleteDialogWithEmail
      className={className}
      workspace={workspace}
      asChild={true}
      redirectAfterDelete={redirectAfterDelete}
    >
      <Button variant="destructive">Workspace löschen</Button>
    </WorkspaceDeleteDialogWithEmail>
  );
};
