import { ApolloCache } from "@apollo/client";

import { superuser_teamListForWorkspaceQueryDocument } from "@/graphql/superuser/queries/team.queries";


import { CreateTeamMutation } from "@/graphql/generated/graphql";
export type Team = CreateTeamMutation["createTeam"];


// Note:
//-----------------------------------------
// - Update writes to the cache seem to be expensive.
// - Thus they should be avoided, if retrieval via type policies is available
//
//-----------------------------------------
export const updateCacheWithNewTeam = (cache: ApolloCache<any>, team: Team) => {
    if (!team.workspaceId) {
      return;
    }
  
    const cachedQueryResult = cache.readQuery({
      query: superuser_teamListForWorkspaceQueryDocument,
      variables: { input: team.workspaceId } 
    });
  
    if (!cachedQueryResult) {
      return;
    }
  
    cache.writeQuery({
      query: superuser_teamListForWorkspaceQueryDocument,
      variables: { input: team.workspaceId },
      data: {
        superuser_teamListForWorkspace: [...cachedQueryResult.superuser_teamListForWorkspace, team]
      },
    });
  };