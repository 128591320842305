import { graphql } from "../../generated/gql";

export const updateWorkspaceMutationDocument = graphql(/* GraphQL */ `
  mutation updateWorkspace($input: WorkspaceUpdateInput!) {
    updateWorkspace(workspaceUpdateInput: $input) {
      id
      name
      workspaceUrl
      logo
      createdAt
      updatedAt
      workspaceMembershipList {
        id
        userId
        workspaceId
        role
        status
        updatedAt
        createdAt
      }
    }
  }
`);

export const deleteWorkspaceMutationDocument = graphql(/* GraphQL */ `
  mutation deleteWorkspace($input: WorkspaceDeleteInput!) {
    deleteWorkspace(workspaceDeleteInput: $input) {
      id
      name
      workspaceUrl
      logo
      createdAt
      updatedAt
    }
  }
`);

export const sendWorkspaceDeleteCodeEmailMutationDocument = graphql(/* GraphQL */ `
  mutation sendWorkspaceDeleteCodeEmail($input: WorkspaceDeleteCodeCreateInput!) {
    sendWorkspaceDeleteCodeEmail(workspaceDeleteCodeCreateInput: $input) {
      success
      email
      message
    }
  }
`);
