import { graphql } from "../../generated/gql";

export const getKeyResultQueryDocument = graphql(/* GraphQL */ `
  query GetKeyResult($input: GetKeyResultInput!) {
    keyResult(getKeyResultInput: $input) {
      id
      objectiveId
      unitId
      title
      description
      initialValue
      targetValue
      currentValue
      startDate
      targetDate
      type
      properties
      unit {
        id
        title
        symbol
      }
    }
  }
`);
