import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getPriorityQueryDocument } from "@/graphql/common";

export interface UseGetPriorityProps {
  id?: string;
}

export const useGetPriority = ({ id }: UseGetPriorityProps) => {
  const queryResult = useQuery(getPriorityQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id,
  });

  const priority = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.priority;
  }, [queryResult.data]);

  return {
    queryResult,
    priority,
  };
};
