import { FC, PropsWithChildren, useState } from "react";

import { Button } from "@/components/_ui/button";

import DeleteConfirmation from "@/components/delete-confirmation";

import { useDeletePriority } from "@/hooks/api/common";
import { toast } from "@/components/_ui/use-toast";

import IconTrash from "@/components/_icons/IconTrash";

export interface PriorityButtonDeleteProps extends PropsWithChildren {
  team: {
    id: string;
  };
  priority: {
    id: string;
    instances: Array<unknown>;
  };
  onSuccess?: () => void;
}

export const PriorityButtonDelete: FC<PriorityButtonDeleteProps> = ({ team, priority, onSuccess }) => {
  const [deleteConfirmationisOpen, setDeleteConfirmationisOpen] = useState(false);
  const { deletePriority } = useDeletePriority({
    teamId: team.id,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  function handleDeleteButtonPressed() {
    if (priority.instances.length > 0) {
      const s = priority.instances.length == 1 ? "ist" : "sind";
      const t = priority.instances.length == 1 ? "Objekt" : "Objekten";
      toast({
        title: "Fehler",
        description: `Der Priorität ${s} ${priority.instances.length} ${t} zugewiesen. Bitten entfernen Sie die Priorität von den zugewiesenen Objekten bevor Sie sie löschen. `,
        variant: "error",
      });
      return;
    }

    setDeleteConfirmationisOpen(true);
  }

  async function handleDeletePriorityConfirmed() {
    await deletePriority({ variables: { input: { id: priority.id } } });
  }

  function onUpdateCompleted() {
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <>
      <Button
        variant="ghost"
        size="icon"
        className="text-muted-foreground/80 hover:text-foreground/80"
        onClick={handleDeleteButtonPressed}
      >
        <IconTrash className="h-2.5 w-2.5" />
      </Button>
      <DeleteConfirmation
        open={deleteConfirmationisOpen}
        onOpenChange={setDeleteConfirmationisOpen}
        onConfirm={handleDeletePriorityConfirmed}
        title={`Sind Sie sich sicher, dass Sie diese Priorität löschen möchten?`}
        description={`Diese Aktion kann nicht rückgängig gemacht werden. Die Priorität wird unwideruflich gelöscht.`}
      />
    </>
  );
};

export default PriorityButtonDelete;
