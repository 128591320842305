import { useMutation } from "@apollo/client";
import { updateStatusMutationDocument, getStatusQueryDocument } from "@/graphql/common";

export interface UseUpdateStatusProps {
  statusId: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useUpdateStatus = ({ statusId, onCompleted, onError }: UseUpdateStatusProps) => {
  const [updateStatus] = useMutation(updateStatusMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: getStatusQueryDocument,
        variables: { input: { id: statusId } },
      },
    ],
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }
  return {
    updateStatus,
  };
};
