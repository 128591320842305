import { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";

import { cn } from "@/lib/utils";

import { CurrentWorkspaceType } from "@/hooks/useCurrentWorkspace";

import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { toast } from "@/components/_ui/use-toast";
import { ColorPicker } from "@/components/color-picker";

import IconCircleSolid from "@/components/_icons/IconCircleSolid";

import { useMutation } from "@apollo/client";
import {
  getLabelListForWorkspaceQueryDocument,
  getLabelGroupQueryDocument,
  createLabelMutationDocument,
} from "@/graphql/common";

export interface WorkspaceLabelCreateForm extends PropsWithChildren {
  workspace: CurrentWorkspaceType;
  className?: string;
  labelGroupId?: string;
  defaultLabelColor?: string;
  onSuccess?: () => void;
  onCanceled?: () => void;
}

export const WorkspaceLabelCreateForm: FC<WorkspaceLabelCreateForm> = ({
  workspace,
  labelGroupId,
  onSuccess,
  onCanceled,
  className,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [newLabelName, setNewLabelName] = useState("");
  const [newLabelColor, setNewLabelColor] = useState(props.defaultLabelColor ?? "#95999F");

  const refectQueries = useMemo(() => {
    if (labelGroupId) {
      return [
        {
          query: getLabelListForWorkspaceQueryDocument,
          variables: { input: { workspaceId: workspace.id } },
        },
        {
          query: getLabelGroupQueryDocument,
          variables: { input: { id: labelGroupId } },
        },
      ];
    }

    return [
      {
        query: getLabelListForWorkspaceQueryDocument,
        variables: { input: { workspaceId: workspace.id } },
      },
    ];
  }, [labelGroupId]);

  const [createLabel] = useMutation(createLabelMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: refectQueries,
  });

  async function handleCreateNewLabel() {
    if (newLabelName == "" || newLabelColor == "") {
      toast({
        title: "Fehler",
        description: "Bitte wählen Sie einen Titel und eine Farbe aus.",
        variant: "error",
      });
      return;
    }

    await createLabel({
      variables: {
        input: {
          title: newLabelName,
          color: newLabelColor,
          workspaceId: workspace.id,
          labelGroupId: labelGroupId,
        },
      },
    });
  }

  function reset() {
    // do not reset the color; the most likely case is that users want to create several objects of the same color
    // setNewLabelColor("#95999F")
    setNewLabelName("");
    ref.current?.focus();
  }

  function onUpdateCompleted() {
    reset();
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div
      className={cn(["my-1 flex flex-row items-center justify-between gap-2 rounded border bg-muted p-2", className])}
    >
      <div className="w-9">
        <ColorPicker
          asChild
          value={newLabelColor}
          onSelect={(color) => {
            setNewLabelColor(color);
          }}
        >
          <Button variant="outline" size="sm">
            <IconCircleSolid className="h-3 w-3" style={{ color: newLabelColor }} />
          </Button>
        </ColorPicker>
      </div>
      <div className="grow">
        <Input
          ref={ref}
          autoFocus
          value={newLabelName}
          onChange={(e) => setNewLabelName(e.currentTarget.value)}
          placeholder="Name der Kategorie"
          className="bg-background ring-0 hover:ring-0 focus:ring-0 active:ring-0"
          style={{
            boxShadow: "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          }}
        />
      </div>
      <div>
        <Button variant="outline" size="sm" className="cursor-pointer" onClick={onCanceled}>
          Abbrechen
        </Button>
      </div>
      <div>
        <Button
          variant="default"
          size="sm"
          className="cursor-pointer"
          disabled={newLabelName == ""}
          onClick={handleCreateNewLabel}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};

export default WorkspaceLabelCreateForm;
