import {
  useGetLabelGroupListForWorkspace,
  useGetLabelListForWorkspace,
  useGetObjectiveListForTeams,
  useTeamListForUserInWorkspace,
  useWorkspaceMembershipList,
} from "@/hooks/api/common";
import { useMemo } from "react";

interface UsePreLoaderProps {
  workspace: {
    id: string;
  };
  user: {
    id: string;
  };
}

export const usePreLoader = ({ workspace, user }: UsePreLoaderProps) => {
  // load data for all teams
  const teamQueryResults = useTeamListForUserInWorkspace({ workspaceId: workspace.id, userId: user.id, shouldUpdateCache: true });

  const userQueryResults = useWorkspaceMembershipList({ workspaceId: workspace.id });
  const labelListForWorkspaceQueryResults = useGetLabelListForWorkspace({ workspaceId: workspace.id });
  const labelGroupListForWorkspaceQueryResults = useGetLabelGroupListForWorkspace({ workspaceId: workspace.id });
  const objectiveListeForTeamsQueryResults = useGetObjectiveListForTeams({ teamList: teamQueryResults.activeTeamList, shouldUpdateCache: true });

  const loading = useMemo(() => {
    return (
      teamQueryResults.queryResult.loading ||
      userQueryResults.queryResult.loading ||
      labelListForWorkspaceQueryResults.queryResult.loading ||
      labelGroupListForWorkspaceQueryResults.queryResult.loading ||
      objectiveListeForTeamsQueryResults.queryResult.loading
    );
  }, [
    teamQueryResults,
    userQueryResults,
    labelListForWorkspaceQueryResults,
    labelGroupListForWorkspaceQueryResults,
    objectiveListeForTeamsQueryResults,
  ]);

  return {
    loading,
  };
};
