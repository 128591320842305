import { FC } from "react";

import { useGetObjective } from "@/hooks/api/common";
import { ProgressSnapshotEventList } from "./progress-snapshot-event-list";

interface ObjectiveEventFeedProps {
  workspace: {
    id: string;
  };
  objective: {
    id: string;
  };
}

export const ObjectiveEventFeed: FC<ObjectiveEventFeedProps> = (props) => {
  const { objective } = useGetObjective({ id: props.objective.id });

  if (!objective) {
    return <></>;
  }

  return (
    <div className="relative flex flex-col items-start overflow-hidden pt-6">
      <div className="absolute -bottom-8 left-3 top-8 w-[1px] bg-border" />
      <ProgressSnapshotEventList {...props} objective={objective} />
    </div>
  );
};
