import { ColumnDef } from "@tanstack/react-table";

import { ObjectiveListCellAssignee, ObjectiveListColumnHeader } from "../..";
import { ObjectiveColumnConfiguration } from "../column-definition-type";
import { filterByIdWithUnknownPayload } from "../../filter-functions";
import {
  FilterByAssigneeConfigurationView,
  FilterByAssigneeDisplayView,
  FilterByAssigneeFilterPaneView,
} from "./components";

import { Objective } from "@/components/_domain/objective/models";

interface AssigneeColumnDefinitionProps {
  workspaceId: string;
  columnId: string;
  orderValue: number;
}

export const AssigneeColumnDefinition = ({
  workspaceId,
  columnId,
  orderValue,
}: AssigneeColumnDefinitionProps): ObjectiveColumnConfiguration => {
  return {
    columnId: columnId,
    getDefaultVisibilityState: () => true,
    getDisplayValue: () => "Verantwortlicher",
    getOrderValue: () => orderValue,
    getColumnDef: (): ColumnDef<Objective> => {
      return {
        id: columnId,
        accessorKey: columnId, // key needed to enable sorting / filtering
        enableHiding: true,
        enableSorting: true,
        enableColumnFilter: true,
        sortingFn: (rowA, rowB) => {
          if (rowA.original.assignee?.user?.username && !rowB.original.assignee?.user?.username) {
            return 1;
          }
          if (rowB.original.assignee?.user?.username && !rowA.original.assignee?.user?.username) {
            return -1;
          }

          const a = rowA.original.assignee?.user?.username ?? rowA.original.assignee?.user?.email ?? "";
          const b = rowA.original.assignee?.user?.username ?? rowA.original.assignee?.user?.email ?? "";

          return a.localeCompare(b);
        },
        filterFn: (row, _columnId, value, _addMeta) => {
          if (!row.original.assignee?.userId) {
            return filterByIdWithUnknownPayload(value, null);
          }
          return filterByIdWithUnknownPayload(value, row.original.assignee?.userId);
        },
        size: 1, // set to 1 instead to 0 for it to work in Safari
        header: ({ column }) => (
          <ObjectiveListColumnHeader column={column} title="" className="px-1 text-xs font-normal" />
        ),
        cell: ({ row }) => <ObjectiveListCellAssignee row={row} className="mx-2" />,
      };
    },
    getDisplayView: () => <FilterByAssigneeDisplayView />,
    getConfigurationView: (onFinished: () => void) => (
      <FilterByAssigneeConfigurationView
        column={{ id: columnId }}
        workspace={{ id: workspaceId }}
        onFinished={onFinished}
      />
    ),
    getConfigurationViewType: () => "popover",
    getFilterPaneView: () => <FilterByAssigneeFilterPaneView column={{ id: columnId }} />,
  };
};
