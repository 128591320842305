import { useGetObjective } from "@/hooks/api/common";
import { FC } from "react";
import { ProgressSnapshotEventListItem } from "./progress-snapshot-event-list-item";

interface ProgressSnapshotEventListProps {
  workspace: {
    id: string;
  };
  objective: {
    id: string;
  };
}

export const ProgressSnapshotEventList: FC<ProgressSnapshotEventListProps> = (props) => {
  const { objective } = useGetObjective(props.objective);

  if (!objective) {
    return <></>;
  }

  if (objective.progressSnapshotList.length < 1) {
    return <div>Es liegen noch keine Einträge vor.</div>;
  }

  return (
    <div className="flex w-full max-w-screen-md	flex-col gap-4">
      {objective.progressSnapshotList.map((ps) => {
        return <ProgressSnapshotEventListItem key={ps.id} workspace={props.workspace} progressSnapshot={ps} />;
      })}
    </div>
  );
};
