import { toast } from "@/components/_ui/use-toast.ts";
import { Slot, type AsChildProps } from "@/components/slot.tsx";

import { useMutation } from "@apollo/client";
import { getLabelGroupQueryDocument, updateLabelGroupMutationDocument } from "@/graphql/common";

type UpdateLabelGroupActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  labelGroup: {
    id: string;
    title: string;
    color: string;
    allowMultiSelect: boolean;
  };
  onSuccess?: () => void;
  onError?: () => void;
};

export const UpdateLabelGroupAction = ({
  labelGroup,
  onSuccess,
  onError,
  asChild,
  ...props
}: UpdateLabelGroupActionProps) => {
  const Comp = asChild ? Slot : "button";
  const [updateLabelGroup] = useMutation(updateLabelGroupMutationDocument, {
    onCompleted: () => {
      toast({
        title: "Label aktualisiert",
        description: `Wir haben das Label erfolgreich aktualisiert.`,
        variant: "success",
      });
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler bei der Aktualisierung des Labels aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
        variant: "error",
      });
      if (onError) {
        onError();
      }
    },
    refetchQueries: [
      {
        query: getLabelGroupQueryDocument,
        variables: { input: { id: labelGroup.id } },
      },
    ],
  });

  async function handleClick() {
    await updateLabelGroup({
      variables: {
        input: {
          id: labelGroup.id,
          title: labelGroup.title,
          color: labelGroup.color,
          allowMultiSelect: labelGroup.allowMultiSelect,
        },
      },
    });
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default UpdateLabelGroupAction;
