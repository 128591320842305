import { useState } from "react";

import { cn } from "@/lib/utils";

import { Button } from "@/components/_ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/_ui/dropdown-menu";
import { Row } from "@tanstack/react-table";

import { MoreHorizontal } from "lucide-react";
import { ArrowTopRightOnSquareIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid";

import { FileDownloader } from "../file-downloader";
import { Dialog, DialogContent } from "@/components/_ui/dialog";
import { FileDeleteForm } from "../file-delete-form";
import { FileUpdateForm } from "../file-update-form";

interface DatatableRowActionsProps<TData> {
  row: Row<TData>;
  file: {
    id: string,
    ownerId: string,
    name: string
  }
}

export function DatatableRowActions<TData>({ file }: DatatableRowActionsProps<TData>) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false)



  return (
    <div className={cn(["flex flex-row items-center gap-1 group-hover:opacity-100", dropdownOpen ? "opacity-100" : "opacity-0"])}>
      <FileDownloader file={file} mode="OPEN">
        <Button variant="ghost" size="icon" className="h-8 w-8 self-end p-0">
          <ArrowTopRightOnSquareIcon className="h-4 w-4" />
          <span className="sr-only">Öffnen</span>
        </Button>
      </FileDownloader>
      <FileDownloader file={file} mode="DOWNLOAD">
        <Button variant="ghost" size="icon" className="h-8 w-8 self-end p-0">
          <ArrowDownTrayIcon className="h-4 w-4" />
          <span className="sr-only">Herunterladen</span>
        </Button>
      </FileDownloader>

      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <div className="flex w-full flex-row items-center justify-end">
            <Button variant="ghost" size="icon" className="h-8 w-8 self-end p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <FileDownloader file={file} mode="OPEN">
            <DropdownMenuItem className="cursor-pointer">
              Öffnen
            </DropdownMenuItem>
          </FileDownloader>
          <FileDownloader file={file} mode="DOWNLOAD">
            <DropdownMenuItem className="cursor-pointer">
              Download
            </DropdownMenuItem>
          </FileDownloader>

          <DropdownMenuItem className="cursor-pointer" onClick={() => { setUpdateDialogOpen(true) }}>
            Umbennen
          </DropdownMenuItem>

          <DropdownMenuItem className="cursor-pointer" onClick={() => { setDeleteDialogOpen(true) }}>
            Löschen
          </DropdownMenuItem>

        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <DialogContent>
          <FileDeleteForm file={file} onSuccess={() => { setDeleteDialogOpen(false) }} onCancel={() => { setDeleteDialogOpen(false) }} />
        </DialogContent>
      </Dialog>

      <Dialog open={updateDialogOpen} onOpenChange={setUpdateDialogOpen}>
        <DialogContent>
          <FileUpdateForm file={file} onSuccess={() => { setUpdateDialogOpen(false) }} onCancel={() => { setUpdateDialogOpen(false) }} />
        </DialogContent>
      </Dialog>
    </div >
  );
}
