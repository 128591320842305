import { graphql } from "../../generated/gql";

export const createKeyResultMutationDocument = graphql(/* GraphQL */ `
  mutation createKeyResult($input: CreateKeyResultInput!) {
    createKeyResult(createKeyResultInput: $input) {
      id
      objectiveId
      unitId
      title
      description
      initialValue
      targetValue
      currentValue
      startDate
      targetDate
      type
      properties
      unit {
        id
        title
        symbol
      }
    }
  }
`);

export const updateKeyResultMutationDocument = graphql(/* GraphQL */ `
  mutation updateKeyResult($input: UpdateKeyResultInput!) {
    updateKeyResult(updateKeyResultInput: $input) {
      id
      objectiveId
      unitId
      title
      description
      initialValue
      targetValue
      currentValue
      startDate
      targetDate
      type
      properties
      unit {
        id
        title
        symbol
      }
    }
  }
`);

export const deleteKeyResultMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteKeyResult($input: DeleteKeyResultInput!) {
    deleteKeyResult(deleteKeyResultInput: $input) {
      id
      objectiveId
      unitId
      title
      description
      initialValue
      targetValue
      currentValue
      startDate
      targetDate
      type
      properties
      unit {
        id
        title
        symbol
      }
    }
  }
`);
