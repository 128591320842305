import { graphql } from "../../generated/gql";

export const workspaceByUrlQueryDocument = graphql(/* GraphQL */ `
  query workspaceByUrl($input: String!) {
    workspaceByUrl(workspaceUrl: $input) {
      id
      name
      workspaceUrl
      logo
      updatedAt
      createdAt
      workspaceMembershipList {
        id
        userId
        workspaceId
        role
        status
        updatedAt
        createdAt
      }
    }
  }
`);

export const workspaceByIdQueryDocument = graphql(/* GraphQL */ `
  query workspaceById($input: String!) {
    workspaceById(workspaceId: $input) {
      id
      name
      workspaceUrl
      logo
      updatedAt
      createdAt
      workspaceMembershipList {
        id
        userId
        workspaceId
        role
        status
        updatedAt
        createdAt
      }
    }
  }
`);
