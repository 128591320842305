import { FC } from "react";

import { FileStorageUploader } from "../file-storage-uploader";
import { useCreateFile, useReplaceFile } from "@/hooks/api/common/useFile";


export interface UploadResult {
    file: File;
    url: string;
    error?: Error | undefined
}

export interface FileUploadAction {
    newFile: File;
    oldFileId?: string | undefined;
    conflictHandling: "REPLACE" | "CREATE"
}

export interface FileItemUploaderProps {
    className?: string;
    owner: {
        id: string
        ownerType: "OBJECTIVE"
    }
    fileUploadAction: FileUploadAction;
    onFileUploadFinished?: (result: UploadResult) => void;
}

export const FileItemUploader: FC<FileItemUploaderProps> = ({ className, fileUploadAction, ...props }) => {
    const { createFile } = useCreateFile({ ownerId: props.owner.id })
    const { replaceFile } = useReplaceFile({ id: fileUploadAction.oldFileId ?? "", ownerId: props.owner.id })

    async function uploadWithoutConflict(result: UploadResult) {
        // there is no old file and we will just create a new one
        await createFile({
            variables: {
                input: {
                    ownerId: props.owner.id,
                    ownerType: props.owner.ownerType,
                    name: result.file.name,
                    url: result.url,
                    size: result.file.size
                },
            },
        });
    }

    async function uploadWithReplace(result: UploadResult, oldFileId: string) {
        // there is an old file and we will replace it
        await replaceFile({
            variables: {
                input: {
                    id: oldFileId,
                    url: result.url
                },
            },
        });
    }

    async function uploadWithCreate(result: UploadResult) {
        // there is an old file and we will rename the new file name
        let filename = result.file.name;

        if (fileUploadAction.oldFileId) {
            const filenameWithoutExtension = filename.replace(/\.[^/.]+$/, "")
            const newFilenameWithoutExtension = `${filenameWithoutExtension} (Kopie)`

            if (filenameWithoutExtension.length == filename.length) {
                // the old filename did not have an extension
                filename = newFilenameWithoutExtension
            } else {
                // the old filename had an extension
                const fileExtension = filename.split(".").pop()
                filename = `${newFilenameWithoutExtension}.${fileExtension}`
            }
        }

        await createFile({
            variables: {
                input: {
                    ownerId: props.owner.id,
                    ownerType: props.owner.ownerType,
                    name: filename,
                    url: result.url,
                    size: result.file.size
                },
            },
        });
    }



    async function handleFileUploadFinished(result: UploadResult) {


        if (result.error) {
            if (props.onFileUploadFinished) {
                props.onFileUploadFinished(result)
            }
            return
        }

        try {

            if (!fileUploadAction.oldFileId) {
                await uploadWithoutConflict(result)
            }

            if (fileUploadAction.oldFileId && fileUploadAction.conflictHandling === "REPLACE") {
                await uploadWithReplace(result, fileUploadAction.oldFileId)
            }

            if (fileUploadAction.oldFileId && fileUploadAction.conflictHandling === "CREATE") {
                await uploadWithCreate(result)
            }

            if (props.onFileUploadFinished) {
                props.onFileUploadFinished(result)
            }
        } catch (e) {
            if (props.onFileUploadFinished) {
                props.onFileUploadFinished({ ...result, error: new Error("Could not persist file in database.") })
            }
        }
    }

    return (
        <FileStorageUploader key={fileUploadAction.newFile.name} file={fileUploadAction.newFile} onFileUploadFinished={handleFileUploadFinished} />
    );
};
