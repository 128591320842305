import { createContext, useContext, ReactNode, FC, useState, useEffect, useMemo, useCallback } from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  SortingState,
  Table,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  useReactTable,
  ExpandedState,
  RowSelectionState,
} from "@tanstack/react-table";

import { Objective, ObjectiveFragment } from "../models";

import { ObjectiveColumnConfiguration } from "../objective-list-overview/columns/column-definition-type";

interface ObjectiveListStateType {
  config: {
    workspace: { id: string };
    user: { id: string };
    teamList: { id: string }[];
    objectiveList: Objective[];
    showChildren: boolean;
    enableExpanding: boolean;

    columnConfigurationList: ObjectiveColumnConfiguration[];
    defaultColumnOrderState?: ColumnOrderState;
    defaultColumnFiltersState?: ColumnFiltersState;
    defaultVisibilityState?: VisibilityState;
    defaultSortingState?: SortingState;
    defaultExpandedState?: ExpandedState;
    defaultRowSelectionState?: RowSelectionState;
    defaultGlobalFilterState?: string;
  };

  data: {
    objectiveList: Objective[];
  };

  fn: {
    getConfigurationForColumn: (id: string) => ObjectiveColumnConfiguration | null;
    getDisplayValueForColumn: (id: string) => string;
    setGlobalFilter: (globalFilter: string) => void;
  };

  table: Table<Objective>;
  columns: ColumnDef<Objective>[];
  columnFilters: ColumnFiltersState;
  columnVisibility: VisibilityState;
  columnOrder: ColumnOrderState;
  expandedState: ExpandedState;
  rowSelectionState: RowSelectionState;
  globalFilterState: string;
}

const ObjectiveListState = createContext<ObjectiveListStateType | undefined>(undefined);

// Define a type for the props that the provider will accept
interface ObjectiveListStateProviderProps {
  children: ReactNode;
  workspace: { id: string };
  user: { id: string };
  teamList: { id: string }[];
  objectiveList: Objective[];
  showChildren: boolean;
  enableExpanding: boolean;

  config: {
    columnConfigurationList: ObjectiveColumnConfiguration[];
    defaultColumnOrderState?: ColumnOrderState;
    defaultColumnFiltersState?: ColumnFiltersState;
    defaultVisibilityState?: VisibilityState;
    defaultSortingState?: SortingState;
    defaultExpandedState?: ExpandedState;
    defaultRowSelectionState?: RowSelectionState;
    defaultGlobalFilterState?: string;
  };
}

// Create the provider component
export const ObjectiveListStateProvider: FC<ObjectiveListStateProviderProps> = ({ children, ...props }) => {
  const [sorting, setSorting] = useState<SortingState>(props.config.defaultSortingState ?? []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(props.config.defaultColumnFiltersState ?? []);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(props.config.defaultVisibilityState ?? {});
  const [columnOrder, _setColumnOrder] = useState<ColumnOrderState>(props.config.defaultColumnOrderState ?? []);
  const [expandedState, setExpandedState] = useState<ExpandedState>(props.config.defaultExpandedState ?? {});
  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>(
    props.config.defaultRowSelectionState ?? {},
  );
  const [globalFilterState, setGlobalFilterState] = useState<string>(props.config.defaultGlobalFilterState ?? "");

  const objectiveList = useMemo(() => {
    return props.objectiveList.slice().sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a.title.localeCompare(b.title);
    });
  }, [props.objectiveList]);

  const topLevelObjectiveList = useMemo(() => {
    return objectiveList.slice().filter((o) => !o.parentId);
  }, [objectiveList]);

  const enableExpanding = useMemo(() => {
    // if there is a filter active deactivate expanding
    if (columnFilters.length > 0) {
      return false;
    }
    if (globalFilterState.length > 0) {
      return false;
    }
    return props.enableExpanding;
  }, [props.enableExpanding, columnFilters, globalFilterState]);

  const showChildren = useMemo(() => {
    // if there is a filter active, show all children
    if (columnFilters.length > 0) {
      return true;
    }
    if (globalFilterState.length > 0) {
      return true;
    }
    return props.showChildren;
  }, [props.showChildren, columnFilters, globalFilterState]);

  const data = useMemo(() => {
    if (!showChildren) {
      return topLevelObjectiveList;
    }
    return objectiveList;
  }, [objectiveList, topLevelObjectiveList, showChildren]);

  const columns = useMemo(() => {
    return props.config.columnConfigurationList.map((c) => c.getColumnDef());
  }, [props.config.columnConfigurationList]);

  const getSubRowsForObjective = useCallback(
    ({ id }: ObjectiveFragment) => {
      return objectiveList.slice().filter((o) => o.parentId === id);
    },
    [objectiveList, columnFilters],
  );

  const table = useReactTable({
    debugTable: false,
    data: data,
    columns: columns,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelectionState,

    onExpandedChange: setExpandedState,
    getRowCanExpand: (row) => {
      return enableExpanding && row.original.children.length > 0;
    },
    getSubRows: (objective) => {
      const objectiveRowChildList = getSubRowsForObjective({ id: objective.id });

      if (!objectiveRowChildList || objectiveRowChildList.length == 0) {
        return undefined;
      }

      return objectiveRowChildList;
    },

    onGlobalFilterChange: setGlobalFilterState,
    getColumnCanGlobalFilter: () => {
      return true;
    },
    globalFilterFn: (row, columnId, value, _addMeta) => {
      if (columnId == "objective_title") {
        return false;
      }

      if (!value || value.length == 0) {
        return true;
      }

      return row.original.title.toLowerCase().includes(value.toLowerCase());
    },

    state: {
      sorting,
      columnFilters,
      columnVisibility,
      columnOrder,
      rowSelection: rowSelectionState,
      expanded: expandedState,
      globalFilter: globalFilterState,
    },
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  const getConfigurationForColumn = useCallback(
    (id: string) => {
      const conf = props.config.columnConfigurationList.find((c) => c.columnId == id);

      if (!conf) {
        return null;
      }

      return conf;
    },
    [props.config.columnConfigurationList],
  );

  const getDisplayValueForColumn = useCallback(
    (id: string): string => {
      const col = props.config.columnConfigurationList.find((c) => c.columnId == id);

      if (!col) {
        return id;
      }

      return col.getDisplayValue();
    },
    [props.config.columnConfigurationList],
  );

  useEffect(() => {
    // if we filter, make sure we reset the expanded state
    if (columnFilters.length > 0) {
      setExpandedState({});
    }
  }, [columnFilters]);

  return (
    <ObjectiveListState.Provider
      value={{
        config: {
          ...props.config,
          workspace: props.workspace,
          user: props.user,
          teamList: props.teamList,
          objectiveList: props.objectiveList,
          showChildren: props.showChildren,
          enableExpanding: props.enableExpanding,
        },
        data: {
          objectiveList: data,
        },
        fn: {
          getConfigurationForColumn,
          getDisplayValueForColumn,
          setGlobalFilter: setGlobalFilterState,
        },

        table,
        columns,
        columnFilters,
        columnOrder,
        columnVisibility,
        expandedState,
        rowSelectionState,
        globalFilterState,
      }}
    >
      {children}
    </ObjectiveListState.Provider>
  );
};

// Define the useObjectiveListState hook
export const useObjectiveListState = (): ObjectiveListStateType => {
  const context = useContext(ObjectiveListState);

  if (!context) {
    throw new Error("useObjectiveListState must be used within a ChildProvider");
  }

  return context;
};
