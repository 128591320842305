import { FC } from "react";

import { cn } from "@/lib/utils";

import IconDashesHorizontal from "@/components/_icons/IconDashesHorizontal";

interface StatusViewEmptyProps {
  logoOnly?: boolean;
  className?: string;
}

export const StatusViewEmpty: FC<StatusViewEmptyProps> = (props) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <IconDashesHorizontal className={cn(["h-4 w-4", props.className])} />
      {!props.logoOnly && <div>Nicht zugewiesen</div>}
    </div>
  );
};

export default StatusViewEmpty;
