import { FC, PropsWithChildren, useEffect, useState } from "react";

import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";

import IconCheckmark from "@/components/_icons/IconCheckmark";

export interface ColorPickerPropsWithChildren extends PropsWithChildren {
  asChild?: boolean;
  value?: string;
  onSelect: (hexString: string) => void;
}

export const ColorPicker: FC<ColorPickerPropsWithChildren> = ({ value, onSelect, asChild, children }) => {
  const ALLOWED_COLORS = [
    "#95999F",
    "#6E7B8B",
    "#5E69D2",
    "#00B5ED",
    "#00B55C",
    "#E8B600",
    "#E57A00",
    "#F38E82",
    "#FB424B",
  ];
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  function isValidHexCode(hexString: string) {
    // make sure the hexString is entered with a leading #, e.g., '#AABBCC80'
    return /^#[0-9A-F]{6}$/i.test(hexString);
  }

  function handleColorSelected(color: string) {
    if (!isValidHexCode(color)) {
      // fail silently
      return;
    }
    setSelectedColor(color);
    setOpen(false);

    // notify parent component
    if (onSelect) {
      onSelect(color);
    }
  }

  useEffect(() => {
    if (!value) {
      return;
    }

    if (!ALLOWED_COLORS.indexOf(value)) {
      return;
    }

    setSelectedColor(value);
  }, [value]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild={asChild}>{children}</PopoverTrigger>
      <PopoverContent>
        <div className="flex h-4 flex-row items-center justify-between">
          {ALLOWED_COLORS.map((color) => {
            return (
              <div
                key={color}
                className="flex h-5 w-5 flex-row items-center justify-center"
                onClick={() => handleColorSelected(color)}
              >
                <div
                  className={`flex h-4 w-4 cursor-pointer flex-row items-center justify-center rounded-full hover:h-5 hover:w-5 ${
                    selectedColor === color ? "h-5 w-5" : ""
                  }`}
                  style={{ backgroundColor: color }}
                >
                  {selectedColor === color && <IconCheckmark className="h-2.5 w-2.5 select-none opacity-40" />}
                </div>
              </div>
            );
          })}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ColorPicker;
