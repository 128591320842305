import { FC, useEffect, useMemo, useState } from "react";

import { FilterByTextPayload, FilterByTextPayloadSchema } from "../../../filter-functions";

import { useObjectiveListState } from "../../../../hooks";

import { Input } from "@/components/_ui/input";
import { Button } from "@/components/_ui/button";

export interface FilterByTitleConfigurationViewProps {
  column: {
    id: string;
  };
  onFinished: () => void;
}

export const FilterByTitleConfigurationView: FC<FilterByTitleConfigurationViewProps> = ({ onFinished, ...props }) => {
  const columnId = props.column.id;
  const { table, columnFilters } = useObjectiveListState();

  const [text, setText] = useState<string>("");

  const column = useMemo(() => {
    return table.getColumn(columnId);
  }, [table]);

  const filterValue = useMemo(() => {
    const filterValue = column?.getFilterValue();

    // Todo: properly parse filter value
    const safePayload = FilterByTextPayloadSchema.safeParse(filterValue);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [column, columnFilters]);

  useEffect(() => {
    if (!filterValue) {
      return;
    }
    setText(filterValue.query);
  }, []);

  const setFilter = (payload: FilterByTextPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id != column.id));
  };

  const handleCancelClicked = () => {
    onFinished();
  };

  const handleApplyClicked = () => {
    if (text == "") {
      clearFilter();
      return;
    }

    setFilter({
      type: "filter_by_text",
      mode: filterValue?.mode ?? "included",
      query: text,
    });

    onFinished();
  };

  return (
    <div>
      <div className="mb-4 text-sm">Filtern Sie nach dem Titel</div>
      <div className="mb-4">
        <Input
          value={text}
          onChange={(e) => {
            setText(e.currentTarget.value);
          }}
          placeholder="Ihr Text ..."
          className="ring-offset-backgroundtransition-colors inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-normal focus:outline-none focus:ring-0 focus-visible:outline-none  focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleApplyClicked();
            }
          }}
        />
      </div>
      <div className="flex flex-row items-center justify-end gap-2">
        <Button
          size="xs"
          variant="outline"
          onClick={() => {
            handleCancelClicked();
          }}
        >
          Abbrechen
        </Button>
        <Button
          size="xs"
          variant="default"
          onClick={() => {
            handleApplyClicked();
          }}
        >
          Anwenden
        </Button>
      </div>
    </div>
  );
};
