import { graphql } from "../../generated/gql";

export const updateTeamMembershipRoleMutationDocument = graphql(/* GraphQL */ `
  mutation updateTeamMembershipRole($input: TeamMembershipUpdateRoleInput!) {
    updateTeamMembershipRole(teamMembershipUpdateRoleInput: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const updateTeamMembershipStatusMutationDocument = graphql(/* GraphQL */ `
  mutation updateTeamMembershipStatus($input: TeamMembershipUpdateStatusInput!) {
    updateTeamMembershipStatus(teamMembershipUpdateStatusInput: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const leaveTeamMutationDocument = graphql(/* GraphQL */ `
  mutation leaveTeam($input: TeamMembershipLeaveInput!) {
    leaveTeam(teamMembershipLeaveInput: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const rejoinTeamMembershipMutationDocument = graphql(/* GraphQL */ `
  mutation rejoinTeamMembership($input: TeamMembershipRejoinInput!) {
    rejoinTeamMembership(teamMembershipRejoinInput: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const createTeamInviteMutationDocument = graphql(/* GraphQL */ `
  mutation createTeamInvite($input: TeamInviteCreateInput!) {
    createTeamInvite(teamInviteCreateInput: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const acceptTeamInviteMutationDocument = graphql(/* GraphQL */ `
  mutation acceptTeamInvite($input: TeamInviteAcceptInput!) {
    acceptTeamInvite(teamInviteAcceptInput: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const deleteTeamInviteMutationDocument = graphql(/* GraphQL */ `
  mutation deleteTeamInvite($input: TeamInviteDeleteInput!) {
    deleteTeamInvite(teamInviteDeleteInput: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const resendTeamInviteNotificationMutationDocument = graphql(/* GraphQL */ `
  mutation resendTeamInviteNotification($input: TeamInviteUpdateInput!) {
    resendTeamInviteNotification(teamInviteUpdateInput: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const joinTeamMutationDocument = graphql(/* GraphQL */ `
  mutation joinTeam($input: TeamMembershipJoinInput!) {
    joinTeam(teamMembershipJoinInput: $input) {
      id
      userId
      teamId
      role
      status
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);
