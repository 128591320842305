import { FC, useMemo, useState } from "react";

import { Input } from "@/components/_ui/input";
import { useTeamListForUserInWorkspace } from "@/hooks/api/common";
import { TeamBrowseListItem } from "./team-browse-list-item";

interface TeamBrowseListProps {
  workspace: {
    id: string;
  };
  user: {
    id: string;
  };
}

export const TeamBrowseList: FC<TeamBrowseListProps> = ({ workspace, user }) => {
  const [queryString, setQueryString] = useState("");

  const { teamList } = useTeamListForUserInWorkspace({
    workspaceId: workspace.id,
    userId: user.id,
  });

  const filteredList = useMemo(() => {
    return teamList
      .slice()
      .filter((t) => {
        if (queryString == "") {
          return true;
        }
        return t.name.toLowerCase().indexOf(queryString.toLowerCase()) !== -1; // matching the querystring
      })
      .sort((a) => {
        if (a.teamMembershipList.some((tm) => tm.userId == user.id && tm.status == "ACTIVE")) {
          return 1;
        }
        return -1;
      })
      .sort((a) => {
        if (a.teamMembershipList.some((tm) => tm.userId == user.id && tm.status == "PENDING")) {
          return -1;
        }
        return 1;
      })
      .sort((a) => {
        if (a.visibility != "PUBLIC" && !a.teamMembershipList.some((tm) => tm.userId == user.id)) {
          return 1;
        }
        if (a.teamMembershipList.some((tm) => tm.userId == user.id && tm.status == "ACTIVE")) {
          return 1;
        }
        return -1;
      });
  }, [teamList, queryString]);

  return (
    <div className="flex flex-col gap-2">
      <div className="mr-4">
        <Input
          value={queryString}
          onChange={(e) => setQueryString(e.currentTarget.value)}
          className="shadow-none border-none pl-0 text-xl font-light outline-none ring-0 focus-visible:border-none focus-visible:ring-0"
          placeholder="Suche nach Teams"
          autoFocus
        />
      </div>
      <div className="h-0 w-full border-b"></div>
      <div className="flex max-h-[240px] flex-col overflow-y-scroll">
        {filteredList.map((team) => {
          return <TeamBrowseListItem key={team.id} workspace={workspace} team={team} user={user} />;
        })}
      </div>
    </div>
  );
};
