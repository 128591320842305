import { useMutation } from "@apollo/client";
import { createFileMutationDocument, fileListForOwnerQueryDocument } from "@/graphql/common";

export interface UseCreateFileProps {
  ownerId: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useCreateFile = ({ ownerId, onCompleted, onError }: UseCreateFileProps) => {
  const [createFile] = useMutation(createFileMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: fileListForOwnerQueryDocument,
        variables: { input: { ownerId: ownerId } },
      },
    ],
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createFile,
  };
};
