import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getLabelListForTeamQueryDocument } from "@/graphql/common";
import { groupBy } from "@/lib/utils";

export interface UseGetLabelListForTeamProps {
  teamId: string;
}

export const useGetLabelListForTeam = ({ teamId }: UseGetLabelListForTeamProps) => {
  const queryResult = useQuery(getLabelListForTeamQueryDocument, {
    variables: { input: { teamId } },
  });

  const labelList = useMemo(() => {
    const { data } = queryResult;

    if (!data) {
      return [];
    }

    return data.labelsForTeam;
  }, [queryResult]);

  const labelListWithoutGroup = useMemo(() => {
    return labelList.filter((l) => !l.labelGroupId);
  }, [labelList]);

  const labelListGroupedByGroupId = useMemo(() => {
    return groupBy(labelList, (label) => label.labelGroupId);
  }, [labelList]);

  return {
    queryResult,
    labelList,
    labelListWithoutGroup,
    labelListGroupedByGroupId,
  };
};
