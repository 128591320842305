import { useMemo, PropsWithChildren } from "react";

import { SelectedThemeAppearance, useTheme } from "@/hooks/useTheme";
import { Link } from "react-router-dom";

export default function AuthPageLayout({ children }: PropsWithChildren) {
  const { selectedThemeAppearance } = useTheme();

  const logoUrl = useMemo(() => {
    if (selectedThemeAppearance == SelectedThemeAppearance.dark) {
      return "/ark-logo-white.png";
    } else {
      return "/ark-logo-color-bg.png";
    }
  }, [selectedThemeAppearance]);

  return (
    <div className="flex h-screen w-screen">
      <div className="flex w-full flex-col items-center justify-center gap-12 bg-background px-5 text-foreground lg:w-1/2">
        <div className="my-2 flex flex-row items-start gap-4">
          <img src={logoUrl} className="object-fit h-12 w-12" />
          <div className="-mt-1.5 flex flex-col items-start">
            <div className="text-2xl font-bold">Ark Climate</div>
            <div className="text-sm">Moderne Software für kommunalen Klimaschutz</div>
          </div>
        </div>

        <div className="shadow z-10 w-full max-w-md overflow-hidden rounded border">{children}</div>

        <div className="max-w-md text-xs ">
          Sie möchten Ark Climate ausprobieren, aber haben noch keinen Account?
          <Link to="https://www.ark-climate.de/contact" className="underline">
            {" "}
            Jetzt Account anfragen
          </Link>
        </div>
      </div>

      <div className="pointer-events-none relative hidden h-full w-1/2 lg:flex">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-black opacity-50"></div>
        <img src="/wallpaper-login.jpg" className="object-cover" />
      </div>
    </div>
  );
}
