import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { Button } from "@/components/_ui/button";

import { useCurrentUser } from "@/hooks/api/common";

import IconCalendarSolid from "./_icons/IconCalendarSolid";
import IconBookSolid from "./_icons/IconBookSolid";
import IconQuestionCircled from "./_icons/IconQuestionCircled";
import IconCommentDots from "./_icons/IconCommentDots";
import IconFlagFilled from "./_icons/IconFlagFilled";
import { Link } from "react-router-dom";

export function ButtonHelp() {
  const { currentUser } = useCurrentUser();

  function openFeedbackModal() {
    if (!window) {
      return;
    }
    if (!window.productlane) {
      return;
    }
    if (!window.productlane.openFeedbackWidget) {
      return;
    }

    window.productlane.openFeedbackWidget({
      project: {
        name: "Ark Climate",
      },
      email: currentUser?.email,
    });
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex flex-initial flex-row items-center gap-2 text-xs">
          <Button variant="outline" size="icon" className="select-none rounded-full">
            ?
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem disabled className="text-xs">
          Was is neu?
        </DropdownMenuItem>
        <DropdownMenuItem asChild className="cursor-pointer" aria-selected>
          <Link
            to="https://ark-climate.productlane.com/changelog"
            target="_blank"
            className="relative flex items-center overflow-hidden text-ellipsis hover:bg-accent hover:text-accent-foreground"
          >
            <IconCalendarSolid className="mr-2 h-3 w-3" />
            Changelog
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild className="cursor-pointer">
          <Link
            to="https://ark-climate.productlane.com/roadmap"
            target="_blank"
            className="relative flex items-center overflow-hidden text-ellipsis hover:bg-accent hover:text-accent-foreground"
          >
            <IconFlagFilled className="mr-2 h-3 w-3" />
            Roadmap
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild disabled>
          Hilfe
        </DropdownMenuItem>
        <DropdownMenuItem asChild className="cursor-pointer" disabled>
          <a
            href="/"
            target="_blank"
            className="relative flex items-center overflow-hidden text-ellipsis hover:bg-accent hover:text-accent-foreground"
          >
            <IconBookSolid className="mr-2 h-3 w-3" />
            Dokumentation
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem asChild className="cursor-pointer" disabled>
          <a
            href="/"
            target="_blank"
            className="relative flex items-center overflow-hidden text-ellipsis hover:bg-accent hover:text-accent-foreground"
          >
            <IconQuestionCircled className="mr-2 h-3 w-3" />
            Support kontaktieren
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem asChild className="cursor-pointer" onClick={openFeedbackModal}>
          <Link
            to=""
            className="relative flex items-center overflow-hidden text-ellipsis hover:bg-accent hover:text-accent-foreground"
          >
            <IconCommentDots className="mr-2 h-3 w-3" />
            Feedback
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
