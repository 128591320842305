import { FC } from "react";

import { cn } from "@/lib/utils";

import { useCreatePriorityInstance, useDeletePriorityInstance, useUpdatePriorityInstance } from "@/hooks/api/common";

import { Button } from "@/components/_ui/button";
import { PrioritySelectDropdown, PriorityViewOptional } from "@/components/_domain/priority";

import { Row } from "@tanstack/react-table";
import { Objective } from "@/components/_domain/objective/models";

interface ObjectiveListCellPriorityProps {
  row: Row<Objective>;
  className?: string;
}

export const ObjectiveListCellPriority: FC<ObjectiveListCellPriorityProps> = ({ row, className }) => {
  const objective = row.original;
  const priorityInstance = row.original.priorityInstance;

  const { updatePriorityInstance } = useUpdatePriorityInstance({}); // always prefer this one
  const { createPriorityInstance } = useCreatePriorityInstance({ ownerId: objective.id, ownerType: "OBJECTIVE" });
  const { deletePriorityInstance } = useDeletePriorityInstance({ ownerId: objective.id, ownerType: "OBJECTIVE" });

  function assignPriority(priority: { id: string }) {
    if (!objective) {
      return;
    }

    if (priorityInstance) {
      updatePriorityInstance({
        variables: {
          input: {
            id: priorityInstance.id,
            priorityId: priority.id,
          },
        },
      });
    } else {
      createPriorityInstance({
        variables: {
          input: {
            priorityId: priority.id,
            ownerId: objective.id,
            ownerType: "OBJECTIVE",
            teamId: objective.teamId,
          },
        },
      });
    }
  }

  function removePriority() {
    if (!priorityInstance) {
      return;
    }
    deletePriorityInstance({
      variables: { input: { id: priorityInstance.id } },
    });
  }

  const handleOnSelect = (priority: { id: string } | null) => {
    if (!priority) {
      return removePriority();
    }
    assignPriority(priority);
  };

  if (!objective) {
    return <></>;
  }

  return (
    <PrioritySelectDropdown
      team={{ id: objective.teamId }}
      onSelect={handleOnSelect}
      selected={priorityInstance}
      side="bottom"
      asChild
    >
      <Button
        variant="ghost"
        size="icon"
        className={cn(
          [
            "overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0",
          ],
          className,
        )}
      >
        <PriorityViewOptional
          priority={priorityInstance ? { id: priorityInstance.priorityId } : null}
          logoOnly={true}
          className={cn(["h-3 w-3.5", priorityInstance ? "" : "text-muted-foreground"])}
        />
      </Button>
    </PrioritySelectDropdown>
  );
};
