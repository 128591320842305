import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { Menubar } from "@/components/_ui/menubar";
import IconChevronDown from "@/components/_icons/IconChevronDown";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarButtonDropdown } from "./ToolbarButtonDropdown";
import { ToolbarButtonDropdownItem } from "./ToolbarButtonDropdownItem";
import { ToolbarButtonDivider } from "./ToolbarButtonDivider";

import { RichTextToolbarContextProvider } from "./useToolbarContext";

import {
  FORMAT_BLOCK_PARAGRAPH,
  FORMAT_BLOCK_HEADING_H1,
  FORMAT_BLOCK_HEADING_H2,
  FORMAT_BLOCK_HEADING_H3,
  FORMAT_BLOCK_LIST_UNORDERED,
  FORMAT_BLOCK_LIST_ORDERED,
  INSERT_LINK,
  FORMAT_TEXT_BOLD,
  FORMAT_TEXT_ITALIC,
  FORMAT_TEXT_UNDERLINE,
  FORMAT_TEXT_STRIKETHROUGH,
  FORMAT_TEXT_CODE,
} from "./ToolbarCommands";
import { ToolbarButtonLink } from "./ToolbarButtonLink";

interface RichTextToolbarComponentProps {
  editor: ReturnType<typeof useLexicalComposerContext>[0];
  shouldShow: boolean;
}

export default function RichTextEditorToolbar({ editor }: RichTextToolbarComponentProps) {
  return (
    <RichTextToolbarContextProvider editor={editor}>
      <div className="flex flex-row items-center">
        <Menubar>
          <ToolbarButtonDropdown
            trigger={
              <span className="flex flex-row items-center">
                Text <IconChevronDown className="ml-1 h-2 w-2" />
              </span>
            }
          >
            <ToolbarButtonDropdownItem command={FORMAT_BLOCK_PARAGRAPH} />
            <ToolbarButtonDropdownItem command={FORMAT_BLOCK_HEADING_H1} />
            <ToolbarButtonDropdownItem command={FORMAT_BLOCK_HEADING_H2} />
            <ToolbarButtonDropdownItem command={FORMAT_BLOCK_HEADING_H3} />
            <ToolbarButtonDropdownItem command={FORMAT_BLOCK_LIST_UNORDERED} />
            <ToolbarButtonDropdownItem command={FORMAT_BLOCK_LIST_ORDERED} />
          </ToolbarButtonDropdown>

          <ToolbarButtonDivider />

          <ToolbarButtonLink command={INSERT_LINK} />

          <ToolbarButtonDivider />

          <ToolbarButton command={FORMAT_TEXT_BOLD} />

          <ToolbarButton command={FORMAT_TEXT_ITALIC} />
          <ToolbarButton command={FORMAT_TEXT_UNDERLINE} />
          <ToolbarButton command={FORMAT_TEXT_STRIKETHROUGH} />
          <ToolbarButton command={FORMAT_TEXT_CODE} />
        </Menubar>
      </div>
    </RichTextToolbarContextProvider>
  );
}
