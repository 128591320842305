import { graphql } from "../../generated/gql";

export const teamByIdQueryDocument = graphql(/* GraphQL */ `
  query teamById($input: String!) {
    teamById(teamId: $input) {
      id
      workspaceId
      name
      visibility
      logo
      logoType
      color
      teamMembershipList {
        id
        userId
        teamId
        role
        status
      }
    }
  }
`);

export const teamsForUserInWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query teamsForUserInWorkspaceQuery($input: String!) {
    teamsForUserInWorkspace(workspaceId: $input) {
      id
      workspaceId
      name
      visibility
      logo
      logoType
      color
      teamMembershipList {
        id
        userId
        teamId
        role
        status
        user {
          id
          email
          username
          fullname
          profilePicture
        }
      }
      labelGroupList {
        id
        title
        color
        allowMultiSelect
        workspaceId
        teamId
        labels {
          id
          title
          color
          labelGroupId
        }
      }
      labelList {
        id
        title
        color
        labelGroupId
        workspaceId
        teamId
        instances {
          id
        }
      }
      priorityList {
        id
        teamId
        name
        color
        type
        instances {
          id
        }
      }
      statusList {
        id
        teamId
        name
        color
        type
        instances {
          id
        }
      }
      unitList {
        id
        teamId
        title
        symbol
        keyResults {
          id
          objectiveId
        }
      }
    }
  }
`);

export const teamDeletionCodeQueryDocument = graphql(/* GraphQL */ `
  query requestTeamDeletionCodeQuery($input: UpdateTeamInput!) {
    requestTeamDeletionCode(updateTeamInput: $input) {
      success
      email
      message
    }
  }
`);
