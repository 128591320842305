import { useEffect } from "react";

import { useQuery } from "@apollo/client";
import { currentUserQueryDocument } from "@/graphql/common";

import Header from "@/components/header";

import PasswordForm from "./password-form";

export default function Page() {
  const { data } = useQuery(currentUserQueryDocument);

  useEffect(() => {
    if (!data) {
      return;
    }

    data.currentUser;
    // setCurrentUser(data.currentUser);
  }, [data]);

  return (
    <main className="relative place-items-stretch overflow-auto">
      <Header className="border-none"></Header>

      <div className="overflow-y-scroll pb-24" style={{ maxHeight: "calc(100vh - 56px)" }}>
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <div className="flex flex-col gap-1.5">
              <div className="text-2xl">Sicherheit</div>
              <div className="text-sm text-muted-foreground">Behalten Sie die Sicherheit Ihres Kontos im Blick.</div>
            </div>

            <div className="my-6 w-full border-t border-solid" />

            {data && data.currentUser && (
              <div className="max-w-xl text-sm">
                <h2 className="mb-6 text-lg">Passwort ändern</h2>
                <PasswordForm id={data.currentUser.id} email={data.currentUser.email} className="px-px" />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
