export const THEME_KEY = "THEME_APPEARANCE";

export const getTheme = () => {
  const theme = localStorage.getItem(THEME_KEY);
  if (!theme) {
    return "auto";
  }
  return theme;
};

export const setTheme = (theme: string) => {
  localStorage.setItem(THEME_KEY, theme);
};

export const removeTheme = () => {
  localStorage.removeItem(THEME_KEY);
};
