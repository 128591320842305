import { FC, useState } from "react";

import { Button } from "@/components/_ui/button";

import { Status } from "../model/status.schema";
import { StatusType } from "../../../../../../components/_domain/status/status-type";
import { StatusTableRow } from "./status-table-row";
import { StatusFormCreate } from "./actions/status-form-create";
import { useCurrentWorkspace } from "@/hooks/useCurrentWorkspace";

export interface StatusTableSectionProps {
  team: {
    id: string;
  };
  statusType: StatusType;
  statusList: Status[];
  className?: string;
}

export const StatusTableSection: FC<StatusTableSectionProps> = ({ team, statusType, statusList, className }) => {
  const [showCreateStatusForm, setShowCreateStatusForm] = useState(false);
  // This is a bit hacky: on the one hand we inject the workspace via prop, but then
  // only check for access rights in the current workspace. This may lead to inconsistencies.
  const { hasEditRights } = useCurrentWorkspace();

  function handleCreateStatusButtonPressed() {
    setShowCreateStatusForm(true);
  }

  function onFormCanceled() {
    setShowCreateStatusForm(false);
  }

  function onFormSuccess() {
    setShowCreateStatusForm(false);
  }

  return (
    <div className={className}>
      <div className="mb-2 flex w-full flex-row items-center justify-between">
        <div className="text-muted-foreground">{statusType}</div>
        <div>
          {hasEditRights && (
            <Button
              size="icon"
              variant="ghost"
              className="text-base text-foreground/80"
              onClick={handleCreateStatusButtonPressed}
            >
              +
            </Button>
          )}
        </div>
      </div>
      {statusList.map((s) => (
        <StatusTableRow key={s.id} status={s} team={team} hideDeleteAction={statusList.length < 2} />
      ))}
      {showCreateStatusForm && (
        <StatusFormCreate team={team} statusType={statusType} onCanceled={onFormCanceled} onSuccess={onFormSuccess} />
      )}
    </div>
  );
};

export default StatusTableSection;
