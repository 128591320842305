import { LucideProps } from "lucide-react";

const Icon = ({ ...props }: LucideProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="circle-outline"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="14"
      width="14"
      {...props}
    >
      <circle
        cx="7"
        cy="7"
        r="6"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeDasharray="3.14 0"
        strokeDashoffset="-0.7"
      ></circle>
      <circle
        cx="7"
        cy="7"
        r="2"
        fill="none"
        stroke="currentColor"
        rotate="20"
        strokeWidth="4"
        strokeDasharray="0 100"
        transform="rotate(-90 7 7)"
      ></circle>
    </svg>
  );
};

export default Icon;
