import { ColumnDef } from "@tanstack/react-table";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
import { Unit } from "./model";

import { DatatableColumnHeader } from "@/components/datatable-column-header";
import { DatatableRowActions } from "./row-actions";

export const columns: ColumnDef<Unit>[] = [
  {
    id: "title",
    accessorKey: "title",
    enableHiding: false,
    header: ({ column }) => <DatatableColumnHeader column={column} title="Name" />,
  },
  {
    id: "symbol",
    accessorKey: "symbol",
    header: ({ column }) => <DatatableColumnHeader column={column} title="Symbol" />,
  },
  {
    id: "ziele",
    accessorKey: "keyResults",
    header: ({ column }) => <DatatableColumnHeader column={column} title="Ziele" />,
    cell: ({ row }) => {
      const keyResults = row.getValue("ziele");
      const length = keyResults instanceof Array ? keyResults.length : 0;
      return <div className="">{length == 1 ? `${length} Ziel` : `${length} Ziele`}</div>;
    },
  },
  {
    id: "actions",
    cell: ({ row }) => <DatatableRowActions row={row} />,
  },
];
