import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getPriorityListForTeamQueryDocument } from "@/graphql/common";
import { PriorityTypes } from "@/components/_domain/priority/priority-type";

export interface UseGetPriorityListProps {
  teamId: string;
}

export const useGetPriorityList = ({ teamId }: UseGetPriorityListProps) => {
  const queryResult = useQuery(getPriorityListForTeamQueryDocument, {
    variables: { input: { teamId } },
  });

  const priorityList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    return queryResult.data.priorityListForTeam.map((s) => {
      let order = 0;

      if (s.type == PriorityTypes.low) {
        order = 100;
      }
      if (s.type == PriorityTypes.medium) {
        order = 200;
      }
      if (s.type == PriorityTypes.high) {
        order = 300;
      }
      if (s.type == PriorityTypes.critical) {
        order = 400;
      }
      return {
        ...s,
        order,
      };
    });
  }, [queryResult.data]);

  return {
    queryResult,
    priorityList,
  };
};
