import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { cn } from "@/lib/utils";

import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";
import { toast } from "@/components/_ui/use-toast";

import { ValueWithUnit } from "@/components/_domain/unit";
import { useGetKeyResult } from "@/hooks/api/common";

import IconFlag from "@/components/_icons/IconFlag";
import IconXCircleFilled from "@/components/_icons/IconXCircleFilled";

export interface ProgressSnapshotValueQuantInputProps {
  keyResult: {
    id: string;
  };
  value?: {
    value: number;
    properties: Object;
  };
  onValueChange?: (
    value:
      | {
          value: number;
          properties: Object;
        }
      | undefined,
  ) => void;
}

export const ProgressSnapshotValueQuantInput: FC<ProgressSnapshotValueQuantInputProps> = ({
  onValueChange,
  ...props
}) => {
  const { quantitativeKeyResult } = useGetKeyResult({ id: props.keyResult.id });
  const [showPopover, setShowPopover] = useState(false);

  const [inputValueText, setInputValueText] = useState<string>(props.value ? `${props.value.value}` : "");
  const [savedValue, setSavedValue] = useState<number | undefined>(props.value ? props.value.value : undefined);

  const inputValueNumber = useMemo(() => {
    if (inputValueText == "" && quantitativeKeyResult) {
      return quantitativeKeyResult.currentValue;
    }

    const maybeNumber = Number(inputValueText);

    if (Number.isNaN(maybeNumber)) {
      return undefined;
    }

    return maybeNumber;
  }, [inputValueText]);

  const fieldWasModified = useCallback(() => {
    if (!quantitativeKeyResult) {
      return false;
    }
    if (savedValue == undefined) {
      return false;
    }

    if (savedValue != quantitativeKeyResult.currentValue) {
      return true;
    }

    return false;
  }, [savedValue]);

  const handleSaveChangedValue = () => {
    if (inputValueNumber == undefined) {
      toast({
        title: "Fehler",
        description: "Der eingegebene Wert ist keine gültige Zahl. Bitte geben Sie einen gültigen Wert ein.",
        variant: "error",
      });
      return;
    }

    if (!quantitativeKeyResult) {
      return;
    }

    const inputValueIsTheSameAsStoredValue = inputValueNumber == quantitativeKeyResult.currentValue;

    // if there is no change to the stored value, set the saved value to undefined
    const newInputValue = inputValueIsTheSameAsStoredValue ? undefined : inputValueNumber;

    setSavedValue(newInputValue);

    if (!onValueChange) {
      return;
    }

    if (inputValueIsTheSameAsStoredValue) {
      // we are back at the start, so no change should be saved
      onValueChange(undefined);
      setShowPopover(false);
      return;
    }

    const properties = {};
    onValueChange({ value: newInputValue ?? quantitativeKeyResult.currentValue, properties: properties });
    setShowPopover(false);
  };

  useEffect(() => {
    if (!quantitativeKeyResult) {
      return;
    }
    setInputValueText(`${quantitativeKeyResult.currentValue}`);
  }, [quantitativeKeyResult]);

  if (!quantitativeKeyResult) {
    return <></>;
  }

  return (
    <Popover
      open={showPopover}
      onOpenChange={(open) => {
        if (!open) {
          // automatically save the value
          handleSaveChangedValue();
        }
        setShowPopover(open);
      }}
    >
      <PopoverTrigger asChild={true}>
        <Button
          variant={"ghost"}
          size="xs"
          className={cn(["shadow-sm rounded-md border text-left text-xs font-normal"])}
        >
          <IconFlag
            className={cn([
              "ml-auto mr-1 h-2.5 w-2.5 opacity-50",
              savedValue != undefined ? "text-primary opacity-100" : "",
            ])}
          />
          <ValueWithUnit
            value={quantitativeKeyResult.currentValue}
            unit={quantitativeKeyResult.unitId ? { id: quantitativeKeyResult.unitId } : undefined}
          />
          {savedValue != undefined && (
            <div className="flex h-full flex-row items-center">
              <span className="ml-0.5 px-0.5">→</span>
              <ValueWithUnit
                value={savedValue}
                unit={quantitativeKeyResult.unitId ? { id: quantitativeKeyResult.unitId } : undefined}
              />
            </div>
          )}
          {fieldWasModified() && <div className="ml-1 h-1.5 w-1.5 rounded-full bg-primary"></div>}
        </Button>
      </PopoverTrigger>
      <PopoverContent side="bottom" align="start" className="w-auto">
        <div className="w-32">
          <div className="mb-1 text-xs">{quantitativeKeyResult.title}</div>
          <div className="mb-1 text-xs text-foreground/60">Neuer Wert</div>

          <div className="flex flex-row items-center gap-2">
            <div className="group relative">
              <Input
                value={inputValueText}
                onChange={(e) => {
                  setInputValueText(e.currentTarget.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSaveChangedValue();
                  }
                }}
                type="number"
                step={0.01}
                autoComplete="off"
                autoFocus
                placeholder={quantitativeKeyResult.currentValue.toString()}
                className=" h-7 w-32 focus-visible:ring-slate-600 dark:focus-visible:ring-slate-600"
              />
              {inputValueText != "" && (
                <div
                  className="invisible absolute right-2 top-0.5 translate-y-1/2 rounded-full hover:cursor-pointer group-hover:visible"
                  onClick={() => {
                    setInputValueText("");
                  }}
                >
                  <IconXCircleFilled className="h-3 w-3 text-foreground/60" />
                </div>
              )}
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ProgressSnapshotValueQuantInput;
