import { z } from "zod";

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const fileSchema = z.object({
  id: z.string(),
  ownerId: z.string(),
  ownerType: z.string(),
  name: z.string(),
  size: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type File = z.infer<typeof fileSchema>;
