import { FC, useState } from "react";

import TextareaAutosize from "react-textarea-autosize";
import { RichTextEditor } from "@/components/rich-text-editor";

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { toast } from "@/components/_ui/use-toast";
import { Button } from "@/components/_ui/button";
import { useCreateObjective } from "@/hooks/api/common";

const formSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
});

export interface ObjectiveCreateChildFormProps {
  className?: string;
  parentObjective: {
    id: string;
  };
  team: {
    id: string;
  };
  callbacks: {
    hideCreateChildObjective: () => void;
  };
}

export const ObjectiveCreateChildForm: FC<ObjectiveCreateChildFormProps> = ({ team, parentObjective, callbacks }) => {
  const [loading, setLoading] = useState(false);

  const [removedFromDom, setRemovedFromDom] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      description: "",
    },
  });

  const { createObjective } = useCreateObjective({
    teamId: team.id,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  async function onRichTextEditorChange(value: string, json: string) {
    if (value == "") {
      form.setValue("description", "");
    } else {
      form.setValue("description", json);
    }
  }

  async function handleCreateObjective() {
    if (loading) {
      return;
    }

    const { title, description } = form.getValues();

    if (!title || title.length < 1) {
      toast({
        title: "Fehler",
        description: "Der Name des Ziels darf nicht leer sein. Bitte geben Sie einen Namen ein.",
        variant: "error",
      });
      return;
    }

    setLoading(true);
    await createObjective({
      variables: {
        input: {
          teamId: team.id,
          title: title,
          properties: description,
          parentId: parentObjective.id,
        },
      },
    });
  }

  function handleCancel() {
    callbacks.hideCreateChildObjective();
  }

  async function onUpdateCompleted() {
    setLoading(false);
    form.reset();
    forceRichTextEditorRerender();
  }

  async function onUpdateError() {
    setLoading(false);
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  function forceRichTextEditorRerender() {
    setRemovedFromDom(true);
    setTimeout(() => {
      setRemovedFromDom(false);
      form.setFocus("title");
    }, 1);
  }

  return (
    <form>
      <div className="shadow-md m-3 box-border grid w-[calc(100%-16px)] rounded border-t-[0.5px] p-2">
        {/* Header */}

        {/* Main Part */}
        <div className="mx-auto w-full">
          <TextareaAutosize
            autoFocus
            id="title"
            defaultValue={"Neues Ziel"}
            placeholder="Name des Ziels"
            className="font-base mt-3 w-full resize-none appearance-none overflow-hidden bg-transparent px-3 py-0 text-sm focus:outline-none"
            {...form.register("title")}
          />
          {!removedFromDom && (
            <RichTextEditor onChange={onRichTextEditorChange} hideFloatingMenu={false} className="min-h-[4rem]" />
          )}
        </div>
        <div className="border-b"></div>
        <div className="flex flex-row items-center justify-end gap-4 pt-2">
          <Button type="button" variant="ghost" size="sm" onClick={() => handleCancel()}>
            Abbrechen
          </Button>
          <Button
            type="button"
            variant="outline"
            size="sm"
            className="shadow-md"
            onClick={() => handleCreateObjective()}
          >
            Speichern
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ObjectiveCreateChildForm;
