import { FC, useContext } from "react";

import { cn } from "@/lib/utils";

import { RichTextToolbarContext } from "./useToolbarContext";
import { ToolbarCommandType } from "./ToolbarCommands";

import { MenubarMenu, MenubarTrigger } from "@/components/_ui/menubar";

export interface ToolbarButtonProps {
  command: ToolbarCommandType;
}

export const ToolbarButton: FC<ToolbarButtonProps> = ({ command }) => {
  const { editor, selection } = useContext(RichTextToolbarContext);

  function clickHandler() {
    if (!editor) {
      return;
    }
    command.execute(editor);
  }

  function isActive() {
    if (!selection) {
      return false;
    }
    return command.isActive(selection);
  }

  return (
    <MenubarMenu>
      <MenubarTrigger
        onClick={() => {
          clickHandler();
        }}
        className={cn(isActive() ? "bg-muted" : "", "h-8 px-3 py-3 text-xs ")}
      >
        {command.element}
      </MenubarTrigger>
    </MenubarMenu>
  );
};
