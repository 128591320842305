import { FC, useMemo } from "react";

import { cn } from "@/lib/utils";

import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/_ui/hover-card";

import { KeyResultProgressView } from "@/routes/workspace/objectives/:id/objective-sheet/key-result/key-result-progress-view";

import { Row } from "@tanstack/react-table";
import { Objective } from "@/components/_domain/objective/models";

interface ObjectiveListCellProgressProps {
  row: Row<Objective>;
  className?: string;
}

export const ObjectiveListCellProgress: FC<ObjectiveListCellProgressProps> = ({ row, className }) => {
  const keyResult = row.original.keyResult;

  const progress = useMemo(() => {
    if (!keyResult) {
      return 0;
    }

    if (keyResult.currentValue == keyResult.initialValue) {
      return 0;
    }

    if (keyResult.currentValue == keyResult.targetValue) {
      return 1;
    }

    const divisor = keyResult.initialValue - keyResult.targetValue;

    if (divisor == 0) {
      return 0;
    }

    return (keyResult.initialValue - keyResult.currentValue) / divisor;
  }, [keyResult]);

  if (!keyResult) {
    return <></>;
  }

  return (
    <HoverCard>
      <HoverCardTrigger>
        <div className={cn(["text-xs"], className)}>
          {Number(progress).toLocaleString(undefined, { style: "percent", minimumFractionDigits: 2 })}
        </div>
      </HoverCardTrigger>
      <HoverCardContent side="top" className="w-auto p-2">
        <div className="w-80">
          <KeyResultProgressView keyResult={keyResult} />
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
