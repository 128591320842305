import { FC } from "react";

import { useUserInWorkspace } from "@/hooks/api/common";
import { UserHover } from "@/components/_domain/user";
import { Cross2Icon } from "@radix-ui/react-icons";

interface UserFragment {
  id: string;
}

export interface TeamMemberSelectChipProps {
  user: {
    id: string;
  };
  workspace: {
    id: string;
  };
  onRemove: (user: UserFragment) => void;
}

export const TeamMemberSelectChip: FC<TeamMemberSelectChipProps> = ({ onRemove, ...props }) => {
  const { user } = useUserInWorkspace({ workspaceId: props.workspace.id, userId: props.user.id });

  if (!user) {
    return <></>;
  }

  function triggerRemove() {
    onRemove(props.user);
  }

  return (
    <div className="w-min overflow-hidden rounded-full border ">
      <div className="flex h-5 w-min flex-row items-center gap-1">
        <UserHover user={user} workspace={props.workspace} type="avatar+username" className="border-0 text-sm" />

        <div className="flex h-5 flex-row items-center px-0.5  hover:cursor-pointer " onClick={triggerRemove}>
          <Cross2Icon className="h-3 w-3" />
        </div>
      </div>
    </div>
  );
};
