import { FC } from "react";

import { Button } from "@/components/_ui/button";
import {
  useCreateStatusInstance,
  useDeleteStatusInstance,
  useGetObjective,
  useGetStatusInstance,
  useUpdateStatusInstance,
} from "@/hooks/api/common";
import { StatusSelectDropdown, StatusViewOptional } from "@/components/_domain/status";

interface ObjectiveStatusViewProps {
  objective: {
    id: string;
  };
  workspace: {
    id: string;
  };
}

export const ObjectiveStatusView: FC<ObjectiveStatusViewProps> = ({ workspace, ...props }) => {
  const { objective } = useGetObjective(props.objective);
  const { statusInstance } = useGetStatusInstance({ id: objective?.statusInstance?.id });
  const { updateStatusInstance } = useUpdateStatusInstance({}); // always prefer this one
  const { createStatusInstance } = useCreateStatusInstance({ ownerId: props.objective.id, ownerType: "OBJECTIVE" });
  const { deleteStatusInstance } = useDeleteStatusInstance({ ownerId: props.objective.id, ownerType: "OBJECTIVE" });

  function assignStatus(status: { id: string }) {
    if (!objective) {
      return;
    }

    if (statusInstance) {
      updateStatusInstance({
        variables: {
          input: {
            id: statusInstance.id,
            statusId: status.id,
          },
        },
      });
    } else {
      createStatusInstance({
        variables: {
          input: {
            statusId: status.id,
            ownerId: props.objective.id,
            ownerType: "OBJECTIVE",
            teamId: objective.teamId,
          },
        },
      });
    }
  }

  function removeStatus() {
    if (!statusInstance) {
      return;
    }
    deleteStatusInstance({
      variables: { input: { id: statusInstance.id } },
    });
  }

  const handleOnStatusSelect = (status: { id: string } | null) => {
    if (!status) {
      return removeStatus();
    }
    assignStatus(status);
  };

  if (!objective) {
    return <></>;
  }

  return (
    <div className="mb-5 flex w-full flex-row items-start gap-2 overflow-x-hidden">
      <div className="mt-2 w-24 shrink-0 font-medium">Status</div>
      <div className="grow">
        <StatusSelectDropdown
          team={{ id: objective.teamId }}
          onSelect={handleOnStatusSelect}
          selected={statusInstance}
          asChild
        >
          <Button
            variant="ghost"
            size="sm"
            className="w-full justify-start overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          >
            <StatusViewOptional status={statusInstance ? { id: statusInstance.statusId } : null} />
          </Button>
        </StatusSelectDropdown>
      </div>
    </div>
  );
};

export default ObjectiveStatusView;
