import { FC, PropsWithChildren, useState } from "react";

import { cn } from "@/lib/utils";

import { Checkbox } from "@/components/_ui/checkbox";
import IconCircleSolid from "@/components/_icons/IconCircleSolid";

import { useCreateLabelInstance, useDeleteLabelInstance, useGetLabel } from "@/hooks/api/common";

export interface LabelItemProps extends PropsWithChildren {
  label: {
    id: string;
  };
  owner: {
    id: string;
    type: "OBJECTIVE";
  };
  workspace: {
    id: string;
  };
  labelInstance?: {
    id: string;
  };
}

export const LabelItem: FC<LabelItemProps> = ({ workspace, owner, labelInstance, ...props }) => {
  const [selected, setSelected] = useState<boolean>(labelInstance ? true : false);

  const { label } = useGetLabel({ id: props.label.id });
  const { createLabelInstance } = useCreateLabelInstance({ ownerId: owner.id, ownerType: owner.type });
  const { deleteLabelInstance } = useDeleteLabelInstance({ ownerId: owner.id, ownerType: owner.type });

  async function handleCheckBoxSelect(e: React.MouseEvent) {
    e.stopPropagation();

    const hasBeenSelected = selected;
    setSelected(!selected);

    if (!hasBeenSelected) {
      await createLabelInstance({
        variables: {
          input: { labelId: props.label.id, ownerId: owner.id, ownerType: owner.type, workspaceId: workspace.id },
        },
      });
    }
    if (hasBeenSelected) {
      if (!labelInstance) {
        return;
      }
      await deleteLabelInstance({
        variables: { input: { id: labelInstance.id } },
      });
    }
  }

  if (!label) {
    return <></>;
  }

  return (
    <div className="group mx-0.5 mb-0.5 flex w-full flex-row items-center gap-2 rounded p-2 text-sm">
      <div
        className={cn([
          "w- flex flex-row items-center opacity-0 group-hover:opacity-100",
          selected ? "opacity-100" : "",
        ])}
        onClick={handleCheckBoxSelect}
      >
        <Checkbox
          className="rounded-md border-foreground/20 hover:border-foreground/40"
          checked={selected}
          onClick={handleCheckBoxSelect}
        />
      </div>
      <div>
        <IconCircleSolid className="h-2.5 w-2.5" style={{ color: label.color }} />
      </div>
      <div>{label.title}</div>
    </div>
  );
};

export default LabelItem;
