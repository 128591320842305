import { useMutation } from "@apollo/client";
import { deleteObjectiveMutationDocument } from "@/graphql/common";
import { updateCacheWithDeletedObjective } from "./_utils";

type ObjectiveFragment = {
  id: string;
};

export interface UseDeleteObjectiveProps {
  teamId: string;
  onCompleted?: (o: ObjectiveFragment | undefined) => void;
  onError?: (e: Error) => void;
}

export const useDeleteObjective = ({ onCompleted, onError }: UseDeleteObjectiveProps) => {
  const [deleteObjective] = useMutation(deleteObjectiveMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (_cache, { data }) => {
      const objective = data?.deleteObjective;
      if (!objective) {
        return;
      }
      updateCacheWithDeletedObjective(objective);
    },
    // refetchQueries: [
    //   {
    //     query: getObjectivesQueryDocument,
    //     variables: { input: { teamIdList: [teamId] } },
    //   },
    // ],
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const id = o?.deleteObjective?.id;

    if (onCompleted) {
      if (id) {
        onCompleted({ id: id });
      } else {
        onCompleted(undefined);
      }
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deleteObjective,
  };
};
