import { useMutation } from "@apollo/client";
import { createStatusMutationDocument, getStatusListForTeamQueryDocument } from "@/graphql/common";

export interface UseCreateStatusProps {
  teamId: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useCreateStatus = ({ teamId, onCompleted, onError }: UseCreateStatusProps) => {
  const [createStatus] = useMutation(createStatusMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: getStatusListForTeamQueryDocument,
        variables: { input: { teamId: teamId } },
      },
    ],
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createStatus,
  };
};
