import { createContext, useContext, ReactNode, FC, useState } from "react";

import { ObjectiveListViewType, ObjectiveListViewTypes } from "../objective-list-overview/objective-list-view-type";

interface ObjectiveListDisplayOptionsStateType {
  selectedObjectiveListViewType: ObjectiveListViewType;
  setSelectedObjectiveListViewType: (objectiveListViewType: ObjectiveListViewType) => void;
}

const ObjectiveListDisplayOptionsState = createContext<ObjectiveListDisplayOptionsStateType | undefined>(undefined);

// Define a type for the props that the provider will accept
interface ObjectiveListDisplayOptionsStateProviderProps {
  children: ReactNode;
  objectiveListViewType?: ObjectiveListViewType;
}

// Create the provider component
export const ObjectiveListDisplayOptionsStateProvider: FC<ObjectiveListDisplayOptionsStateProviderProps> = ({
  children,
  ...props
}) => {
  const [selectedObjectiveListViewType, setSelectedObjectiveListViewType] = useState<ObjectiveListViewType>(
    props.objectiveListViewType ? props.objectiveListViewType : ObjectiveListViewTypes.LIST_VIEW,
  );

  return (
    <ObjectiveListDisplayOptionsState.Provider
      value={{ selectedObjectiveListViewType, setSelectedObjectiveListViewType }}
    >
      {children}
    </ObjectiveListDisplayOptionsState.Provider>
  );
};

// Define the useObjectiveListState hook
export const useObjectiveListDisplayOptions = (): ObjectiveListDisplayOptionsStateType => {
  const context = useContext(ObjectiveListDisplayOptionsState);

  if (!context) {
    throw new Error("useObjectiveListDisplayOptions must be used within a ObjectiveListDisplayOptionsStateProvider");
  }

  return context;
};
