import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import NotFound from "../../NotFound";

import { useQuery } from "@apollo/client";
import { findWorkspaceInviteQueryDocument } from "@/graphql/common";
import AcceptInviteCard from "./accept-invite-card";

export default function Page() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  if (!token) {
    return <NotFound />;
  }

  return <IsValidTokenInnerPage token={token} />;
}

export type WorkspaceProp = {
  id: string;
  name: string;
  workspaceUrl: string;
  logo?: string | null | undefined;
};

export type UserProp = {
  id: string;
  email: string;
  fullname?: string | null | undefined;
  username?: string | null | undefined;
  profilePicture?: string | null | undefined;
  activated: boolean;
};

export interface IsValidTokenInnerPageProps extends PropsWithChildren {
  token: string;
}

const IsValidTokenInnerPage: FC<IsValidTokenInnerPageProps> = ({ token }) => {
  const { data, loading } = useQuery(findWorkspaceInviteQueryDocument, { variables: { input: token } });
  const [workspace, setWorkspace] = useState<WorkspaceProp | null>(null);
  const [user, setUser] = useState<UserProp | null>(null);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (!data.findWorkspaceInvite.user) {
      return;
    }
    if (!data.findWorkspaceInvite.workspace) {
      return;
    }

    setWorkspace({
      ...data.findWorkspaceInvite.workspace,
    });
    setUser({
      ...data.findWorkspaceInvite.user,
    });
  }, [data]);

  if (loading) {
    return <></>;
  }

  if (!user || !workspace) {
    return <NotFound />;
  }

  return <InnerPage token={token} workspace={workspace} user={user} />;
};

export interface InnerPageProps extends PropsWithChildren {
  token: string;
  workspace: WorkspaceProp;
  user: UserProp;
}

const InnerPage: FC<InnerPageProps> = ({ token, workspace, user }) => {
  useEffect(() => {}, []);

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-background text-foreground">
      <AcceptInviteCard token={token} workspace={workspace} user={user}></AcceptInviteCard>
    </div>
  );
};
