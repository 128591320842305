import { LucideProps } from "lucide-react";

const Icon = ({ ...props }: LucideProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="priority-high"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      height="16"
      width="16"
      {...props}
    >
      <rect fill="currentColor" x="1" y="8" width="3" height="6" rx="1"></rect>
      <rect fill="currentColor" x="6" y="5" width="3" height="9" rx="1"></rect>
      <rect fill="currentColor" x="11" y="2" width="3" height="12" rx="1"></rect>
    </svg>
  );
};

export default Icon;
