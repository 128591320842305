import { graphql } from "../../generated/gql";

export const createObjectiveMutationDocument = graphql(/* GraphQL */ `
  mutation createObjective($input: CreateObjectiveInput!) {
    createObjective(createObjectiveInput: $input) {
      id
      teamId
      title
      properties
      parentId
      parent {
        id
        teamId
        title
        properties
        parentId
      }
      children {
        id
        teamId
        title
        properties
        parentId
      }
      keyResult {
        id
        objectiveId
        unitId
        title
        description
        initialValue
        targetValue
        currentValue
        startDate
        targetDate
        type
        properties
        unit {
          id
          title
          symbol
        }
      }
      progressSnapshotList {
        id
        objectiveId
        keyResultId
        userId
        value
        health
        description
        snapshotDate
        properties
      }
      assignee {
        id
        userId
        teamId
        ownerId
        ownerType
        user {
          id
          email
          username
          fullname
          profilePicture
        }
      }
      priorityInstance {
        id
        priorityId
        ownerId
        ownerType
        teamId
        priority {
          id
          teamId
          name
          color
          type
        }
      }
      statusInstance {
        id
        statusId
        ownerId
        ownerType
        teamId
        status {
          id
          teamId
          name
          color
          type
        }
      }
      labelInstanceList {
        id
        labelId
        labelGroupId
        ownerId
        ownerType
        workspaceId
        teamId
        label {
          id
          title
          color
          workspaceId
          teamId
          labelGroupId
        }
      }
    }
  }
`);

export const updateObjectiveMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateObjective($input: UpdateObjectiveInput!) {
    updateObjective(updateObjectiveInput: $input) {
      id
      teamId
      title
      properties
      parentId
    }
  }
`);

export const deleteObjectiveMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteObjective($input: DeleteObjectiveInput!) {
    deleteObjective(deleteObjectiveInput: $input) {
      id
      teamId
      title
      properties
      parentId
    }
  }
`);
