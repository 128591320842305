import { graphql } from "../../generated/gql";

export const createProgressSnapshotMutationDocument = graphql(/* GraphQL */ `
  mutation createProgressSnapshot($input: CreateProgressSnapshotInput!) {
    createProgressSnapshot(createProgressSnapshotInput: $input) {
      id
      keyResultId
      userId
      value
      health
      description
      snapshotDate
      properties
    }
  }
`);

export const deleteProgressSnapshotMutationDocument = graphql(/* GraphQL */ `
  mutation deleteProgressSnapshot($input: DeleteProgressSnapshotInput!) {
    deleteProgressSnapshot(deleteProgressSnapshotInput: $input) {
      id
      keyResultId
      userId
      value
      health
      description
      snapshotDate
      properties
    }
  }
`);
