import { graphql } from "../../generated/gql";

export const getAssigneeQueryDocument = graphql(/* GraphQL */ `
  query Assignee($input: GetAssigneeInput!) {
    assignee(getAssigneeInput: $input) {
      id
      userId
      teamId
      ownerId
      ownerType
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

// this query returns a AssigneeQueryResult which has a nullable assignee
export const getAssigneeByOwnerQueryDocument = graphql(/* GraphQL */ `
  query AssigneeByOwner($input: GetAssigneesByOwnerInput!) {
    assigneeByOwner(getAssigneesByOwnerInput: $input) {
      id
      userId
      teamId
      ownerId
      ownerType
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);
