import { Reference, StoreObject } from "@apollo/client";

export function mergeArraysByField<T extends StoreObject | Reference>(
  existing: T[],
  incoming: T[],
  fieldname: string,
  readField: (fieldname: string, obj: T) => string,
  mergeObjects: (existing: T, incoming: T) => T,
): T[] {
  const merged = existing ? existing.slice(0) : [];

  // First, build an index over the existing array
  const idToIndex: Record<string, number> = Object.create(null);
  if (existing) {
    existing.forEach((item, index) => {
      const id = readField(fieldname, item);
      if (id) {
        idToIndex[id] = index;
      }
    });
  }

  // Second, merge the incoming array into the existing array
  incoming.forEach((item) => {
    const id = readField(fieldname, item);
    if (id) {
      // Merge the new item data with the existing item data.
      const index = idToIndex[id];
      if (typeof index === "number") {
        // Merge the new item data with the existing item data.
        merged[index] = mergeObjects(merged[index], item);
      } else {
        // First time we've seen this item in this array.
        idToIndex[id] = merged.length;
        merged.push(item);
      }
    }
  });
  return merged;
}
