import { graphql } from "../../generated/gql";

export const createPriorityMutationDocument = graphql(/* GraphQL */ `
  mutation createPriority($input: CreatePriorityInput!) {
    createPriority(createPriorityInput: $input) {
      id
      teamId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);

export const updatePriorityMutationDocument = graphql(/* GraphQL */ `
  mutation UpdatePriority($input: UpdatePriorityInput!) {
    updatePriority(updatePriorityInput: $input) {
      id
      teamId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);

export const deletePriorityMutationDocument = graphql(/* GraphQL */ `
  mutation DeletePriority($input: DeletePriorityInput!) {
    deletePriority(deletePriorityInput: $input) {
      id
      teamId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);
