import { FC, useMemo, useState } from "react";

import { Button } from "@/components/_ui/button";
import IconPen from "@/components/_icons/IconPen";

import { useCurrentUser, useGetStatus, useTeamById } from "@/hooks/api/common";

import { StatusIcon } from "@/components/_domain/status";
import { InstanceCountLabel } from "./instance-count-label";
import { StatusButtonDelete } from "./actions/status-button-delete";
import { StatusFormEdit } from "./actions/status-form-edit";

export interface StatusTableRowProps {
  team: {
    id: string;
  };
  status: {
    id: string;
  };
  hideDeleteAction?: boolean;
  className?: string;
}

export const StatusTableRow: FC<StatusTableRowProps> = ({ className, team, hideDeleteAction, ...props }) => {
  const [showEditMode, setShowEditMode] = useState(false);
  const { status } = useGetStatus({ id: props.status.id });

  const { currentUser } = useCurrentUser();
  const { userHasEditRights } = useTeamById({ id: team.id });

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    return userHasEditRights(currentUser.id);
  }, [currentUser, userHasEditRights]);

  function handleEditStatusButtonPressed() {
    setShowEditMode(true);
  }

  function onSuccess() {
    setShowEditMode(false);
  }

  function onCanceled() {
    setShowEditMode(false);
  }

  if (!status) {
    return <></>;
  }

  if (showEditMode) {
    return (
      <div className="my-1 flex flex-row items-center justify-between gap-2 rounded border bg-muted p-2">
        <StatusFormEdit status={status} onSuccess={onSuccess} onCanceled={onCanceled} />
      </div>
    );
  }

  return (
    <div key={status.id} className="mb-1 rounded border">
      <div className="m-2 flex flex-row items-center gap-2">
        <div>
          <StatusIcon status={{ type: status.type, color: status.color }} />
        </div>
        <div>{status.name}</div>
        <InstanceCountLabel instances={status.instances} className="text-muted-foreground" />
        <div className="grow"></div>
        {hasEditRights && (
          <>
            <div>
              <Button
                variant="ghost"
                size="icon"
                className="text-muted-foreground hover:text-foreground/80"
                onClick={handleEditStatusButtonPressed}
              >
                <IconPen className="h-3 w-3" />
              </Button>
            </div>
            {!hideDeleteAction && <StatusButtonDelete team={team} status={status} />}
          </>
        )}
      </div>
    </div>
  );
};

export default StatusTableRow;
