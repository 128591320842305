import { FC } from "react";

import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/_ui/hover-card";

import { format } from "date-fns";
import { de } from "date-fns/locale";
import { cn } from "@/lib/utils";

import { Row } from "@tanstack/react-table";
import { Objective } from "@/components/_domain/objective/models";

interface ObjectiveListCellStartDateProps {
  row: Row<Objective>;
  className?: string;
}

export const ObjectiveListCellStartDate: FC<ObjectiveListCellStartDateProps> = ({ row, className }) => {
  const keyResult = row.original.keyResult;

  if (!keyResult) {
    return <></>;
  }

  return (
    <HoverCard>
      <HoverCardTrigger>
        <div className={cn(["whitespace-nowrap text-xs"], className)}>{`${format(
          new Date(keyResult.startDate),
          "LLL, yyyy",
          {
            locale: de,
          },
        )}`}</div>
      </HoverCardTrigger>
      <HoverCardContent side="top" className="w-auto p-2">
        <div className="pt-1 text-xs text-muted-foreground">
          {`Startdatum: ${format(new Date(keyResult.startDate), "PPP", { locale: de })}`}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
