import { FC, useMemo } from "react";

import { useGetLabelGroupListForTeam } from "@/hooks/api/common";
import { TeamLabelGroupListItem } from "./team-label-group-list-item";

export interface TeamLabelGroupListProps {
  team: {
    id: string;
  };
}

export const TeamLabelGroupList: FC<TeamLabelGroupListProps> = ({ team }) => {
  const { labelGroupList } = useGetLabelGroupListForTeam({ teamId: team.id });

  const sortedLabelGrouplist = useMemo(() => {
    return labelGroupList.slice().sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a.title.localeCompare(b.title);
    });
  }, [labelGroupList]);

  return (
    <>
      {sortedLabelGrouplist.map((group) => (
        <TeamLabelGroupListItem key={group.id} labelGroup={group} team={team} />
      ))}
    </>
  );
};
