import { LucideProps } from "lucide-react";

const Icon = ({ ...props }: LucideProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="moon"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      height="1.25em"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.3907 10.1063C8.11878 10.1063 6.02726 8.05327 6.02726 4.77878C6.02726 4.09975 6.19047 3.13453 6.40679 2.62716C6.46202 2.49235 6.46974 2.40113 6.46974 2.34328C6.46974 2.18506 6.34882 2 6.11494 2C6.05067 2 5.91076 2.01413 5.76953 2.06806C3.53361 2.9611 2.03345 5.36441 2.03345 7.90669C2.03345 11.4585 4.74167 14 8.28971 14C10.8935 14 13.1548 12.4214 13.9062 10.4502C13.9538 10.3102 13.9666 10.169 13.9666 10.1112C13.9666 9.8875 13.7841 9.73961 13.6117 9.73961C13.5373 9.73961 13.4692 9.75375 13.355 9.78712C12.8866 9.94023 12.1361 10.1063 11.3907 10.1063Z"
      />
    </svg>
  );
};

export default Icon;
