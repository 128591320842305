import { graphql } from "../../generated/gql";

export const getLabelInstancesQueryDocument = graphql(/* GraphQL */ `
  query GetLabelInstances($input: GetLabelInstancesInput!) {
    labelInstances(getLabelInstancesInput: $input) {
      id
      labelId
      labelGroupId
      ownerId
      ownerType
      workspaceId
      teamId
      label {
        id
        title
        color
        workspaceId
        teamId
        labelGroupId
      }
    }
  }
`);

export const getLabelInstanceQueryDocument = graphql(/* GraphQL */ `
  query GetLabelInstance($input: GetLabelInstanceInput!) {
    labelInstance(getLabelInstanceInput: $input) {
      id
      labelId
      labelGroupId
      ownerId
      ownerType
      workspaceId
      teamId
      label {
        id
        title
        color
        workspaceId
        teamId
        labelGroupId
      }
    }
  }
`);
