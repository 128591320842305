import { useMutation } from "@apollo/client";
import {
  getKeyResultQueryDocument,
  getObjectiveQueryDocument,
  getProgressSnapshotsQueryDocument,
  getProgressSnapshotsForKeyResultQueryDocument,
} from "@/graphql/common";
import { createProgressSnapshotMutationDocument } from "@/graphql/common/mutations/progress-snapshot.mutations";
import { useMemo } from "react";

export interface UseCreateProgressSnapshotProps {
  objectiveId: string;
  keyResultId?: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useCreateProgressSnapshot = ({
  objectiveId,
  keyResultId,
  onCompleted,
  onError,
}: UseCreateProgressSnapshotProps) => {
  const refetchQueries = useMemo(() => {
    if (keyResultId) {
      return [
        {
          query: getObjectiveQueryDocument,
          variables: { input: { id: objectiveId } },
        },
        {
          query: getProgressSnapshotsQueryDocument,
          variables: { input: { objectiveId: objectiveId } },
        },
        {
          query: getKeyResultQueryDocument,
          variables: { input: { id: keyResultId } },
        },
        {
          query: getProgressSnapshotsForKeyResultQueryDocument,
          variables: { input: { keyResultId: keyResultId } },
        },
      ];
    }

    return [
      {
        query: getObjectiveQueryDocument,
        variables: { input: { id: objectiveId } },
      },
      {
        query: getProgressSnapshotsQueryDocument,
        variables: { input: { objectiveId: objectiveId } },
      },
    ];
  }, [objectiveId, keyResultId]);

  const [createProgressSnapshot] = useMutation(createProgressSnapshotMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: refetchQueries,
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createProgressSnapshot,
  };
};
