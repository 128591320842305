import { FC } from "react";

interface ObjectiveOverviewTabProps {
  workspace: {
    id: string;
  };
  objective: {
    id: string;
  };
}

export const ObjectiveOverviewTab: FC<ObjectiveOverviewTabProps> = ({}) => {
  return <main className="relative place-items-stretch overflow-auto">Overview</main>;
};
