import { Navigate } from "react-router-dom";

// AUTH STUFF
import LoginRoute from "./auth/login/route.tsx";
import ForgotRoute from "./auth/forgot/route.tsx";
import ResetRoute from "./auth/reset/route.tsx";
import InviteWorkspaceRoute from "./auth/invite-workspace/route.tsx";
import InviteTeamRoute from "./auth/invite-team/route.tsx";
import DownloadNotFoundRoute from "./misc/download/route.tsx"

import WorkspaceIndexRoute from "./workspace/route.tsx";
import TeamsIndexRoute from "./workspace/teams/route.tsx";
import TeamsRoute from "./workspace/teams/objectives/route.tsx";

import MyObjectivesRoute from "./workspace/my-objectives/route.tsx";

import StrategicObjectivesRoute from "./workspace/objectives/route.tsx";
import StrategicGoalsListRoute from "./workspace/teams/objectives/all/route.tsx";
import StrategicGoalsDetailRoute from "./workspace/objectives/:id/route.tsx";

import SettingsIndexRoute from "./settings/route.tsx";
import SettingsProfileRoute from "./settings/account/profile/route.tsx";
import SettingsSecurityRoute from "./settings/account/security/route.tsx";
import SettingsWorkspaceGeneralRoute from "./settings/workspace/general/route.tsx";
import SettingsWorkspaceMembersRoute from "./settings/workspace/members/route.tsx";
import SettingsWorkspaceLabelsRoute from "./settings/workspace/labels/route.tsx";

import SettingsNewTeamRoute from "./settings/new-team/route.tsx";
import SettingsTeamsAllRoute from "./settings/teams/route.tsx";
import SettingsTeamsGeneralRoute from "./settings/teams/:id/general/route.tsx";
import SettingsTeamsMembersRoute from "./settings/teams/:id/members/route.tsx";
import SettingsTeamsLabelsRoute from "./settings/teams/:id/labels/route.tsx";
import SettingsTeamsPriorityRoute from "./settings/teams/:id/priority/route.tsx";
import SettingsTeamsStatusRoute from "./settings/teams/:id/status/route.tsx";
import SettingsTeamsUnitsRoute from "./settings/teams/:id/units/route.tsx";

import AdminIndexRoute from "./admin/route.tsx";
import AdminWorkspaceListRoute from "./admin/workspace/route.tsx";
import AdminWorkspaceDetailRoute from "./admin/workspace/:id/route.tsx";

import NotFound from "./NotFound.tsx";
import WorkspaceInitialization from "./WorkspaceInitialization.tsx";

export const routes = (isAuthenticated: boolean) => [
  { path: "/", element: isAuthenticated ? <WorkspaceInitialization /> : <Navigate to="/login" /> },
  { path: "/download", ...DownloadNotFoundRoute },
  { ...LoginRoute, path: "/login", element: isAuthenticated ? <Navigate to="/" replace /> : LoginRoute.element },
  {
    ...ForgotRoute,
    path: "/forgot-password",
    element: isAuthenticated ? <Navigate to="/" replace /> : ForgotRoute.element,
  },
  {
    ...ResetRoute,
    path: "/reset-password",
    element: isAuthenticated ? <Navigate to="/" replace /> : ResetRoute.element,
  },
  { ...InviteWorkspaceRoute, path: "/invitation/workspace", element: InviteWorkspaceRoute.element },
  { ...InviteTeamRoute, path: "/invitation/team", element: InviteTeamRoute.element },
  {
    ...AdminIndexRoute,
    path: "/admin",
    element: AdminIndexRoute.element,
    children: [
      { path: "", element: <Navigate to="workspaces" replace /> },
      { path: "workspaces", ...AdminWorkspaceListRoute },
      { path: "workspaces/:workspaceId", ...AdminWorkspaceDetailRoute },
      // Render the not found within the sidebar layout so the user can recover quicker
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    ...SettingsIndexRoute,
    path: "/:workspaceUrl/settings",
    element: !isAuthenticated ? <Navigate to="/login" /> : SettingsIndexRoute.element,
    children: !isAuthenticated
      ? []
      : [
        { path: "", element: <Navigate to="account/profile" replace /> },
        { path: "account/profile", ...SettingsProfileRoute },
        { path: "account/security", ...SettingsSecurityRoute },
        { path: "workspace", element: <Navigate to="workspace/general" replace /> },
        { path: "workspace/general", ...SettingsWorkspaceGeneralRoute },
        { path: "workspace/members", ...SettingsWorkspaceMembersRoute },
        { path: "workspace/labels", ...SettingsWorkspaceLabelsRoute },
        { path: "new-team", ...SettingsNewTeamRoute },
        { path: "teams", ...SettingsTeamsAllRoute },
        {
          path: "teams/:teamId",
          children: [
            { path: "", element: <Navigate to="general" replace /> },
            { path: "general", ...SettingsTeamsGeneralRoute },
            { path: "members", ...SettingsTeamsMembersRoute },
            { path: "labels", ...SettingsTeamsLabelsRoute },
            { path: "status", ...SettingsTeamsStatusRoute },
            { path: "priority", ...SettingsTeamsPriorityRoute },
            { path: "units", ...SettingsTeamsUnitsRoute },
          ],
        },
        // Render the not found within the sidebar layout so the user can recover quicker
        { path: "*", element: <NotFound /> },
      ],
  },
  {
    path: "/:workspaceUrl",
    element: !isAuthenticated ? <Navigate to="/login" /> : WorkspaceIndexRoute.element,
    children: !isAuthenticated
      ? []
      : [
        // Todo: Fix Default path
        { path: "", element: <Navigate to="my-goals" replace /> },
        { path: "my-goals", element: MyObjectivesRoute.element },
        { path: "teams", element: TeamsIndexRoute.element },
        {
          path: "teams/:teamId",
          ...TeamsRoute,
          children: [
            { path: "", element: <Navigate to="goals" replace /> },
            { path: "goals", ...StrategicGoalsListRoute },
          ],
        },
        {
          path: "goals",
          ...StrategicObjectivesRoute,
          children: [
            // { path: "", element: <Navigate to="all" replace /> }, // Todo fix this
            // { path: "all", ...StrategicGoalsListRoute }, // Todo: implement this
            { path: ":id", ...StrategicGoalsDetailRoute },
            { path: ":id/:tab", ...StrategicGoalsDetailRoute },
          ],
        },
        // Render the not found within the sidebar layout so the user can recover quicker
        { path: "*", element: <NotFound /> },
      ],
  },
  // {path: ":workspaceId/*", element: !isAuthenticated ? <Navigate to="/login"/> : <NotFound/> },
  { path: "*", element: <NotFound /> },
];

export default routes;
