import { FC } from "react";

import { Card, CardContent, CardHeader } from "@/components/_ui/card";

import AvatarWorkspace from "@/components/_domain/workspace/avatar-workspace";

import { UserProp, TeamProp, WorkspaceProp } from "./page";

export interface JoinWorkspaceFirstCardProps {
  token: string;
  workspace: WorkspaceProp;
  team: TeamProp;
  user: UserProp;
}

const JoinWorkspaceFirstCard: FC<JoinWorkspaceFirstCardProps> = ({ team, workspace, user }) => {

  return (
    <Card className="shadow-xl z-10 w-full max-w-xl overflow-hidden border p-10">
      <CardHeader>
        <div className="flex flex-col gap-4">
          <AvatarWorkspace workspace={workspace} className="h-16 w-16 text-2xl" />
          <h2 className="text-xl">
            Sie sind noch nicht Teil des Workspaces <span className="font-medium">{workspace.name}</span>.
          </h2>

        </div>
      </CardHeader>
      <CardContent>
        <div className="mb-10 mt-4 flex flex-col gap-4">
          <p className="mb-2 text-base font-light text-foreground">
            Um die Einladung in das Team <span className="font-medium">{team.name}</span> mit ihrem Account <span className="font-medium">{user.email}</span> annehmen zu können, müssen Sie zuerst dem Workspace beitreten.
          </p>
          <p className="mb-2 text-base font-light text-foreground">
            Dazu sollten Sie eine E-Mail mit einer Einladung zum Workspace erhalten haben.
          </p>
        </div>


        <div className="mt-6 w-full border-t border-solid pt-2">
          <p className="text-sm font-light text-foreground">
            Ark Climate ist eine moderne Software zur Umsetzung von Klimaschutz in fortschrittlichen Städten und
            Gemeinden.
          </p>
          <p className="text-sm font-light text-foreground mt-2">
            Erfahren Sie mehr auf{" "}
            <a href="https://ark-climate.de/" target="_blank" className="underline">ark-climate.de</a>
          </p>
        </div>


      </CardContent>
    </Card>
  );
};

export default JoinWorkspaceFirstCard;
