import { FC, useEffect, useMemo, useState } from "react";

import { cn } from "@/lib/utils";

import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";
import { Checkbox } from "@/components/_ui/checkbox";

import { FilterByTextList, FilterByTextListPayload, FilterByTextListPayloadSchema } from "../../../filter-functions";

import { useObjectiveListState } from "../../../../hooks";
import { useGetLabelListForTeamsInWorkspace } from "@/hooks/api/common";

import { LabelView } from "@/components/_domain/labels";

export interface FilterByLabelConfigurationViewProps {
  workspace: {
    id: string;
  };
  column: {
    id: string;
  };
  onFinished: () => void;
}

export const FilterByLabelConfigurationView: FC<FilterByLabelConfigurationViewProps> = ({
  workspace,
  onFinished,
  ...props
}) => {
  const columnId = props.column.id;
  const { table, columnFilters, config } = useObjectiveListState();
  const [selectedLabelTitleList, setSelectedLabelTitleList] = useState<FilterByTextList | null>(null);

  const { labelListWithoutGroup, getLabelListWithDistinctTitle } = useGetLabelListForTeamsInWorkspace({
    workspaceId: workspace.id,
    userId: config.user.id,
    teamList: config.teamList,
  });

  const labelList = useMemo(() => {
    return getLabelListWithDistinctTitle(labelListWithoutGroup);
  }, [labelListWithoutGroup, getLabelListWithDistinctTitle]);

  const column = useMemo(() => {
    return table.getColumn(columnId);
  }, [table]);

  const filterValue = useMemo(() => {
    const filterValue = column?.getFilterValue();

    const safePayload = FilterByTextListPayloadSchema.safeParse(filterValue);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [column, columnFilters]);

  useEffect(() => {
    if (!filterValue) {
      return;
    }
    setSelectedLabelTitleList(filterValue.textList);
  }, []);

  useEffect(() => {
    if (!selectedLabelTitleList) {
      return;
    }

    if (selectedLabelTitleList.length == 0) {
      clearFilter();
      return;
    }

    setFilter({
      type: "filter_by_text_list",
      mode: filterValue?.mode ?? "included",
      textList: selectedLabelTitleList,
    });
  }, [selectedLabelTitleList]);

  const setFilter = (payload: FilterByTextListPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id != column.id));
  };

  const handleSelect = (labelTitle: string | null, shouldFinish?: boolean) => {
    const selectedIdList = selectedLabelTitleList ?? [];
    const updatedIdList = selectedIdList.slice().filter((title) => title != labelTitle);
    setSelectedLabelTitleList([...updatedIdList, labelTitle]);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  const handleDeselect = (labelTitle: string | null, shouldFinish?: boolean) => {
    const selectedIdList = selectedLabelTitleList ?? [];
    const updatedIdList = selectedIdList.slice().filter((id) => id != labelTitle);

    setSelectedLabelTitleList(updatedIdList);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  return (
    <Command>
      <CommandInput placeholder={"Kategorie"} />
      <CommandList>
        <CommandGroup>
          {[null].map((_) => {
            const isSelected = !!selectedLabelTitleList && !!selectedLabelTitleList.includes(null);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={null}
                value={"Nicht zugewiesen"}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(null, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(null);
                  }}
                />
                <LabelView label={null} />
              </CommandItem>
            );
          })}

          {labelList.map((label) => {
            const isSelected =
              !!selectedLabelTitleList && !!selectedLabelTitleList.find((title) => title === label.title);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={label.id}
                value={label.id}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(label.title, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(label.title);
                  }}
                />
                <LabelView label={label} />
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
