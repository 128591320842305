import { FC, useMemo } from "react";

import { cn, groupBy } from "@/lib/utils";

import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/_ui/hover-card";
import { Column, Row } from "@tanstack/react-table";

import { LabelView } from "@/components/_domain/labels";
import { Objective } from "@/components/_domain/objective/models";

interface ObjectiveListCellLabelGroupCellProps {
  row: Row<Objective>;
  column: Column<Objective>;
  className?: string;
}

export const ObjectiveListCellLabelGroupCell: FC<ObjectiveListCellLabelGroupCellProps> = ({
  row,
  column,
  className,
}) => {
  const labelGroupId = useMemo(() => {
    return column.id.replace("label_group_", "");
  }, [column]);

  const labelInstanceListGroupedByGroupId = useMemo(() => {
    return groupBy(row.original.labelInstanceList, (labelInstance) => {
      return labelInstance.label?.labelGroupId;
    });
  }, [row.original.labelInstanceList]);

  const labelInstanceListForGroup = useMemo(() => {
    return labelInstanceListGroupedByGroupId.get(labelGroupId) ?? [];
  }, [labelInstanceListGroupedByGroupId, column.id]);

  if (!labelInstanceListForGroup.length) {
    return <></>;
  }

  if (labelInstanceListForGroup.length == 1) {
    return (
      <div className="w-min rounded-full border-[0.5px] bg-background px-1.5 py-0.5">
        <LabelView label={{ id: labelInstanceListForGroup[0].labelId }} />
      </div>
    );
  }

  return (
    <HoverCard>
      <HoverCardTrigger>
        <div
          className={cn(
            [
              "flex w-min shrink-0 flex-row items-center gap-1 rounded-full border-[0.5px] bg-background px-1.5 py-0.5 ",
            ],
            className,
          )}
        >
          <div className="flex flex-row items-center ">
            {labelInstanceListForGroup.map((labelInstance) => {
              return (
                <LabelView
                  key={labelInstance.labelId}
                  label={{ id: labelInstance.labelId }}
                  logoOnly={true}
                  className="-m-0.5 rounded-full border-[0.5px] border-background"
                />
              );
            })}
          </div>
          <div
            className="shrink-0 pr-1"
            style={{ marginRight: `${labelInstanceListForGroup.length * 0.125}rem` }}
          >{`${labelInstanceListForGroup.length} Kategorien`}</div>
        </div>
      </HoverCardTrigger>
      <HoverCardContent side="top" align="start" className="w-auto p-3">
        <div className="flex w-min shrink-0 flex-col items-start gap-2 text-sm">
          {labelInstanceListForGroup.map((labelInstance) => {
            return (
              <div key={labelInstance.id} className="rounded-full border-[0.5px] bg-background px-1.5 py-0.5">
                <LabelView label={{ id: labelInstance.labelId }} />
              </div>
            );
          })}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
