import { FC, useMemo } from "react";
import { useObjectiveListState } from "../../hooks";
import { AddAdditionalFilterButton } from "./objective-filter-select-dropdown/components/add-additional-filter-button";

export interface ObjectiveListFilterViewProps {}

export const ObjectiveListFilterView: FC<ObjectiveListFilterViewProps> = () => {
  const { table, columnFilters, fn } = useObjectiveListState();

  const columns = useMemo(() => {
    return table
      .getAllColumns()
      .slice()
      .flatMap((column) => {
        if (!column.getCanFilter()) {
          return [];
        }

        const filterValue = column.getFilterValue();
        if (!filterValue) {
          return [];
        }

        return column;
      });
  }, [table, columnFilters]);

  if (columns.length == 0) {
    return <></>;
  }

  return (
    <div className="max-w-screen relative flex h-[45px] shrink-0 items-center justify-between gap-2 border-b border-solid px-6 text-sm">
      <div className="flex flex-row items-center justify-start gap-1">
        {columns.map((column) => {
          const filterPaneView = fn.getConfigurationForColumn(column.id)?.getFilterPaneView();

          if (!filterPaneView) {
            return <></>;
          }

          return <div key={column.id}>{filterPaneView}</div>;
        })}
        <AddAdditionalFilterButton />
      </div>
      <div className="flex grow flex-row items-center justify-start"></div>
      <div className="flex flex-row items-center justify-end gap-2"></div>
    </div>
  );
};
