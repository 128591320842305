import { FC } from "react";

import { ObjectiveEventFeed } from "./objective-event-feed";

interface ObjectiveActivityFeedTabProps {
  workspace: {
    id: string;
  };
  objective: {
    id: string;
  };
}

export const ObjectiveActivityFeedTab: FC<ObjectiveActivityFeedTabProps> = (props) => {
  return (
    <main className="relative place-items-stretch overflow-auto">
      <div>Aktivität</div>

      <div>
        <ObjectiveEventFeed {...props} />
      </div>
    </main>
  );
};
