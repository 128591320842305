import { FC, useEffect, useState } from "react";

import { Button } from "@/components/_ui/button";
import { UserSelectDropdown } from "@/components/_domain/user";
import AvatarUser from "@/components/_domain/user/avatar-user";
import IconUserUnassigned from "@/components/_icons/IconUserUnassigned";

import {
  useCreateAssignee,
  useDeleteAssignee,
  useGetAssigneeByOwner,
  useGetObjective,
  useUpdateAssignee,
} from "@/hooks/api/common";

interface ObjectiveAssigneeViewProps {
  objective: {
    id: string;
  };
  workspace: {
    id: string;
  };
}

export const ObjectiveAssigneeView: FC<ObjectiveAssigneeViewProps> = ({ workspace, ...props }) => {
  const { objective } = useGetObjective({ id: props.objective.id });
  const { assignee, assignedUser } = useGetAssigneeByOwner({ ownerId: props.objective.id });

  const [initialized, setInitialized] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(assignee?.userId ?? null);

  const { createAssignee } = useCreateAssignee({
    ownerId: props.objective.id,
    ownerType: "OBJECTIVE",
    newUserId: userId ?? undefined,
    oldUserId: assignee?.userId ?? undefined,
  });
  const { updateAssignee } = useUpdateAssignee({}); // always prefer this one
  const { deleteAssignee } = useDeleteAssignee({
    ownerId: props.objective.id,
    ownerType: "OBJECTIVE",
    oldUserId: assignee?.userId ?? undefined,
  });

  function assignObjectiveToUser() {
    if (!objective) {
      return;
    }

    if (!userId) {
      return;
    }

    if (assignee) {
      updateAssignee({
        variables: {
          input: {
            id: assignee.id,
            userId: userId,
          },
        },
      });
    } else {
      createAssignee({
        variables: {
          input: {
            userId: userId,
            ownerId: props.objective.id,
            ownerType: "OBJECTIVE",
            teamId: objective.teamId,
          },
        },
      });
    }
  }

  function removeAssignedUserFromObjective() {
    if (!assignee) {
      return;
    }
    deleteAssignee({
      variables: { input: { id: assignee.id } },
    });
  }

  const handleUserSelected = (user: { id: string } | null) => {
    setInitialized(true);
    setUserId(user ? user.id : null);
  };

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (!userId) {
      removeAssignedUserFromObjective();
    } else {
      assignObjectiveToUser();
    }
  }, [userId]);

  if (!objective?.teamId) {
    return <></>;
  }

  return (
    <div className="mb-5 flex w-full flex-row items-start gap-2 overflow-x-hidden">
      <div className="mt-2 w-24 shrink-0 font-medium">Verantwortlich</div>
      <div className="grow">
        <UserSelectDropdown
          team={{ id: objective?.teamId }}
          onSelect={handleUserSelected}
          selected={assignedUser}
          asChild
        >
          <Button
            variant="ghost"
            size="sm"
            className="w-full justify-start overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          >
            {!assignedUser && (
              <>
                <IconUserUnassigned className="mr-2 h-4 w-4" />
                nicht zugewiesen
              </>
            )}
            {assignedUser && (
              <>
                <AvatarUser user={assignedUser} className="mr-2 h-4 w-4 text-xxs" />
                <span className="text-xs font-medium">
                  {assignedUser.username ?? assignedUser.fullname ?? assignedUser.email.split("@")[0]}
                </span>
              </>
            )}
          </Button>
        </UserSelectDropdown>
      </div>
    </div>
  );
};

export default ObjectiveAssigneeView;
