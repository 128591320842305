import { FC, PropsWithChildren, useMemo } from "react";

import { cn } from "@/lib/utils";

import { useToast } from "@/components/_ui/use-toast";

import { Button } from "@/components/_ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";

import IconDots from "@/components/_icons/IconDots";

import { useMutation } from "@apollo/client";
import {
  updateTeamMembershipRoleMutationDocument,
  teamMembershipsForTeamQueryDocument,
  updateTeamMembershipStatusMutationDocument,
  leaveTeamMutationDocument,
  resendTeamInviteNotificationMutationDocument,
  deleteTeamInviteMutationDocument,
} from "@/graphql/common";

import { useCurrentUser, useTeamMembershipList } from "@/hooks/api/common";
import { useCurrentWorkspace } from "@/hooks/useCurrentWorkspace";
import { useTeamMembershipDelete, useTeamMembershipUpdateStatus } from "@/hooks/api/superuser";

export interface TeamMembershipActionButtonPropsWithChildren extends PropsWithChildren {
  team: {
    id: string;
    workspaceId: string
  };
  teamMembership: {
    id: string;
  };
  disabled?: boolean;
  className?: string;
}
export const TeamMembershipActionButton: FC<TeamMembershipActionButtonPropsWithChildren> = ({
  className,
  disabled,
  ...props
}) => {
  const { toast } = useToast();
  const { currentUser, isSuperUser } = useCurrentUser()
  const { currentWorkspace } = useCurrentWorkspace();

  const { getTeamMembershipById } = useTeamMembershipList({ teamId: props.team.id });

  const teamMembership = useMemo(() => {
    return getTeamMembershipById(props.teamMembership.id);
  }, [getTeamMembershipById, props.teamMembership]);

  const { deleteTeamMembership } = useTeamMembershipDelete({
    team: props.team
  });

  const { updateStatus: updateStatusAsSuperuser } = useTeamMembershipUpdateStatus({
    team: props.team
  });

  const [updateRole] = useMutation(updateTeamMembershipRoleMutationDocument, {
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
        variant: "error",
      });
    },
    refetchQueries: [
      {
        query: teamMembershipsForTeamQueryDocument,
        variables: {
          input: props.team.id,
        },
      },
    ],
  });

  const [updateStatus] = useMutation(updateTeamMembershipStatusMutationDocument, {
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
        variant: "error",
      });
    },
    refetchQueries: [
      {
        query: teamMembershipsForTeamQueryDocument,
        variables: {
          input: props.team.id,
        },
      },
    ],
  });

  const [leaveTeam] = useMutation(leaveTeamMutationDocument, {
    onCompleted: () => {
      // Todo: fix this hack – do a complete workspace reload after leaving a team
      window.location.replace(new URL(`${window.location.origin}/${currentWorkspace.workspaceUrl}/settings/teams`));
    },
    onError: () => {
      const isAdminAndSelf = currentUser?.id == teamMembership?.userId && teamMembership?.role == "ADMIN";

      const message = isAdminAndSelf
        ? "Leider ist ein Fehler aufgetreten. Stellen Sie sicher, dass Sie nicht der einzige Nutzer mit Admin Privilegien sind, bevor Sie das Team verlassen."
        : "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.";

      toast({
        title: "Fehler",
        description: message,
        variant: "error",
      });
    },
  });

  const [resendInvitationNotification] = useMutation(resendTeamInviteNotificationMutationDocument, {
    onCompleted: () => {
      toast({
        title: "Einladung gesendet",
        description: `Wir haben eine weitere Einladung gesendet.`,
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description: "Leider ist beim Versenden der Einladung ein Fehler aufgetreten.",
        variant: "error",
      });
    },
  });

  const [revokeInvitation] = useMutation(deleteTeamInviteMutationDocument, {
    onError: () => {
      toast({
        title: "Fehler",
        description: "Leider ist beim Entfernen der Einladung ein Fehler aufgetreten.",
        variant: "error",
      });
    },
    refetchQueries: [
      {
        query: teamMembershipsForTeamQueryDocument,
        variables: {
          input: props.team.id,
        },
      },
    ],
  });

  function renderDropdownMenuContent() {
    if (currentUser && currentUser.id == teamMembership?.userId) {
      return renderDropdownContentForCurrentUser();
    }

    if (teamMembership?.status == "ACTIVE") {
      const isAdmin = teamMembership?.role == "ADMIN";
      return renderDropdownContentForActiveUser(isAdmin);
    }

    if (teamMembership?.status == "SUSPENDED") {
      const isAdmin = teamMembership?.role == "ADMIN";
      return renderDropdownContentSuspendedUser(isAdmin);
    }

    if (teamMembership?.status == "LEFT") {
      const isAdmin = teamMembership?.role == "ADMIN";
      return renderDropdownContentLeftUser(isAdmin);
    }

    if (teamMembership?.status == "PENDING") {
      return renderDropdownContentPendingUser();
    }

    return <></>;
  }

  function renderDropdownContentForCurrentUser() {
    return <DropdownMenuItem onClick={() => handleLeaveWorkspace()}>Team verlassen</DropdownMenuItem>;
  }

  function renderDropdownContentForActiveUser(isAdmin: boolean) {
    return (
      <>
        <DropdownMenuItem onClick={() => updateStatusTo("SUSPENDED")}>Account deaktivieren</DropdownMenuItem>
        {isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("USER")}>Adminrechte entziehen</DropdownMenuItem>}
        {!isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("ADMIN")}>Adminrechte vergeben</DropdownMenuItem>}
      </>
    );
  }

  function renderDropdownContentSuspendedUser(isAdmin: boolean) {
    return (
      <>
        <DropdownMenuItem onClick={() => updateStatusTo("ACTIVE")}>Account aktivieren</DropdownMenuItem>
        {isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("USER")}>Adminrechte entziehen</DropdownMenuItem>}
        {!isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("ADMIN")}>Adminrechte vergeben</DropdownMenuItem>}
      </>
    );
  }

  function renderDropdownContentLeftUser(isAdmin: boolean) {
    return (
      <>
        <DropdownMenuItem onClick={() => updateStatusTo("SUSPENDED")}>Account deaktivieren</DropdownMenuItem>
        {isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("USER")}>Adminrechte entziehen</DropdownMenuItem>}
        {!isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("ADMIN")}>Adminrechte vergeben</DropdownMenuItem>}
      </>
    );
  }

  function renderDropdownContentPendingUser() {
    return (
      <>
        <DropdownMenuItem onClick={() => handleResendNotification()}>E-mail erneut senden</DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleRevokeInvite()}>Einladung zurückziehen</DropdownMenuItem>
      </>
    );
  }

  function updateRoleTo(to: "ADMIN" | "USER") {
    if (!teamMembership) {
      return;
    }

    updateRole({
      variables: {
        input: {
          userId: teamMembership.userId,
          teamId: teamMembership.teamId,
          role: to,
        },
      },
    });
  }

  function updateStatusTo(to: "ACTIVE" | "SUSPENDED") {
    if (!teamMembership) {
      return;
    }

    if (isSuperUser) {
      updateStatusAsSuperuser({
        variables: {
          input: {
            userId: teamMembership.userId,
            teamId: teamMembership.teamId,
            status: to,
          },
        },
      });
    } else {
      updateStatus({
        variables: {
          input: {
            userId: teamMembership.userId,
            teamId: teamMembership.teamId,
            status: to,
          },
        },
      });
    }

  }

  function handleLeaveWorkspace() {
    if (!teamMembership) {
      return;
    }

    leaveTeam({
      variables: {
        input: { teamId: teamMembership.teamId }
      },
    });
  }

  function handleResendNotification() {
    if (!teamMembership) {
      return;
    }

    resendInvitationNotification({
      variables: {
        input: {
          userId: teamMembership.userId,
          teamId: teamMembership.teamId,
        },
      },
    });
  }

  function handleRevokeInvite() {
    if (!teamMembership) {
      return;
    }

    revokeInvitation({
      variables: {
        input: {
          userId: teamMembership.userId,
          teamId: teamMembership.teamId,
        },
      },
    });
  }

  function handleDeleteMembership() {
    if (!isSuperUser) {
      return;
    }

    if (!teamMembership) {
      return;
    }

    deleteTeamMembership({
      variables: {
        input: {
          userId: teamMembership.userId,
          teamId: teamMembership.teamId,
        },
      },
    });
  }


  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" className={cn("", className)} disabled={disabled}>
          <IconDots />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">{
        <>
          {renderDropdownMenuContent()}
          {isSuperUser && currentUser && currentUser.id == teamMembership?.userId && (
            <>
              <DropdownMenuItem onClick={() => handleDeleteMembership()}>Mitgliedschaft löschen</DropdownMenuItem>
              {teamMembership?.role == "ADMIN" && (
                <DropdownMenuItem onClick={() => updateRoleTo("USER")}>Adminrechte entziehen</DropdownMenuItem>
              )}
              {teamMembership?.role != "ADMIN" && (
                <DropdownMenuItem onClick={() => updateRoleTo("ADMIN")}>Adminrechte vergeben</DropdownMenuItem>
              )}
            </>
          )}
        </>
      }
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default TeamMembershipActionButton;
