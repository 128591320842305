export const TAB_DATA_KEYS = ["overview", "activity", "details", "documents"] as const;
export type TabDataKey = (typeof TAB_DATA_KEYS)[number];

interface TabData {
  id: TabDataKey;
  label: string;
}

export const TAB_DATA: TabData[] = [
  // {
  //   id: "overview",
  //   label: "Übersicht",
  // },
  {
    id: "details",
    label: "Details",
  },
  {
    id: "activity",
    label: "Aktivität",
  },
  {
    id: "documents",
    label: "Dokumente",
  },
];
