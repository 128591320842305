import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
  TextNode,
  $getSelection,
  $createTextNode,
  CONTROLLED_TEXT_INSERTION_COMMAND,
  KEY_ENTER_COMMAND,
} from "lexical";
import { useCallback, useEffect } from "react";

import {
  $createVariableInterpolationNode,
  VariableInterpolationNode,
  VariableInterpolationPayload,
  INTERPOLATION_VARIABLE_REGEX,
  $isVariableInterpolationNode,
} from "../rich-text-nodes";

export type InsertInterpolationPayload = Readonly<VariableInterpolationPayload>;
export const INSERT_INTERPOLATION_COMMAND: LexicalCommand<InsertInterpolationPayload> =
  createCommand("INSERT_INTERPOLATION_COMMAND");

export const VariableInterpolationsPlugin = (): JSX.Element | null => {
  const [editor] = useLexicalComposerContext();

  const textNodeTransform = useCallback((textNode: TextNode) => {
    if (!textNode.isSimpleText() || !textNode.getTextContent()) return;
    const regex = RegExp(INTERPOLATION_VARIABLE_REGEX, "g");
    const matchArr = regex.exec(textNode.getTextContent());
    if (matchArr === null) {
      return;
    }
    const [firstNode, secondNode] = textNode.splitText(matchArr.index, regex.lastIndex);

    const interpolationNode = $createVariableInterpolationNode({
      variableName: matchArr[1],
    });
    interpolationNode.setStyle(textNode.getStyle());
    if (matchArr.index > 0 && secondNode) {
      secondNode.replace(interpolationNode);
    } else {
      firstNode.replace(interpolationNode);
    }
  }, []);

  const handleAppendToNode = () => {
    const selection = $getSelection();
    const nodes = selection?.getNodes();
    if (nodes?.length === 1 && $isVariableInterpolationNode(nodes[0])) {
      nodes[0].insertAfter($createTextNode("")).select();
    }
  };

  useEffect(() => {
    if (!editor.hasNodes([VariableInterpolationNode])) {
      throw new Error("InterpolationsPlugin: VariableInterpolationNode not registered on editor");
    }

    return mergeRegister(
      editor.registerNodeTransform(TextNode, textNodeTransform),
      editor.registerCommand<InsertInterpolationPayload>(
        INSERT_INTERPOLATION_COMMAND,
        (payload) => {
          const interpolationNode = $createVariableInterpolationNode(payload);
          $insertNodes([interpolationNode]);
          if ($isRootOrShadowRoot(interpolationNode.getParentOrThrow())) {
            $wrapNodeInElement(interpolationNode, $createParagraphNode).selectEnd();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      // prevent deletion of interpolation node when typing on it
      editor.registerCommand(
        CONTROLLED_TEXT_INSERTION_COMMAND,
        () => {
          handleAppendToNode();
          return false;
        },
        1,
      ),
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (input: KeyboardEvent | null) => {
          input?.preventDefault();
          handleAppendToNode();
          return false;
        },
        1,
      ),
    );
  }, [editor]);

  return null;
};
