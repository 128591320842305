import { cn } from "@/lib/utils";

import { Button } from "@/components/_ui/button";

import { Table } from "@tanstack/react-table";
import { useMemo } from "react";

interface ObjectiveListColumnToggleViewProps<TData> {
  table: Table<TData>;
  getDisplayValueForColumn: (id: string) => string;
}

export function ObjectiveListColumnToggleView<TData>({
  table,
  getDisplayValueForColumn,
}: ObjectiveListColumnToggleViewProps<TData>) {
  const columnsThatCanHide = useMemo(() => {
    return table.getAllColumns().filter((c) => c.getCanHide());
  }, [table]);

  const firstSectionColumns = useMemo(() => {
    return columnsThatCanHide
      .slice()
      .filter((c) => !c.id.startsWith("label_"))
      .sort((a, b) => {
        const displayValueA = getDisplayValueForColumn(a.id).toLocaleLowerCase();
        const displayValueB = getDisplayValueForColumn(b.id).toLocaleLowerCase();
        return displayValueA.localeCompare(displayValueB);
      });
  }, [columnsThatCanHide]);

  const secondSectionColumns = useMemo(() => {
    return columnsThatCanHide
      .slice()
      .filter((c) => c.id.startsWith("label_"))
      .sort((a, b) => {
        const displayValueA = getDisplayValueForColumn(a.id).toLocaleLowerCase();
        const displayValueB = getDisplayValueForColumn(b.id).toLocaleLowerCase();
        return displayValueA.localeCompare(displayValueB);
      });
  }, [columnsThatCanHide]);

  return (
    <div className="flex flex-row flex-wrap items-center gap-1">
      <div className="flex flex-row flex-wrap items-center gap-1">
        {firstSectionColumns.map((column) => {
          const isVisible = column.getIsVisible();
          return (
            <Button
              variant="ghost"
              size="xs"
              key={column.id}
              className={cn([
                "h-6 border-[0.5px] text-xs font-medium text-foreground/80",
                isVisible ? "opacity-100" : "opacity-70	",
              ])}
              onClick={() => column.toggleVisibility(!isVisible)}
            >
              {getDisplayValueForColumn(column.id)}
            </Button>
          );
        })}
      </div>
      <div className="mb-2 mt-3 w-full text-xs font-medium text-muted-foreground">Kategorien</div>
      <div className="flex flex-row flex-wrap items-center gap-1">
        {secondSectionColumns.map((column) => {
          const isVisible = column.getIsVisible();
          return (
            <Button
              variant="ghost"
              size="xs"
              key={column.id}
              className={cn([
                "h-6 border-[0.5px] text-xs font-medium text-foreground/80",
                isVisible ? "opacity-100" : "opacity-70	",
              ])}
              onClick={() => {
                column.toggleVisibility(!isVisible);
              }}
            >
              {getDisplayValueForColumn(column.id)}
            </Button>
          );
        })}
      </div>
    </div>
  );
}
