import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { fileListForOwnerQueryDocument } from "@/graphql/common";

export interface UseGetFileListForOwnerProps {
  ownerId: string;
}

export const useGetFileListByOwner = ({ ownerId }: UseGetFileListForOwnerProps) => {
  const queryResult = useQuery(fileListForOwnerQueryDocument, {
    variables: { input: { ownerId } },
  });

  const fileList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    if (!queryResult.data.fileListForOwner) {
      return [];
    }

    return queryResult.data.fileListForOwner;
  }, [queryResult.data]);

  return {
    queryResult,
    fileList,
  };
};
