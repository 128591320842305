import { ColumnDef } from "@tanstack/react-table";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
import { Team } from "./model";

import { DatatableColumnHeader } from "@/components/datatable-column-header";

import { DatatableRowActions } from "./row-actions";
import { TeamView } from "../team-view";

export const columns: ColumnDef<Team>[] = [
  {
    id: "logo",
    accessorKey: "logo",
    enableHiding: false,
    enableSorting: false,
    header: ({ column }) => <DatatableColumnHeader column={column} title="Logo" className="pl-6" />,
    cell: ({ row }) => <TeamView team={row.original} className="ml-6 h-6 w-6 text-xxs" />,
  },
  {
    id: "name",
    accessorKey: "name",
    enableHiding: false,
    header: ({ column }) => <DatatableColumnHeader column={column} title="Team Name" />,
  },
  {
    id: "visibility",
    accessorKey: "visibility",
    enableHiding: false,
    header: ({ column }) => <DatatableColumnHeader column={column} title="Visibility" />,
  },
  {
    id: "members",
    accessorKey: "teamMembershipList",
    accessorFn: (original) => {
      return `${original.teamMembershipList.filter((wsm) => wsm.status == "ACTIVE").length}`;
    },
    header: ({ column }) => <DatatableColumnHeader column={column} title="Active" />,
  },
  {
    id: "invited",
    accessorKey: "teamMembershipList",
    accessorFn: (original) => {
      return `${original.teamMembershipList.filter((wsm) => wsm.status == "PENDING").length}`;
    },
    header: ({ column }) => <DatatableColumnHeader column={column} title="Invited" />,
  },
  {
    id: "left",
    accessorKey: "teamMembershipList",
    accessorFn: (original) => {
      return `${original.teamMembershipList.filter((wsm) => wsm.status == "LEFT").length}`;
    },
    header: ({ column }) => <DatatableColumnHeader column={column} title="Left" />,
  },
  {
    id: "suspended",
    accessorKey: "teamMembershipList",
    accessorFn: (original) => {
      return `${original.teamMembershipList.filter((wsm) => wsm.status == "SUSPENDED").length}`;
    },
    header: ({ column }) => <DatatableColumnHeader column={column} title="Suspended" />,
  },
  {
    id: "actions",
    cell: ({ row }) => <DatatableRowActions row={row} />,
  },
];
