import { graphql } from "../../generated/gql";

export const updateWorkspaceMembershipRoleMutationDocument = graphql(/* GraphQL */ `
  mutation updateWorkspaceMembershipRole($input: WorkspaceMembershipUpdateRoleInput!) {
    updateWorkspaceMembershipRole(workspaceMembershipUpdateRoleInput: $input) {
      userId
      workspaceId
      role
      status
      updatedAt
      createdAt
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const updateWorkspaceMembershipStatusMutationDocument = graphql(/* GraphQL */ `
  mutation updateWorkspaceMembershipStatus($input: WorkspaceMembershipUpdateStatusInput!) {
    updateWorkspaceMembershipStatus(workspaceMembershipUpdateStatusInput: $input) {
      userId
      workspaceId
      role
      status
      updatedAt
      createdAt
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const leaveWorkspaceMembershipMutationDocument = graphql(/* GraphQL */ `
  mutation leaveWorkspaceMembership($input: WorkspaceMembershipLeaveInput!) {
    leaveWorkspaceMembership(workspaceMembershipLeaveInput: $input) {
      userId
      workspaceId
      role
      status
      updatedAt
      createdAt
      user {
        id
        email
        username
        fullname
        profilePicture
      }
    }
  }
`);

export const rejoinWorkspaceMembershipMutationDocument = graphql(/* GraphQL */ `
  mutation rejoinWorkspaceMembership($input: WorkspaceMembershipRejoinInput!) {
    rejoinWorkspaceMembership(workspaceMembershipRejoinInput: $input) {
      userId
      workspaceId
      role
      status
      updatedAt
      createdAt
    }
  }
`);

export const createWorkspaceInviteMutationDocument = graphql(/* GraphQL */ `
  mutation createWorkspaceInvite($input: WorkspaceInviteCreateWithEmailInput!) {
    createWorkspaceInvite(workspaceInviteCreateWithEmailInput: $input) {
      userId
      workspaceId
      role
      status
      updatedAt
      createdAt
      user {
        id
        email
      }
    }
  }
`);

export const acceptWorkspaceInviteMutationDocument = graphql(/* GraphQL */ `
  mutation acceptWorkspaceInvite($input: WorkspaceInviteAcceptInput!) {
    acceptWorkspaceInvite(workspaceInviteAcceptInput: $input) {
      userId
      workspaceId
      user {
        id
        email
        username
        fullname
        profilePicture
        activated
      }
      workspace {
        id
        name
        workspaceUrl
        logo
      }
    }
  }
`);

export const deleteWorkspaceInviteMutationDocument = graphql(/* GraphQL */ `
  mutation deleteWorkspaceInvite($input: WorkspaceInviteDeleteInput!) {
    deleteWorkspaceInvitation(workspaceInviteDeleteInput: $input) {
      userId
      workspaceId
    }
  }
`);

export const resendWorkspaceInviteNotificationMutationDocument = graphql(/* GraphQL */ `
  mutation resendWorkspaceInviteNotification($input: WorkspaceInviteUpdateInput!) {
    resendWorkspaceInviteNotification(workspaceInviteUpdateInput: $input) {
      userId
      workspaceId
    }
  }
`);
