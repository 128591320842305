import { useMemo } from "react";

import { useCurrentUser, useTeamListForUserInWorkspace, useWorkspaceGetByUrl } from "@/hooks/api/common";

import { Navigate, Outlet, useParams } from "react-router-dom";

export default function Page() {
  const { currentUser } = useCurrentUser();
  const { workspaceUrl, teamId } = useParams();
  const { workspace } = workspaceUrl ? useWorkspaceGetByUrl({ workspaceUrl: workspaceUrl }) : { workspace: undefined };
  const { activeTeamList } =
    currentUser && workspace
      ? useTeamListForUserInWorkspace({ workspaceId: workspace.id, userId: currentUser.id })
      : { activeTeamList: [] };

  const nextTeam = useMemo(() => {
    if (activeTeamList.length == 0) {
      return null;
    }

    return activeTeamList[0];
  }, [activeTeamList]);

  if (!teamId && nextTeam) {
    return <Navigate to={`/${workspaceUrl}/teams/${nextTeam.id}`} replace={true} />;
  }

  return (
    <main className="relative place-items-stretch overflow-auto">
      <Outlet />
    </main>
  );
}
