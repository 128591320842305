import { LucideProps } from "lucide-react";

const Icon = ({ ...props }: LucideProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="child-objective"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.0002 7C13.2094 7 15.0002 8.79086 15.0002 11C15.0002 13.2091 13.2094 15 11.0002 15C8.79111 15 7.00024 13.2091 7.00024 11C7.00024 8.79086 8.79111 7 11.0002 7ZM10.7461 1C12.8172 1 14.4961 2.67893 14.4961 4.75V5.24561C14.4961 5.65982 14.1603 5.99561 13.7461 5.99561C13.3319 5.99561 12.9961 5.65982 12.9961 5.24561V4.75C12.9961 3.50736 11.9887 2.5 10.7461 2.5H4.74609C3.50345 2.5 2.49609 3.50736 2.49609 4.75V10.9705C2.49609 12.2131 3.50345 13.2205 4.74609 13.2205H6.05259C6.46681 13.2205 6.80259 13.5562 6.80259 13.9705C6.80259 14.3847 6.46681 14.7205 6.05259 14.7205H4.74609C2.67503 14.7205 0.996094 13.0415 0.996094 10.9705V4.75C0.996094 2.67893 2.67503 1 4.74609 1H10.7461ZM11.0002 8.25488L10.8985 8.26173C10.5657 8.30688 10.3022 8.57032 10.2571 8.90311L10.2502 9.00488V10.2509L9.00104 10.25L8.89926 10.2567C8.56642 10.3015 8.3027 10.5647 8.2572 10.8974L8.25024 10.9992L8.25698 11.101C8.30178 11.4338 8.56494 11.6975 8.89769 11.743L8.99945 11.75L10.2502 11.7509V13.0036L10.2571 13.1054C10.3022 13.4382 10.5657 13.7016 10.8985 13.7468L11.0002 13.7536L11.102 13.7468C11.4348 13.7016 11.6982 13.4382 11.7434 13.1054L11.7502 13.0036V11.7529L12.9994 11.7542L13.1012 11.7475C13.4341 11.7027 13.6978 11.4395 13.7433 11.1068L13.7502 11.005L13.7435 10.9033C13.6987 10.5704 13.4355 10.3067 13.1028 10.2612L13.001 10.2542L11.7502 10.2529V9.00488L11.7434 8.90311C11.6982 8.57032 11.4348 8.30688 11.102 8.26173L11.0002 8.25488Z"
      />
    </svg>
  );
};

export default Icon;
