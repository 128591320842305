import { z } from "zod";

export const workspaceMembershipSchema = z.object({
  id: z.string(),
  workspaceId: z.string(),
  userId: z.string(),
  status: z.string(),
  role: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type WorkspaceMembership = z.infer<typeof workspaceMembershipSchema>;

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const workspaceSchema = z.object({
  id: z.string(),
  name: z.string(),
  workspaceUrl: z.string(),
  logo: z.string().optional().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
  workspaceMembershipList: z.array(workspaceMembershipSchema),
});

export type Workspace = z.infer<typeof workspaceSchema>;
