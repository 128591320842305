import { graphql } from "../../generated/gql";

export const createStatusMutationDocument = graphql(/* GraphQL */ `
  mutation createStatus($input: CreateStatusInput!) {
    createStatus(createStatusInput: $input) {
      id
      teamId
      name
      color
      type
    }
  }
`);

export const updateStatusMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateStatus($input: UpdateStatusInput!) {
    updateStatus(updateStatusInput: $input) {
      id
      teamId
      name
      color
      type
    }
  }
`);

export const deleteStatusMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteStatus($input: DeleteStatusInput!) {
    deleteStatus(deleteStatusInput: $input) {
      id
      teamId
      name
      color
      type
    }
  }
`);
