import { FC, useMemo } from "react";

import { OwnerType } from "@/components/_domain/labels/models/owner-types";
import { useGetLabelGroup, useGetLabelInstanceList } from "@/hooks/api/common";
import { ObjectiveLabelGroupInstanceItem } from "./objective-label-group-instance-item";

interface ObjectiveLabelGroupInstanceViewProps {
  owner: {
    id: string;
    type: OwnerType;
  };
  workspace: {
    id: string;
  };
  team: {
    id: string;
  };
  user: {
    id: string;
  };
  labelGroup: {
    id: string;
  };
}

export const ObjectiveLabelGroupInstanceView: FC<ObjectiveLabelGroupInstanceViewProps> = ({
  workspace,
  team,
  owner,
  user,
  ...props
}) => {
  const { labelGroup } = useGetLabelGroup({ id: props.labelGroup.id });
  const { labelInstanceListGroupedByGroupId } = useGetLabelInstanceList({ ownerId: owner.id });

  const filteredLabelInstanceList = useMemo(() => {
    return labelInstanceListGroupedByGroupId.get(props.labelGroup.id) ?? [];
  }, [labelInstanceListGroupedByGroupId, props.labelGroup]);

  if (!labelGroup) {
    return <></>;
  }

  if (filteredLabelInstanceList.length == 0) {
    return <></>;
  }
  return (
    <div className="mb-5 flex flex-row items-start gap-2">
      <div className="w-24 shrink-0 overflow-hidden text-ellipsis font-medium">{labelGroup.title}</div>
      <div className="flex grow flex-row flex-wrap gap-1">
        {filteredLabelInstanceList.map((labelInstance) => {
          return (
            <ObjectiveLabelGroupInstanceItem
              key={labelInstance.id}
              workspace={workspace}
              team={team}
              owner={owner}
              user={user}
              labelGroup={labelGroup}
              labelInstance={labelInstance}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ObjectiveLabelGroupInstanceView;
