import { FC, PropsWithChildren, useEffect, useState } from "react";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";
import { Tabs, TabsContent } from "@/components/_ui/tabs";

import { Icon, IconType, IconTypes, iconTypefromString } from "./icon.schema";

import { SelectedThemeAppearance, useTheme } from "@/hooks/useTheme";

export interface IconPickerProps extends PropsWithChildren {
  asChild?: boolean;
  value?: Icon;
  onChange?: (value: Icon | null) => void;
}

export const IconPicker: FC<IconPickerProps> = ({ value, onChange, asChild, children }) => {
  const { selectedThemeAppearance } = useTheme();
  //   const ALLOWED_COLORS = [
  //     "#95999F",
  //     "#6E7B8B",
  //     "#5E69D2",
  //     "#00B5ED",
  //     "#00B55C",
  //     "#E8B600",
  //     "#E57A00",
  //     "#F38E82",
  //     "#FB424B",
  //   ];
  const [selectedView, setSelectedView] = useState<IconType>(value?.mode ?? IconTypes.emoji);
  const [_selectedIcon, setSelectedIcon] = useState<Icon | null>(null);
  const [open, setOpen] = useState(false);

  function handleTabChange(value: string) {
    const iconType = iconTypefromString(value);

    if (!iconType) {
      return;
    }

    setSelectedView(iconType);
  }

  function handleEmojiSelected(value: string) {
    const newIcon = {
      mode: IconTypes.emoji,
      value: value,
      color: null,
    };
    setSelectedIcon(newIcon);

    if (onChange) {
      onChange(newIcon);
    }
  }

  useEffect(() => {
    if (!value) {
      return;
    }

    setSelectedIcon(value);
    setSelectedView(value.mode);
  }, [value]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild={asChild}>{children}</PopoverTrigger>
      <PopoverContent side="bottom" align="start" style={{ width: "auto" }} className=" p-0">
        <Tabs value={selectedView} onValueChange={handleTabChange}>
          {/* Only allow emojis for now */}
          {/* <div className="flex h-4 flex-row items-center justify-between">
            <TabsList className="h-6 p-0">
              <TabsTrigger
                value={IconTypes.icon}
                className={cn([
                  "h-full border text-xs",
                  selectedView == IconTypes.icon ? "text-foreground/80" : "border-transparent text-foreground/60",
                ])}
              >
                Icons
              </TabsTrigger>
              <TabsTrigger
                value={IconTypes.emoji}
                className={cn([
                  "h-full border text-xs",
                  selectedView == IconTypes.emoji ? "text-foreground/80" : "border-transparent text-foreground/60",
                ])}
              >
                Emoji
              </TabsTrigger>
            </TabsList>
          </div> */}
          <TabsContent value={IconTypes.icon}>Icon</TabsContent>

          <TabsContent value={IconTypes.emoji} className=" flex-row p-0">
            <Picker
              data={data}
              onEmojiSelect={(e: { native: string }) => {
                handleEmojiSelected(e.native);
                setOpen(false);
              }}
              locale="de"
              theme={selectedThemeAppearance == SelectedThemeAppearance.dark ? "dark" : "light"}
              autofocus={true}
              previewPosition="none"
              className="shadow-0 border-0"
            />
          </TabsContent>
        </Tabs>
      </PopoverContent>
    </Popover>
  );
};
