import { FC, useEffect, useState } from "react";
import { useGetObjective } from "@/hooks/api/common";
import ObjectiveEditForm from "../../../objective-edit-form";

interface ObjectiveDescriptionProps {
  workspace: {
    id: string;
  };
  objective: {
    id: string;
  };
}

export const ObjectiveDescription: FC<ObjectiveDescriptionProps> = (props) => {
  const { objective } = useGetObjective({ id: props.objective.id });
  const [removedFromDom, setRemovedFromDom] = useState(false);

  useEffect(() => {
    // The RichTextEditor component does not properly update if not removed from the dom.
    // A bit of a hack, but works for now.
    forceRichTextEditorRerender();
  }, [props.objective.id]);

  function forceRichTextEditorRerender() {
    setRemovedFromDom(true);
    setTimeout(() => {
      setRemovedFromDom(false);
    }, 1);
  }

  if (!objective) {
    return <></>;
  }

  // force a re-render when the objective changes
  if (removedFromDom) {
    return <></>;
  }

  return <ObjectiveEditForm objectiveFormData={objective} />;
};
