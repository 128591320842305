import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { currentUserQueryDocument } from "@/graphql/common";

// Application Monitoring
import * as Sentry from "@sentry/react";
import { posthogService } from "@/services/posthog.service"

export const useCurrentUser = () => {
  const queryResult = useQuery(currentUserQueryDocument);

  const currentUser = useMemo(() => {
    if (!queryResult) {
      return null;
    }

    if (!queryResult.data) {
      return null;
    }

    Sentry.setUser({ email: queryResult.data.currentUser.email });
    posthogService.identify(
      queryResult.data.currentUser.id, // Replace 'distinct_id' with your user's unique identifier
      { email: queryResult.data.currentUser.email, name: queryResult.data.currentUser.fullname }, // optional: set additional user properties
    );

    return queryResult.data.currentUser;
  }, [queryResult]);

  const isSuperUser = useMemo(() => {
    if (!queryResult) {
      return null;
    }

    if (!queryResult.data) {
      return null;
    }

    return !!queryResult.data.currentUser.superUser
  }, [queryResult])

  return {
    loading: queryResult.loading,
    queryResult,
    currentUser,
    isSuperUser
  };
};
