import { FC } from "react";

import { Card, CardContent, CardHeader } from "@/components/_ui/card";

import { Button } from "@/components/_ui/button";
import AvatarWorkspace from "@/components/_domain/workspace/avatar-workspace";

import { UserProp, TeamProp, WorkspaceProp } from "./page";

import LoadingDots from "@/components/loading-dots";

import { toast } from "@/components/_ui/use-toast";
import { signout } from "@/services/auth.service";
import { useCurrentUser, useTeamInviteAccept } from "@/hooks/api/common";

export interface AcceptInviteCardProps {
  token: string;
  workspace: WorkspaceProp;
  team: TeamProp;
  user: UserProp;
}

const AcceptInviteCard: FC<AcceptInviteCardProps> = ({ token, team, workspace, user }) => {
  const { acceptTeamInvitation, queryResult } = useTeamInviteAccept({ team: { id: team.id }, onCompleted: onUpdateCompleted, onError: onUpdateError })

  const { currentUser } = useCurrentUser();

  function invitationIsForAuthenticatedAccount() {
    return currentUser && currentUser.email == user.email;
  }

  function handleAcceptInvitePressed() {
    acceptTeamInvitation({
      fetchPolicy: "no-cache",
      variables: {
        input: {
          token: token,
        },
      },
    });
  }

  function onUpdateCompleted() {
    let message = "Wir leiten dich jetzt zur Login Seite weiter.";

    if (invitationIsForAuthenticatedAccount()) {
      // the same user is logged in

      message = "Wir leiten dich jetzt zur Workspace Seite des Teams weiter.";

      setTimeout(function () {
        window.location.replace(new URL(`${window.location.origin}/${workspace.workspaceUrl}/teams/${team.id}`));
      }, 2000);
      return;
    } else if (currentUser && currentUser.email != user.email) {
      // a different user is logged in
      setTimeout(function () {
        signout();
      }, 2000);
      return;
    } else {
      // no user is logged in
      setTimeout(function () {
        window.location.replace(new URL(`${window.location.origin}`));
      }, 2000);
    }

    toast({
      title: `Willkomen im Team ${team.name}`,
      description: message,
      variant: "success",
    });
  }

  function onUpdateError() {
    toast({
      title: "Fehler beim Versenden der Einladung",
      description: "Es ist etwas schiefgegangen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
      variant: "error",
    });
  }

  return (
    <Card className="shadow-xl z-10 w-full max-w-xl overflow-hidden border p-10">
      <CardHeader>
        <div className="flex flex-col gap-4">
          <AvatarWorkspace workspace={workspace} className="h-16 w-16 text-2xl" />
          <h2 className="text-xl">{`Sie wurden dazu eingeladen dem Team ${team.name} im Workspace ${workspace.name} beizutreten`}</h2>
          <p className="text-md font-light text-muted-foreground">
            Ark Climate ist eine moderne Software zur Umsetzung von Klimaschutz in fortschrittlichen Städten und
            Gemeinden.
          </p>
          <div className="w-full border-t border-solid" />
        </div>
      </CardHeader>
      <CardContent>
        <div className="mb-10 mt-4 flex flex-col gap-4">
          <p className="mb-2 text-base font-light text-foreground">
            Um dem Team mit Ihrem Account <span className="font-medium">{user.email}</span> beizutreten akzeptieren Sie
            die Einladung
            {invitationIsForAuthenticatedAccount() ? "." : "und melden sich mit mit dem Account an."}
          </p>
          <Button
            onClick={() => handleAcceptInvitePressed()}
            disabled={queryResult.loading}
            variant="default"
            className={`${queryResult.loading ? "cursor-not-allowed bg-muted" : ""
              } h-10 w-48 items-center justify-center text-sm focus:outline-none`}
          >
            {queryResult.loading ? <LoadingDots color="#808080" /> : <p>Einladung Annehmen</p>}
          </Button>
        </div>

        {currentUser && currentUser.email != user.email && (
          <div className="mt-6 w-full border-t border-solid pt-2">
            <p className="text-sm font-light text-foreground">
              Sie sind aktuell mit dem Account <span className="font-medium">{currentUser.email}</span> angemeldet. Wenn
              Sie diesen Account auch für das Team {team.name} nutzen wollen, bitten Sie einen Administrator Sie mit
              dieser E-mail Adresse einzuladen.
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default AcceptInviteCard;
