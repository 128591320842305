import { ColumnDef } from "@tanstack/react-table";
import { ObjectiveListCellTitle, ObjectiveListColumnHeader } from "../..";
import { ObjectiveColumnConfiguration } from "../column-definition-type";
import { Objective } from "@/components/_domain/objective/models";
import { FilterByTitleConfigurationView, FilterByTitleDisplayView, FilterByTitleFilterPaneView } from "./components";
import { filterByTextWithUnknownPayload } from "../../filter-functions";

interface ObjectiveTitleColumnDefinitionProps {
  workspaceId: string;
  columnId: string;
  orderValue: number;
}

export const ObjectiveTitleColumnDefinition = ({
  workspaceId,
  columnId,
  orderValue,
}: ObjectiveTitleColumnDefinitionProps): ObjectiveColumnConfiguration => {
  return {
    columnId: columnId,
    getDefaultVisibilityState: () => true,
    getDisplayValue: () => "Titel",
    getOrderValue: () => orderValue, // prefix with 100, 200, 300 to assign to sections
    getColumnDef: (): ColumnDef<Objective> => {
      return {
        id: columnId,
        accessorKey: columnId, // key needed to enable sorting / filtering
        enableHiding: false,
        enableSorting: true,
        enableColumnFilter: true,
        sortingFn: (rowA, rowB) => {
          return rowA.original.title.localeCompare(rowB.original.title);
        },
        filterFn: (row, _columnId, value, _addMeta) => {
          return filterByTextWithUnknownPayload(value, row.original.title);
        },
        header: ({ column }) => (
          <ObjectiveListColumnHeader column={column} title="" className={`px-1 text-xs font-normal`} />
        ),
        cell: ({ row }) => (
          <ObjectiveListCellTitle row={row} objective={row.original} workspace={{ id: workspaceId }} />
        ),
      };
    },
    getDisplayView: () => <FilterByTitleDisplayView />,
    getConfigurationView: (onFinished: () => void) => (
      <FilterByTitleConfigurationView column={{ id: columnId }} onFinished={onFinished} />
    ),
    getConfigurationViewType: () => "modal",
    getFilterPaneView: () => <FilterByTitleFilterPaneView column={{ id: columnId }} />,
  };
};
