import { FC } from "react";

import { Badge } from "@/components/_ui/badge";
import AvatarWorkspace from "@/components/_domain/workspace/avatar-workspace";

import { useGetObjective, useWorkspaceGetById } from "@/hooks/api/common";

export interface ProgressSnapshotHeaderProps {
  workspace: {
    id: string;
  };
  objective: {
    id: string;
  };
}

export const ProgressSnapshotHeader: FC<ProgressSnapshotHeaderProps> = ({ ...props }) => {
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });
  const { objective } = useGetObjective({ id: props.objective.id });

  return (
    <div className="flex flex-row items-center gap-1">
      {!!workspace && (
        <>
          <Badge variant="outline" className="py-2 opacity-40">
            <div className="flex flex-row items-center gap-1">
              <AvatarWorkspace workspace={workspace} className="h-5 w-5 text-xxs" />
              <span className="ml-1 overflow-hidden text-ellipsis font-normal">{workspace.name}</span>
            </div>
          </Badge>
          <span className="ml-1 mr-1">{"›"}</span>
        </>
      )}
      {!!objective && (
        <>
          <div className="block max-w-[8rem] overflow-hidden truncate text-ellipsis whitespace-nowrap text-xs">
            {objective.title}
          </div>
          <span className="ml-1 mr-1">{"›"}</span>
        </>
      )}
      <div className="block max-w-[8rem] overflow-hidden truncate text-ellipsis whitespace-nowrap text-xs">
        Fortschrittsbericht
      </div>
    </div>
  );
};

export default ProgressSnapshotHeader;
