import { FC, useState } from "react";

import { Dialog, DialogContent } from "@/components/_ui/dialog";
import { Button } from "@/components/_ui/button";

import { WorkspaceDuplicateForm } from "../workspace-duplicate-form";
import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

interface WorkspaceDuplicateDialogProps {}

export const WorkspaceDuplicateDialog: FC<WorkspaceDuplicateDialogProps> = ({}) => {
  const [dialogisOpen, setDialogisOpen] = useState(false);

  function onDialogOpenChange(open: boolean) {
    setDialogisOpen(open);
  }

  return (
    <>
      <Button
        variant="ghost"
        size="xs"
        className="shadow-sm h-7 rounded-md border px-2 text-xs text-foreground/75"
        onClick={() => setDialogisOpen(true)}
      >
        <DocumentDuplicateIcon className="mr-2 h-3 w-3" />
        Workspace Duplizieren
      </Button>
      <Dialog open={dialogisOpen} onOpenChange={onDialogOpenChange}>
        <DialogContent className="top-[1%] max-h-[99vh] max-w-xl translate-y-[0%] overflow-y-scroll md:top-[10%] md:max-h-[80vh]">
          <WorkspaceDuplicateForm
            triggerCloseDialog={() => {
              setDialogisOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
