import { LucideProps } from "lucide-react";

const Icon = ({ ...props }: LucideProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="sun"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      height="1.25em"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8.75881 14.2396C8.75881 14.6593 8.41855 14.9996 7.99882 14.9996C7.57908 14.9996 7.23881 14.6593 7.23881 14.2396V12.7596C7.23881 12.3398 7.57908 11.9996 7.99882 11.9996C8.41855 11.9996 8.75881 12.3398 8.75881 12.7596V14.2396Z"
      />
      <path
        fill="currentColor"
        d="M5.17157 10.8267C4.87477 10.5299 4.39357 10.5299 4.09677 10.8267L3.05025 11.8733C2.75345 12.1701 2.75345 12.6513 3.05025 12.9481C3.34705 13.2449 3.82825 13.2449 4.12505 12.9481L5.17157 11.9015C5.46837 11.6047 5.46837 11.1235 5.17157 10.8267Z"
      />
      <path
        fill="currentColor"
        d="M10.8286 10.8266C10.5318 11.1234 10.5318 11.6046 10.8286 11.9014L11.8751 12.9479C12.1719 13.2447 12.6531 13.2447 12.9499 12.9479C13.2467 12.6511 13.2467 12.1699 12.9499 11.8731L11.9034 10.8266C11.6066 10.5298 11.1254 10.5298 10.8286 10.8266Z"
      />
      <path
        fill="currentColor"
        d="M10.1144 5.8839C11.286 7.05547 11.286 8.95497 10.1144 10.1265C8.94285 11.2981 7.04335 11.2981 5.87178 10.1265C4.70021 8.95497 4.70021 7.05547 5.87178 5.8839C7.04335 4.71233 8.94285 4.71233 10.1144 5.8839Z"
      />
      <path
        fill="currentColor"
        d="M3.05042 4.1232C2.75362 3.8264 2.75362 3.3452 3.05042 3.0484C3.34722 2.7516 3.82843 2.7516 4.12523 3.0484L5.17174 4.09492C5.46854 4.39172 5.46854 4.87292 5.17174 5.16972C4.87495 5.46652 4.39374 5.46652 4.09694 5.16972L3.05042 4.1232Z"
      />
      <path
        fill="currentColor"
        d="M11.8749 3.04856C12.1717 2.75176 12.6529 2.75176 12.9497 3.04856C13.2465 3.34536 13.2465 3.82656 12.9497 4.12336L11.9032 5.16988C11.6064 5.46668 11.1252 5.46668 10.8284 5.16988C10.5316 4.87308 10.5316 4.39188 10.8284 4.09508L11.8749 3.04856Z"
      />
      <path
        fill="currentColor"
        d="M14.2394 7.2389C14.6592 7.2389 14.9994 7.57916 14.9994 7.9989C14.9994 8.41864 14.6592 8.7589 14.2394 8.7589H12.7594C12.3397 8.7589 11.9994 8.41864 11.9994 7.9989C11.9994 7.57916 12.3397 7.2389 12.7594 7.2389H14.2394Z"
      />
      <path
        fill="currentColor"
        d="M4 7.9983C4 7.57856 3.65974 7.2383 3.24 7.2383H1.76C1.34026 7.2383 1 7.57856 1 7.99829C1 8.41803 1.34026 8.7583 1.76 8.7583H3.24C3.65974 8.7583 4 8.41803 4 7.9983Z"
      />
      <path
        fill="currentColor"
        d="M7.99821 4.00012C8.41795 4.00012 8.75821 3.65986 8.75821 3.24012V1.76012C8.75821 1.34039 8.41795 1.00012 7.99821 1.00012C7.57847 1.00012 7.23821 1.34039 7.23821 1.76012V3.24012C7.23821 3.65986 7.57847 4.00012 7.99821 4.00012Z"
      />
    </svg>
  );
};

export default Icon;
