import { FC, PropsWithChildren, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { textToEMailArray } from "@/lib/utils";

import { Button } from "@/components/_ui/button";
import { Textarea } from "@/components/_ui/textarea";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/_ui/dialog";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/_ui/form";
import { toast } from "@/components/_ui/use-toast";

import {
  workspaceMembershipListForWorkspaceQueryDocument,
  createWorkspaceInviteMutationDocument,
} from "@/graphql/common";
import { apolloClient } from "@/services/apollo.service/apollo.service";

import LoadingDots from "@/components/loading-dots";

import AvatarWorkspace from "@/components/_domain/workspace/avatar-workspace";
import { ApolloError } from "@apollo/client";
import { superuser_workspaceListQueryDocument } from "@/graphql/superuser";

const FormSchema = z.object({
  emails: z.string().superRefine((value, ctx) => {
    const potentialEMails = textToEMailArray(value);
    if (potentialEMails.length == 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: 1,
        type: "array",
        inclusive: true,
        message: "Bitte geben Sie mindestens eine E-Mail Adresse ein.",
      });
    }

    const eMailSchema = z.string().email();
    const faultyEMails = [];
    for (const potentialEMail of potentialEMails) {
      const { success } = eMailSchema.safeParse(potentialEMail);
      if (!success) {
        faultyEMails.push(potentialEMail);
      }
    }

    if (faultyEMails.length == 1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `${faultyEMails[0]} ist keine gültige E-Mail Adresse.`,
      });
    }

    if (faultyEMails.length > 1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `${faultyEMails.join(",")} sind keine gültigen E-Mail Adressen.`,
      });
    }
  }),
});

export interface WorkspaceMemberInviteButtonPropsWithChildren extends PropsWithChildren {
  workspace: {
    id: string;
  };
  className?: string;
}

export const WorkspaceMemberInviteButton: FC<WorkspaceMemberInviteButtonPropsWithChildren> = ({
  className,
  ...props
}) => {
  const [dialogisOpen, setDialogisOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  function onDialogOpenChange(open: boolean) {
    setDialogisOpen(open);
  }

  async function handleButtonPressed() {
    setDialogisOpen(true);
  }
  async function onSubmit(values: z.infer<typeof FormSchema>) {
    setLoading(true);
    const emails = textToEMailArray(values.emails);

    let atLeastOneErrorHasOccured = false;
    for (const email of emails) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: createWorkspaceInviteMutationDocument,
          fetchPolicy: "no-cache",
          variables: {
            input: {
              workspaceId: props.workspace.id,
              email: email,
            },
          },
        });

        if (data) {
          toast({
            title: "Einladung verschickt",
            description: `Wir haben eine Einladung an ${email} verschickt.`,
            variant: "success",
          });
        }
      } catch (error: unknown) {
        atLeastOneErrorHasOccured = true;
        let message = `Es ist etwas schiefgegangen. Die Einladung an ${email} konnte nicht verschickt werden. Bitte laden Sie die Seite neu.`;
        const e = error as ApolloError;
        if (
          e &&
          e.graphQLErrors &&
          e.graphQLErrors.length > 0 &&
          e.graphQLErrors[0] &&
          e.graphQLErrors[0].extensions &&
          e.graphQLErrors[0].extensions.status == 409
        ) {
          message = `Ein Nutzer mit der E-Mail ${email} ist bereits Mitglied dieses Workspaces.`;
        }
        toast({
          title: "Fehler beim Versenden der Einladung",
          description: message,
          variant: "error",
        });
      }
    }

    await apolloClient.refetchQueries({
      include: [workspaceMembershipListForWorkspaceQueryDocument, superuser_workspaceListQueryDocument],
    });
    setLoading(false);
    if (!atLeastOneErrorHasOccured) {
      form.setValue("emails", "");
      setDialogisOpen(false);
    }
  }

  return (
    <div className={className}>
      <Button variant="default" size="xs" onClick={handleButtonPressed}>
        Personen einladen
      </Button>

      <Dialog open={dialogisOpen} onOpenChange={onDialogOpenChange}>
        <DialogContent className="p-10 sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>
              <div className="flex flex-row items-center justify-start">
                <AvatarWorkspace workspace={props.workspace} className="mr-3" />
                <span className="text-base font-normal">Laden Sie Personen in Ihren Workspace ein</span>
              </div>
            </DialogTitle>
            <DialogDescription asChild>
              <div className="h-4 w-full border-b border-solid" />
            </DialogDescription>
          </DialogHeader>
          <div className="relative max-w-full font-light">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
                <FormField
                  control={form.control}
                  name="emails"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>E-Mails</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="email@beispiel.de, email2@beispiel.de, ..."
                          className={
                            loading
                              ? "disabled text-muted-forground pointer-events-none w-full cursor-wait resize-none"
                              : "w-full resize-none"
                          }
                          disabled={loading}
                          {...field}
                          autoComplete="off"
                          data-1p-ignore
                        />
                      </FormControl>
                      <FormDescription>
                        Sie können mehrere E-Mail Adressen eingeben. Bitten trennen Sie E-Mail Adressen mit einem Komma.
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex flex-row justify-end">
                  <Button type="submit" size="sm" disabled={loading} style={{ minWidth: "120px" }}>
                    {loading && (
                      <span className="cursor-wait">
                        <LoadingDots />
                      </span>
                    )}
                    {!loading && <span>Absenden</span>}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WorkspaceMemberInviteButton;
