import { useMemo } from "react";

import Header from "@/components/header";

import { TeamDeleteButton } from "./team-delete";
import { useParams } from "react-router-dom";
import NotFound from "@/routes/NotFound";
import { useCurrentUser, useTeamById } from "@/hooks/api/common";

import { TeamEditForm } from "@/components/_domain/team";

export default function Page() {
  const { teamId } = useParams();
  const { currentUser } = useCurrentUser();

  const { team, userHasEditRights } = useTeamById({ id: teamId });

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    return userHasEditRights(currentUser.id);
  }, [currentUser, userHasEditRights]);

  if (!team) {
    return <NotFound />;
  }

  return (
    <main className="relative place-items-stretch overflow-auto">
      <Header className="border-none"></Header>

      <div className="overflow-y-scroll pb-24" style={{ maxHeight: "calc(100vh - 56px)" }}>
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <div className="flex flex-col gap-1.5">
              <div className="text-2xl">Team</div>
              <div className="text-sm text-muted-foreground">Verwalten Sie Ihr Team</div>
            </div>

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <TeamEditForm team={team} />
            </div>

            {hasEditRights && (
              <div>
                <div className="my-12 w-full border-t border-solid" />
                <h2 className="mb-4 text-lg">Team Löschen</h2>
                <p className="mb-4 text-sm text-muted-foreground">
                  Wenn Sie den Team und alle damit assozierten Daten löschen wollen, können Sie das hier tun.
                </p>
                <TeamDeleteButton team={team} />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
