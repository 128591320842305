import { Navigate, Outlet, useParams } from "react-router-dom";

import NotFound from "@/routes/NotFound";
import { useCurrentUser, useTeamListForUserInWorkspace, useWorkspaceGetByUrl } from "@/hooks/api/common";
import { useMemo } from "react";

export default function Page() {
  const { currentUser } = useCurrentUser();

  const { workspaceUrl, teamId } = useParams();
  const { workspace } = workspaceUrl ? useWorkspaceGetByUrl({ workspaceUrl: workspaceUrl }) : { workspace: undefined };
  const { activeTeamList } =
    currentUser && workspace
      ? useTeamListForUserInWorkspace({ workspaceId: workspace.id, userId: currentUser.id })
      : { activeTeamList: [] };

  const nextTeam = useMemo(() => {
    if (activeTeamList.length == 0) {
      return null;
    }

    return activeTeamList[0];
  }, [activeTeamList]);

  if (!workspaceUrl) {
    return <NotFound />;
  }

  if (!teamId) {
    if (!nextTeam) {
      return <NotFound />;
    }

    return <Navigate to={`/${workspaceUrl}/settings/teams/${nextTeam.id}`} replace={true} />;
  }

  return <Outlet />;
}
