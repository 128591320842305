import { FC, PropsWithChildren, useState } from "react";

import { Button } from "@/components/_ui/button";

import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
  MenubarSeparator,
} from "@/components/_ui/menubar";

import IconDots from "@/components/_icons/IconDots";
import IconTrash from "@/components/_icons/IconTrash";

import { toast } from "@/components/_ui/use-toast";
import { useNavigate } from "react-router-dom";

import IconParentObjective from "@/components/_icons/IconParentObjective";
import SetParentObjectiveDialog from "./objective-set-parent-dialog";
import IconChildObjective from "@/components/_icons/IconChildObjective";
import DeleteConfirmation from "@/components/delete-confirmation";
import { useDeleteObjective, useWorkspaceGetById } from "@/hooks/api/common";

interface ObjectiveEditDropdownProps extends PropsWithChildren {
  objective: {
    id: string;
    teamId: string;
    parentId?: string | null | undefined;
  };
  workspace: {
    id: string;
  };
  callbacks: {
    showCreateChildObjective: () => void;
  };
}
export const ObjectiveEditDropdown: FC<ObjectiveEditDropdownProps> = ({ objective, callbacks, ...props }) => {
  const navigate = useNavigate();
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });
  const [deleteConfirmationisOpen, setDeleteConfirmationisOpen] = useState(false);
  const { deleteObjective } = useDeleteObjective({
    teamId: objective.teamId,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });
  const [parentObjectiveDialogisOpen, setParentObjectiveDialogisOpen] = useState(false);

  function onUpdateCompleted() {
    toast({
      title: "Ziel gelöscht",
      description: "Wir haben das Ziel erfolgreich gelöscht.",
      variant: "success",
    });

    if (!workspace) {
      return;
    }

    navigate(`/${workspace.workspaceUrl}/teams/${objective.teamId}`);
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler beim Löschen des Zieles aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
      variant: "error",
    });
  }

  async function handleDeleteObjective() {
    deleteObjective({
      variables: {
        input: {
          id: objective.id,
        },
      },
    });
  }

  async function handleSetParentObjective() {
    setParentObjectiveDialogisOpen(true);
  }

  return (
    <>
      <Menubar className="shadow-none rounded-md">
        <MenubarMenu>
          <MenubarTrigger asChild>
            <Button variant="ghost" size="sm" className="select-none rounded-md">
              <IconDots />
            </Button>
          </MenubarTrigger>
          <MenubarContent className="rounded-md">
            <MenubarItem onClick={() => callbacks.showCreateChildObjective()}>
              <div className="flex flex-row items-center gap-1">
                <IconChildObjective className="mr-1 h-4 w-4 text-muted-foreground" />
                Teilziel hinzufügen
              </div>
            </MenubarItem>

            <MenubarItem onClick={() => handleSetParentObjective()}>
              <div className="flex flex-row items-center gap-1">
                <IconParentObjective className="mr-1 h-4 w-4 text-muted-foreground" />
                {!objective.parentId && <span>Übergeordnetes Ziel setzen</span>}
                {objective.parentId && <span>Übergeordnetes Ziel ändern</span>}
              </div>
            </MenubarItem>

            <MenubarSeparator />

            <MenubarItem
              onClick={() => {
                setDeleteConfirmationisOpen(true);
              }}
            >
              <div className="flex flex-row items-center gap-1">
                <IconTrash className="mr-2 h-3 w-3 text-muted-foreground" />
                Ziel löschen
              </div>
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>

      <SetParentObjectiveDialog
        open={parentObjectiveDialogisOpen}
        onOpenChange={setParentObjectiveDialogisOpen}
        objective={objective}
      />
      <DeleteConfirmation
        open={deleteConfirmationisOpen}
        onOpenChange={setDeleteConfirmationisOpen}
        onConfirm={() => {
          handleDeleteObjective();
        }}
        title={`Sind Sie sich sicher, dass Sie dieses Ziel löschen möchten?`}
        description={`Diese Aktion kann nicht rückgängig gemacht werden. Das Ziel und alle damit verbundenen Teilziele werden unwiderruflich gelöscht.`}
      />
    </>
  );
};
