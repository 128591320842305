import { LucideProps } from "lucide-react";

const Icon = ({ ...props }: LucideProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="dashes-horizontal"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <rect fill="currentColor" x="1" y="7.25" width="3" height="1.5" rx="0.5" opacity="0.9"></rect>
      <rect fill="currentColor" x="6" y="7.25" width="3" height="1.5" rx="0.5" opacity="0.9"></rect>
      <rect fill="currentColor" x="11" y="7.25" width="3" height="1.5" rx="0.5" opacity="0.9"></rect>
    </svg>
  );
};

export default Icon;
