const createImage = (url: string | ArrayBuffer): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url as string;
  });

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

function blobToFile(inputBlob: Blob, fileName: string): File {
  // eslint-disable-next-line
  const b: any = inputBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return inputBlob as File;
}

type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc: string | ArrayBuffer,
  pixelCrop: Area,
  rotation: number = 0,
): Promise<File> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Could not instantiate canvas context.");
  }

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // const maxSize = Math.max(image.width, image.height);
  // const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  return new Promise((resolve, reject) => {
    // setTimeout(() => {

    // As a blob

    canvas.toBlob(
      (blob) => {
        if (!blob) {
          return reject(new Error("Could not convert canvas to blob."));
        }

        // const anchor = document.createElement('a');
        // anchor.download = 'my-file-name.jpg'; // optional, but you can give the file a name
        // anchor.href = URL.createObjectURL(blob);
        // anchor.click(); // ✨ magic!
        // URL.revokeObjectURL(anchor.href);

        const file = blobToFile(blob, "profilePicture.jpg");

        resolve(file);
        // const image = URL.createObjectURL(file);
        // return resolve(image);
      },
      "image/jpeg",
      0.9,
    );
    // }, 100);
  });
}
