import { FC } from "react";

import { Icon, IconTypes } from "./icon.schema";
import { cn } from "@/lib/utils";

export interface IconViewProps {
  icon: Icon;
  className?: string;
}

export const IconView: FC<IconViewProps> = ({ icon, ...props }) => {
  if (icon.mode == IconTypes.emoji) {
    return (
      <div className={cn(["flex h-full w-full flex-row items-center justify-center text-lg", props.className])}>
        <span>{icon.value}</span>
      </div>
    );
  }
  // Todo implement displaying custom icons
  return <div {...props}> CI </div>;
};
