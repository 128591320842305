import { FC, useEffect, useRef, useState } from "react";

import { toast } from "@/components/_ui/use-toast";
import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { ColorPicker } from "@/components/color-picker";

import { useUpdateStatus } from "@/hooks/api/common/useStatus/useUpdateStatus";

import { StatusIcon } from "@/components/_domain/status";

export interface StatusFormEditProps {
  status: {
    id: string;
    name: string;
    color: string;
    type: string;
  };
  className?: string;
  onSuccess?: () => void;
  onCanceled?: () => void;
}

export const StatusFormEdit: FC<StatusFormEditProps> = ({ status, onSuccess, onCanceled }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [statusName, setStatusName] = useState(status.name);
  const [statusColor, setStatusColor] = useState(status.color);

  const { updateStatus } = useUpdateStatus({
    statusId: status.id,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  async function handleUpdateStatus() {
    if (statusName == "" || statusColor == "") {
      toast({
        title: "Fehler",
        description: "Bitte wählen Sie einen Name und eine Farbe aus.",
        variant: "error",
      });
      return;
    }

    await updateStatus({
      variables: {
        input: {
          id: status.id,
          name: statusName,
          color: statusColor,
          type: status.type,
        },
      },
    });
  }

  function onUpdateCompleted() {
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler bei der Aktualisierung des Status aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div className="flex w-full flex-row items-center justify-between gap-2 p-1">
      <div className="w-9">
        <ColorPicker
          asChild
          value={statusColor}
          onSelect={(color) => {
            setStatusColor(color);
          }}
        >
          <Button variant="outline" size="sm">
            <StatusIcon className="h-4 w-4" status={{ type: status.type, color: statusColor }} />
          </Button>
        </ColorPicker>
      </div>
      <div className="grow">
        <Input
          ref={ref}
          autoFocus
          value={statusName}
          onChange={(e) => setStatusName(e.currentTarget.value)}
          placeholder="Name des Status"
          className="bg-background ring-0 hover:ring-0 focus:ring-0 active:ring-0"
          style={{
            boxShadow: "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          }}
        />
      </div>
      <div>
        <Button variant="outline" size="sm" className="cursor-pointer" onClick={onCanceled}>
          Abbrechen
        </Button>
      </div>
      <div>
        <Button
          variant="default"
          size="sm"
          className="cursor-pointer"
          disabled={statusName == ""}
          onClick={handleUpdateStatus}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};

export default StatusFormEdit;
