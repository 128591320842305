import { FC, PropsWithChildren } from "react";

export const SidebarTopContent: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex flex-initial shrink-0 flex-col items-stretch gap-3 px-3 py-3">
      <div className="flex max-w-full shrink grow flex-row items-center">{children}</div>
      <div className="flex max-w-full shrink grow flex-row items-center"></div>
    </div>
  );
};
