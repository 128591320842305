import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { workspaceByIdQueryDocument } from "@/graphql/common";

export interface UseGetWorkspaceProps {
  id?: string;
}

export const useWorkspaceGetById = (props: UseGetWorkspaceProps) => {
  const queryResult = useQuery(workspaceByIdQueryDocument, { variables: { input: props.id ?? "" }, skip: !props.id });

  const workspace = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }
    return queryResult.data.workspaceById;
  }, [queryResult.data]);

  return {
    queryResult,
    workspace,
  };
};
