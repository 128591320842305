import { useEffect, useMemo } from "react";

import { useQuery } from "@apollo/client";
import { getObjectiveQueryDocument } from "@/graphql/common";
import { updateCacheFromObjectiveQueryResult } from "./_utils";

export interface UseGetObjectiveProps {
  id: string;
}

export const useGetObjective = ({ id }: UseGetObjectiveProps) => {
  const queryResult = useQuery(getObjectiveQueryDocument, {
    variables: { input: { id: id } },
  });

  const objective = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.objective;
  }, [queryResult.data]);

  useEffect(() => {
    if (!queryResult.data) {
      return;
    }
    // Note:
    // ------------------------------------
    // This is an expensive call (> 1-5ms) It will be repeated EVERY time an objective
    // is fetched (often). 
    // It should be called every time, the response come from the actual network, but
    // not, if it comes from the cache. However, we do not know when this is the case.
    // So right now, we call it every time. 
    //-------------------------------------
    updateCacheFromObjectiveQueryResult(queryResult.data.objective); // expensive

  }, [queryResult]);

  return {
    queryResult,
    objective,
  };
};
