import { useMutation } from "@apollo/client";
import { createTeamMutationDocument, teamsForUserInWorkspaceQueryDocument } from "@/graphql/common";
import { updateCacheWithNewTeam } from "./_utils";

type TeamFragment = {
  id: string;
};

export interface UseCreateTeamProps {
  workspaceId: string;
  onCompleted?: (o: TeamFragment | undefined) => void;
  onError?: (e: Error) => void;
}

export const useTeamCreate = ({ workspaceId, onCompleted, onError }: UseCreateTeamProps) => {
  const [createTeam] = useMutation(createTeamMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: teamsForUserInWorkspaceQueryDocument,
        variables: { input: workspaceId },
      },
    ],
    update: (cache, { data }) => {
      const team = data?.createTeam;
      if (!team) {
        return;
      }
      updateCacheWithNewTeam(cache, team);
    },
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const id = o?.createTeam?.id;

    if (onCompleted) {
      if (id) {
        onCompleted({ id: id });
      } else {
        onCompleted(undefined);
      }
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createTeam,
  };
};
