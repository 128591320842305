import { FC, useEffect, useMemo, useState } from "react";

import { cn } from "@/lib/utils";

import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";
import { Checkbox } from "@/components/_ui/checkbox";

import {
  FilterByTextList,
  FilterByTextListPayload,
  FilterByTextListPayloadSchema,
} from "@/components/_domain/objective/objective-list-overview/filter-functions";
import { useObjectiveListState } from "@/components/_domain/objective/hooks";

import { PriorityView } from "@/components/_domain/priority";

import { useGetPriorityListForTeamsInWorkspace } from "@/hooks/api/common";

export interface FilterByPriorityConfigurationViewProps {
  column: {
    id: string;
  };
  onFinished: () => void;
}

export const FilterByPriorityConfigurationView: FC<FilterByPriorityConfigurationViewProps> = ({
  onFinished,
  ...props
}) => {
  const columnId = props.column.id;
  const { table, columnFilters, config } = useObjectiveListState();
  const { priorityListOfUniqueNames: priorityList } = useGetPriorityListForTeamsInWorkspace({
    workspaceId: config.workspace.id,
    userId: config.user.id,
    teamList: config.teamList,
  });

  const [selectedPriorityNameList, setSelectedPriorityNameList] = useState<FilterByTextList | null>(null);

  const column = useMemo(() => {
    return table.getColumn(columnId);
  }, [table]);

  const filterValue = useMemo(() => {
    const filterValue = column?.getFilterValue();

    const safePayload = FilterByTextListPayloadSchema.safeParse(filterValue);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [column, columnFilters]);

  useEffect(() => {
    if (!filterValue) {
      return;
    }
    setSelectedPriorityNameList(filterValue.textList);
  }, []);

  useEffect(() => {
    if (!selectedPriorityNameList) {
      return;
    }

    if (selectedPriorityNameList.length == 0) {
      clearFilter();
      return;
    }

    setFilter({
      type: "filter_by_text_list",
      mode: filterValue?.mode ?? "included",
      textList: selectedPriorityNameList,
    });
  }, [selectedPriorityNameList]);

  const setFilter = (payload: FilterByTextListPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id != column.id));
  };

  const handleSelect = (priorityName: string | null, shouldFinish?: boolean) => {
    const selectedNameList = selectedPriorityNameList ?? [];
    const updatedNameList = selectedNameList.slice().filter((name) => name != priorityName);
    setSelectedPriorityNameList([...updatedNameList, priorityName]);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  const handleDeselect = (priorityName: string | null, shouldFinish?: boolean) => {
    const selectedNameList = selectedPriorityNameList ?? [];
    const updatedNameList = selectedNameList.slice().filter((name) => name != priorityName);
    setSelectedPriorityNameList(updatedNameList);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  return (
    <Command>
      <CommandInput placeholder={"Priority"} />
      <CommandList>
        <CommandGroup>
          {[null].map((_) => {
            const isSelected = !!selectedPriorityNameList && !!selectedPriorityNameList.includes(null);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={null}
                value={"Nicht zugewiesen"}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(null, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(null);
                  }}
                />
                <PriorityView priority={null} className="h-3.5 w-3.5 rounded-full" />
              </CommandItem>
            );
          })}

          {priorityList.map((priority) => {
            const isSelected =
              !!selectedPriorityNameList && !!selectedPriorityNameList.find((name) => name === priority.name);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={priority.id}
                value={priority.name}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(priority.name, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(priority.name);
                  }}
                />
                <PriorityView priority={priority} className="h-3.5 w-3.5" />
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
